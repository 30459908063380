import { joiResolver } from '@hookform/resolvers/joi';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import updateContactSchema from './update-contact-schema';

const defaultValues = (isAddToCompany: boolean) => ({
  phoneArr: [{ ext: '1', phone: '', type: 'Mobile', extPhone: '', country: 'US', fullPhone: '1', phoneClean: ''}],
  address1: '',
  address2: '',
  city: '',
  zipCode: '',
  state: '',
  country: '',
  isEmployee: isAddToCompany ? true : false,
  defaultAccess: false,
  email: '',
  firstName: '',
  middleName: '',
  lastName: '',
  suffix: '',
  prefix: '',
  contactTitle: '',
  userType: '',
  contactRole: '',
  venderArr: [],
  comments: '',
  dateOfBirthday: null,
  pictureFile: null,
});

export const useUpdateContactForm = ({ isAddToCompany, contact, open, onFieldChange }) => {

  const contactForm = useForm({
    defaultValues: defaultValues(isAddToCompany),
    resolver: joiResolver(updateContactSchema),
  });

  useEffect(() => {
    if (!open) {
      contactForm.reset();
    }

    if (open) {
      if (contact) contactForm.reset(contact,{ keepDefaultValues: true });
      else contactForm.reset();
    }
  }, [open]);

  useEffect(() => {
    const subscription = contactForm.watch((value, { name }) => {
      if (onFieldChange) {
        onFieldChange(name, value);
      }
    });
    return () => subscription.unsubscribe();
  }, [contactForm.watch, onFieldChange]);

  return contactForm;
};
