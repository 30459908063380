import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { getAllNotesAction, deleteNotesAction, pinNoteAction } from '../../actions/message';
import _ from 'lodash';
import { LightBoxComponent } from './LightBox.component';
import NoteEditor from '../Dialogs/note-editor-modal';
import { NoteView } from './RightSectionRender';
import NotePopUp from '../Common/NotePopUp';
import styles from './right-section.module.scss';
import ActivityHeader from './ActivityHeader.component';
import { appConstants } from '../../_constants';
import ReactLoading from 'react-loading';

const NotesTab = ({
  location,
  typeId,
  typeRef,
  allMessages,
  getAllNotesAction,
  deleteNotesAction,
  canFollowUp,
  isfetched,
  newNoteCreated
}) => {
  const [accountId] = useState(localStorage.getItem('accountId'));
  const [commentList, setCommentList] = useState([]);
  const [showEditNote, setShowEditNote] = useState(false);
  const [followUpNote, setFollowUpNote] = useState(false);
  const [lightBoxOpen, setLightBoxOpen] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [attachmentIndex, setAttachmentIndex] = useState(-1);
  const [noteAction, setNoteAction] = useState('NEW');
  const [noteObjectId, setNoteObjectId] = useState(null);
  const [showNoteDetailModal, setShowNoteDetailModal] = useState(false);
  const [showNoteDetailId, setShowNoteDetailId] = useState(null);
  const [filterText, setFilterText] = useState('');
  const [currentlyEditingNoteId, setCurrentlyEditingNoteId] = useState(null);
  const [sortDirection, setSortDirection] = useState('desc');
  const [filterType, setFilterType] = useState(['all']);

  const filterOptions = [
    {
      title: 'All notes',
      key: 'all',
      children: [
        { title: 'Regular notes', key: 'regular_notes' },
        { title: 'Follow-up notes', key: 'followup_notes' },
      ],
    },
  ];

  const projectFilterOptions = [
    {
      title: 'All notes',
      key: 'all',
      children: [
        { title: 'Regular notes', key: 'regular_notes' },
        { title: 'Follow-up notes', key: 'followup_notes' },
      ],
    },
  ];

  const companyFilterOptions = [
    {
      title: 'Company notes',
      key: 'all',
    },
    {
      title: 'Include employee notes',
      key: 'subNotes',
    }
  ];

  useEffect(() => {
    if (location.pathname.includes('notes/followUp')) {
      setFollowUpNote(true);
      setShowEditNote(true);
      
    }else if (location.pathname.includes('notes/view')) {

      const noteId = location.pathname.split('/').pop();
      setShowNoteDetailId(noteId);
      setShowNoteDetailModal(true);

    }else if (location.pathname.includes('notes/edit')) {
      const noteId = location.pathname.split('/').pop();
      setNoteObjectId(noteId);
      setNoteAction('EDIT');
      setShowEditNote(true);
      setCurrentlyEditingNoteId(noteId);

    
    }else if (location.pathname.includes('notes/add')) {
      setShowEditNote(true);
      window.scrollTo(0, 0);
    }

    getAllNotes();
  }, [location.pathname, typeId, typeRef]);
  
  useEffect(() => {
    if(!showEditNote && currentlyEditingNoteId){
      setCurrentlyEditingNoteId(null);
    }
  }, [showEditNote]);

  useEffect(() => {
    if (allMessages !== commentList && isfetched && isLoading) {
      setCommentList(allMessages);
      setIsLoading(false);
      applyFilters();
    }
  }, [allMessages, isfetched]);

  const formatNoteAttachments = (selectedNote) => {
    return selectedNote?.attachments?.map((attachment) => ({
      ...attachment,
      description:
        selectedNote.title + '\n' + selectedNote.description?.replace(/(<([^>]+)>)/gi, ''),
    }));
  };

  const [isLoading, setIsLoading] = useState(true);

  const handleSearch = (value) => {
    setFilterText(value);
  };

  const handleSort = (direction) => {
    setSortDirection(direction);
    // Implement sorting logic here
    const sortedNotes = sortNotes([...commentList], direction);
    setCommentList(sortedNotes);
  };

  const handleFilter = (filters) => {
    setFilterType(filters);
  };

  useEffect(() => {
    applyFilters();
  }, [filterText, filterType, sortDirection, commentList]);

  const [filteredList, setFilteredList] = useState([]);

  const applyFilters = () => {
    if (!commentList) return;
    let filtered = commentList.filter((item) => {
      const matchesText =
        !(filterText?.length > 0) ||
        true ||
        item?.title?.toLowerCase().includes(filterText.toLowerCase()) ||
        item?.description?.toLowerCase().includes(filterText.toLowerCase());
      const matchesType =
        (filterType.includes('all')&&!item.originName) || // all original items
        (filterType.includes('subNotes') && item.originName) || // all replies
        (item.classType && filterType.includes(item.classType.toLowerCase().replace('cpp', ''))) ||
        filterType.includes(item.itemType);
      return matchesText && matchesType;
    });

    filtered = sortNotes(filtered, sortDirection);
    setFilteredList(filtered);
  };

  const handleAddNew = () => {
    showEditNoteCallback();
  };

  
  const sortNotes = (items, direction) => {
    // Helper function to sort items
    const sortItems = (a, b) => {
      if (a.isPinned !== b.isPinned) return b.isPinned ? 1 : -1;
      if (a.isPinned && b.isPinned) return new Date(b.pinDate) - new Date(a.pinDate);
      const dateComparison = new Date(b.createdAt) - new Date(a.createdAt);
      return direction === 'asc' ? -dateComparison : dateComparison;
    };

    // Group items by threads
    const threadMap = new Map();
    const rootItems = [];

    items.forEach((item) => {
      if (item.replyTo) {
        if (!threadMap.has(item.replyTo)) {
          threadMap.set(item.replyTo, []);
        }
        threadMap.get(item.replyTo).push(item);
      } else {
        rootItems.push(item);
      }
    });

    // Sort root items
    rootItems.sort(sortItems);

    // Function to recursively sort and flatten threads
    const sortAndFlattenThread = (item) => {
      const result = [item];
      if (threadMap.has(item.objectId)) {
        const replies = threadMap.get(item.objectId);
        replies.sort(sortItems);
        replies.forEach((reply) => {
          result.push(...sortAndFlattenThread(reply));
        });
      }
      return result;
    };

    // Sort and flatten all threads
    const sortedItems = rootItems.map((item) => sortAndFlattenThread(item)).flat();

    return sortedItems;
  };

  const getAllNotes = () => {
    const data = {
      accountId,
      typeId: typeId,
      typeRef: typeRef,
      page: 0,
      displayLimit: 99999999,
    };
    getAllNotesAction(data);
    setIsLoading(true);
  };

  const cancelDelete = (noteData, confirm) => async () => {
    const confirmed =
      (await confirm?.({
        title: 'Delete note',
        question: 'Are you sure you want to Delete this note?',
      })) ?? true;

    if (!confirmed) {
      return;
    }

    const formData = {
      objectId: noteData.objectId,
      typeRef: noteData.typeRef,
      typeId: noteData.typeId,
    };

    await deleteNotesAction(formData);
    getAllNotes();
  };

  const editNote = (data) => {
    const objectId = data.objectId;
    setNoteObjectId(objectId);

    setNoteAction('EDIT');
    setShowEditNote(true);
    setCurrentlyEditingNoteId(objectId);

  };

  const replyToNote = (noteData) => {
    const noteObjectId = noteData.objectId;
    setNoteObjectId(noteObjectId);

    setNoteAction('REPLY');
    setShowEditNote(true);
  };

  const pinNote = async (noteData, shouldPin=true) => {
    const pinData = {
      noteId: noteData.objectId,
      typeRef: noteData.typeRef,
      typeId: noteData.typeId,
      shouldPin: shouldPin,
    };
    await pinNoteAction(pinData);
    getAllNotes();
    
  };

  const showEditNoteCallback = () => {
    setShowEditNote(true);
    setNoteObjectId('');
    setNoteAction('NEW');
  };

  const showEditNoteCallbackFollowUp = () => {
    setFollowUpNote(true);
    setShowEditNote(true);
    setNoteObjectId('');
    setNoteAction('NEW');
  };

  const closeWindowCallback = () => {
    setShowEditNote(false);
    setNoteObjectId('');
    setNoteAction('NEW');
  };

  const showHideLightBox = (note, attachmentIndex) => {
    setLightBoxOpen(!lightBoxOpen);
    setSelectedNote(note);
    setAttachmentIndex(attachmentIndex);
  };

  const closeDetailModal = () => {
    setShowNoteDetailModal(false);
    setShowNoteDetailId(null);
  };


  const commentListData =
    (filteredList?.length >=0)?filteredList.map((v) => (
        <NoteView
          noteData={v}
          editNote={editNote}
          replyToNote={replyToNote}
          cancelDelete={cancelDelete}
          showHideLightBox={showHideLightBox}
          pinNote={pinNote}
          filterText={filterText}
          isEditing={currentlyEditingNoteId === v.objectId}
        ></NoteView>
      )
    ) : (
      <div className='outputBox scheduleHtml'>No note activities</div>
    );

  const showEditNoteComponent = showEditNote || noteObjectId;

  const canFollowUpLocal = canFollowUp||location.pathname.includes('notes/followUp');

  const newButton =<button className='btn btn-primary d-flex p-1 pe-2 ps-2' onClick={showEditNoteCallback}>
  Add Note
</button>

  const additionalButtons = canFollowUp ? [
    <div> {newButton}
    <div key="add-followup" className="btn-group">
      <button
        type="button"
        className="btn btn-secondary dropdown-toggle dropdown-toggle-split"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span className="visually-hidden">Toggle Dropdown</span>
      </button>
      <ul className="dropdown-menu">
        <li>
          <a className="dropdown-item" href="#" onClick={showEditNoteCallbackFollowUp}>
            Add follow-up note
          </a>
        </li>
      </ul>
    </div>
    </div>
  ] : [newButton];

  useEffect(() => {
   if(newNoteCreated && !isLoading){
      getAllNotes();
   }
  }, [newNoteCreated]);

  const isCompany = typeRef === 'COMPANY';
  const isProject = typeRef === 'PROJECT'||typeRef === 'LEAD';


  return (
    <div>
       {!showEditNote ? (
        <div className="container">
          <div className="row pt-3 pb-2">
            <div className="col ps-0 pe-2">
              <ActivityHeader
                onSearch={handleSearch}
                onSort={handleSort}
                onFilter={handleFilter}
                onAddNew={handleAddNew}
                canFilter={isCompany}
                defaultFilter={['all']}
                addNewLabel="Add Note"
                filterOptions={isCompany?companyFilterOptions:projectFilterOptions}
                sortDirection={sortDirection}
                additionalButtons={additionalButtons}
              />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <br />
          <NoteEditor
            showEditNote={showEditNote}
            typeId={typeId}
            typeRef={typeRef}
            objectId={noteObjectId}
            noteAction={noteAction}
            closeWindowCallback={closeWindowCallback}
            followUpNote={followUpNote}
          />
        </div>
      )}
       {isLoading ? (
        <ReactLoading
          className='table-loader'
          type={appConstants.LOADER_TYPE}
          color={appConstants.LOADER_COLOR}
          height={appConstants.LOADER_HEIGHT}
          width={appConstants.LOADER_WIDTH}
        />
      ) : commentListData.length > 0 ? (
      <Scrollbars style={{ height: 'calc(100vh)', minHeight:'500px'}}>{commentListData}</Scrollbars>
      ):
      <div className='p-2 ps-4'>No notes found</div>}
      
      <LightBoxComponent
        attachments={formatNoteAttachments(selectedNote) ?? []}
        shouldOpen={lightBoxOpen}
        initialIndex={attachmentIndex}
        closeCallback={showHideLightBox}
      />
      {showNoteDetailModal && showNoteDetailId && (
        <NoteView
          noteId={showNoteDetailId}
          typeId={typeId}
          typeRef={typeRef}
          asModal={true}
          editNote={editNote}
          replyToNote={replyToNote}
          cancelDelete={cancelDelete}
          showHideLightBox={showHideLightBox}
          toggle={closeDetailModal}
          showModal={true}
        ></NoteView>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { message } = state;
  const { allMessage, isfetched } = message;
  return {
    allMessages: allMessage && allMessage.length ? allMessage : [],
    isfetched: isfetched,
    newNoteCreated: message.isSaveM,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getAllNotesAction,
    deleteNotesAction,
    pinNoteAction,
  })(NotesTab)
);
