import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import DatePickerWrapper from '../../shared/datepicker-wrapper/datepicker-wrapper';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getExistingProjectAction } from '../../actions/project';
import { getAllJobStatusAction } from '../../actions/jobStatusCode';
import { updateProjectStatusAction } from '../../actions/projectStatus';
import { getReasonCodesByStatusCodeAction } from '../../actions/reasonCode';
import { getAccountSettingsAction } from '../../actions/accountSettings';
import { selectGeneralInfo } from '../../selectors/accountSettings';
import { getProjectStageByCode } from '../../shared/custom-fields-controls/constants';
import { isCurrentOrPrevStage } from '../../shared/custom-fields-controls/constants';
import { numberToCurrency } from '../../utils/number-formatters';
import {
  dateValidation,
  dateFormatMMDDYYYYtime,
  dateFormatMMDDYYYY,
  formatDateAdd,
  formatDateObject,
  formatDateObjectOrNull,
  formatDateObjectWithTime,
  formatDateUTC,
} from '../../utils/date-formatters';
import { appConstants, isAllowed, moduleConstants } from '../../_constants';
import { ChangeEventDateModal, QuickHistoryModal } from '../Dialogs';

const styleData = { color: 'red' };

let Validator = require('validatorjs');
let rules = {
  //jobStatusCode: 'required',
  //coNumber: 'required',
  //coCostAmount: 'required',
  //profitPercentage: 'required',
};

let mess = {
  required: 'This field is required',
  alpha: 'This field may only contain letters',
  numeric: 'This field must be a number',
};

let validation = [];

validation = new Validator([], rules, mess);
validation.passes();
validation.fails();

let errorMsg = '';
let susErrorMsg = '';
let startDateErrMsg = '';

let modalCanInitialize = true;

class ProjectStatusModal extends React.Component {
  constructor(props) {

    super(props);
    this.state = {
      userId: localStorage.getItem('userId'),
      objectId: '',
      projectId: '',
      accountId: localStorage.getItem('accountId'),
      modalOpen: false,
      initialUpdateType: null,
      selectDateBox: {},
      reasonCodes: {},
      selectedStatusReason: { label: '', value: '' },
      selectedStartDateReason: { label: '', value: '' },
      selectedEndDateReason: { label: '', value: '' },
      selectedStatusBox: {},
      statusUpdateProject: {},
      startDateReasonReq: false,
      endDateReasonReq: false,
      statusReasonRequired: false,
      statusChangeReasonCodes: [],
      dateChangeReasonCodes: [],
      isPromoted: false,
      reasonWithStatus: {
        statusReasons: [],
        dateReasons: [],
        projectStatus: {},
      },
      currentProjectStatus: {},
      projectData: {},
      allProjectStatus: [],
      projectStatusUpdate: [],
      projectStatusHistoryModal: false,
      buttonActive: false,
      allJobStatusCodes: [],
      formArr: [],
      eventDateModal: false,
      eventDateData: {},
      updateType: null,
      nextStatusCode: '',
      nextStatusId: '',
      generalInfo: {},
      statusUpdateData: {},
    };

    this.onDiscardStatusUpdate = this.props.onDiscardStatusUpdate?.bind(this);
  }


  componentDidMount() {
    this.props.getAccountSettingsAction();
    this.props.getAllJobStatusAction({
      className: 'jobStatusCodes',
      accountId: this.state.accountId,
    });

    if ((!this.props.projectData?.objectId) && this.props.updateType?.length > 0) {
      this.toggleStatusUpdateReset();
    }
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef && childRef(undefined);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { childRef } = this.props;
    childRef && childRef(this);
  }

  showRowComponent(fieldName) {

    const { formItemOptions, hideClearedFields } = this.state;

    if (formItemOptions && !!Object.getOwnPropertyDescriptor(formItemOptions, fieldName)) {

      const itemExists = formItemOptions[fieldName] || {};
      if (itemExists?.show) {
        const isClearedLocked = (itemExists.lock && itemExists.clear);
        return (!(hideClearedFields && isClearedLocked));
      }
    } else {
      return false;
    }


  }

  shouldComponentUpdate(nextProps, nextState) {
    if (((!nextProps.projectId && this.state.projectId == '' && this.props.projectData?.objectId) || nextProps.projectId == this.props.projectData?.objectId) && nextProps.initialUpdateType && modalCanInitialize) {
      const currentProjectStatus =
        nextProps.allProjectStatus?.allUsedJobStatus?.find(
          ({ objectId }) => this.props.projectData.jobStatusCodes?.objectId === objectId || this.props.projectData.jobStatusCodes === objectId
        ) ?? {};

      const lastProjectStatus =
        nextProps.allProjectStatus?.allUsedJobStatus?.find(
          ({ objectId }) => this.props.projectData?.prevJobStatus === objectId
        ) ?? {};

      const isServiceProject = currentProjectStatus.jobStatusCodesCode === 'SVC' || currentProjectStatus.jobStatusCodesCode === 'SVC-D' || currentProjectStatus.jobStatusCodesCode === 'SVC-C';

      modalCanInitialize = false;

      let updateType = this.state.isPromoted ? 'PromotedLead' : nextProps.initialUpdateType;
      if (!updateType) {
        updateType = 'None';
      }


      let nextStatusOptionCodes = [];
      let defaultNextStatusCode = [];

      let formItemOptions = {};

      formItemOptions['bidDue'] = { show: false, lock: false, clear: false, required: false };
      formItemOptions['bidStart'] = { show: false, lock: false, clear: false, required: false };
      formItemOptions['bidSubmittedDate'] = {
        show: false,
        lock: false,
        clear: false,
        required: false,
      };
      formItemOptions['responseReceivedDate'] = {
        show: false,
        lock: false,
        clear: false,
        required: false,
      };

      formItemOptions['baselineStartDate'] = {
        show: false,
        lock: true,
        clear: false,
        required: false,
      };
      formItemOptions['baselineEndDate'] = {
        show: false,
        lock: true,
        clear: false,
        required: false,
      };

      formItemOptions['currentBaselineEndDate'] = {
        show: false,
        lock: true,
        clear: false,
        required: false,
      };

      formItemOptions['originalGrossProfit'] = {
        show: false,
        lock: true,
        clear: false,
        required: false,
      };
      formItemOptions['originalContractAmount'] = {
        show: false,
        lock: true,
        clear: false,
        required: false,
      };

      formItemOptions['effectiveDate'] = {
        show: false,
        lock: false,
        clear: true,
        required: false,
      };

      formItemOptions['startDate'] = { show: true, lock: true, clear: false, required: true };
      formItemOptions['endDate'] = { show: true, lock: true, clear: false, required: true };

      formItemOptions['deliveredDate'] = {
        show: false,
        lock: false,
        clear: false,
        required: false,
      };
      formItemOptions['closeoutDate'] = { show: false, lock: false, clear: false, required: false };

      formItemOptions['currentContractAmount'] = { show: false, lock: true, clear: false, required: false };
      formItemOptions['currentGrossProfit'] = { show: false, lock: true, clear: false, required: false };

      formItemOptions['ntpDate'] = { show: false, lock: false, clear: false, required: false };
      formItemOptions['resumeFromHoldDate'] = {
        show: false,
        lock: false,
        clear: true,
        required: false,
      };
      formItemOptions['suspendStartDate'] = {
        show: false,
        lock: false,
        clear: false,
        required: false,
      };
      formItemOptions['suspendEndDate'] = {
        show: false,
        lock: false,
        clear: false,
        required: false,
      };

      let hideCurrentFields = updateType === 'Submitted'; // hide prior value column
      const hideStatusRow = (updateType === 'UpdateWIP' || updateType === 'UpdateOriginalContract');
      const hideStatusChangeReasonRow = (updateType === 'UpdateWIP' || updateType === 'UpdateOriginalContract');

      let modalTitle = 'Update Project Status';

      const setOriginalContract =
        updateType === 'Approved' || updateType === 'UpdateOriginalContract';

      const canChangeStatus = !(
        updateType === 'UpdateOriginalContract' ||
        updateType === 'PromotedLead' ||
        updateType === 'UpdateWIP'
      );

      const { requireNTP, enableEstimatingScheduling, restrictContractAdjustments } = nextProps.generalInfo;

      if (updateType === 'Submitted') {
        formItemOptions['bidDue'] = { show: true, lock: true, clear: false, required: false };
        formItemOptions['bidStart'] = { show: false, lock: true, clear: false, required: false };
        formItemOptions['bidSubmittedDate'] = {
          show: true,
          lock: false,
          clear: false,
          required: true,
        };

        if (moment(this.props.projectData.bidDue?.iso).isBefore(moment())) {
          this.props.projectData.bidSubmittedDate = this.props.projectData.bidDue;
        } else {
          this.props.projectData.bidSubmittedDate = { type: 'date', iso: moment().toISOString() };
        }


        modalTitle = 'Mark as submitted';

        if (currentProjectStatus.jobStatusCodesCode === 'B') {
          nextStatusOptionCodes = ['BP', 'P1P', 'P2P', 'P3P'];
          defaultNextStatusCode = ['BP'];
        } else if (
          currentProjectStatus.jobStatusCodesCode === 'P1' ||
          currentProjectStatus.jobStatusCodesCode === 'P2' ||
          currentProjectStatus.jobStatusCodesCode === 'P3'
        ) {
          nextStatusOptionCodes = ['P1P', 'P2P', 'P3P'];
          if (currentProjectStatus.jobStatusCodesCode === 'P1') {
            defaultNextStatusCode = ['P1P'];
          } else if (currentProjectStatus.jobStatusCodesCode === 'P2') {
            defaultNextStatusCode = ['P2P'];
          } else if (currentProjectStatus.jobStatusCodesCode === 'P3') {
            defaultNextStatusCode = ['P3P'];
          }
        }
      } else if (updateType === 'Rescinded') {
        formItemOptions['bidDue'] = { show: true, lock: false, clear: true, required: true };
        formItemOptions['bidStart'] = { show: true, lock: false, clear: true, required: true };
        formItemOptions['bidSubmittedDate'] = {
          show: true,
          lock: true,
          clear: true,
          required: false,
        };
        formItemOptions['responseReceivedDate'] = {
          show: false,
          lock: false,
          clear: true,
          required: false,
        };
        modalTitle = 'Mark as rescinded';
        hideCurrentFields = true;

        formItemOptions['startDate'] = { show: true, lock: false, clear: false, required: true };
        formItemOptions['endDate'] = { show: true, lock: false, clear: false, required: true };

        if (currentProjectStatus.jobStatusCodesCode === 'BP') {
          nextStatusOptionCodes = ['B', 'R', 'NC'];
          defaultNextStatusCode = ['R'];
        } else if (currentProjectStatus.jobStatusCodesCode === 'P1P') {
          nextStatusOptionCodes = ['P1', 'R', 'NC'];
          defaultNextStatusCode = ['R'];
        } else if (currentProjectStatus.jobStatusCodesCode === 'P2P') {
          nextStatusOptionCodes = ['P2', 'R', 'NC'];
          defaultNextStatusCode = ['R'];
        } else if (currentProjectStatus.jobStatusCodesCode === 'P3P') {
          nextStatusOptionCodes = ['P3', 'R', 'NC'];
          defaultNextStatusCode = ['R'];
        }
      } else if (updateType === 'Rebid' || updateType === 'DirectRebid') {
        const isDirect = updateType === 'DirectRebid';
        hideCurrentFields = true;
        formItemOptions['bidDue'] = { show: true, lock: false, clear: true, required: true };
        formItemOptions['bidStart'] = { show: true, lock: false, clear: true, required: false };
        formItemOptions['bidSubmittedDate'] = {
          show: isDirect,
          lock: !isDirect,
          clear: true,
          required: false,
        };
        modalTitle = 'Mark as rebid';
        formItemOptions['responseReceivedDate'] = {
          show: false,
          lock: false,
          clear: true,
          required: false,
        };
        formItemOptions['startDate'] = { show: true, lock: false, clear: false, required: true };
        formItemOptions['endDate'] = { show: true, lock: false, clear: false, required: true };
        if (currentProjectStatus.jobStatusCodesCode === 'BP') {
          nextStatusOptionCodes = ['B'];
          defaultNextStatusCode = ['B'];
        } else if (currentProjectStatus.jobStatusCodesCode === 'P1P') {
          nextStatusOptionCodes = ['P1'];
          defaultNextStatusCode = ['P1'];
        } else if (currentProjectStatus.jobStatusCodesCode === 'P2P') {
          nextStatusOptionCodes = ['P2'];
          defaultNextStatusCode = ['P2'];
        } else if (currentProjectStatus.jobStatusCodesCode === 'P3P') {
          nextStatusOptionCodes = ['P3'];
          defaultNextStatusCode = ['P3'];
        } else if (isDirect) {
          nextStatusOptionCodes = [currentProjectStatus.jobStatusCodesCode];
          defaultNextStatusCode = [currentProjectStatus.jobStatusCodesCode];
        }
      } else if (updateType === 'Lost') {
        modalTitle = 'Mark as lost';
        hideCurrentFields = true;
        formItemOptions['bidDue'] = { show: true, lock: true, clear: true, required: false };
        formItemOptions['bidStart'] = { show: true, lock: true, clear: true, required: false };
        formItemOptions['bidSubmittedDate'] = {
          show: true,
          lock: true,
          clear: true,
          required: false,
        };
        formItemOptions['responseReceivedDate'] = {
          show: true,
          lock: false,
          clear: true,
          required: false,
        };

        nextStatusOptionCodes = ['XC'];
        defaultNextStatusCode = ['XC'];
      } else if (updateType === 'DirectLost') {
        modalTitle = 'Mark as lost';
        hideCurrentFields = true;

        if (moment(this.props.projectData.bidDue?.iso).isBefore(moment())) {
          this.props.projectData.bidSubmittedDate = this.props.projectData.bidDue;
        } else {
          this.props.projectData.bidSubmittedDate = { type: 'date', iso: moment().toISOString() };
        }

        formItemOptions['bidDue'] = { show: false, lock: false, clear: true, required: false };
        formItemOptions['bidStart'] = { show: false, lock: true, clear: true, required: false };
        formItemOptions['bidSubmittedDate'] = {
          show: true,
          lock: false,
          clear: false,
          required: true,
        };
        formItemOptions['responseReceivedDate'] = {
          show: true,
          lock: false,
          clear: true,
          required: false,
        };

        nextStatusOptionCodes = ['XC'];
        defaultNextStatusCode = ['XC'];
      }
      else if (updateType === 'Closed') {
        hideCurrentFields = true;
        modalTitle = 'Mark as not-bid';
        formItemOptions['bidDue'] = { show: true, lock: true, clear: true, required: false };
        formItemOptions['bidStart'] = { show: true, lock: true, clear: true, required: false };
        formItemOptions['bidSubmittedDate'] = {
          show: true,
          lock: true,
          clear: true,
          required: false,
        };
        formItemOptions['responseReceivedDate'] = {
          show: false,
          lock: false,
          clear: true,
          required: false,
        };

        nextStatusOptionCodes = ['NC', 'R'];
        defaultNextStatusCode = ['NC'];
      } else if (updateType === 'Approved') {
        modalTitle = 'Mark as awarded';
        formItemOptions['bidDue'] = { show: true, lock: false, clear: true, required: true };
        formItemOptions['bidStart'] = { show: true, lock: false, clear: true, required: true };
        formItemOptions['bidSubmittedDate'] = {
          show: true,
          lock: true,
          clear: true,
          required: false,
        };
        formItemOptions['ntpDate'] = { show: false, lock: false, clear: false, required: false };
        formItemOptions['responseReceivedDate'] = {
          show: true,
          lock: false,
          clear: true,
          required: false,
        };

        formItemOptions['currentContractAmount'] = {
          show: true,
          lock: true,
          clear: false,
          required: false,
        };

        formItemOptions['currentGrossProfit'] = {
          show: true,
          lock: true,
          clear: false,
          required: false,
        };
        formItemOptions['startDate'] = { show: true, lock: false, clear: false, required: true };
        formItemOptions['endDate'] = { show: true, lock: false, clear: false, required: true };

        if (currentProjectStatus.jobStatusCodesCode === 'BP') {
          defaultNextStatusCode = ['A'];
          nextStatusOptionCodes = ['P1', 'P2', 'P3', 'A', 'SNS'];
        } else if (currentProjectStatus.jobStatusCodesCode === 'P1P') {
          defaultNextStatusCode = ['P2'];
          nextStatusOptionCodes = ['P2', 'P3', 'A', 'SNS'];
        } else if (currentProjectStatus.jobStatusCodesCode === 'P2P') {
          defaultNextStatusCode = ['P3'];
          nextStatusOptionCodes = ['P3', 'A', 'SNS'];
        } else if (currentProjectStatus.jobStatusCodesCode === 'P3P') {
          formItemOptions['ntpDate'] = { show: true, lock: false, clear: false, required: true };
          defaultNextStatusCode = ['A'];
          nextStatusOptionCodes = ['A', 'SNS'];

          this.props.projectData.originalContractAmount = this.props.projectData.currentContractAmount;
          this.props.projectData.originalGrossProfit = this.props.projectData.currentGrossProfit;

          formItemOptions['baselineStartDate'] = {
            show: true,
            lock: false,
            clear: false,
            required: true,
          };
          formItemOptions['baselineEndDate'] = {
            show: true,
            lock: false,
            clear: false,
            required: true,
          };

          formItemOptions['originalGrossProfit'] = {
            show: true,
            lock: false,
            clear: false,
            required: true,
          };
          formItemOptions['originalContractAmount'] = {
            show: true,
            lock: false,
            clear: false,
            required: true,
          };
        }
      } else if (updateType === 'DirectPromote') {
        modalTitle = 'Directly promote as awarded';

        if (moment(this.props.projectData.bidDue?.iso).isBefore(moment())) {
          this.props.projectData.bidSubmittedDate = this.props.projectData.bidDue;
        } else {
          this.props.projectData.bidSubmittedDate = { type: 'date', iso: moment().toISOString() };
        }

        formItemOptions['bidDue'] = { show: true, lock: false, clear: true, required: true };
        formItemOptions['bidStart'] = { show: true, lock: false, clear: true, required: true };
        formItemOptions['bidSubmittedDate'] = {
          show: true,
          lock: false,
          clear: false,
          required: true,
        };
        formItemOptions['ntpDate'] = { show: false, lock: false, clear: false, required: false };
        formItemOptions['responseReceivedDate'] = {
          show: true,
          lock: false,
          clear: true,
          required: false,
        };

        formItemOptions['currentContractAmount'] = {
          show: true,
          lock: true,
          clear: false,
          required: false,
        };

        formItemOptions['currentGrossProfit'] = {
          show: true,
          lock: true,
          clear: false,
          required: false,
        };
        formItemOptions['startDate'] = { show: true, lock: false, clear: false, required: true };
        formItemOptions['endDate'] = { show: true, lock: false, clear: false, required: true };

        if (currentProjectStatus.jobStatusCodesCode === 'B') {
          defaultNextStatusCode = ['A'];
          nextStatusOptionCodes = ['P1', 'P2', 'P3', 'A', 'SNS'];
        } else if (currentProjectStatus.jobStatusCodesCode === 'P1') {
          defaultNextStatusCode = ['P2'];
          nextStatusOptionCodes = ['P2', 'P3', 'A', 'SNS'];
        } else if (currentProjectStatus.jobStatusCodesCode === 'P2') {
          defaultNextStatusCode = ['P3'];
          nextStatusOptionCodes = ['P3', 'A', 'SNS'];
        } else if (currentProjectStatus.jobStatusCodesCode === 'P3') {
          formItemOptions['ntpDate'] = { show: true, lock: false, clear: false, required: true };
          defaultNextStatusCode = ['A'];
          nextStatusOptionCodes = ['A', 'SNS'];

          this.props.projectData.originalContractAmount = this.props.projectData.currentContractAmount;
          this.props.projectData.originalGrossProfit = this.props.projectData.currentGrossProfit;

          formItemOptions['baselineStartDate'] = {
            show: true,
            lock: false,
            clear: false,
            required: true,
          };
          formItemOptions['baselineEndDate'] = {
            show: true,
            lock: false,
            clear: false,
            required: true,
          };

          formItemOptions['originalGrossProfit'] = {
            show: true,
            lock: false,
            clear: false,
            required: true,
          };
          formItemOptions['originalContractAmount'] = {
            show: true,
            lock: false,
            clear: false,
            required: true,
          };
        }
      }
      else if (updateType === 'On Hold') {
        modalTitle = 'Place project on hold';
        nextStatusOptionCodes = ['OH', 'PC'];
        if (currentProjectStatus.jobStatusCodesCode === 'B') {
          defaultNextStatusCode = ['OH'];
        } else if (currentProjectStatus.jobStatusCodesCode === 'P1') {
          defaultNextStatusCode = ['PC'];
        } else if (currentProjectStatus.jobStatusCodesCode === 'P2') {
          defaultNextStatusCode = ['PC'];
        } else if (currentProjectStatus.jobStatusCodesCode === 'P3') {
          defaultNextStatusCode = ['PC'];
        } else if (currentProjectStatus.jobStatusCodesCode === 'BP') {
          defaultNextStatusCode = ['OHP'];
          nextStatusOptionCodes = ['OHP'];
        } else if (currentProjectStatus.jobStatusCodesCode === 'P1P') {
          defaultNextStatusCode = ['OHP'];
          nextStatusOptionCodes = ['OHP', 'PC'];
        } else if (currentProjectStatus.jobStatusCodesCode === 'P2P') {
          defaultNextStatusCode = ['OHP'];
          nextStatusOptionCodes = ['OHP', 'PC'];
        } else if (currentProjectStatus.jobStatusCodesCode === 'P3P') {
          defaultNextStatusCode = ['OHP'];
          nextStatusOptionCodes = ['OHP', 'PC'];
        }
        formItemOptions['resumeFromHoldDate'] = {
          show: true,
          lock: false,
          clear: true,
          required: false,
        };

      } else if (updateType === 'UpdateOriginalContract') {
        modalTitle = 'Manage original contract';
        // check if is SNS or A, only allow return to SNS or A
        if (currentProjectStatus?.jobStatusCodesCode) {
          nextStatusOptionCodes = [currentProjectStatus.jobStatusCodesCode];
          defaultNextStatusCode = [currentProjectStatus.jobStatusCodesCode];
        }

        formItemOptions['startDate'] = {
          show: false,
          lock: false,
          clear: false,
          required: false,
        };
        formItemOptions['endDate'] = {
          show: false,
          lock: false,
          clear: false,
          required: false,
        };

        formItemOptions['baselineStartDate'] = {
          show: true,
          lock: false,
          clear: false,
          required: true,
        };
        formItemOptions['baselineEndDate'] = {
          show: true,
          lock: false,
          clear: false,
          required: true,
        };

        formItemOptions['originalGrossProfit'] = {
          show: true,
          lock: false,
          clear: false,
          required: true,
        };
        formItemOptions['originalContractAmount'] = {
          show: true,
          lock: false,
          clear: false,
          required: true,
        };
      } else if (updateType === 'PromotedLead') {
        modalTitle = 'Promote lead';
        nextStatusOptionCodes = ['B'];
        defaultNextStatusCode = ['B'];
        formItemOptions['bidDue'] = { show: true, lock: false, clear: true, required: true };
        formItemOptions['bidStart'] = { show: true, lock: false, clear: true, required: true };
        formItemOptions['startDate'] = { show: true, lock: false, clear: false, required: true };
        formItemOptions['endDate'] = { show: true, lock: false, clear: false, required: true };
      } else if (updateType === 'Delivered') {

        this.props.projectData.deliveredDate = { type: 'date', iso: moment().toISOString() };

        modalTitle = 'Delivered project update';
        formItemOptions['deliveredDate'] = {
          show: true,
          lock: false,
          clear: false,
          required: true,
        };
        formItemOptions['startDate'] = { show: true, lock: true, clear: false, required: false };
        formItemOptions['endDate'] = { show: true, lock: true, clear: false, required: false };
        formItemOptions['currentBaselineEndDate'] = { show: true, lock: true, clear: false, required: false };

        if (currentProjectStatus.jobStatusCodesCode === 'A') {
          nextStatusOptionCodes = ['D', 'W', 'C'];
          defaultNextStatusCode = ['D'];
        }

        if (isServiceProject) {
          nextStatusOptionCodes = ['SVC-D', 'SVC-C'];
          defaultNextStatusCode = ['SVC-D'];
        }
      } else if (updateType === 'SNS') {
        modalTitle = 'Move to sold, not started';
        formItemOptions['startDate'] = { show: true, lock: true, clear: false, required: true };
        formItemOptions['endDate'] = { show: true, lock: true, clear: false, required: true };
        formItemOptions['ntpDate'] = { show: true, lock: false, clear: false, required: false };

        if (currentProjectStatus.jobStatusCodesCode === 'A') {
          nextStatusOptionCodes = ['SNS'];
          defaultNextStatusCode = ['SNS'];
        }
      } else if (updateType === 'ProceedToActive') {
        modalTitle = 'Mark as active / notice to proceed received';
        formItemOptions['startDate'] = { show: true, lock: true, clear: false, required: true };
        formItemOptions['endDate'] = { show: true, lock: true, clear: false, required: true };
        formItemOptions['ntpDate'] = { show: true, lock: false, clear: false, required: true };

        if (currentProjectStatus.jobStatusCodesCode === 'SNS') {
          nextStatusOptionCodes = ['A'];
          defaultNextStatusCode = ['A'];
        }
      } else if (updateType === 'Suspended') {
        modalTitle = 'Suspend project';
        formItemOptions['startDate'] = { show: true, lock: true, clear: false, required: false };
        formItemOptions['endDate'] = { show: true, lock: true, clear: false, required: false };
        formItemOptions['ntpDate'] = { show: false, lock: false, clear: false, required: false };

        formItemOptions['currentContractAmount'] = {
          show: false,
          lock: false,
          clear: false,
          required: false,
        };

        nextStatusOptionCodes = ['SUSP'];
        defaultNextStatusCode = ['SUSP'];
        formItemOptions['suspendStartDate'] = {
          show: true,
          lock: false,
          clear: true,
          required: true,
        };
        formItemOptions['suspendEndDate'] = {
          show: true,
          lock: false,
          clear: true,
          required: false,
        };
      } else if (updateType === 'Closeout') {
        modalTitle = 'Closeout project';
        //this.props.projectData.closeoutDate = { type: 'date', iso: moment().toISOString() };
        formItemOptions['startDate'] = { show: true, lock: true, clear: false, required: true };
        formItemOptions['endDate'] = { show: true, lock: true, clear: false, required: true };
        formItemOptions['deliveredDate'] = {
          show: true,
          lock: false,
          clear: false,
          required: true,
        };
        formItemOptions['ntpDate'] = { show: false, lock: false, clear: false, required: false };

        if (!isServiceProject) {
          nextStatusOptionCodes = ['W', 'C'];
          defaultNextStatusCode = ['W'];
        } else {
          nextStatusOptionCodes = ['SVC-C'];
          defaultNextStatusCode = ['SVC-C'];
        }

        if(!this.props.projectData.deliveredDate){
          this.props.projectData.deliveredDate = { type: 'date', iso: moment().toISOString() };
        }
        
        this.props.projectData.closeoutDate = { type: 'date', iso: moment().toISOString() };
        

      } else if (updateType === 'Unsuspend') {
        // Check last status, return to last status
        modalTitle = 'Unsuspend project';
        formItemOptions['startDate'] = { show: true, lock: true, clear: false, required: true };
        formItemOptions['endDate'] = { show: true, lock: true, clear: false, required: true };
        formItemOptions['ntpDate'] = { show: true, lock: false, clear: false, required: false };

        if (lastProjectStatus?.jobStatusCodesCode) {
          nextStatusOptionCodes = [lastProjectStatus.jobStatusCodesCode];
          defaultNextStatusCode = [lastProjectStatus.jobStatusCodesCode];
        } else {
          nextStatusOptionCodes = ['A', 'SNS'];
          defaultNextStatusCode = ['A'];
        }
        formItemOptions['suspendStartDate'] = {
          show: true,
          lock: false,
          clear: false,
          required: true,
        };
        formItemOptions['suspendEndDate'] = {
          show: true,
          lock: false,
          clear: false,
          required: true,
        };
      } else if (updateType === 'ResumeFromHold') {
        // Check last status, return to last status
        modalTitle = 'Resume project from hold';
        formItemOptions['bidSubmittedDate'] = {
          show: true,
          lock: true,
          clear: false,
          required: false,
        };

        formItemOptions['startDate'] = { show: true, lock: true, clear: false, required: true };
        formItemOptions['endDate'] = { show: true, lock: true, clear: false, required: true };

        if (currentProjectStatus.jobStatusCode === 'OHP') {
          formItemOptions['bidDue'] = { show: true, lock: true, clear: false, required: false };
          formItemOptions['bidStart'] = { show: true, lock: true, clear: false, required: false };
        } else {
          formItemOptions['bidDue'] = { show: true, lock: false, clear: false, required: true };
          formItemOptions['bidStart'] = { show: true, lock: false, clear: false, required: true };
        }
        if (lastProjectStatus?.jobStatusCodesCode) {
          nextStatusOptionCodes = [lastProjectStatus.jobStatusCodesCode];
          defaultNextStatusCode = [lastProjectStatus.jobStatusCodesCode];
        } else if (currentProjectStatus.jobStatusCodesCode === 'OH') {
          nextStatusOptionCodes = ['B'];
          defaultNextStatusCode = ['B'];
        } else if (currentProjectStatus.jobStatusCodesCode === 'PC') {
          nextStatusOptionCodes = ['P1', 'P2', 'P3'];
          defaultNextStatusCode = [''];
        } else {
          nextStatusOptionCodes = ['B', 'P1', 'P2', 'P3'];
          defaultNextStatusCode = [''];
        }

        formItemOptions['resumeFromHoldDate'] = {
          show: true,
          lock: false,
          clear: false,
          required: true,
        };
      } else if (updateType === 'reopenProject') {
        modalTitle = 'Reopen bidding project';
        // Check last status, return to last status

        formItemOptions['bidSubmittedDate'] = {
          show: true,
          lock: true,
          clear: false,
          required: false,
        };

        formItemOptions['startDate'] = { show: true, lock: true, clear: false, required: true };
        formItemOptions['endDate'] = { show: true, lock: true, clear: false, required: true };

        if (currentProjectStatus.jobStatusCode === 'OHP') {
          formItemOptions['bidDue'] = { show: true, lock: true, clear: false, required: false };
          formItemOptions['bidStart'] = { show: true, lock: true, clear: false, required: false };
        } else {
          formItemOptions['bidDue'] = { show: true, lock: false, clear: false, required: true };
          formItemOptions['bidStart'] = { show: true, lock: false, clear: false, required: true };
        }
        if (lastProjectStatus?.jobStatusCodesCode) {
          if (currentProjectStatus.jobStatusCodesCode === 'R' || currentProjectStatus.jobStatusCodesCode === 'XC') {
            nextStatusOptionCodes = ['B', 'P1', 'P2', 'P3'];
            defaultNextStatusCode = ['B'];
          } else {
            nextStatusOptionCodes = [lastProjectStatus.jobStatusCodesCode];
            defaultNextStatusCode = [lastProjectStatus.jobStatusCodesCode];
          }

        } else if ( lastProjectStatus?.jobStatusCodesCode === 'SUSP'){
          nextStatusOptionCodes = ['SNS','A'];
          defaultNextStatusCode = ['SNS'];
        }
         else if (currentProjectStatus.jobStatusCodesCode === 'OH') {
          nextStatusOptionCodes = ['B'];
          defaultNextStatusCode = ['B'];
        } else if (currentProjectStatus.jobStatusCodesCode === 'PC') {
          nextStatusOptionCodes = ['P1', 'P2', 'P3'];
          defaultNextStatusCode = [''];
        } else {
          nextStatusOptionCodes = ['B', 'P1', 'P2', 'P3'];
          defaultNextStatusCode = [''];
        }

        
      } else if (updateType === 'UpdateWIP') {
        modalTitle = 'Update project progress';
        // Check last status, return to last status

        formItemOptions['startDate'] = { show: true, lock: false, clear: false, required: true };

        if(currentProjectStatus.jobStatusCodesCode === 'D' || currentProjectStatus.jobStatusCodesCode === 'W'|| currentProjectStatus.jobStatusCodesCode === 'C') {
          formItemOptions['endDate'] = { show: false, lock: false, clear: false, required: false };
          formItemOptions['deliveredDate'] = { show: true, lock: false, clear: false, required: true };
        }
        else{
          formItemOptions['endDate'] = { show: true, lock: false, clear: false, required: true };
        }
        formItemOptions['baselineStartDate'] = { show: true, lock: true, clear: false, required: false };
        formItemOptions['baselineEndDate'] = { show: true, lock: true, clear: false, required: false };
        formItemOptions['currentBaselineEndDate'] = { show: true, lock: restrictContractAdjustments, clear: false, required: false };

        formItemOptions['effectiveDate'] = { show: true, lock: false, clear: false, required: true };

        formItemOptions['currentContractAmount'] = {
          show: true,
          lock: restrictContractAdjustments,
          clear: false,
          required: false,
        };

        formItemOptions['currentGrossProfit'] = {
          show: true,
          lock: false,
          clear: false,
          required: false,
        };

        if (currentProjectStatus?.jobStatusCodesCode) {
          nextStatusOptionCodes = [currentProjectStatus.jobStatusCodesCode];
          defaultNextStatusCode = [currentProjectStatus.jobStatusCodesCode];
        }
      } else if (updateType === 'Terminated') {
        modalTitle = 'Mark as terminated';
        formItemOptions['bidDue'] = { show: false, lock: true, clear: true, required: false };
        formItemOptions['bidStart'] = { show: true, lock: true, clear: false, required: false };
        formItemOptions['bidSubmittedDate'] = {
          show: false,
          lock: true,
          clear: true,
          required: false,
        };
        formItemOptions['responseReceivedDate'] = {
          show: true,
          lock: false,
          clear: true,
          required: false,
        };

        nextStatusOptionCodes = ['XT'];
        defaultNextStatusCode = ['XT'];
      } else if (updateType === 'OverrideStatus') {
        modalTitle = 'Override project status';
        nextStatusOptionCodes = this.props.allJobStatusCodes.map(
          (jstat) => jstat.jobStatusCodesCode
        );
        defaultNextStatusCode = [currentProjectStatus.jobStatusCodesCode];
      } else {
        defaultNextStatusCode = [currentProjectStatus.jobStatusCodesCode];
      }

      let nextStatusOptions;
      if (!nextStatusOptionCodes || nextStatusOptionCodes?.length == 0) {
        nextStatusOptions = this.props.allJobStatusCodes;
      } else {
        nextStatusOptions = this.props.allJobStatusCodes.filter((jsc) =>
          nextStatusOptionCodes.includes(jsc.jobStatusCodesCode)
        );
      }

      if (nextStatusOptions) {
        nextStatusOptions.sort((a, b) => (a.jobStatusSortIndex > b.jobStatusSortIndex ? 1 : -1));
      }

      const isResumeType = (updateType === 'ResumeFromHold') || (updateType === 'Unsuspend');
      if (nextStatusOptions && !isResumeType) {
        nextStatusOptions = nextStatusOptions.filter((jsc) =>
          isAllowed(moduleConstants[jsc.permissionsGroupName], 'assigned',
            this.props.projectData?.userAssignData)
        );
      }

      let defaultNextStatus = this.props.allJobStatusCodes.filter((jsc) =>
        defaultNextStatusCode.includes(jsc.jobStatusCodesCode)
      );


      if (defaultNextStatus?.length === 1) {
        defaultNextStatus = defaultNextStatus[0];
      }

      if (defaultNextStatus && !hideStatusRow && !isAllowed(moduleConstants[defaultNextStatus.permissionsGroupName], 'assigned', this.props.projectData?.userAssignData)) {
        if (nextStatusOptions?.length > 0) {
          defaultNextStatus = nextStatusOptions[0];
          defaultNextStatusCode = defaultNextStatus.jobStatusCodesCode;
        } else {
          defaultNextStatus = null;
          defaultNextStatusCode = [];
        }
      }


      let allStatusIds = this.props.allJobStatusCodes.map((jsc) => jsc.objectId);
      let allStatusCodes = this.props.allJobStatusCodes.map((jsc) => jsc.jobStatusCodesCode);

      let statusNameLookup = {};
      for (let jsIdx in allStatusIds) {
        statusNameLookup[allStatusIds[jsIdx]] = allStatusCodes[jsIdx];
      }


      const hideClearedFields = true; // hide cleared values

      formItemOptions['ntpDate'].required = requireNTP;

      if (!enableEstimatingScheduling) {
        formItemOptions['bidStart'].show = false;
        formItemOptions['bidStart'].required = false;
      }

      this.setState({
        statusUpdateData: this.props.statusUpdateData,
        projectData: this.props.projectData,
        reasonCodes: this.props.reasonCodes.allReasonCode,
        objectId: this.props.projectData.objectId,
        projectId: this.props.projectData.objectId,
        allProjectStatus: this.props.allProjectStatus?.allJobStatus,
        allJobStatusCodes: this.props.allJobStatusCodes,
        nextStatusOptions: nextStatusOptions,
        defaultNextStatus: defaultNextStatus,
        statusNameLookup: statusNameLookup,
        currentProjectStatus,
        formItemOptions: formItemOptions,
        updateType: updateType,
        modalOpen: false,
        setOriginalContract: setOriginalContract,
        canChangeStatus: canChangeStatus,
        hideCurrentFields: hideCurrentFields,
        hideClearedFields: hideClearedFields,
        hideStatusRow: hideStatusRow,
        modalTitle: modalTitle,
        initialUpdateType: null,
        generalInfo: nextProps.generalInfo,
        statusReasonRequired: !hideStatusChangeReasonRow
      });

      setTimeout(this.toggleStatusUpdate, 250);
    }

    else if(nextProps.projectId !== this.state.projectId &&nextProps.projectId !== this.props.projectData?.objectId && nextProps.initialUpdateType ==='UpdateWIP' && !this.state.updateType) {
      this.props.getExistingProjectAction({ projectId: nextProps.projectId });
      this.setState( {projectId: nextProps.projectId, initialUpdateType: 'UpdateWIP' });
    }
      
    if (nextState.updateSubmitted && nextProps.projectStatusResult) {
      this.toggleStatusUpdateReset();
      modalCanInitialize = true;
      this.setState({ updateSubmitted: false });
      this.props.onAfterUpdate?.();

      const biddingStates = ['B', 'P1', 'P2', 'P3'];
      const skipStatuses = ['OH'];
      const isNextBidding = biddingStates.includes(nextProps.projectStatusUpdate?.jobStatusCodesCode);
      const shouldSkip = skipStatuses.includes(this.state.projectData?.jobStatusCodesCode);
      if (isNextBidding && !shouldSkip) {
        this.props.onAfterPromoteToBidding?.();
      }
    }
    
    if ((nextProps.allReasonCodeByStatusCode?.data && this.state.statusChangeReasonCodes.length !== nextProps.allReasonCodeByStatusCode?.data?.length)
      || (nextProps.allReasonCodeByStatusCode?.data?.length > 0 && this.state.statusChangeReasonCodes.length == nextProps.allReasonCodeByStatusCode?.data?.length &&
        nextProps.allReasonCodeByStatusCode?.data[0].objectId !== this.state.statusChangeReasonCodes[0].value)) {
      {
        const statusChangeReasonCodes = nextProps.allReasonCodeByStatusCode?.data?.map((item) => {
          return {
            label: item.reasonName,
            value: item.objectId,
            statusCode: item.jobStatusCodes,
          };
        }) ?? [];
        this.setState({ statusChangeReasonCodes });
      }
    }

    if (nextProps.allReasonCodeByStatusCode?.dateChange?.length > 0 && this.state.dateChangeReasonCodes.length == 0) {
      const dateChangeReasonCodes = nextProps.allReasonCodeByStatusCode?.dateChange?.map((item) => {
        return {
          label: item.reasonName,
          value: item.objectId,
        };
      }) ?? [];
      this.setState({ dateChangeReasonCodes: dateChangeReasonCodes });
    }

    if (_.isEmpty(nextProps.statusUpdateData) && !_.isEmpty(this.state.statusUpdateData)) {
      this.setState({ statusUpdateData: {} });
    }

    if (!_.isEmpty(nextProps.statusUpdateData) && _.isEmpty(this.state.statusUpdateData)) {
      this.setState({ statusUpdateData: nextProps.statusUpdateData });
    }

    return true;
  }

  findArrayElementById = (array, objectId) => {
    return array.find((element) => {
      return element.objectId === objectId;
    });
  };

  toggleStatusUpdate = (modal_name) => {
    const isOpening = !this.state.modalOpen;
    if (!isOpening) {
      modalCanInitialize = true;
    }

    let { projectData } = this.state;

    let statusUpdateProject = Object.assign({}, projectData);
    statusUpdateProject['accountId'] = this.state.accountId;
    statusUpdateProject['userId'] = this.state.userId;
    statusUpdateProject['projectId'] = statusUpdateProject.objectId
      ? statusUpdateProject.objectId
      : this.state.projectId;

    const formItemOptions = this.state.formItemOptions;

    let selectDateBox = this.state.selectDateBox;

    const updateType = this.state.updateType;

    const dateList = [
      'startDate',
      'endDate',
      'bidStart',
      'bidDue',
      'bidSubmittedDate',
      'ntpDate',
      'responseReceivedDate',
      'closeoutDate',
      'resumeFromHoldDate',
      'suspendStartDate',
      'suspendEndDate',
      'deliveredDate',
      'baselineStartDate',
      'baselineEndDate',
      'currentBaselineEndDate',
      'effectiveDate',
    ];

    if (!statusUpdateProject['baselineStartDate']?.iso) {
      statusUpdateProject['baselineStartDate'] = statusUpdateProject['startDate'];
    }
    if (!statusUpdateProject['baselineEndDate']?.iso) {
      statusUpdateProject['baselineEndDate'] = statusUpdateProject['endDate'];
    }
    if (!statusUpdateProject['currentBaselineEndDate']?.iso) {
      statusUpdateProject['currentBaselineEndDate'] = statusUpdateProject['baselineEndDate'];
    }

    for (let dIdx in dateList) {
      let dateType = dateList[dIdx];
      selectDateBox[dateType] =
        !formItemOptions[dateType]?.clear && statusUpdateProject[dateType]?.iso
          ? statusUpdateProject[dateType].iso
          : '';

      statusUpdateProject[dateType] = !formItemOptions[dateType]?.clear && selectDateBox[dateType];
    }

    if (updateType === 'DirectPromote' || updateType === 'Submitted' || updateType === 'DirectLost' || updateType === 'DirectRebid') {
      if (!statusUpdateProject['bidSubmittedDate']) {
        statusUpdateProject['bidSubmittedDate'] = statusUpdateProject['bidDue'];
      }
    }

    if (!(formItemOptions['resumeFromHoldDate']?.clear)) {
      selectDateBox['resumeFromHoldDate'] = formatDateObject();
      //statusUpdateProject['resumeFromHoldDate']=formatDateObject();
    } else {
      //statusUpdateProject['resumeFromHoldDate']=null;
    }

    statusUpdateProject['comment'] = '';
    this.setState({
      nextStatusCode:
        this.state.currentProjectStatus && this.state.currentProjectStatus['jobStatusCodesCode'],
    });

    let dateErrorMsg = '';
    if (
      (this.state.currentProjectStatus && this.state.currentProjectStatus['jobStatusCodesCode']) ===
      'D'
    ) {
      //dateErrorMsg = dateValidation(formatDateObject(selectDateBox["startDate"]).add(1, "day"), formatDateObject(selectDateBox["endDate"]), formatDateObject() )
      dateErrorMsg = dateValidation(
        formatDateObject(selectDateBox['startDate']),
        formatDateObject(selectDateBox['endDate']),
        formatDateObject()
      );
      if (dateErrorMsg === 'start') {
        startDateErrMsg = 'Delivered should be greater than start date.';
      } else if (dateErrorMsg === 'end') {
        selectDateBox['endDate'] = formatDateUTC();
        statusUpdateProject['endDate'] = formatDateUTC();
        //this.setState({selectDateBox });
      } else {
        startDateErrMsg = '';
      }
    } else {
      dateErrorMsg = '';
      startDateErrMsg = '';
    }

    this.processWIPProgressUpdate(statusUpdateProject, selectDateBox);

    this.setState(
      {
        statusUpdateData: {},
        selectDateBox: selectDateBox,
        statusUpdateProject: statusUpdateProject,
        modalOpen: !this.state.modalOpen,
        successUploading1: true,
        errorUploading1: true,
        startDateReasonReq: false,
        endDateReasonReq: false,
        selectedStartDateReason: { label: '', value: '' },
        selectedEndDateReason: { label: '', value: '' },
        selectedStatusReason: {},
        isPromoted: false,
      },
      this.props.toggle?.()
    );

    if (this.state.defaultNextStatus) {
      this.handleStatusChange({
        label: this.state.defaultNextStatus.jobStatusCodesName,
        value: this.state.defaultNextStatus.objectId,
      });
    }
  };

  processWIPProgressUpdate = (statusUpdateProject, selectDateBox) => {
    const { statusUpdateData, projectData } = this.state;

    if (_.isEmpty(statusUpdateData)) {
      return;
    }

    if (statusUpdateData.isCO) {
      const { scheduleImpact, profitDollars, coCostAmount } = statusUpdateData;
      const endDateObject = moment(selectDateBox.endDate);

      if (endDateObject?.isValid()) {
        endDateObject.add(scheduleImpact, 'days');
        const endDateString = endDateObject.toISOString();

        selectDateBox.endDate = endDateString;
        statusUpdateProject.endDate = endDateString;
      }

      statusUpdateProject.currentContractAmount = projectData.currentContractAmount + coCostAmount + profitDollars;
      statusUpdateProject.currentGrossProfit = projectData.currentGrossProfit + profitDollars;
    } else {
      const { startDateChange, endDateChange, totalChange, profitChange } = statusUpdateData;
      const startDateObject = moment(selectDateBox.startDate)
      const endDateObject = moment(selectDateBox.endDate);

      startDateObject.add(startDateChange, 'days');
      const startDateString = startDateObject.toISOString();

      selectDateBox.startDate = startDateString;
      statusUpdateProject.startDate = startDateString;

      endDateObject.add(endDateChange, 'days');
      const endDateString = endDateObject.toISOString();

      selectDateBox.endDate = endDateString;
      statusUpdateProject.endDate = endDateString;

      if (startDateObject?.isValid()) {
        const startDateString = startDateObject.toISOString();

        selectDateBox.startDate = startDateString;
        statusUpdateProject.startDate = startDateString;
      }

      if (endDateObject?.isValid()) {
        const endDateString = endDateObject.toISOString();

        selectDateBox.endDate = endDateString;
        statusUpdateProject.endDate = endDateString;
      }

      statusUpdateProject.currentContractAmount = statusUpdateProject.currentContractAmount + totalChange;
      statusUpdateProject.currentGrossProfit = statusUpdateProject.currentGrossProfit + profitChange;
    }

    this.onDiscardStatusUpdate();
  };

  toggleStatusUpdateReset = (modal_name) => {
    if (this.state.updateSubmitted && this.state.projectId !== '') {
      this.props.getExistingProjectAction({ projectId: this.state.projectId });
    }

    this.setState({
      formArr: [],
    });
    if (!this.state.modalOpen) {
      var formArr = this.state.formArr;
      if (this.state.formArr && this.state.formArr.currentContractAmount === 'TT') {
        if (rules['currentContractAmount'] === 'required') {
          delete rules['currentContractAmount'];
          delete formArr['currentContractAmount'];
        }

        this.setState({
          formArr: formArr,
        });
      }
    }
    modalCanInitialize = true;
    this.setState({
      modalOpen: false,
      updateType: null,
      updateSubmitted: false,
      nextStatusCode: null,
      selectedStatusReason: {},
    });
  };

  handleDatePickerBidWithTime = (name, date) => {
    this.setState((prevState) => ({
      statusUpdateProject: {
        ...prevState.statusUpdateProject,
        [name]: date,
      },
      selectDateBox: {
        ...prevState.selectDateBox,
        [name]: date,
      },
    }));
  };

  submitUpdateProject = (e, newProjectData, updateType = 'Unknown') => {
    const { statusUpdateProject, projectData, selectDateBox, formItemOptions } = this.state;
    let isDataValid = true;

    if (newProjectData) {
      statusUpdateProject.startDate = newProjectData.startDate;
      statusUpdateProject.endDate = newProjectData.endDate;
      statusUpdateProject.baselineStartDate = newProjectData.baselineStartDate;
      statusUpdateProject.baselineEndDate = newProjectData.baselineEndDate;
      statusUpdateProject.currentBaselineEndDate = newProjectData.baselineEndDate;
      statusUpdateProject.currentContractAmount = newProjectData.originalContractAmount;
      statusUpdateProject.currentGrossProfit = newProjectData.originalGrossProfit;
      statusUpdateProject.currentContractProfit = newProjectData.originalGrossProfit;
      statusUpdateProject.originalContractAmount = newProjectData.originalContractAmount;
      statusUpdateProject.originalGrossProfit = newProjectData.originalGrossProfit;
    }

    if (
      formItemOptions?.['deliveredDate'].required &&
      this.state.nextStatusCode === appConstants.jobStatusCode.D &&
      !selectDateBox.deliveredDate
    ) {
      rules.deliveredDate = 'required';
      isDataValid = false;

      this.setState((prevState) => ({
        formArr: {
          ...prevState.formArr,
          deliveredDate: 'TT',
        },
      }));
    }


    if (
      formItemOptions?.currentContractAmount.required &&
      (statusUpdateProject.currentContractAmount === '' ||
        statusUpdateProject.currentContractAmount === 0) &&
      isAllowed(
        moduleConstants.EBAGP,
        projectData?.userAssignData?.length ?? 0,
        projectData.userAssignData
      )
    ) {

      rules.currentContractAmount = 'required';
      isDataValid = false;

      this.setState((prevState) => ({
        formArr: {
          ...prevState.formArr,
          currentContractAmount: 'TT',
        },
      }));
    }

    if (isDataValid) {
      let formdata = {};

      formdata = {
        projectId: this.state.projectId,
        userId: this.state.userId,
        accountId: this.state.accountId,
        statusReasonCode: this.state.selectedStatusReason.label,
        startDateReasonCode: this.state.selectedStartDateReason.label,
        endDateReasonCode: this.state.selectedEndDateReason.label,

        currentContractAmount: parseFloat(statusUpdateProject.currentContractAmount),
        currentGrossProfit: parseFloat(statusUpdateProject.currentGrossProfit),
        currentContractProfit: parseFloat(statusUpdateProject.currentContractProfit),

        originalContractAmount: parseFloat(statusUpdateProject.originalContractAmount),
        originalGrossProfit: parseFloat(statusUpdateProject.originalGrossProfit),

        jobStatusCodes: this.state.reasonWithStatus['projectStatus'].objectId,
        changeOrderReasonCode: '',
        comment: statusUpdateProject.comment,
        eventDate: formatDateUTC(),
        effectiveDate: formatDateUTC(this.state.selectDateBox.effectiveDate),
      };

      const dateList = [
        'startDate',
        'endDate',
        'bidStart',
        'bidDue',
        'bidSubmittedDate',
        'ntpDate',
        'responseReceivedDate',
        'closeoutDate',
        'resumeFromHoldDate',
        'suspendStartDate',
        'suspendEndDate',
        'deliveredDate',

        'baselineStartDate',
        'baselineEndDate',
        'currentBaselineEndDate',
      ];

      for (let dIdx in dateList) {
        if (statusUpdateProject[dateList[dIdx]]?.iso) {
          statusUpdateProject[dateList[dIdx]] = statusUpdateProject[dateList[dIdx]].iso;
        }
        formdata[dateList[dIdx]] = statusUpdateProject[dateList[dIdx]];
      }


      if (
        this.state.nextStatusCode === appConstants.jobStatusCode.D ||
        this.state.nextStatusCode === appConstants.jobStatusCode.W ||
        this.state.nextStatusCode === appConstants.jobStatusCode.C
      ) {
        if (statusUpdateProject && !statusUpdateProject.deliveredDate) {
          formdata['deliveredDate'] = formatDateUTC();
        }
      }

      formdata['updateType'] = this.state.updateType ?? updateType;

      this.props.updateProjectStatusAction(formdata, this.props);

      this.props.onAfterSubmit?.();

      modalCanInitialize = true;

      this.setState({ updateSubmitted: true });
    }
  };

  handleStatusChange(e) {
    let selectedStatus = '';

    let selectedStatusBox = this.state.selectedStatusBox;

    let statusNameLookup = this.state.statusNameLookup;

    const { updateType, projectData, statusUpdateProject } = this.state;


    let formItemOptions = this.state.formItemOptions;

    if (!e.value) {
      return;
    }

    selectedStatus = this.findArrayElementById(this.state.allProjectStatus, e.value);
    this.setState({ nextStatusCode: selectedStatus.jobStatusCodesCode, nextStatusId: selectedStatus.objectId });

    let reasonWithStatus = this.state.reasonWithStatus;
    reasonWithStatus['projectStatus'] = selectedStatus;

    //*if (this.state.currentProjectStatus['jobStatusCodesName'] === e.label) {
    // statusReasonRequired = false;


    if (statusNameLookup[e.value] === 'A' || statusNameLookup[e.value] === 'SNS') {
      if (updateType === 'Approved' || updateType === 'DirectPromote') {
        formItemOptions.bidDue = { show: true, clear: true, required: false, lock: true };
        formItemOptions.bidStart = { show: true, clear: true, required: false, lock: true };

        if (updateType === 'DirectPromote') {
          formItemOptions.bidSubmittedDate = { show: true, clear: true, required: true, lock: false };
        }
        else {
          formItemOptions.bidSubmittedDate = { show: true, clear: true, required: false, lock: true };
        }

        if (statusNameLookup[e.value] === 'A') {
          formItemOptions.ntpDate = { show: true, clear: false, required: true, lock: false };
        } else {
          formItemOptions.ntpDate = { show: false, clear: false, required: false, lock: false };
        }



        statusUpdateProject.originalContractAmount = projectData.currentContractAmount;
        statusUpdateProject.originalGrossProfit = projectData.currentGrossProfit;

        formItemOptions.startDate = { show: false, clear: false, required: false, lock: true };
        formItemOptions.endDate = { show: false, clear: false, required: false, lock: true };


        this.setState({ statusUpdateProject, setOriginalContract: true });

        formItemOptions['baselineStartDate'] = {
          show: false,
          lock: false,
          clear: false,
          required: false,
        };
        formItemOptions['baselineEndDate'] = {
          show: false,
          lock: false,
          clear: false,
          required: false,
        };

        formItemOptions['originalGrossProfit'] = {
          show: false,
          lock: false,
          clear: false,
          required: false,
        };
        formItemOptions['originalContractAmount'] = {
          show: false,
          lock: false,
          clear: false,
          required: false,
        };

        formItemOptions['currentContractAmount'] = {
          show: false,
          lock: false,
          clear: false,
          required: false,
        };
        formItemOptions['currentGrossProfit'] = {
          show: false,
          lock: false,
          clear: false,
          required: false,
        };
      } else {
        formItemOptions.bidDue = { show: false, clear: true, required: false, lock: true };
        formItemOptions.bidStart = { show: false, clear: true, required: false, lock: true };
        formItemOptions.bidSubmittedDate = {
          show: false,
          clear: true,
          required: false,
          lock: true,
        };

        if (updateType === 'ProceedToActive') {
          formItemOptions.ntpDate = { show: true, clear: false, required: true, lock: false };
        }
      }
    } else if (updateType === 'Approved' || updateType === 'DirectPromote') {
      formItemOptions.bidDue = { show: true, clear: true, required: true, lock: false };
      formItemOptions.bidStart = { show: true, clear: true, required: false, lock: false };
      if (updateType === 'DirectPromote') {
        formItemOptions.bidSubmittedDate = { show: true, clear: true, required: true, lock: false };
      }
      else {
        formItemOptions.bidSubmittedDate = { show: true, clear: true, required: false, lock: true };
      }
      formItemOptions.ntpDate = { show: false, clear: false, required: false, lock: false };

      formItemOptions.startDate = { show: true, clear: false, required: false, lock: false };
      formItemOptions.endDate = { show: true, clear: false, required: false, lock: false };

      formItemOptions['baselineStartDate'] = {
        show: false,
        lock: true,
        clear: false,
        required: false,
      };
      formItemOptions['baselineEndDate'] = {
        show: false,
        lock: true,
        clear: false,
        required: false,
      };

      formItemOptions['originalGrossProfit'] = {
        show: false,
        lock: true,
        clear: false,
        required: false,
      };
      formItemOptions['originalContractAmount'] = {
        show: false,
        lock: true,
        clear: false,
        required: false,
      };

      this.setState({ setOriginalContract: false });
    }

    if (statusNameLookup[e.value] === 'NC' || statusNameLookup[e.value] === 'XC' || statusNameLookup[e.value] === 'R') {
      if (updateType === 'Rescinded' || updateType === 'Lost' || updateType === 'Closed') {
        formItemOptions.bidDue = { show: true, clear: true, required: false, lock: true };
        formItemOptions.bidStart = { show: true, clear: true, required: false, lock: true };
        formItemOptions.bidSubmittedDate = { show: true, clear: true, required: false, lock: true };
      }
    } else if (updateType === 'Rescinded') {
      formItemOptions.bidDue = { show: true, clear: true, required: false, lock: false };
      formItemOptions.bidStart = { show: true, clear: true, required: false, lock: false };
      formItemOptions.bidSubmittedDate = { show: true, clear: true, required: false, lock: true };
    }

    if (statusNameLookup[e.value] === 'W' || statusNameLookup[e.value] === 'C') {
      formItemOptions.closeoutDate = { show: true, clear: true, required: true, lock: false };
    } else {
      formItemOptions.closeoutDate = { show: false, clear: false, required: false, lock: false };

    }

    let dateErrorMsg = '';
    if (updateType === 'Delivered' || statusNameLookup[e.value] === 'D' || updateType === 'Closeout') {
      formItemOptions.deliveredDate = { show: true, clear: true, required: true, lock: false };
      if (updateType === 'UpdateWIP') {
        formItemOptions.endDate = { show: false, clear: false, required: false, lock: false };
      }
      selectedStatusBox['projectStatus'] = { value: e.value, label: e.label };
      this.setState({ selectedStatusBox });
      let { statusUpdateProject, selectDateBox } = this.state;
      dateErrorMsg = dateValidation(
        formatDateObject(this.state.selectDateBox.startDate),
        formatDateObject(this.state.selectDateBox.endDate),
        formatDateObject()
      );

      if (dateErrorMsg === 'start') {
        startDateErrMsg = 'Delivered should be greater than start date.';
      } else if (dateErrorMsg === 'end') {
        selectDateBox['endDate'] = formatDateUTC();
        statusUpdateProject['endDate'] = formatDateUTC();
        this.setState({ statusUpdateProject, selectDateBox });
      } else {
        startDateErrMsg = '';
      }
    } else {
      selectedStatusBox['projectStatus'] = { value: e.value, label: e.label };
      dateErrorMsg = '';
      startDateErrMsg = '';
      formItemOptions.deliveredDate.show = false;
    }

    let sendDataArr = {};
    sendDataArr['accountId'] = this.state.accountId;
    sendDataArr['projectId'] = this.state.projectId;
    sendDataArr['jobStatusCodes'] = selectedStatus.objectId;
    this.props.getReasonCodesByStatusCodeAction(sendDataArr);
    this.setState({
      statusChangeReasonCodes: [],
    })

    const projectStage = getProjectStageByCode(selectedStatus.jobStatusCodesCode);
    const { requireNTP, enableEstimatingScheduling, requireConstructionDatesOn } = this.state.generalInfo;
    const requireConstructionDates = isCurrentOrPrevStage(projectStage, requireConstructionDatesOn);

    formItemOptions['ntpDate'].required = requireNTP;

    formItemOptions['startDate'].required = requireConstructionDates;
    formItemOptions['endDate'].required = requireConstructionDates;

    formItemOptions['baselineStartDate'].required = requireConstructionDates;
    formItemOptions['baselineEndDate'].required = requireConstructionDates;

    if (!enableEstimatingScheduling) {
      formItemOptions['bidStart'].show = false;
      formItemOptions['bidStart'].required = false;
    }

    this.setState({
      reasonWithStatus: reasonWithStatus,
      selectedStatusReason: {},
      formItemOptions: formItemOptions,
      // statusReasonRequired: statusReasonRequired,
    });
  }

  changeOrderHistory = () => {
    this.setState((prevState) => ({
      projectStatusHistoryModal: !prevState.projectStatusHistoryModal,
    }));
  };

  changeEventDate = () => {
    this.setState((prevState) => ({
      eventDateModal: !prevState.eventDateModal,
    }));
  };

  handleStatusUpdateChange = (e) => {
    let statusUpdateProject = this.state.statusUpdateProject;

    //let currentJobStatus = this.state.currentProjectStatus['jobStatusCodesName'];
    let nextJobStatus = this.state?.selectedStatusBox?.projectStatus?.label && this.state.reasonWithStatus['projectStatus'];

    statusUpdateProject[e.target.name] = e.target.value;


    if (
      this.state.formItemOptions['originalContractAmount'].required &&
      e.target.name === 'originalContractAmount' &&
      this.state.formArr?.originalContractAmount === 'TT'
    ) {
      if (rules[e.target.name] && rules[e.target.name] === 'required') {
        delete rules[e.target.name];
      }
      var formArr = this.state.formArr;
      delete formArr[e.target.name];
      this.setState({
        formArr: formArr,
      });
    }

    if (
      this.state.formItemOptions['originalGrossProfit'].required &&
      e.target.name === 'originalGrossProfit' &&
      this.state.formArr?.originalGrossProfit === 'TT'
    ) {
      if (rules[e.target.name] && rules[e.target.name] === 'required') {
        delete rules[e.target.name];
      }
      var formArr = this.state.formArr;
      delete formArr[e.target.name];
      this.setState({
        formArr: formArr,
      });
    }

    if (
      this.state.formItemOptions['currentContractAmount'].required === true &&
      e.target.name === 'currentContractAmount' &&
      this.state.formArr?.currentContractAmount === 'TT'
    ) {
      if (rules[e.target.name] && rules[e.target.name] === 'required') {
        delete rules[e.target.name];
      }
      var formArr = this.state.formArr;
      delete formArr[e.target.name];
      this.setState({
        formArr: formArr,
      });
    }

    if (
      this.state.formItemOptions['currentContractAmount'].required &&
      e.target.name === 'currentGrossProfit' &&
      this.state.formArr?.currentGrossProfit === 'TT'
    ) {
      if (rules[e.target.name] && rules[e.target.name] === 'required') {
        delete rules[e.target.name];
      }
      var formArr = this.state.formArr;
      delete formArr[e.target.name];
      this.setState({
        formArr: formArr,
      });
    }

    if (e.target.name === 'currentContractAmount' || e.target.name === 'currentGrossProfit') {
      if (
        Number(statusUpdateProject.currentContractAmount) >=
        Number(statusUpdateProject.currentGrossProfit)
      ) {
        errorMsg = '';
      } else {
        errorMsg = 'Profit should be less than Contract Amount';
      }
    }

    if (e.target.name === 'originalContractAmount' || e.target.name === 'originalGrossProfit') {
      if (
        Number(statusUpdateProject.originalContractAmount) >=
        Number(statusUpdateProject.originalGrossProfit)
      ) {
        errorMsg = '';
      } else {
        errorMsg = 'Profit should be less than Contract Amount';
      }
    }

    if (!nextJobStatus?.length > 0 && !!nextJobStatus?.trash) {
      errorMsg = 'Next Status cannot be empty';
    }

    this.setState({
      statusUpdateProject: statusUpdateProject,
    });
  };

  handleStatusReasonCodeChange = (e) => {
    if (e?.value) {
      let selectedStatusReason = {
        label: '',
        value: '',
      };
      selectedStatusReason['label'] = e.label;
      selectedStatusReason['value'] = e.value;

      this.setState({
        selectedStatusReason: selectedStatusReason,
      });
    } else {
      this.setState({
        selectedStatusReason: {
          label: '',
          value: '',
        },
      });
    }

  };

  handleStartDateReasonCodeChange = (e) => {
    let selectedStartDateReason = this.state.selectedStartDateReason;
    selectedStartDateReason['label'] = e?.label ?? '';
    selectedStartDateReason['value'] = e?.value ?? '';

    this.setState({
      selectedStartDateReason: selectedStartDateReason,
    });
  };

  handleEndDateReasonCodeChange = (e) => {
    let selectedEndDateReason = this.state.selectedEndDateReason;
    selectedEndDateReason['label'] = e?.label ?? '';
    selectedEndDateReason['value'] = e?.value ?? '';

    this.setState({
      selectedEndDateReason: selectedEndDateReason,
    });
  };

  handleDatePickerBid = (name, date) => {
    let { statusUpdateProject, selectDateBox, projectData } = this.state;
    let startDateReasonReq = '';
    let endDateReasonReq = '';

    if (statusUpdateProject['startDate'] && statusUpdateProject['startDate']['iso']) {
      statusUpdateProject['startDate'] = statusUpdateProject['startDate']['iso'];
    }
    if (statusUpdateProject['endDate'] && statusUpdateProject['endDate']['iso']) {
      statusUpdateProject['endDate'] = statusUpdateProject['endDate']['iso'];
    }
    statusUpdateProject[name] = date;
    selectDateBox[name] = date;
    if (projectData.startDate?.iso === statusUpdateProject['startDate']) {
      startDateReasonReq = false;
      let selectedStartDateReason = { label: '', value: '' };
      this.setState({ startDateReasonReq, selectedStartDateReason });
    } else {
      if (name === 'startDate') {
        startDateReasonReq = true;
        this.setState({ startDateReasonReq });
      }
    }
    if (projectData.endDate?.iso === statusUpdateProject['endDate']) {
      endDateReasonReq = false;
      let selectedEndDateReason = { label: '', value: '' };
      this.setState({ endDateReasonReq, selectedEndDateReason });
    } else {
      if (name === 'endDate') {
        endDateReasonReq = true;
        this.setState({ endDateReasonReq });
      }
    }

    if (name === 'startDate') {
      let dateObj = '';
      if (date === null) {
        dateObj = formatDateObject().add(1, 'day');
        selectDateBox['endDate'] = dateObj;
        statusUpdateProject['endDate'] = dateObj;
      } else {
        dateObj = formatDateObject(date).add(1, 'Day');

        if (selectDateBox['endDate']._d) {
          selectDateBox['endDate'] = formatDateUTC(selectDateBox['endDate']._d);
        }
        if (formatDateUTC(selectDateBox['startDate']) > selectDateBox['endDate']) {
          selectDateBox['endDate'] = dateObj;
          statusUpdateProject['endDate'] = dateObj;
        }
      }
    }

    let dateErrorMsg = '';
    if (this.state.currentProjectStatus?.jobStatusCodesCode === 'D') {
      if (name === 'startDate' || name === 'endDate') {
        //dateErrorMsg = dateValidation(formatDateObject(selectDateBox["startDate"]).add(1, "day"),formatDateObject(selectDateBox["endDate"]),formatDateObject());
        dateErrorMsg = dateValidation(
          formatDateObject(selectDateBox['startDate']),
          formatDateObject(selectDateBox['endDate']),
          formatDateObject()
        );
        if (dateErrorMsg === 'start') {
          startDateErrMsg = 'Delivered should be greater than start date.';
        } else if (dateErrorMsg === 'end') {
          startDateErrMsg = 'End Date should be greater than start date.';
        } else {
          startDateErrMsg = '';
        }
      }
    } else {
      dateErrorMsg = '';
      startDateErrMsg = '';
    }

    this.setState({ statusUpdateProject, selectDateBox });
  };

  selectedHistoryRow = (row) => {
    this.setState({ eventDateData: row });
  };

  toggleModal = () => {
    this.setState((prevState) => ({ modalOpen: !prevState.modalOpen }));
  };

  getResponseReceivedLabel = () => {
    const lostUpdateTypes = ['DirectLost', 'Lost'];
    const approvedUpdateTypes = ['DirectPromote', 'Approved'];

    if (lostUpdateTypes.includes(this.state.updateType)) {
      return 'Date Lost';
    }

    if (approvedUpdateTypes.includes(this.state.updateType)) {
      return 'Date Approved/Awarded';
    }

    return 'Response Received';
  };

  render() {
    validation = new Validator([], rules, mess);
    validation.passes();
    validation.fails();

    if (this.state.projectStatusHistoryModal) {
      this.state.modalOpen = true;
    }

    let optionStatus = [];
    this.state.nextStatusOptions?.map((v) => {
      if (v.objectId && v.jobStatusCodesCode && v.jobStatusCodesCode !== 'L') {
        optionStatus.push({ value: v.objectId, label: v.jobStatusCodesName });
      }
      return true;
    });

    const { selectDateBox, projectData, formItemOptions, selectedStatusBox, statusNameLookup = {} } = this.state;

    let nextStatus = [];
    let projectStatus = [this.state.reasonWithStatus['projectStatus']];
    projectStatus.map((item) => {
      if (item && item.jobStatusCodesName !== '' && item.jobStatusCodesCode !== '') {
        nextStatus.push({
          value: item.jobStatusCodesCode,
          label: item.jobStatusCodesName,
        });
      }
      return true;
    });

    const bidSubmittedPicker = () => {

      return <DatePickerWrapper
        className={formItemOptions?.bidSubmittedDate?.lock ? 'form-control-plaintext' : 'form-control'}
        selected={
          (selectDateBox.bidSubmittedDate === '' || !selectDateBox.bidSubmittedDate)
            ? undefined
            : formatDateObjectWithTime(selectDateBox.bidSubmittedDate)
        }
        onChange={this.handleDatePickerBidWithTime.bind(this, 'bidSubmittedDate')}
        dateFormat={appConstants.DATEFORMATPICKERTIME}
        placeholderText={formItemOptions?.bidSubmittedDate?.lock ? 'NA' : 'Click to select a date'}
        timeCaption='time'
        timeFormat='h:mm a'
        timeIntervals={15}
        showTimeSelect={true}
        todayButton='Today'
        showMonthDropdown
        isClearable={!formItemOptions?.bidSubmittedDate?.lock}
        injectTimes={[moment().hours(23).minutes(59)]}
        required={formItemOptions?.bidSubmittedDate?.required}
        disabled={formItemOptions?.bidSubmittedDate?.lock}
        maxDate={formatDateAdd(1, 'day')}
      />
    };

    const bidStartPicker = (
      <DatePickerWrapper
        className={formItemOptions?.bidStart?.lock ? 'form-control-plaintext' : 'form-control'}
        selected={
          (selectDateBox.bidStart === '' || !selectDateBox.bidStart)
            ? undefined
            : formatDateObjectWithTime(selectDateBox.bidStart)
        }
        onChange={this.handleDatePickerBidWithTime.bind(this, 'bidStart')}
        dateFormat={appConstants.DATEFORMATPICKER}
        placeholderText={formItemOptions?.bidStart?.lock ? 'NA' : 'Click to select a date'}
        injectTimes={[moment().hours(23).minutes(59)]}
        required={formItemOptions?.bidStart?.required}
        disabled={formItemOptions?.bidStart?.lock}
        selectsStart
        todayButton='Today'
        showMonthDropdown
        isClearable={!formItemOptions?.bidStart?.lock}
        startDate={formatDateObjectOrNull(selectDateBox.bidStart)}
        endDate={formatDateObjectOrNull(selectDateBox.bidDue)}
        maxDate={formatDateAdd(5, 'months', formatDateObjectOrNull(selectDateBox.bidDue))}
      />
    );

    const bidDuePicker = (
      <DatePickerWrapper
        className={formItemOptions?.bidDue?.lock ? 'form-control-plaintext' : 'form-control'}
        selected={
          (selectDateBox.bidDue === '' || !selectDateBox.bidDue) ? undefined : formatDateObjectWithTime(selectDateBox.bidDue)
        }
        onChange={this.handleDatePickerBidWithTime.bind(this, 'bidDue')}
        dateFormat={appConstants.DATEFORMATPICKERTIME}
        placeholderText={formItemOptions?.bidDue?.lock ? 'NA' : 'Click to select a date'}
        timeCaption='time'
        timeFormat='h:mm a'
        timeIntervals={15}
        showTimeSelect
        injectTimes={[moment().hours(23).minutes(59)]}
        required={formItemOptions?.bidDue?.required}
        disabled={formItemOptions?.bidDue?.lock}
        selectsEnd
        todayButton='Today'
        showMonthDropdown
        isClearable={!formItemOptions?.bidDue?.lock}
        startDate={formatDateObjectOrNull(selectDateBox.bidStart)}
        endDate={formatDateObjectOrNull(selectDateBox.bidDue)}
        maxDate={formatDateAdd(48, 'months')}
      />
    );

    const responseReceivedPicker = (
      <DatePickerWrapper
        className={formItemOptions?.responseReceivedDate?.lock ? 'form-control-plaintext' : 'form-control'}
        selected={
          (selectDateBox.responseReceivedDate === '' || !selectDateBox.responseReceivedDate)
            ? undefined
            : formatDateObjectWithTime(selectDateBox.responseReceivedDate)
        }
        onChange={this.handleDatePickerBidWithTime.bind(this, 'responseReceivedDate')}
        dateFormat={appConstants.DATEFORMATPICKER}
        placeholderText={formItemOptions?.responseReceivedDate?.lock ? 'NA' : 'Click to select a date'}
        injectTimes={[moment().hours(23).minutes(59)]}
        todayButton='Today'
        showMonthDropdown
        required={formItemOptions?.responseReceivedDate?.required}
        disabled={formItemOptions?.responseReceivedDate?.lock}
        maxDate={formatDateAdd(1, 'week')}
      />
    );

    const ntpDatePicker = (
      <DatePickerWrapper
        className={formItemOptions?.ntpDate?.lock ? 'form-control-plaintext' : 'form-control'}
        selected={
          (selectDateBox.ntpDate === ''||!selectDateBox.ntpDate) ? undefined : formatDateObjectWithTime(selectDateBox.ntpDate)
        }
        onChange={this.handleDatePickerBidWithTime.bind(this, 'ntpDate')}
        dateFormat={appConstants.DATEFORMATPICKER}
        placeholderText={formItemOptions?.ntpDate?.lock ? 'NA' : 'Click to select a date'}
        injectTimes={[moment().hours(23).minutes(59)]}
        required={formItemOptions?.ntpDate?.required}
        disabled={formItemOptions?.ntpDate?.lock}
        todayButton='Today'
        showMonthDropdown
        maxDate={formatDateAdd(5, 'days')}
      />
    );

    const isBidDueFilled = !!selectDateBox.bidDue || !formItemOptions?.bidDue?.required;
    const isBidStartFilled = !!selectDateBox.bidStart || !formItemOptions?.bidStart?.required;
    const isBidDateFilled =
      !!selectDateBox.bidSubmittedDate || !formItemOptions?.bidSubmittedDate?.required;
    const isResponseReceivedFilled =
      !!selectDateBox.responseReceivedDate || !formItemOptions?.responseReceivedDate?.required;
    const isNtpDateFilled = !!selectDateBox.ntpDate || !formItemOptions?.ntpDate?.required;
    const isCloseoutDateFilled =
      !!selectDateBox.closeoutDate || !formItemOptions?.closeoutDate?.required;
    const isResumeFromHoldFilled =
      !!selectDateBox.resumeFromHoldDate || !formItemOptions?.resumeFromHoldDate?.required;
    const isSuspendStartDateFilled =
      !!selectDateBox.suspendStartDate || !formItemOptions?.suspendStartDate?.required;
    const isSuspendEndDateFilled =
      !!selectDateBox.suspendEndDate || !formItemOptions?.suspendEndDate?.required;
    const isBaselineStartFilled =
      !!selectDateBox.baselineStartDate || !formItemOptions?.baselineStartDate?.required;
    const isBaselineEndFilled =
      !!selectDateBox.baselineEndDate || !formItemOptions?.baselineEndDate?.required;
    const isStartDateFilled = !!selectDateBox.startDate || !formItemOptions?.startDate?.required;
    const isEndDateFilled = !!selectDateBox.endDate || !formItemOptions?.endDate?.required;
    const isDeliveredDateFilled =
      !!selectDateBox.deliveredDate || !formItemOptions?.deliveredDate?.required;
    const isEffectiveDateFilled =
      !!selectDateBox.effectiveDate || !formItemOptions?.effectiveDate?.required;

    const isContractAmountNumeric = this.state.statusUpdateProject.currentContractAmount ? /^-?\d{1,}[.]{0,1}\d*$/.test(
      this.state.statusUpdateProject.currentContractAmount
    ) : !formItemOptions?.currentContractAmount?.required;

    const isGrossProfitNumeric = this.state.statusUpdateProject.currentGrossProfit ? /^-?\d{1,}[.]{0,1}\d*$/.test(
      this.state.statusUpdateProject.currentGrossProfit
    ) : !formItemOptions?.currentGrossProfit?.required;

    const isOriginalContractAmountNumeric = this.state.statusUpdateProject.originalContractAmount ? /^-?\d{1,}[.]{0,1}\d*$/.test(
      this.state.statusUpdateProject.originalContractAmount
    ) : !formItemOptions?.originalContractAmount?.required;

    const isOriginalGrossProfitNumeric = this.state.statusUpdateProject.originalGrossProfit ? /^-?\d{1,}[.]{0,1}\d*$/.test(
      this.state.statusUpdateProject.originalGrossProfit
    ) : !formItemOptions?.originalGrossProfit?.required;


    const hasNextStatus = selectedStatusBox?.projectStatus?.label?.length > 1;

    const activeStatuses = ['A', 'SNS'];
    const biddingStates = ['B', 'P1', 'P2', 'P3'];
    const nextStatusCode = statusNameLookup[selectedStatusBox?.projectStatus?.value];
    const currentStatusCode = projectData.jobStatusCodesCode;
    const isPromoteToActive = activeStatuses.includes(nextStatusCode) && (![...activeStatuses, 'SUSP'].includes(currentStatusCode));
    const isPromoteToBidding = biddingStates.includes(nextStatusCode) && (![...biddingStates, 'OH'].includes(currentStatusCode));
    const actionButtonLabel = (isPromoteToActive || isPromoteToBidding) ? 'Next' : 'Update';
    const actionButtonOnClick = (isPromoteToActive && this.props.onNext)
      ? this.props.onNext
      : this.submitUpdateProject;

    const projectStage = getProjectStageByCode(statusNameLookup[nextStatusCode]);
    const { requireNTP, requireConstructionDatesOn, requireReasonCodes } = this.state.generalInfo;
    const requireConstructionDates = isCurrentOrPrevStage(projectStage, requireConstructionDatesOn);

    let PreventUpdate = !(
      isBidDueFilled &&
      isBidStartFilled &&
      isBidDateFilled &&
      isResponseReceivedFilled &&
      isCloseoutDateFilled &&
      isResumeFromHoldFilled &&
      isSuspendStartDateFilled &&
      isSuspendEndDateFilled &&
      isDeliveredDateFilled &&
      hasNextStatus
    );

    if (formItemOptions?.ntpDate?.show && requireNTP && !isNtpDateFilled) {
      PreventUpdate = true;
    }

    if (requireConstructionDates) {
      const isStartDateMissing = !(isBaselineStartFilled || isStartDateFilled);
      const isEndDateMissing = !(isBaselineEndFilled || isEndDateFilled);

      if (isStartDateMissing || isEndDateMissing) {
        PreventUpdate = true;
      }
    }

    const canChangeStatus = this.state.canChangeStatus;

    const hideCurrentFields = this.state.hideCurrentFields;

    const hideStatusRow = this.state.hideStatusRow;

    const newValueClass = hideCurrentFields ? 'col-md-8' : 'col-md-5';
    const fieldClass = hideCurrentFields ? 'col-md-4' : 'col-md-4';
    const currentValueClass = 'col-md-3';
    const commentClass = hideCurrentFields ? 'col-md-8' : 'col-md-8';

    const modalTitle = this.state.modalTitle;

    const updateType = this.state.updateType;

    const modalClassName = hideCurrentFields ? 'modal-dialog modal-md' : 'modal-dialog modal-lg'



    const isNewBidding = (updateType === 'DirectPromote' || updateType === 'Approved');
    const biddingUpdateTypes = ['DirectPromote', 'DirectLost','DirectRebid','Approved', 'Rescind', 'Lost', 'Closed', 'On Hold', 'Rebid', 'PromotedLead', 'ResumeFromHold', 'reopenProject', 'Submitted'];
    const isBidding = (biddingUpdateTypes.includes(updateType)) && !this.state.setOriginalContract;

    const canEditCurrentStartEndDates = (isNewBidding && isAllowed(
      moduleConstants.EBD,
      projectData?.userAssignData?.length ?? 0,
      projectData.userAssignData
    )) || (updateType === 'UpdateWIP' && isAllowed(
      moduleConstants.ECD,
      projectData?.userAssignData?.length ?? 0,
      projectData.userAssignData
    )) &&
      !this.state.setOriginalContract;

    const statusChangeReasonCodes = this.state.statusChangeReasonCodes;
    const statusReasonRequired = this.state.statusReasonRequired;
    const dateChangeReasonCodes = this.state.dateChangeReasonCodes;

    if(requireReasonCodes && updateType?.length>0 && statusChangeReasonCodes?.length === 1 && (this.state.selectedStatusReason?.value?.length===0 || this.state.selectedStatusReason?.value === undefined
      && statusChangeReasonCodes[0].statusCode === this.state.nextStatusId
    )){
      this.setState({
        selectedStatusReason: {
          label: statusChangeReasonCodes[0].label,
          value: statusChangeReasonCodes[0].value
          
        }
      });
    }

    if (requireReasonCodes && updateType !== 'UpdateWIP' && statusChangeReasonCodes?.length > 0 && _.isEmpty(this.state.selectedStatusReason)) {
      PreventUpdate = true;
    }

    return (
      <div className=''>
        {/*Status Update Model*/}
        <Modal
          backdrop='static'
          keyboard
          isOpen={this.state.modalOpen}
          toggle={this.toggleStatusUpdate}
          className={modalClassName}
          id='projectStatusUpdatePopup'
        >
          <ModalHeader className='modalHeader' toggle={this.toggleStatusUpdateReset}>
            {modalTitle}
          </ModalHeader>
          <ModalBody>
            <div className='modalBody'>
              <div className='container'>
                {
                <div className='row mb-4'>
                  <div className='col-md-6'>
                    <p className='dnn'>
                      <strong>Job Name:</strong>
                      {projectData && projectData.jobName}
                    </p>
                  </div>
                  <div className='col-md-6'>
                    <p className='dnn'>
                      <strong>Job Number:</strong>
                      {projectData && projectData.jobNumFormatted}
                    </p>
                  </div>
                </div>
                }
                {!hideCurrentFields && (<>
                  <div className='row'>
                    <div className={fieldClass}>
                      <p>
                        <strong></strong>
                      </p>
                    </div>

                    <div className={currentValueClass}>
                      <p>
                        <strong>Current Value</strong>
                      </p>
                    </div>

                    <div className={newValueClass}>
                      <p>
                        <strong>New Value</strong>
                      </p>
                    </div>

                  </div>

                  {canChangeStatus && <hr />}
                </>

                )}

                {!hideStatusRow && (<>

                  <div className='row mb-2'>
                    <div className={fieldClass}>
                      <p>
                        <strong>{hideCurrentFields && (<>New </>)}Status:</strong>
                      </p>
                    </div>
                    {!hideCurrentFields && (
                      <div className={currentValueClass}>
                        <div className='form-group'>
                          <input
                            type='text'
                            className='form-control-plaintext'
                            id='currentJobStatusName'
                            disabled
                            value={
                              this.state.currentProjectStatus &&
                              this.state.currentProjectStatus['jobStatusCodesName']
                            }
                          />
                        </div>
                      </div>)}
                    <div className={newValueClass}>
                      <div className='form-group'>
                        {this.state.currentProjectStatus &&
                          canChangeStatus && optionStatus?.length > 1 ? (
                          <Select
                            name='nextStatus'
                            components={makeAnimated()}
                            value={nextStatus && nextStatus[0]}
                            options={optionStatus}
                            onChange={this.handleStatusChange.bind(this)}
                          />
                        ) : (
                          <input
                            type='text'
                            className='form-control-plaintext'
                            id='nextStatus'
                            disabled
                            value={(nextStatus && nextStatus[0]?.label) ?? 'Insufficient Permissions'}
                          />
                        )}
                        <span className='error'>{susErrorMsg}</span>
                      </div>
                    </div>
                  </div>

                </>
                )}
                {statusChangeReasonCodes?.length !== 0 &&
                  statusReasonRequired === true ? (
                  <div className='row mb-2'>
                    <div className={fieldClass}>
                      <p>
                        <strong>Status change reason:</strong>
                        {requireReasonCodes && <span className='requiredField'>*</span>}
                      </p>
                    </div>
                    {!hideCurrentFields && (
                      <div className={currentValueClass}></div>)}
                    <div className={newValueClass}>
                      <div className='form-group'>
                        <Select
                          name='statusChangeReason'
                          components={makeAnimated()}
                          value={this.state.selectedStatusReason}
                          onChange={this.handleStatusReasonCodeChange.bind(this)}
                          options={statusChangeReasonCodes}
                          required={requireReasonCodes}
                          isClearable
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {canChangeStatus && this.showRowComponent('bidDue') && <hr />}
                {this.showRowComponent('bidStart') && (
                  <div className='row'>
                    <div className={fieldClass}>
                      <p>
                        <strong>Estimating Start:</strong>
                        {formItemOptions?.bidStart?.required && <span className='requiredField'>*</span>}
                      </p>
                    </div>
                    {!hideCurrentFields && (
                      <div className={currentValueClass}>
                        <div className='form-group'>
                          <input
                            type='text'
                            className='form-control-plaintext'
                            id='companyname'
                            disabled
                            value={
                              projectData && projectData.bidStart && projectData.bidStart.iso
                                ? dateFormatMMDDYYYY(projectData.bidStart)
                                : 'NA'
                            }
                          />
                        </div>
                      </div>
                    )}
                    <div className={newValueClass}>
                      <div className='form-group'>
                        {isAllowed(
                          moduleConstants.EBD,
                          projectData && projectData.userAssignData
                            ? projectData.userAssignData.length
                            : 0,
                          projectData.userAssignData
                        ) ? (
                          bidStartPicker
                        ) : (
                          <input
                            type='text'
                            className='form-control-plaintext'
                            id='companyname'
                            disabled
                            value={
                              projectData && projectData.bidStart && projectData.bidStart.iso
                                ? dateFormatMMDDYYYY(projectData.bidStart)
                                : 'MM/DD/YYYY'
                            }
                          />
                        )}
                        <span style={styleData} className='error'>
                          {formItemOptions?.bidStart?.required &&
                            !isBidStartFilled &&
                            'Bidding Start Date is required field'}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {this.showRowComponent('bidDue') && (
                  <div className='row'>
                    <div className={fieldClass}>
                      <p>
                        <strong>{isNewBidding ? 'Upcoming ' : ''}Bid Date:</strong>
                        {formItemOptions?.bidDue?.required && <span className='requiredField'>*</span>}
                      </p>
                    </div>
                    {!hideCurrentFields && (
                      <div className={currentValueClass}>
                        <div className='form-group'>
                          <input
                            type='text'
                            className='form-control-plaintext'
                            id='companyname'
                            disabled
                            value={
                              projectData && projectData.bidDue && projectData.bidDue.iso
                                ? dateFormatMMDDYYYYtime(projectData.bidDue)
                                : 'NA'
                            }
                          />
                        </div>
                      </div>
                    )}
                    <div className={newValueClass}>
                      <div className='form-group'>
                        {isAllowed(
                          moduleConstants.EBD,
                          projectData && projectData.userAssignData
                            ? projectData.userAssignData.length
                            : 0,
                          projectData.userAssignData
                        ) ? (
                          bidDuePicker
                        ) : (
                          <input
                            type='text'
                            className='form-control-plaintext'
                            id='companyname'
                            disabled
                            value={
                              projectData && projectData.bidDue && projectData.bidDue.iso
                                ? dateFormatMMDDYYYYtime(projectData.bidDue)
                                : 'MM/dd/YYYY'
                            }
                          />
                        )}
                        <span style={styleData} className='error'>
                          {formItemOptions?.bidDue?.required &&
                            !isBidDueFilled &&
                            'Due Date is required field'}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {this.showRowComponent('bidSubmittedDate') && (
                  <div className='row'>
                    <div className={fieldClass}>
                      <p>
                        <strong>Submitted Date:</strong>
                        {formItemOptions?.bidSubmittedDate?.required && <span className='requiredField'>*</span>}
                      </p>
                    </div>
                    {!hideCurrentFields && (
                      <div className={currentValueClass}>
                        <div className='form-group'>
                          <input
                            type='text'
                            className='form-control-plaintext'
                            id='companyname'
                            disabled
                            value={
                              projectData.bidSubmittedDate?.iso ? dateFormatMMDDYYYYtime(projectData.bidSubmittedDate) : 'NA'
                            }
                          />
                        </div>
                      </div>
                    )}
                    <div className={newValueClass}>
                      <div className='form-group'>
                        {isAllowed(
                          moduleConstants.EBD,
                          projectData.userAssignData?.length ?? 0,
                          projectData.userAssignData
                        ) ? (
                          bidSubmittedPicker()
                        ) : (
                          <input
                            type='text'
                            className='form-control-plaintext'
                            id='companyname'
                            disabled
                            value={
                              projectData.bidSubmittedDate?.iso
                                ? dateFormatMMDDYYYYtime(projectData.bidSubmittedDate)
                                : 'NA'
                            }
                          />
                        )}
                        <span style={styleData} className='error'>
                          {formItemOptions?.bidSubmittedDate?.required &&
                            !isBidDateFilled &&
                            'Submitted Date is a required field'}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {this.showRowComponent('responseReceivedDate') && (
                  <>
                    {!this.showRowComponent('bidSubmittedDate') && <hr />}
                    <div className='row'>
                      <div className={fieldClass}>
                        <p>
                          <strong>{this.getResponseReceivedLabel()}:</strong>
                          {formItemOptions?.responseReceivedDate?.required && <span className='requiredField'>*</span>}
                        </p>
                      </div>
                      {!hideCurrentFields && (
                        <div className={currentValueClass}>
                          <div className='form-group'>
                            <input
                              type='text'
                              className='form-control-plaintext'
                              id='companyname'
                              disabled
                              value={
                                projectData &&
                                  projectData.responseReceivedDate &&
                                  projectData.responseReceivedDate.iso
                                  ? dateFormatMMDDYYYY(projectData.responseReceivedDate)
                                  : 'NA'
                              }
                            />
                          </div>
                        </div>
                      )}
                      <div className={newValueClass}>
                        <div className='form-group'>
                          {formItemOptions?.responseReceivedDate?.lock ? (

                            <input
                              type='text'
                              className='form-control-plaintext'
                              id='companyname'
                              disabled
                              value={
                                projectData &&
                                  projectData.responseReceivedDate &&
                                  projectData.responseReceivedDate.iso
                                  ? formatDateObjectOrNull(projectData.responseReceivedDate)
                                  : 'MM/dd/YYYY'
                              }
                            />
                          ) : (responseReceivedPicker
                          )}
                          <span style={styleData} className='error'>
                            {formItemOptions?.responseReceivedDate?.required &&
                              !isResponseReceivedFilled &&
                              'Client Response Received is required field'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {this.showRowComponent('effectiveDate') && (
                  <div className='row'>
                    <div className={fieldClass}>
                      <p>
                        <strong>Effective Date:</strong> {formItemOptions?.effectiveDate?.required && <span className='requiredField'>*</span>}
                      </p>
                    </div>
                    {!hideCurrentFields && (
                      <div className={currentValueClass}>
                        <div className='form-group'>
                          <input
                            type='text'
                            className='form-control-plaintext'
                            id='companyname'
                            disabled
                            value={
                              projectData.effectiveDate?.iso
                                ? dateFormatMMDDYYYY(projectData.effectiveDate.iso)
                                : ''
                            }
                          />
                        </div>
                      </div>
                    )}
                    <div className={newValueClass}>
                      <div className='form-group'>
                        <DatePickerWrapper
                          className={formItemOptions?.effectiveDate?.lock ? 'form-control-plaintext' : 'form-control'}
                          selected={
                            selectDateBox.effectiveDate
                              ? formatDateObjectOrNull(selectDateBox.effectiveDate)
                              : formatDateObject(new Date())
                          }
                          onChange={this.handleDatePickerBid.bind(this, 'effectiveDate')}
                          timeIntervals={15}
                          dateFormat={appConstants.DATEFORMATPICKER}
                          timeCaption='time'
                          placeholderText={formItemOptions?.effectiveDate?.lock ? 'NA' : 'Click to select a date'}
                          selectsStart
                          todayButton='Today'
                          showMonthDropdown
                          required={formItemOptions?.effectiveDate?.required}
                          disabled={formItemOptions?.effectiveDate?.lock}
                          startDate={formatDateObjectOrNull(selectDateBox.effectiveDate)}
                          minDate={formatDateObject(selectDateBox.startDate)}
                          maxDate={formatDateObject(new Date())}
                        />
                        <span style={styleData} className='error'>
                          {!selectDateBox.effectiveDate && validation.errors.first('effectiveDate')}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {this.showRowComponent('resumeFromHoldDate') && (
                  <div className='row'>
                    <div className={fieldClass}>
                      <p>
                        <strong>Resume Date</strong>
                        {(formItemOptions?.resumeFromHoldDate?.required && <span className='requiredField'>*</span>)}:
                      </p>
                    </div>
                    {!hideCurrentFields && (
                      <div className={currentValueClass}>
                        <div className='form-group'>
                          <input
                            type='text'
                            className='form-control-plaintext'
                            id='companyname'
                            disabled
                            value={
                              projectData.resumeFromHoldDate?.iso && !(formItemOptions?.resumeFromHoldDate?.clear)
                                ? dateFormatMMDDYYYY(projectData.resumeFromHoldDate.iso)
                                : ''
                            }
                          />
                        </div>
                      </div>
                    )}
                    <div className={newValueClass}>
                      <div className='form-group'>
                        <DatePickerWrapper
                          className={formItemOptions?.resumeFromHoldDate?.lock ? 'form-control-plaintext' : 'form-control'}
                          selected={formatDateObjectOrNull(selectDateBox.resumeFromHoldDate)}
                          onChange={this.handleDatePickerBid.bind(this, 'resumeFromHoldDate')}
                          timeIntervals={15}
                          dateFormat={appConstants.DATEFORMATPICKER}
                          timeCaption='time'
                          placeholderText={formItemOptions?.resumeFromHoldDate?.lock ? 'NA' : 'Click to select a date'}
                          todayButton='Today'
                          showMonthDropdown
                          required={formItemOptions?.resumeFromHoldDate?.required}
                          disabled={formItemOptions?.resumeFromHoldDate?.lock}
                          startDate={formatDateObjectOrNull(selectDateBox.bidStart)}
                          endDate={formatDateObjectOrNull(selectDateBox.bidDue)}
                          minDate={formatDateObject(Date())}
                          maxDate={null}
                        />

                      </div>
                    </div>
                  </div>
                )}
                {this.showRowComponent('suspendStartDate') && (
                  <div className='row'>
                    <div className={fieldClass}>
                      <p>
                        <strong>Suspended Start:</strong>
                        {formItemOptions?.suspendStartDate?.required && <span className='requiredField'>*</span>}
                      </p>
                    </div>
                    {!hideCurrentFields && (
                      <div className={currentValueClass}>
                        <div className='form-group'>
                          <input
                            type='text'
                            className='form-control-plaintext'
                            id='companyname'
                            disabled
                            value={
                              projectData.suspendStartDate?.iso
                                ? dateFormatMMDDYYYY(projectData.suspendStartDate.iso)
                                : ''
                            }
                          />
                        </div>
                      </div>
                    )}
                    <div className={newValueClass}>
                      <div className='form-group'>
                        <DatePickerWrapper
                          className={formItemOptions?.suspendStartDate?.lock ? 'form-control-plaintext' : 'form-control'}
                          selected={formatDateObjectOrNull(selectDateBox.suspendStartDate)}
                          onChange={this.handleDatePickerBid.bind(this, 'suspendStartDate')}
                          timeIntervals={15}
                          dateFormat={appConstants.DATEFORMATPICKER}
                          timeCaption='time'
                          placeholderText={formItemOptions?.suspendStartDate?.lock ? 'NA' : 'Click to select a date'}
                          selectsStart
                          todayButton='Today'
                          showMonthDropdown
                          required={formItemOptions?.suspendStartDate?.required}
                          disabled={formItemOptions?.suspendStartDate?.lock}
                          startDate={formatDateObjectOrNull(selectDateBox.startDate)}
                          endDate={formatDateObjectOrNull(selectDateBox.suspendEndDate)}
                          minDate={formatDateObjectOrNull(selectDateBox.startDate)}
                          maxDate={formatDateAdd(
                            5,
                            'months',
                            formatDateObjectOrNull(selectDateBox.endDate)
                          )}
                        />
                        <span style={styleData} className='error'>
                          {!selectDateBox.suspendStartDate &&
                            validation.errors.first('suspendStartDate')}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {this.showRowComponent('suspendEndDate') && (
                  <div className='row'>
                    <div className={fieldClass}>
                      <p>
                        <strong>Suspended End:</strong>
                      </p>
                    </div>
                    {!hideCurrentFields && (
                      <div className={currentValueClass}>
                        <div className='form-group'>
                          <input
                            type='text'
                            className='form-control-plaintext'
                            id='companyname'
                            disabled
                            value={
                              projectData.suspendEndDate?.iso
                                ? dateFormatMMDDYYYY(projectData.suspendEndDate.iso)
                                : ''
                            }
                          />
                        </div>
                      </div>
                    )}
                    <div className={newValueClass}>
                      <div className='form-group'>
                        <DatePickerWrapper
                          className={formItemOptions?.suspendEndDate?.lock ? 'form-control-plaintext' : 'form-control'}
                          selected={
                            selectDateBox.suspendEndDate
                              ? formatDateObjectOrNull(selectDateBox.suspendEndDate)
                              : ''
                          }
                          todayButton='Today'
                          showMonthDropdown
                          onChange={this.handleDatePickerBid.bind(this, 'suspendEndDate')}
                          timeIntervals={15}
                          dateFormat={appConstants.DATEFORMATPICKER}
                          timeCaption='time'
                          placeholderText={formItemOptions?.suspendEndDate?.lock ? 'NA' : 'Click to select a date'}
                          selectsEnd
                          disabled={formItemOptions?.suspendEndDate?.lock}
                          startDate={formatDateObjectOrNull(selectDateBox.suspendStartDate)}
                          endDate={formatDateObjectOrNull(selectDateBox.suspendEndDate)}
                          minDate={formatDateObject(selectDateBox.suspendStartDate).add(1, 'day')}

                        />
                        <span style={styleData} className='error'>
                          {!selectDateBox.suspendEndDate &&
                            validation.errors.first('suspendStartDate')}
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                {this.showRowComponent('ntpDate') && (
                  <div className='row'>
                    <hr />
                    <div className={fieldClass}>
                      <p>
                        <strong>NTP Received:</strong>
                        {formItemOptions?.ntpDate?.required && <span className='requiredField'>*</span>}
                      </p>
                    </div>
                    {!hideCurrentFields && (
                      <div className={currentValueClass}>
                        <div className='form-group'>
                          <input
                            type='text'
                            className='form-control-plaintext'
                            id='companyname'
                            disabled
                            value={
                              projectData.ntpDate?.iso
                              ? dateFormatMMDDYYYY(projectData.ntpDate.iso)
                              : ''
                            }
                          />
                        </div>
                      </div>
                    )}
                    <div className={newValueClass}>
                      <div className='form-group'>
                        {(isAllowed(
                          moduleConstants.ECD,
                          projectData && projectData.userAssignData
                            ? projectData.userAssignData.length
                            : 0,
                          projectData.userAssignData
                        ) || (updateType == 'Approved' || updateType == 'DirectPromote')) ? (
                          ntpDatePicker
                        ) : (
                          <input
                            type='text'
                            className='form-control-plaintext'
                            id='ntpDateDsiplayText'
                            disabled
                            value={
                              projectData.ntpDate?.iso
                              ? dateFormatMMDDYYYY(projectData.ntpDate.iso)
                              : ''
                            }
                          />
                        )}
                        <span style={styleData} className='error'>
                          {formItemOptions?.ntpDate?.required &&
                            !isNtpDateFilled &&
                            'Notice To Proceed Date is required field'}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {isAllowed(
                  moduleConstants.ECD,
                  projectData && projectData.userAssignData ? projectData.userAssignData.length : 0,
                  projectData.userAssignData
                ) &&
                  this.showRowComponent('baselineStartDate') && (
                    <div className='row'>
                      <hr />
                      <div className={fieldClass}>
                        <p>
                          <strong>
                            Original Contract Start:
                            {formItemOptions?.baselineStartDate?.required && <span className='requiredField'>*</span>}
                          </strong>
                        </p>
                      </div>
                      {!hideCurrentFields && (
                        <div className={currentValueClass}>
                          <div className='form-group'>
                            <input
                              type='text'
                              className='form-control-plaintext'
                              id='companyname'
                              disabled
                              value={
                                projectData?.baselineStartDate?.iso
                                  ? dateFormatMMDDYYYY(projectData.baselineStartDate.iso)
                                  : projectData?.startDate?.iso
                                    ? dateFormatMMDDYYYY(projectData.startDate.iso)
                                    : ''
                              }
                            />
                          </div>
                        </div>
                      )}
                      <div className={newValueClass}>
                        <div className='form-group'>
                          <DatePickerWrapper
                            className={formItemOptions?.baselineStartDate?.lock ? 'form-control-plaintext' : 'form-control'}
                            selected={formatDateObjectOrNull(
                              this.state.selectDateBox.baselineStartDate
                            )}
                            onChange={this.handleDatePickerBidWithTime.bind(
                              this,
                              'baselineStartDate'
                            )}
                            timeIntervals={15}
                            dateFormat={appConstants.DATEFORMATPICKER}
                            timeCaption='time'
                            placeholderText={formItemOptions?.baselineStartDate?.lock ? 'NA' : 'Click to select a date'}
                            disabled={formItemOptions?.baselineStartDate?.lock}
                            selectsStart
                            todayButton='Today'
                            showMonthDropdown
                            startDate={formatDateObjectOrNull(
                              this.state.selectDateBox.baselineStartDate
                            )}
                            endDate={formatDateObjectOrNull(
                              this.state.selectDateBox.baselineEndDate
                            )}
                            maxDate={formatDateAdd(
                              5,
                              'months',
                              formatDateObjectOrNull(this.state.selectDateBox.baselineEndDate)
                            )}
                          />
                          <span className='error'>{startDateErrMsg}</span>
                        </div>
                      </div>
                    </div>
                  )}

                {isAllowed(
                  moduleConstants.ECD,
                  projectData && projectData.userAssignData ? projectData.userAssignData.length : 0,
                  projectData.userAssignData
                ) &&
                  this.showRowComponent('baselineEndDate') && (
                    <div className='row'>
                      <div className={fieldClass}>
                        <p>
                          <strong>
                            Original Contract End:
                            {formItemOptions?.baselineEndDate?.required && <span className='requiredField'>*</span>}
                          </strong>
                        </p>
                      </div>
                      {!hideCurrentFields && (
                        <div className={currentValueClass}>
                          <div className='form-group'>
                            <input
                              type='text'
                              className='form-control-plaintext'
                              id='companyname'
                              disabled
                              value={
                                projectData?.baselineEndDate?.iso
                                  ? dateFormatMMDDYYYY(projectData.baselineEndDate.iso)
                                  : projectData?.endDate?.iso
                                    ? dateFormatMMDDYYYY(projectData.endDate.iso)
                                    : ''
                              }
                            />
                          </div>
                        </div>
                      )}
                      <div className={newValueClass}>
                        <div className='form-group'>
                          <DatePickerWrapper
                            className={formItemOptions?.baselineEndDate?.lock ? 'form-control-plaintext' : 'form-control'}
                            selected={formatDateObjectOrNull(
                              this.state.selectDateBox.baselineEndDate
                            )}
                            onChange={this.handleDatePickerBidWithTime.bind(
                              this,
                              'baselineEndDate'
                            )}
                            timeIntervals={15}
                            dateFormat={appConstants.DATEFORMATPICKER}
                            timeCaption='time'
                            placeholderText={formItemOptions?.baselineEndDate?.lock ? 'NA' : 'Click to select a date'}
                            selectsEnd
                            todayButton='Today'
                            showMonthDropdown
                            startDate={formatDateObjectOrNull(
                              this.state.selectDateBox.baselineStartDate
                            )}
                            disabled={formItemOptions?.baselineEndDate?.lock}
                            endDate={formatDateObjectOrNull(
                              this.state.selectDateBox.baselineEndDate
                            )}
                            minDate={formatDateObject(
                              this.state.selectDateBox.baselineStartDate
                            ).add(1, 'day')}
                          /*maxDate={formatDateObject(this.state.selectDateBox.baselineEndDate).add(
                            5,
                            'months'
                          )}*/
                          />
                        </div>
                      </div>
                    </div>
                  )}
                {isAllowed(
                  moduleConstants.ECD,
                  projectData && projectData.userAssignData ? projectData.userAssignData.length : 0,
                  projectData.userAssignData
                ) &&
                  this.showRowComponent('currentBaselineEndDate') && (
                    <div className='row'>
                      <div className={fieldClass}>
                        <p>
                          <strong>
                            Current Contract End:
                            {formItemOptions?.currentBaselineEndDate?.required && <span className='requiredField'>*</span>}
                          </strong>
                        </p>
                      </div>
                      {!hideCurrentFields && (
                        <div className={currentValueClass}>
                          <div className='form-group'>
                            <input
                              type='text'
                              className='form-control-plaintext'
                              id='companyname'
                              disabled
                              value={
                                projectData?.currentBaselineEndDate?.iso
                                  ? dateFormatMMDDYYYY(projectData.currentBaselineEndDate.iso)
                                  : projectData?.baselineEndDate?.iso
                                    ? dateFormatMMDDYYYY(projectData.baselineEndDate.iso)
                                    : projectData?.endDate?.iso
                                      ? dateFormatMMDDYYYY(projectData.endDate.iso)
                                      : ''
                              }
                            />
                          </div>
                        </div>
                      )}
                      <div className={newValueClass}>
                        <div className='form-group'>
                          <DatePickerWrapper
                            className={formItemOptions?.currentBaselineEndDate?.lock ? 'form-control-plaintext' : 'form-control'}
                            selected={formatDateObjectOrNull(
                              this.state.selectDateBox.currentBaselineEndDate
                            ) ?? formatDateObjectOrNull(
                              this.state.selectDateBox.baselineEndDate
                            )}
                            onChange={this.handleDatePickerBidWithTime.bind(
                              this,
                              'currentBaselineEndDate'
                            )}
                            timeIntervals={15}
                            dateFormat={appConstants.DATEFORMATPICKER}
                            timeCaption='time'
                            placeholderText={formItemOptions?.currentBaselineEndDate?.lock ? 'NA' : 'Click to select a date'}
                            selectsEnd
                            todayButton='Today'
                            showMonthDropdown
                            startDate={formatDateObjectOrNull(
                              this.state.selectDateBox.currentBaselineEndDate
                            )}
                            disabled={formItemOptions?.currentBaselineEndDate?.lock}
                            endDate={formatDateObjectOrNull(
                              this.state.selectDateBox.baselineEndDate
                            )}
                            minDate={formatDateObject(
                              this.state.selectDateBox.baselineStartDate
                            ).add(0, 'day')}
                          /*maxDate={formatDateObject(this.state.selectDateBox.baselineEndDate).add(
                            5,
                            'months'
                          )}*/
                          />
                        </div>
                      </div>
                    </div>
                  )}

                {canEditCurrentStartEndDates &&
                  this.showRowComponent('startDate') && (
                    <><hr />
                      <div className='row'>

                        <div className={fieldClass}>
                          <p>
                            <strong>
                              {isBidding ? 'Const. ' : 'Actual'} Start Date:
                              {formItemOptions?.startDate?.required && <span className='requiredField'>*</span>}
                            </strong>
                          </p>
                        </div>
                        {!hideCurrentFields && (
                          <div className={currentValueClass}>
                            <div className='form-group'>
                              <input
                                type='text'
                                className='form-control-plaintext'
                                id='startDate'
                                disabled
                                value={
                                  projectData &&
                                    projectData.startDate &&
                                    dateFormatMMDDYYYY(projectData.startDate.iso)
                                    ? dateFormatMMDDYYYY(projectData.startDate.iso)
                                    : ''
                                }
                              />
                            </div>
                          </div>
                        )}
                        <div className={newValueClass}>
                          <div className='form-group'>
                            <DatePickerWrapper
                              className={formItemOptions?.startDate?.lock ? 'form-control-plaintext' : 'form-control'}
                              selected={formatDateObjectOrNull(this.state.selectDateBox.startDate)}
                              onChange={this.handleDatePickerBid.bind(this, 'startDate')}
                              timeIntervals={15}
                              dateFormat={appConstants.DATEFORMATPICKER}
                              timeCaption='time'
                              placeholderText={formItemOptions?.startDate?.lock ? 'NA' : 'Click to select a date'}
                              disabled={formItemOptions?.startDate?.lock}
                              todayButton='Today'
                              showMonthDropdown
                              selectsStart
                              startDate={formatDateObjectOrNull(this.state.selectDateBox.startDate)}
                              required={formItemOptions?.startDate?.required}
                              endDate={formatDateObject(this.state.selectDateBox.endDate)}
                             
                              maxDate={formatDateAdd(5, 'months')}
                            />
                            <span className='error'>{startDateErrMsg}</span>
                          </div>
                        </div>
                      </div></>
                  )}
                {this.state.startDateReasonReq === true ? (
                  <div className='row mb-2'>
                    <div className={fieldClass}>
                      <p>
                        <strong>Start Date change reason:</strong>
                      </p>
                    </div>
                    {!hideCurrentFields && (
                      <div className={currentValueClass}>
                        <div className='form-group'></div>
                      </div>
                    )}
                    <div className={newValueClass}>
                      <div className='form-group'>
                        <Select
                          name='startDate'
                          components={makeAnimated()}
                          value={this.state.selectedStartDateReason}
                          onChange={this.handleStartDateReasonCodeChange.bind(this)}
                          options={dateChangeReasonCodes}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}

                {this.showRowComponent('deliveredDate') && (
                  <div className='row'>
                    <div className={fieldClass}>
                      <p>
                        <strong>Delivered Date:</strong> {formItemOptions?.deliveredDate?.required && <span className='requiredField'>*</span>}
                      </p>
                    </div>
                    {!hideCurrentFields && (
                      <div className={currentValueClass}>
                        <div className='form-group'>
                          <input
                            type='text'
                            className='form-control-plaintext'
                            id='companyname'
                            disabled
                            value={
                              projectData.deliveredDate?.iso
                                ? dateFormatMMDDYYYY(projectData.deliveredDate.iso)
                                : ''
                            }
                          />
                        </div>
                      </div>
                    )}
                    <div className={newValueClass}>
                      <div className='form-group'>
                        <DatePickerWrapper
                          className={formItemOptions?.deliveredDate?.lock ? 'form-control-plaintext' : 'form-control'}
                          selected={
                            selectDateBox.deliveredDate
                              ? formatDateObjectOrNull(selectDateBox.deliveredDate)
                              : moment(projectData.endDate?.iso).isBefore(moment()) ? formatDateObjectOrNull(projectData.endDate) : ''
                          }
                          onChange={this.handleDatePickerBid.bind(this, 'deliveredDate')}
                          timeIntervals={15}
                          dateFormat={appConstants.DATEFORMATPICKER}
                          timeCaption='time'
                          placeholderText={formItemOptions?.deliveredDate?.lock ? 'NA' : 'Click to select a date'}
                          selectsEnd
                          isClearable={!formItemOptions?.deliveredDate?.lock}
                          todayButton='Today'
                          showMonthDropdown
                          disabled={formItemOptions?.deliveredDate?.lock}
                          startDate={formatDateObjectOrNull(selectDateBox.startDate)}
                          endDate={formatDateObjectOrNull(selectDateBox.deliveredDate)}
                          minDate={formatDateObjectOrNull(selectDateBox.startDate)}
                          maxDate={formatDateObject(Date())}
                          required={formItemOptions?.deliveredDate?.required}
                        />
                        <span style={styleData} className='error'>
                          {!selectDateBox.deliveredDate && validation.errors.first('deliveredDate')}
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                {this.showRowComponent('closeoutDate') && (
                  <div className='row'>
                    <div className={fieldClass}>
                      <p>
                        <strong>Closeout Date:</strong>
                        {formItemOptions?.closeoutDate?.required && <span className='requiredField'>*</span>}
                      </p>
                    </div>
                    {!hideCurrentFields && (
                      <div className={currentValueClass}>
                        <div className='form-group'>
                          <input
                            type='text'
                            className='form-control-plaintext'
                            id='companyname'
                            disabled
                            value={
                              projectData.closeoutDate?.iso
                                ? dateFormatMMDDYYYY(projectData.closeoutDate.iso)
                                : ''
                            }
                          />
                        </div>
                      </div>
                    )}
                    <div className={newValueClass}>
                      <div className='form-group'>
                        <DatePickerWrapper
                          className={formItemOptions?.closeoutDate?.lock ? 'form-control-plaintext' : 'form-control'}
                          selected={
                            selectDateBox.closeoutDate
                              ? formatDateObjectOrNull(selectDateBox.closeoutDate)
                              : selectDateBox.deliveredDate ? formatDateObjectOrNull(selectDateBox.deliveredDate) : ''
                          }
                          onChange={this.handleDatePickerBid.bind(this, 'closeoutDate')}
                          timeIntervals={15}
                          dateFormat={appConstants.DATEFORMATPICKER}
                          timeCaption='time'
                          placeholderText={formItemOptions?.closeoutDate?.lock ? 'NA' : 'Click to select a date'}
                          selectsEnd
                          isClearable={!formItemOptions?.closeoutDate?.lock}
                          todayButton='Today'
                          showMonthDropdown
                          disabled={formItemOptions?.closeoutDate?.lock}
                          startDate={formatDateObjectOrNull(selectDateBox.deliveredDate)}
                          endDate={formatDateObjectOrNull(selectDateBox.deliveredDate)}
                          minDate={ formatDateObjectOrNull(selectDateBox.startDate)}
                          maxDate={formatDateObject(Date())}
                          required={formItemOptions?.closeoutDate?.required}
                        />
                        <span style={styleData} className='error'>
                          {!selectDateBox.closeoutDate && validation.errors.first('closeoutDate')}
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                {canEditCurrentStartEndDates &&
                  this.showRowComponent('endDate') && (
                    <div className='row'>
                      <div className={fieldClass}>
                        <p>
                          <strong>
                            {isBidding ? 'Const. ' : 'Anticipated '}End Date:
                            {formItemOptions?.endDate?.required && <span className='requiredField'>*</span>}
                          </strong>
                        </p>
                      </div>
                      {!hideCurrentFields && (
                        <div className={currentValueClass}>
                          <div className='form-group'>
                            <input
                              type='text'
                              className='form-control-plaintext'
                              id='companyname'
                              disabled
                              value={
                                projectData &&
                                  projectData.endDate &&
                                  dateFormatMMDDYYYY(projectData.endDate.iso)
                                  ? dateFormatMMDDYYYY(projectData.endDate.iso)
                                  : ''
                              }
                            />
                          </div>
                        </div>
                      )}
                      <div className={newValueClass}>
                        <div className='form-group'>
                          <DatePickerWrapper
                            className={formItemOptions?.endDate?.lock ? 'form-control-plaintext' : 'form-control'}
                            selected={formatDateObjectOrNull(this.state.selectDateBox.endDate)}
                            onChange={this.handleDatePickerBid.bind(this, 'endDate')}
                            timeIntervals={15}
                            dateFormat={appConstants.DATEFORMATPICKER}
                            timeCaption='time'
                            placeholderText={formItemOptions?.endDate?.lock ? 'NA' : 'Click to select a date'}
                            selectsEnd
                            todayButton='Today'
                            showMonthDropdown
                            startDate={formatDateObjectOrNull(this.state.selectDateBox.startDate)}
                            endDate={formatDateObjectOrNull(this.state.selectDateBox.endDate)}
                            disabled={formItemOptions?.startDate?.lock}
                            //  endDate={formatDateObject(this.state.selectDateBox.endDate)}
                            minDate={formatDateObject(this.state.selectDateBox.startDate).add(
                              1,
                              'day'
                            )}
                            maxDate={formatDateAdd(5, 'years')}
                            required={formItemOptions?.endDate?.required}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                {this.state.endDateReasonReq === true ? (
                  <div className='row mb-2'>
                    <div className={fieldClass}>
                      <p>
                        <strong>End Date change reason:</strong>
                      </p>
                    </div>
                    {!hideCurrentFields && (
                      <div className={currentValueClass}>
                        <div className='form-group'></div>
                      </div>)}
                    <div className={newValueClass}>
                      <div className='form-group'>
                        <Select
                          name='endDate'
                          components={makeAnimated()}
                          value={this.state.selectedEndDateReason}
                          onChange={this.handleEndDateReasonCodeChange.bind(this)}
                          options={dateChangeReasonCodes}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}

                {isAllowed(moduleConstants.VIEWCONTRACTVAL, 'assigned',
                  projectData.userAssignData) && isAllowed(
                    moduleConstants.EBAGP,
                    projectData && projectData.userAssignData ? projectData.userAssignData.length : 0,
                    projectData.userAssignData
                  ) &&
                  this.state.setOriginalContract &&
                  this.showRowComponent('originalContractAmount') && (
                    <div className='row'>
                      <hr />
                      <div className={fieldClass}>
                        <p>
                          <strong>Original Contract Value:</strong>{' '}
                          {formItemOptions?.originalContractAmount?.required && <span className='requiredField'>*</span>}
                        </p>
                      </div>
                      {!hideCurrentFields && (
                        <div className={currentValueClass}>
                          <div className='form-group'>
                            <input
                              type='text'
                              className='form-control-plaintext'
                              id='originalContractAmount'
                              disabled
                              value={numberToCurrency(projectData?.originalContractAmount,'$')}
                              placeholder='Original Contract Value'
                            />
                          </div>
                        </div>
                      )}
                      <div className={newValueClass}>
                        <div className='form-group'>
                          {isAllowed(
                            moduleConstants.EBAGP,
                            projectData?.userAssignData?.length > 0
                              ? projectData.userAssignData.length
                              : 0,
                            projectData.userAssignData
                          ) ? (
                            <input
                              type='text'
                              className={formItemOptions?.originalContractAmount?.lock ? 'form-control-plaintext' : 'form-control'}
                              onChange={(e) => {
                                this.handleStatusUpdateChange(e);
                              }}
                              name='originalContractAmount'
                              value={this.state.statusUpdateProject?.originalContractAmount}
                              disabled={formItemOptions?.originalContractAmount?.lock}
                              placeholder='Original Contract Value'
                            />
                          ) : (
                            <input
                              type='text'
                              className='form-control-plaintext'
                              id='originalContractAmount'
                              disabled
                              value={numberToCurrency(projectData?.originalContractAmount,'$')}
                              placeholder='Original Contract Value'
                            />
                          )}
                          <span style={styleData} className='error'>
                            {this.state.formArr.originalContractAmount &&
                              validation.errors.first('originalContractAmount')}

                            {!isOriginalContractAmountNumeric &&
                              'Contract amount should include only numbers'}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                {isAllowed(moduleConstants.VIEWPROFIT, 'assigned',
                  projectData.userAssignData) && isAllowed(
                    moduleConstants.EBAGP,
                    projectData?.userAssignData?.length > 0 ? projectData.userAssignData.length : 0,
                    projectData.userAssignData
                  ) &&
                  this.state.setOriginalContract &&
                  this.showRowComponent('originalGrossProfit') && (
                    <div className='row'>
                      <div className={fieldClass}>
                        <p>
                          <strong>Initial Profit Estimate:</strong>
                        </p>
                      </div>
                      {!hideCurrentFields && (
                        <div className={currentValueClass}>
                          <div className='form-group'>
                            <input
                              type='text'
                              className='form-control-plaintext'
                              id='companyname'
                              disabled
                              value={numberToCurrency(projectData?.originalGrossProfit,'$')}
                              placeholder='Initial Gross Profit Estimate'
                            />
                          </div>
                        </div>
                      )}
                      <div className={newValueClass}>
                        <div className='form-group'>
                          <input
                            type='text'
                            className={formItemOptions?.originalGrossProfit?.lock ? 'form-control-plaintext' : 'form-control'}
                            onChange={(e) => {
                              this.handleStatusUpdateChange(e);
                            }}
                            name='originalGrossProfit'
                            value={this.state.statusUpdateProject?.originalGrossProfit}
                            disabled={formItemOptions?.originalGrossProfit?.lock}
                            id='companyname'
                            placeholder='Initial Gross Profit Estimate'
                          />
                          <span style={styleData} className='error'>
                            {!isOriginalGrossProfitNumeric
                              ? 'Profit amount should include only numbers'
                              : errorMsg}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}

                {isAllowed(moduleConstants.VIEWCONTRACTVAL, 'assigned',
                  projectData.userAssignData) && isAllowed(
                    moduleConstants.EBAGP,
                    projectData?.userAssignData?.length > 0 ? projectData.userAssignData.length : 0,
                    projectData.userAssignData
                  ) &&
                  !this.state.setOriginalContract && 
                  this.showRowComponent('currentContractAmount') && (
                    <div className='row'>
                      <hr />
                      <div className={fieldClass}>
                        <p>
                          <strong>Contract Amount:</strong>
                          {formItemOptions?.currentContractAmount?.required && <span className='requiredField'>*</span>}
                        </p>
                      </div>
                      {!hideCurrentFields && (
                        <div className={currentValueClass}>
                          <div className='form-group'>
                            <input
                              type='text'
                              className='form-control-plaintext'
                              id='companyname'
                              disabled
                              value={numberToCurrency(projectData?.currentContractAmount,'$')}
                              placeholder='Contract Amount'
                            />
                          </div>
                        </div>
                      )}
                      <div className={newValueClass}>
                        <div className='form-group'>
                          {(!formItemOptions?.currentContractAmount?.lock) && isAllowed(
                            moduleConstants.EBAGP,
                            projectData && projectData.userAssignData
                              ? projectData.userAssignData.length
                              : 0,
                            projectData.userAssignData
                          ) ? (
                            <input
                              type='text'
                              className={formItemOptions?.currentContractAmount?.lock ? 'form-control-plaintext' : 'form-control'}
                              onChange={(e) => {
                                this.handleStatusUpdateChange(e);
                              }}
                              name='currentContractAmount'
                              value={
                                this.state.statusUpdateProject?.currentContractAmount
                              }
                              disabled={formItemOptions?.currentContractAmount?.lock}
                              placeholder='Contract Amount'
                            />
                          ) : (
                            <input
                              type='text'
                              className='form-control-plaintext'
                              id='currentContractAmountNew'
                              disabled
                              value={numberToCurrency(projectData?.currentContractAmount,'$')}
                              placeholder='Contract Amount'
                            />
                          )}
                          <span style={styleData} className='error'>
                            {this.state.formArr.currentContractAmount &&
                              validation.errors.first('currentContractAmount')}

                            {!isContractAmountNumeric &&
                              'Contract amount should include only numbers'}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                {isAllowed(moduleConstants.VIEWPROFIT, 'assigned',
                  projectData.userAssignData) && isAllowed(
                    moduleConstants.EBAGP,
                    projectData && projectData.userAssignData ? projectData.userAssignData.length : 0,
                    projectData.userAssignData
                  ) &&
                  !this.state.setOriginalContract &&
                  this.showRowComponent('currentContractAmount') && (
                    <div className='row'>
                      <div className={fieldClass}>
                        <p>
                          <strong>Gross Profit:</strong>
                        </p>
                      </div>
                      {!hideCurrentFields && (
                        <div className={currentValueClass}>
                          <div className='form-group'>
                            <input
                              type='text'
                              className='form-control-plaintext'
                              id='currentGrossProfit'
                              disabled
                              value={numberToCurrency(projectData?.currentGrossProfit,'$')}
                              placeholder='Gross Profit'
                            />
                          </div>
                        </div>
                      )}
                      <div className={newValueClass}>
                        <div className='form-group'>
                          {(!formItemOptions?.currentGrossProfit?.lock) && isAllowed(
                            moduleConstants.EBAGP,
                            projectData && projectData.userAssignData
                              ? projectData.userAssignData.length
                              : 0,
                            projectData.userAssignData
                          ) ? (
                            <input
                              type='text'
                              className={formItemOptions?.currentGrossProfit?.lock ? 'form-control-plaintext' : 'form-control'}
                              onChange={(e) => {
                                this.handleStatusUpdateChange(e);
                              }}
                              name='currentGrossProfit'
                              value={
                                this.state.statusUpdateProject?.currentGrossProfit
                              }
                              disabled={formItemOptions?.currentGrossProfit?.lock}
                              placeholder='Gross Profit'
                            />
                          ) : (
                            <input
                              type='text'
                              className='form-control-plaintext'
                              id='currentGrossProfitNew'
                              disabled
                              value={numberToCurrency(projectData?.currentGrossProfit,'$')}
                              placeholder='Gross Profit'
                            />
                          )}
                          <span style={styleData} className='error'>
                            {!isGrossProfitNumeric
                              ? 'Profit amount should include only numbers'
                              : errorMsg}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                <hr />
                <div className='row'>
                  <div className={fieldClass}>
                    <p>
                      <strong>Comment:</strong>
                    </p>
                  </div>
                  <div className={commentClass}>
                    <div className='form-group'>
                      <textarea
                        className='form-control'
                        rows='2'
                        id='comment'
                        onChange={(e) => {
                          this.handleStatusUpdateChange(e);
                        }}
                        name='comment'
                        value={
                          this.state.statusUpdateProject && this.state.statusUpdateProject.comment
                        }
                        placeholder='Comment'
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter className='modalFooter'>
            <button className='btn btn-primary' onClick={this.toggleStatusUpdateReset}>
              Cancel
            </button>
            {/*<button
              className='btn btn-primary'
              disabled={this.props.buttonActive}
              onClick={this.changeOrderHistory}
            >
              View History
                      </button>*/}
            <button
              className='btn btn-primary'
              disabled={
                this.props.buttonActive ||
                PreventUpdate ||
                !isContractAmountNumeric ||
                !isGrossProfitNumeric ||
                errorMsg ||
                susErrorMsg ||
                startDateErrMsg
              }
              onClick={actionButtonOnClick}
            >
              {this.props.buttonActive ? 'Loading...' : actionButtonLabel}
            </button>
          </ModalFooter>
        </Modal>

        <QuickHistoryModal
          isOpen={this.state.projectStatusHistoryModal}
          toggle={this.changeOrderHistory}
          onToggleChangeEventDateModal={this.changeEventDate}
          onRowClick={this.selectedHistoryRow}
        />

        <ChangeEventDateModal
          isOpen={this.state.eventDateModal}
          toggle={this.changeEventDate}
          eventDateData={this.state.eventDateData}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    projectData: state.project.projectData,
    buttonActive: state.projectStatus?.buttonActive,
    allProjectStatus: state.jobStatusCode,
    reasonCodes: state.reasonCode,
    projectStatusUpdate: state.projectStatus.projectStatusUpdate,
    projectStatusResult: state.projectStatus.successStatus,
    allJobStatusCodes: state.jobStatusCode.allJobStatus,
    allReasonCodeByStatusCode: state.reasonCode.allReasonCodeByStatusCode,
    generalInfo: selectGeneralInfo(state),
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getExistingProjectAction,
    getReasonCodesByStatusCodeAction,
    updateProjectStatusAction,
    getAccountSettingsAction,
    getAllJobStatusAction,
  })(ProjectStatusModal)
);
