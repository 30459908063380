import { columnDescriptor } from '../../shared/data-grid/use-row-selection/use-row-selection';
import {
  cellFormatter,
  uploadDescriptor,
  errorDescriptor,
} from '../../shared/data-grid/import-components';
import moment from 'moment';

const fieldArrFormatter = (arrayName, fieldName, row) => {
  let index = 0;
  let getNextValue = true;

  if (Object.hasOwn(row, arrayName) && Array.isArray(row[arrayName])) {
    const itemArray = row[arrayName];

    const textArr: Array<string> = [];
    itemArray.forEach((item) => {
      const fieldItem = item[fieldName];

      if (fieldItem?.length > 0) {
        textArr.push(fieldItem || '');
      } else {
        textArr.push('\u00A0');
      }
    });

    return (
      <table>
        {textArr.map((text, index) => (
          <tr key={index}>{text || '\u00A0'}</tr>
        ))}
      </table>);
  } else {

    const textArr: Array<string> = [];
    while (getNextValue) {
      const propertyName = `${arrayName}[${index}].${fieldName}`;
      if (Object.hasOwn(row, propertyName)) {
        textArr.push(row[propertyName] || '');
        index++;
      } else {
        getNextValue = false;
      }
    }

    return (
      <table>
        {textArr.map((text, index) => (
          <tr key={index}>{text || '\u00A0'}</tr>
        ))}
      </table>
    );
  }
};

export const gridColumns = [
  errorDescriptor,
  columnDescriptor,
  uploadDescriptor,
  {
    Header: 'First Name',
    accessor: 'firstName',
    Cell: cellFormatter(),
    width: 120,
  },
  {
    Header: 'Middle Name',
    accessor: 'middleName',
    Cell: cellFormatter(),
    width: 120,
  },
  {
    Header: 'Last Name',
    accessor: 'lastName',
    Cell: cellFormatter(),
    width: 120,
  },
  {
    Header: '',
    accessor: 'isEmployee',
    Cell: cellFormatter((_, row) => {
      const { affiliations = [] } = row;
      const checked = affiliations.some((aff) => aff.isEmployee);

      return <input type='checkbox' checked={checked} />;
    }),
    width: 20,
  },
  {
    Header: 'Company Name(s)',
    id: 'companyName',
    accessor: 'affiliations[0].companyName.companyName',
    Cell: cellFormatter((_, row) => {
      const { affiliations = [] } = row;
      const affCompanyNames = affiliations
        .map((aff) => {
          if (!aff.companyName) {
            return '<No Company Selected>';
          }

          if (typeof aff.companyName === 'string') {
            return aff.companyName;
          }

          return aff.companyName.companyName;
        });

      const cellValue = affCompanyNames.filter((val) => val).join(', ');

      return cellValue === '' ? '<No Company Selected>' : cellValue;
    }),
    width: 200,
  },
  {
    Header: 'Contact Role',
    id: 'contactRole',
    accessor: 'affiliations[0].contactRole',
    Cell: cellFormatter((value) => (typeof value === 'string' ? value : value?.name ?? '')),
    width: 120,
  },
  {
    Header: 'Department',
    id: 'department',
    accessor: 'affiliations[0].department',
    Cell: cellFormatter(),
    width: 120,
  },
  {
    Header: 'Title',
    id: 'title',
    accessor: 'affiliations[0].title',
    Cell: cellFormatter(),
    width: 120,
  },
  {
    Header: 'Birthday',
    id: 'birthday',
    accessor: 'birthday',
    Cell: cellFormatter((value) => {
      if (!value) {
        return '';
      }

      return moment(value).format('L');
    }),
    width: 120,
  },
  {
    Header: 'Comments',
    id: 'comments',
    accessor: 'comments',
    Cell: cellFormatter(),
    width: 120,
  },
  {
    Header: 'Employee ID',
    id: 'employeeID',
    accessor: 'employeeID',
    Cell: cellFormatter((_, row) => {
      const { affiliations = [] } = row;
      const employeeIDs = affiliations
        .filter((aff) => aff.companyName !== '') // skip self affiliations
        .map((aff) => aff.employeeID);

      return employeeIDs.filter((val) => val).join(', ');
    }),
    width: 120,
  },
  {
    Header: 'Independent Contractor',
    accessor: 'affiliations[0].independentContractor',
    Cell: cellFormatter((v) => <input type='checkbox' checked={v} />),
    width: 120,
  },
  {
    Header: 'Email',
    id: 'email',
    accessor: 'affiliations[0]',
    arrayName: 'emailArr',
    Cell: cellFormatter((value) => fieldArrFormatter('emailArr', 'email', value)),
    width: 200,
  },
  {
    Header: 'Email Label',
    id: 'emailLabel',
    accessor: 'affiliations[0]',
    arrayName: 'emailArr',
    Cell: cellFormatter((value) => fieldArrFormatter('emailArr', 'label', value)),
    width: 120,
  },
  {
    Header: 'Phone Type',
    id: 'phoneType',
    accessor: 'affiliations[0]',
    arrayName: 'phoneArr',
    Cell: cellFormatter((value) => fieldArrFormatter('phoneArr', 'type', value)),
    width: 120,
  },
  {
    Header: 'Phone Country Code',
    id: 'phoneCountryCode',
    accessor: 'affiliations[0]',
    arrayName: 'phoneArr',
    Cell: cellFormatter((value) => fieldArrFormatter('phoneArr', 'code', value)),
    width: 120,
  },
  {
    Header: 'Phone Number',
    id: 'phoneNumber',
    accessor: 'affiliations[0]',
    arrayName: 'phoneArr',
    Cell: cellFormatter((value) => fieldArrFormatter('phoneArr', 'phone', value)),
    width: 120,
  },
  {
    Header: 'Phone Ext',
    id: 'phoneExt',
    accessor: 'affiliations[0]',
    arrayName: 'phoneArr',
    Cell: cellFormatter((value) => fieldArrFormatter('phoneArr', 'extPhone', value)),
    width: 120,
  },
  {
    Header: 'Address Label',
    id: 'addressLabel',
    accessor: 'affiliations[0]',
    arrayName: 'addressArr',
    Cell: cellFormatter((value) => fieldArrFormatter('addressArr', 'label', value)),
    width: 120,
  },
  {
    Header: 'Address 1',
    id: 'address1',
    accessor: 'affiliations[0]',
    arrayName: 'addressArr',
    Cell: cellFormatter((value) => fieldArrFormatter('addressArr', 'address1', value)),
    width: 120,
  },
  {
    Header: 'Address 2',
    id: 'address2',
    accessor: 'affiliations[0]',
    arrayName: 'addressArr',
    Cell: cellFormatter((value) => fieldArrFormatter('addressArr', 'address2', value)),
    width: 120,
  },
  {
    Header: 'City',
    id: 'city',
    accessor: 'affiliations[0]',
    arrayName: 'addressArr',
    Cell: cellFormatter((value) => fieldArrFormatter('addressArr', 'city', value)),
    width: 120,
  },
  {
    Header: 'State',
    id: 'state',
    accessor: 'affiliations[0]',
    arrayName: 'addressArr',
    Cell: cellFormatter((value) => fieldArrFormatter('addressArr', 'state', value)),
    width: 120,
  },
  {
    Header: 'Country',
    id: 'country',
    accessor: 'affiliations[0]',
    arrayName: 'addressArr',
    Cell: cellFormatter((value) => fieldArrFormatter('addressArr', 'country', value)),
    width: 120,
  },
  {
    Header: 'Zip Code',
    id: 'zipCode',
    accessor: 'affiliations[0]',
    arrayName: 'addressArr',
    Cell: cellFormatter((value) => fieldArrFormatter('addressArr', 'zipCode', value)),
    width: 120,
  },
];
