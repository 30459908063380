import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { validateContactName } from '../../modules/contacts';
import { useAppDispatch } from '../../shared/hooks/use-app-dispatch';
import { selectValidateContactName } from '../../modules/contacts/selectors';

interface Company {
  _id: string;
  companyName: string;
}

interface ContactAffiliation {
  company: Company;
  title?: string;
}

interface Contact {
  _id: string;
  firstName: string;
  lastName: string;
  middleName: string;
  prefix: string;
  suffix: string;
  affiliations: ContactAffiliation[];
}

interface ValidationData {
  email?: { email: string }[];
  phone?: { phone: string; phoneClean: string; extPhone?: string }[];
}

interface GroupedValidationData {
  type: 'email' | 'phone' | 'name';
  value: any;
  data: any[];
}

interface MatchingContactsContextType {
  pushValidationData: (args: { affiliationIndex: number; type: string; data: any[] }) => void;
  groupedValidationData: GroupedValidationData[];
  nameMatchingContacts: Contact[];
  setCurrentName: (name: { firstName: string; lastName: string, middleName: string, prefix: string, suffix: string, contactId:string } | null) => void;
}

const MatchingContactsContext = createContext<MatchingContactsContextType | undefined>(undefined);

interface MatchingContactsProviderProps {
  children: React.ReactNode;
  onGroupedValidationDataChange?: (data: GroupedValidationData[]) => void;
}

export const MatchingContactsProvider = ({
  children,
  onGroupedValidationDataChange = () => void 0,
}: MatchingContactsProviderProps) => {
  const dispatch = useAppDispatch();
  const [validationData, setValidationData] = useState<ValidationData[]>([]);
  const [nameMatchingContacts, setNameMatchingContacts] = useState<Contact[]>([]);
  const [currentName, setCurrentName] = useState<{ firstName: string; lastName: string, middleName: string, prefix: string, suffix: string, contactId: string } | null>(null);

  const pushValidationData = (args: { affiliationIndex: number; type: string; data: any[] }) => {
    setValidationData((prev) => {
      const newArr = [...prev];
      const element = { ...(newArr[args.affiliationIndex] || {}) };
      element[args.type] = args.data;
      newArr[args.affiliationIndex] = element;
      return newArr;
    });
  };


  const groupedValidationData = useMemo(() => {
    const result: GroupedValidationData[] = [];

    validationData.filter(Boolean).forEach(({ email=[], phone=[] }) => {

      phone.forEach((phoneData) => {
        if (!phoneData) return;

        const phoneValue = phoneData[0].phone;

        const foundValue = result.find(
          (item) =>
            item.value.phoneClean === phoneValue.phoneClean &&
            (!phoneValue.extPhone
              ? [null, '', undefined].includes(item.value.extPhone)
              : item.value.extPhone === phoneValue.extPhone)
        );

        if (foundValue) return;

        result.push({
          type: 'phone',
          value: phoneValue,
          data: phoneData as any,
        });
      });

      email.forEach((emailData) => {
        if (!emailData) return;

        const emailValue = emailData[0].email;

        const foundValue = result.find((item) => item.value.email === emailValue.email);

        if (foundValue) return;

        result.push({
          type: 'email',
          value: emailValue,
          data: emailData as any,
        });
      });
    });

    if (nameMatchingContacts.length > 0) {
      result.push({
        type: 'name',
        value: currentName,
        data: nameMatchingContacts.map((contact) => ({
          _id: contact._id,
          contactId: contact._id,
          contactFullName: [contact.prefix, contact.firstName, contact.middleName, contact.lastName, contact.suffix].filter(Boolean).join(' '),
          affiliations: contact.affiliations,
        })),
      });
    }

    return result;
  }, [validationData, nameMatchingContacts, currentName]);

  const validationStatus = useSelector(selectValidateContactName)


  useEffect(() =>  {
    if (currentName && (currentName.firstName?.length > 0 || currentName.lastName?.length > 0)) {
      dispatch(validateContactName({
        firstName: currentName.firstName?.toLowerCase()?.trim(),
        lastName: currentName.lastName?.toLowerCase()?.trim(),
        middleName: currentName.middleName?.toLowerCase()?.trim(),
        suffix: currentName.suffix?.toLowerCase()?.trim(),
        prefix: currentName.prefix?.toLowerCase()?.trim(),
        contactId: currentName.contactId,
      }));
    }
  }, [currentName, dispatch]);

  useEffect(() => {
    if (!validationStatus.error && !validationStatus.loading  && currentName) {
      const results = validationStatus.data as Contact[];
      setNameMatchingContacts(results)
    }
    else if (validationStatus.error) {
      console.error('Error searching for matching contacts');
    }
  }, [validationStatus, currentName]);

  useEffect(() => {
    onGroupedValidationDataChange(groupedValidationData);
  }, [groupedValidationData]);

  const contextValue: MatchingContactsContextType = {
    pushValidationData,
    groupedValidationData,
    nameMatchingContacts,
    setCurrentName,
  };

  return (
    <MatchingContactsContext.Provider
    value={contextValue}
    >
      {children}
    </MatchingContactsContext.Provider>
  );
};

export const useMatchingContactsContext = () => {
  const context = useContext(MatchingContactsContext);
  if (!context) {
    throw new Error('useMatchingContactsContext must be used within a MatchingContactsProvider');
  }
  return context as any;
};
