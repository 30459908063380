
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import GlobalSearchBox from './GlobalSearchBox.component.jsx';
import { useNavigationContext } from '../../shared/navigation-provider';
import { useEffect } from 'react';

function MainMenu({props}) {
  const { t } = useTranslation();

  const { mainMenu, refreshMainMenu } = useNavigationContext();

  useEffect(() => {
    refreshMainMenu();
  }, []);

  const displayMenuForSection = (section) => {
    const menu = mainMenu?.[section];

    if (!menu) return null;

    return menu.map((item, reportIdx) => {
      if (!item.isAllowed) return null;

      if(item.reportType === 'divider'){
        return  (<div 
        className={'dropdown-item nav-divider  '+(item.label?.length>0?'has-label':'')}
        key={item.value}
        data-label={item.label}
      />);
      }

      return (
        <li className='dropdown-item nav-item' key={item.value}>
          <Link
            className=''
            to={{
              pathname: item.path,
              state: { ...props.rowData },
            }}
            data-tooltip-content={t(item.tooltip)}
            data-tooltip-id={reportIdx + item.value}
          >
            {item.label}
          </Link>
          <Tooltip id={reportIdx + item.value} place='right' delayShow={1000} />
        </li>
      );
    });
  };

  const highlightedClassName = (section) => {
    return section === props.menuSection ? 'nav-item dropdown active' : 'nav-item dropdown';
  }

  return (
    <nav className='navbar navbar-expand-lg navbar-dark bg-dark'>
      <div className='container-fluid no-print'>
        <button
          className='navbar-toggler order-2 order-xl-0'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navBar7'
          aria-controls='navBar7'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <div className=' order-lg-last order-2 order-1 order-0  searchbox navbar-dark bg-dark'>
          <GlobalSearchBox props={props} />
        </div>

        <div className='collapse navbar-collapse order-lg-first' id='navBar7'>
          <div className='navigationBox'>
            <ul className='navbar-nav '>
              <li className={highlightedClassName('leads')} id='navbarDropdownMenuSection'>
                <a
                  className='nav-link dropdown-toggle'
                  href='#'
                  id='navbarDropdownMenuLink'
                  role='button'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  {t('Leads')}
                </a>

                <ul className='dropdown-menu ' aria-labelledby='navbarDropdownMenuLink'>
                  {displayMenuForSection('leads')}
                </ul>
              </li>
              <li className={highlightedClassName('bidding')} id='navbarDropdownMenuSection'>
                <a
                  className='nav-link dropdown-toggle'
                  href='#'
                  id='navbarDropdownMenuLink'
                  role='button'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  {t('Bidding')}
                </a>

                <ul className='dropdown-menu' aria-labelledby='navbarDropdownMenuLink'>
                  {displayMenuForSection('bidding')}
                </ul>
              </li>
              <li className={highlightedClassName('bidsPending')}>
                <a
                  className='nav-link dropdown-toggle'
                  href='#'
                  id='navbarDropdownMenuLink'
                  role='button'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  {t('Bids Pending')}
                </a>

                <ul className='dropdown-menu' aria-labelledby='navbarDropdownMenuLink'>
                  {displayMenuForSection('bidsPending')}
                </ul>
              </li>
              <li className={highlightedClassName('active')} id='navbarDropdownMenuSection'>
                <a
                  className='nav-link dropdown-toggle'
                  href='#'
                  id='navbarDropdownMenuLink'
                  role='button'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  {t('Active')}
                </a>

                <ul className='dropdown-menu' aria-labelledby='navbarDropdownMenuLink'>
                  {displayMenuForSection('active')}
                </ul>
              </li>
              <li className={highlightedClassName('delivered')} id='navbarDropdownMenuSection'>
                <a
                  className='nav-link dropdown-toggle'
                  href='#'
                  id='navbarDropdownMenuLink'
                  role='button'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  {t('Delivered')}
                </a>

                <ul className='dropdown-menu   ' aria-labelledby='navbarDropdownMenuLink'>
                  {displayMenuForSection('delivered')}
                </ul>
              </li>
              <li className={highlightedClassName('warranty')} id='navbarDropdownMenuSection'>
                <a
                  className='nav-link dropdown-toggle'
                  href='#'
                  id='navbarDropdownMenuLink'
                  role='button'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  {t('Warranty')}
                </a>

                <ul className='dropdown-menu' aria-labelledby='navbarDropdownMenuLink'>
                  {displayMenuForSection('warranty')}
                </ul>
              </li>
              <li className={highlightedClassName('completed')} id='navbarDropdownMenuSection'>
                <a
                  className='nav-link dropdown-toggle'
                  href='#'
                  id='navbarDropdownMenuLink'
                  role='button'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  {t('Completed')}
                </a>

                <ul className='dropdown-menu ' aria-labelledby='navbarDropdownMenuLink'>
                  {displayMenuForSection('completed')}
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default MainMenu;
