import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';

const PdfPreviewModal = ({
  isOpen,
  toggle,
  pdfUrl,
  onSend,
  //onSendEmail,
  title = 'PDF Preview',
  sendButtonText = 'Create draft email',
  cancelButtonText = 'Cancel',
  showPageControls = false,
  maxHeight = '80vh'
}) => {

  const [expanded, setExpanded] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function toggleExpand() {
    setExpanded(!expanded);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function downloadPdf() {
    /*const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = 'document.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);*/
  
    // Open in new window
    window.open(pdfUrl, '_blank');
  }

  const ModalHeader = ({ modalTitle, toggleFn, toggleExpandFn }) => {
    return (
      <div className='modal-header'>
        <h5 className='modal-title'>{modalTitle}</h5>
        <div className='ms-auto align-right'>

          <span
            onClick={toggleExpandFn}
            //variant="outline"
            className='ms-auto px-2 py-1'
          >
            <i className='fa fa-expand'></  i>
          </span>

          <button className='btn-close' aria-label='Close' onClick={toggleFn} />
        </div>
      </div>
    );
  };

  ModalHeader.propTypes = {
    modalTitle: PropTypes.string.isRequired,
    toggleFn: PropTypes.func.isRequired,
    toggleExpandFn: PropTypes.func.isRequired,
  };


  return (
    <Modal isOpen={isOpen} toggle={toggle} size={expanded ? 'xl' : 'lg'}>
      {ModalHeader({ modalTitle: title, toggleFn: toggle, toggleExpandFn: toggleExpand })}
      <ModalBody style={{ maxHeight: maxHeight, overflow: 'auto' }}>
        {/*onSendEmail?.to?.length > 0 && <div className="text-left ms-4 mb-4">
          Send to email: {onSendEmail.to[0].label}
        </div>*/}
        <Document
          file={pdfUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={<div>Loading PDF...</div>}
        >
          <div className="overflow-y-auto overflow-x-hidden ">
            {numPages && !showPageControls &&
              Array.from({ length: numPages }, (_, index) => index + 1).map(
                (index) => <><Page key={index} className='pdfPreviewPage' pageNumber={index} width={expanded ? 960 : 640} />
                  {index < numPages &&
                    <>
                      <div className="text-center mt-2" style={{ fontSize: '0.8rem', borderTop: '1px solid #ccc', paddingTop: '5px' }}>
                        <span>Page {index} of {numPages}</span>
                      </div>
                    </>
                  }
                </>
              )}
            {showPageControls && <Page pageNumber={pageNumber} />}
          </div>
        </Document>
        {showPageControls && numPages && numPages > 1 && (
          <div className="text-center mt-3">
            <button
              className="btn btn-sm btn-secondary me-2"
              disabled={pageNumber <= 1}
              onClick={previousPage}
            >
              Previous
            </button>
            <span>Page {pageNumber} of {numPages}</span>
            <button
              className="btn btn-sm btn-secondary ms-2"
              disabled={pageNumber >= numPages}
              onClick={nextPage}
            >
              Next
            </button>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-secondary" onClick={toggle}>{cancelButtonText}</button>
        <button className="btn btn-primary me-2 ms-auto" onClick={downloadPdf}><i className="fa fa-download me-1"></i></button>
        <button className="btn btn-primary" onClick={onSend}>{sendButtonText}</button>
      </ModalFooter>
    </Modal>
  );
};

PdfPreviewModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  pdfUrl: PropTypes.string.isRequired,
  onSend: PropTypes.func.isRequired,
  onSendEmail: PropTypes.object,
  title: PropTypes.string,
  sendButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  showPageControls: PropTypes.bool,
  maxHeight: PropTypes.string
};

export default PdfPreviewModal;