import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
//import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import {
  selectGetAccountAffiliation,
  selectGetCompanyContactAffiliations,
} from '../../modules/companies/selectors';
//import { useConfirm } from '../../shared/confirmation-dialog';
import SoloAutocompleteInput from '../../shared/solo-autocomplete-input';
import { useWithPermissions } from '../../shared/with-permissions';
import { moduleConstants } from '../../_constants';
import AddCompanyModal from '../add-company-modal/add-company-modal';
import { getCompanyContactAffiliations } from '../../modules/companies';
import { useConfirm } from '../../shared/confirmation-dialog';

type SearchAffiliationModalProps = {
  open?: boolean;
  preAffiliations?: Array<string>,
  onClose?: (...args: any) => unknown;
  onAffiliationPush?: (...args: any) => unknown;
  onChangeSelection?: (...args: any) => unknown;
  addCompanyText?: string;
  initialSelectedName?: string;
  showSelected?: boolean;
};

export const SearchAffiliationModal = ({
  open = false,
  preAffiliations = [],
  onClose = () => void 0,
  onAffiliationPush = () => void 0,
  onChangeSelection = () => void 0,
  initialSelectedName='',
  showSelected = false,
  //addCompanyText = 'Add Another Company',
}: SearchAffiliationModalProps) => {
  const dispatch = useDispatch();
  const allowed = useWithPermissions({ required: [moduleConstants.EE] });

  const [selected, setSelected] = useState<any>(null);

  useEffect(() => {
    if (open) {
      setSelected(null);
    }
  }, [open]);

  const { data: companies } = useSelector(selectGetCompanyContactAffiliations);
  const { data: accountAffiliation = {} } = useSelector(selectGetAccountAffiliation);

  const { control, watch } = useFormContext();

  const activeAffiliations = watch('affiliations');
  const formerAffiliations = watch('formerAffiliations');
  const affiliationsFetched = watch('affiliationsFetched');

  const disabledValues = useMemo(() => {
    return [...activeAffiliations, ...formerAffiliations];
  }, [activeAffiliations, formerAffiliations]);

  const { append, insert } = useFieldArray({
    name: 'affiliations',
    control,
  });
  
  const confirm = useConfirm();

  const prepareSelectedAffiliation = (aff) => {
    const result = { ...aff, active: true };

    result.emailArr = [];
    result.phoneArr = [];
    result.addressArr = [];
    result.title = '';
    result.department = '';
    result.isPrimary = false;

    return result;
  };

  useEffect(() => {
    if (initialSelectedName?.length>0) {
      const selected = companies.find((c) => c.companyName === initialSelectedName);

      if (selected) {
        selectAffiliation(selected, true);
      }
    }
  }, [initialSelectedName]);

  const selectAffiliation = useCallback(async (selection: any, skipConfirm = false) => {
    const result = prepareSelectedAffiliation(selection);
    //setIsInputVisible(false);

    if (result.isAccountAffiliation && !skipConfirm) {
      const ok = await confirm({
        title: 'Save as employee',
        question: `Are you sure you want to save this contact as employee of ${result.companyName}?`,
      });

      if (!ok) return;
    }

    addAffiliation(result);

    if(showSelected){
      setSelected(result);
    }else{
      setSelected(null);
    }

    onChangeSelection(result);
  }, []);

  const addAffiliation = (aff) => {
    aff.isAccountAffiliation ? insert(1, aff) : append(aff);

    onAffiliationPush(aff);
    
  };

  const options = useMemo(
    () => (allowed && accountAffiliation ? [accountAffiliation, ...companies.filter(company=>company?.companyId!=accountAffiliation?.companyId)] : companies.filter(company=>company?.companyId!=accountAffiliation?.companyId)),
    [accountAffiliation, companies, allowed]
  );

  useEffect(() => {
    if (Array.isArray(options) && Array.isArray(preAffiliations) && affiliationsFetched) {
      preAffiliations.forEach((pAffId) => {
        const selected = options.find((c) => c.companyId === pAffId);
        const isAlreadyAffiliated = activeAffiliations.find((aff) => aff.companyId === pAffId);
  
        if (selected && !isAlreadyAffiliated) {
          addAffiliation(prepareSelectedAffiliation(selected));
        }
      });
    }
  }, [preAffiliations, options, affiliationsFetched]);

  const [companyModalOpen, setCompanyModalOpen] = useState(false);
  const [companyName, setCompanyName] = useState('');

  const onAddCompanyClick = useCallback((companyName: string) => {

    setCompanyName(companyName);
    setCompanyModalOpen(true);
  }, []);

  const onCompanyUpdate = useCallback(async ({ companyId }) => {
    const companies = await dispatch(getCompanyContactAffiliations() as any).unwrap()

    const company = companies.find((c) => c.companyId === companyId);

    if (company) {
      selectAffiliation(company);

      setCompanyModalOpen(false);
      onAffiliationPush(company);
      onClose();
    }
  }, []);

  return (
    <>
<SoloAutocompleteInput
            title='Select company'
            options={options}
            selected={selected}
            onSelect={selectAffiliation}
            getLabel={(o) => o?.companyName}
            getValue={(o) => o?.companyId}
            disabledValues={disabledValues}
            getExtraOption={(input) => ({
              label: <a>Add &#39;{input}&#39; as new company</a>,
              onClick: () => onAddCompanyClick(input),
            })}
          />
      

      <AddCompanyModal
        isQuickAdd
        open={companyModalOpen}
        defaultValues={{companyName} as any}
        onCompanyUpdate={onCompanyUpdate as any}
        onClose={() => setCompanyModalOpen(false)}
      />
    </>
  );
};
