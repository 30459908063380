import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { getAllEmailAction, deleteEmailAction } from '../../actions/message';
import _, { set } from 'lodash';
import { LightBoxComponent } from './LightBox.component';
import EmailEditor from '../Dialogs/email-editor-modal';
import { EmailView } from './RightSectionRender';
import ActivityHeader from './ActivityHeader.component';
import { appConstants } from '../../_constants';
import ReactLoading from 'react-loading';
import styles from './right-section.module.scss';
//import EmailPopUp from '../Common/EmailPopUp';

const EmailTab = ({
  location,
  typeId,
  typeRef,
  getAllEmailAction,
  deleteEmailAction,
  allEmails,
  isfetched,
  data = {},
}) => {
  const [accountId] = useState(localStorage.getItem('accountId'));
  const [commentList, setCommentList] = useState([]);
  const [showEditEmail, setShowEditEmail] = useState(false);
  const [lightBoxOpen, setLightBoxOpen] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [attachmentIndex, setAttachmentIndex] = useState(-1);
  const [emailAction, setEmailAction] = useState('new');
  const [emailObjectId, setEmailObjectId] = useState(null);
  const [showEmailDetailModal, setShowEmailDetailModal] = useState(false);
  const [showEmailDetailId, setShowEmailDetailId] = useState(null);
  const [filterText, setFilterText] = useState('');
  const [currentlyEditingEmailId, setCurrentlyEditingEmailId] = useState(null);
  const [sortDirection, setSortDirection] = useState('desc');
  const [filterType, setFilterType] = useState(['all']);
  const [isLoading, setIsLoading] = useState(true);
  


  const filterOptions = [
    {
      title: 'All',
      key: 'all',
      children: [
        { title: 'Regular Notes', key: 'regular_notes' },
        { title: 'Follow-up Notes', key: 'followup_notes' },
        {
          title: 'By Status',
          key: 'status',
          children: [
            { title: 'Pinned', key: 'pinned' },
            { title: 'Unpinned', key: 'unpinned' },
          ],
        },
      ],
    },
  ];


  const showEditEmailCallback = () => {
    setShowEditEmail(true);
    setEmailObjectId('');
    setEmailAction('new');
  };

  const handleFilter = (filters) => {
    setFilterType(filters);
    // Implement filtering logic here
    const filteredEmails = allEmails;//filterNotes(allMessages, filters);
    setCommentList(filteredEmails);
  };

  const newButton =<button className='btn btn-primary d-flex p-1 pe-2 ps-2' onClick={showEditEmailCallback}>
  New email
</button>
  
  useEffect(() => {
    if (location.pathname.includes('email/new')) {
      setShowEditEmail(true);
    } else if (location.pathname.includes('email/view')) {
      const emailId = location.pathname.split('/').pop();
      setShowEmailDetailId(emailId);
      setShowEmailDetailModal(true);
    } else if (location.pathname.includes('email/edit')) {
      const emailId = location.pathname.split('/').pop();
      setCurrentlyEditingEmailId(emailId);
      editDraft({ objectId: emailId });
      setShowEditEmail(true);
    }
    getAllEmails();
   
  }, [location.pathname, typeId, typeRef]);

  useEffect(() => {
    if (allEmails !== commentList && isfetched){
      setCommentList(allEmails);
      setIsLoading(false);
    }
  }, [allEmails,isfetched]);

  const formatEmailAttachments = (selectedEmail) => {
    return selectedEmail?.attachments?.map((attachment) => ({
      ...attachment,
      description:
        'Draft Email\n' +
        'to: ' +
        selectedEmail.emailTo?.map((to) => to.value).join(', ') +
        '\n' +
        'sub: ' +
        selectedEmail.emailSub +
        '\n' +
        selectedEmail.emailBody?.replace(/(<([^>]+)>)/gi, ''),
    }));
  };

  useEffect(() => {
    if(!showEditEmail && currentlyEditingEmailId){
      setCurrentlyEditingEmailId(null);
    }
  }, [showEditEmail]);

  const getAllEmails = () => {
    const data = {
      accountId,
      typeId: typeId,
      typeRef: typeRef,
      page: 0,
      displayLimit: 99999999,
    };
    getAllEmailAction(data);
    setIsLoading(true);
  };

  const cancelDelete = (emailData, confirm) => async () => {
    const confirmed =
      (await confirm?.({
        title: 'Delete draft',
        question: 'Are you sure you want to delete this draft?',
      })) ?? true;

    if (!confirmed) {
      return;
    }

    const formData = {
      objectId: emailData.objectId,
      typeRef: emailData.typeRef,
      typeId: emailData.typeId,
    };

    await deleteEmailAction(formData);
    getAllEmails();
  };

  const editDraft = (data) => {
    const objectId = data.objectId;
    setEmailObjectId(objectId);

    setEmailAction('EDIT');
    setShowEditEmail(true);
    setCurrentlyEditingEmailId(objectId);
  };

  const copyEmail = (emailData) => {
    const emailObjectId = emailData.objectId;
    setEmailObjectId(emailObjectId);

    setEmailAction('COPY');
    setShowEditEmail(true);
  };

  const forwardEmail = (emailData) => {
    const emailObjectId = emailData.objectId;
    setEmailObjectId(emailObjectId);

    setEmailAction('FORWARD');
    setShowEditEmail(true);
  };

  const replyEmail = (emailData) => {
    const emailObjectId = emailData.objectId;
    setEmailObjectId(emailObjectId);

    setEmailAction('REPLY');
    setShowEditEmail(true);
  };

  const handleAddNew = () => {
    showEditEmailCallback();
  };


  const closeWindowCallback = () => {
    setShowEditEmail(false);
    setEmailObjectId('');
    setEmailAction('new');
  };

  const showHideLightBox = (email, attachmentIndex) => {
    setLightBoxOpen(!lightBoxOpen);
    setSelectedEmail(email);
    setAttachmentIndex(attachmentIndex);
  };

  const closeDetailModal = () => {
    setShowEmailDetailModal(false);
    setShowEmailDetailId(null);
  };

  const sortEmails = (emails, direction) => {
    let rootEmails = emails.filter((email) => !email.replyTo);

    let sortedEmails = rootEmails
      .map((email) => {
        let replies = emails.filter((n) => n.parentEmailId === email.objectId);
        replies.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        return [email, ...replies];
      })
    .flat();

    if(direction === 'asc'){
      sortedEmails = sortedEmails.reverse();
    }
    return sortedEmails;
  };

  const commentListData =
    commentList?.length > 0 ? (
      sortEmails(commentList).map((v) => (
        <EmailView
          emailData={v}
          editDraft={editDraft}
          copyEmail={copyEmail}
          forwardEmail={forwardEmail}
          replyEmail={replyEmail}
          cancelDelete={cancelDelete}
          showHideLightBox={showHideLightBox}
          filterText={filterText}
          isEditing={currentlyEditingEmailId === v.objectId}
        ></EmailView>
      ))
    ) : (
      <div className='outputBox scheduleHtml'>No sent emails or drafts</div>
    );

  const showEditEmailComponent = showEditEmail || emailObjectId;


  const handleSearch = (value) => {
    setFilterText(value);
  };

  const handleSort = (direction) => {
    setSortDirection(direction);
    // Implement sorting logic here
    const sortedEmails = sortEmails([...allEmails], direction);
    setCommentList(sortedEmails);
  };


  return (
    <div>
      {!showEditEmailComponent ? (
         <div className="container">
         <div className="row pt-3 pb-2">
           <div className="col ps-0 pe-2">
             <ActivityHeader
               onSearch={handleSearch}
               onSort={handleSort}
               onFilter={handleFilter}
               onAddNew={handleAddNew}
               canFilter={false}
               addNewLabel="Add Email"
               filterOptions={filterOptions}
               sortDirection={sortDirection}
               additionalButtons={[newButton]}
             />
           </div>
         </div>
       </div>
      ) : (
        <div>
          <br />
          <EmailEditor
            showEditEmail={showEditEmailComponent}
            typeId={typeId}
            typeRef={typeRef}
            objectId={emailObjectId}
            emailAction={emailAction}
            closeWindowCallback={closeWindowCallback}
            data={data}
          ></EmailEditor>
        </div>
      )}
      {isLoading ? (
        <ReactLoading
          className='table-loader'
          type={appConstants.LOADER_TYPE}
          color={appConstants.LOADER_COLOR}
          height={appConstants.LOADER_HEIGHT}
          width={appConstants.LOADER_WIDTH}
        />
      ) : (<Scrollbars style={{ height:'calc(100vh)', minHeight:'500px' }}>{commentListData}</Scrollbars>
      )}
      <LightBoxComponent
        attachments={formatEmailAttachments(selectedEmail) ?? []}
        shouldOpen={lightBoxOpen}
        initialIndex={attachmentIndex}
        closeCallback={showHideLightBox}
      />
      {showEmailDetailModal && showEmailDetailId && (
        <EmailView
          emailId={showEmailDetailId}
          typeId={typeId}
          typeRef={typeRef}
          asModal={true}
          editDraft={editDraft}
          copyEmail={copyEmail}
          cancelDelete={cancelDelete}
          showHideLightBox={showHideLightBox}
          toggle={closeDetailModal}
          showModal={true}
        ></EmailView>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { message } = state;
  const { allEmail, isEmailFetched: isfetched } = message;
  return {
    allEmails: allEmail && allEmail.length ? allEmail : [],
    isfetched: isfetched,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getAllEmailAction,
    deleteEmailAction,
  })(EmailTab)
);
