import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import bannerImage from '../static/images/cpostLogo.png';
import Footer from '../Layout/Footer.js';
import { getTermsConditionAction } from '../../actions/account';
import TitleComponent from "../Common/TitleComponent";
import TermsAndConditionsPage from '../../pages/terms-and-conditions-page'

const TermAndConditions = () => {
  const [description, setDescription] = useState('');
  const dispatch = useDispatch();

  const termsDescription = useSelector(state => state.account?.termsResponse?.description);

  useEffect(() => {
    dispatch(getTermsConditionAction());
  }, [dispatch]);

  useEffect(() => {
    if (termsDescription) {
      setDescription(termsDescription);
    }
  }, [termsDescription]);

  return (
    <div>
      <TitleComponent data={{title:`Terms & Conditions`}}/>
      <div className="wrapperBox">
        <header className="frontLoginHeader">
          <div className="container">
            <div className="row">
              <div className="col-sm-6">
                <div className="logo">
                  <a href="/home">
                    <img src={bannerImage} alt="Logo" />
                  </a>
                  <span className="tagline">Business Intelligence Software</span>
                </div>
              </div>
              <div className="col-sm-6"></div>
            </div>
          </div>
        </header>
        <div className="homeContainer">
          <TermsAndConditionsPage />
        </div>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <div className="fixfooter"><Footer /></div>
      </div>
    </div>
  );
};

export default TermAndConditions;