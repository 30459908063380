import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { viewChangeOrdersAsync } from '../../modules/orders';
import { ChangeOrderTable } from './sections/change-order-table';
import { getAllReasonCodeAction } from '../../actions/reasonCode';
import { PotentialChangeOrderTable } from './sections/potential-change-order-table';
import { ChangeOrderModal } from '../change-order-modal';
import styles from './change-order-intermediate-modal.module.scss';
import { DeclinedChangeOrderTable } from './sections/declined-change-order-table';

type ChangeOrderIntermediateModalProps = {
  isOpen: boolean;
  toggle: (boolean?) => void;
  project: any;
  //onSuggestProgress?: (data: any) => void;
  isExpanded?: boolean;
};

export const ChangeOrderIntermediateModal = ({
  isOpen = false,
  toggle,
  project = {},
  //onSuggestProgress = () => null,
  isExpanded = true,
}: ChangeOrderIntermediateModalProps) => {
  const dispatch = useDispatch();

  const [dialogCOId, setDialogCOId] = useState<string>('');
  // const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [showDeclined, setShowDeclined] = useState<boolean>(false);
  const [isAddCOOpened, setAddCOOpened] = useState<boolean>(false);
  const [changeOrders, setChangeOrders] = useState<Array<any>>([]);

  const { objectId: projectId } = project;
  const approvedCOs = useMemo(() => changeOrders.filter((co) => !co.isPCO), [changeOrders]);
  const potentialCOs = useMemo(
    () => changeOrders.filter((co) => co.isPCO && !co.notApproved),
    [changeOrders]
  );
  const declinedCOs = useMemo(
    () => changeOrders.filter((co) => co.isPCO && co.notApproved),
    [changeOrders]
  );

  const getData = useCallback(async (shouldClose?) => {
    const data = await dispatch((viewChangeOrdersAsync as any)(projectId)).unwrap();
    setChangeOrders([...data]);
    if(shouldClose) {
      toggle(true);
    }
    setDialogCOId('');
  }, [projectId]);

  useEffect(() => {
    if (isOpen && projectId) {
      dispatch(getAllReasonCodeAction({ className: 'reasonCodes' }));
      getData();
    }
  }, [isOpen, projectId]);

  useEffect(() => {
    if(!isAddCOOpened) {
      setDialogCOId('');
    }
  }
  , [isAddCOOpened]);

  const onOpenCOModal = useCallback((id: string) => {
    setDialogCOId(id);
    if(!id) {
      setAddCOOpened(true);
    }
  }, []);

  useEffect(() => {
    if (dialogCOId?.length>0&&!isAddCOOpened) {
      setAddCOOpened(true);
    }
  }, [dialogCOId]);

  return (
    <>
      <Modal
        className={isExpanded ? styles.coModalDialogExpanded : ''}
        backdrop='static'
        isOpen={isOpen}
        toggle={toggle}
        size='lg'
      >
        <ModalHeader toggle={toggle}>Change Orders</ModalHeader>
        <ModalBody className={`${styles.coModalBody} gap-2`}>
          <ChangeOrderTable
            project={project}
            changeOrders={approvedCOs}
            isExpanded={isExpanded}
            onOpenCOModal={onOpenCOModal}
          />
          <PotentialChangeOrderTable
            changeOrders={potentialCOs}
            isExpanded={isExpanded}
            onOpenCOModal={onOpenCOModal}
          />

          {declinedCOs.length > 0 && (
            <>
              {showDeclined && (
                <DeclinedChangeOrderTable
                  changeOrders={declinedCOs}
                  isExpanded={isExpanded}
                  onOpenCOModal={onOpenCOModal}
                />
              )}
              <div className='d-flex justify-content-start'>
                <a onClick={() => setShowDeclined(!showDeclined)}>
                  {showDeclined ? 'Hide' : 'View'} declined change orders
                </a>
              </div>
            </>
          )}
          <div className='d-flex justify-content-end'>
            {/*<a onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? 'Hide' : 'View'} detailed summary
            </a>*/}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className='d-flex justify-content-start'>
            <button type='button' className='btn btn-primary' onClick={() => toggle(true)}>
              Close
            </button>
          </div>
          <button
            type='button'
            className='btn btn-primary ms-auto'
            onClick={() => onOpenCOModal('')}
          >
            Add change order
          </button>
        </ModalFooter>
      </Modal>

      <ChangeOrderModal
        isOpen={isAddCOOpened}
        toggle={() => setAddCOOpened(false)}
        onSubmit={getData}
        project={project}
        coId={dialogCOId}
        //onSuggestProgress={onSuggestProgress}
      />
    </>
  );
};
