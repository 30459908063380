import { useEffect, useMemo, useState } from 'react';
import { joiResolver } from '@hookform/resolvers/joi';
import { useAppDispatch } from '../../shared/hooks/use-app-dispatch';
import { FormProvider, useForm } from 'react-hook-form';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { useSubmit } from '../../shared/hooks/use-submit';
import { createChangeOrderAsync, viewChangeOrdersAsync } from '../../modules/orders';
import { findContactAffiliations, userRolesDropdown } from '../../modules/contacts';

import loaderImage from '../../components/static/images/loading_spinner.gif';
import {
  ChangeOrderModalTypeSchema,
  changeOrderModalValidationSchema,
} from './change-order-modal.schema';
import { ChangeOrderFields } from './sections/change-order-fields';
//import { ChangeOrderTable } from './sections/change-order-table';
import {
  formatDateUTC,
  formatDateObject,
  formatDateObjectOrNull,
} from '../../utils/date-formatters';
import { formatEstData } from '../../utils/number-formatters';
import styles from './change-order-modal.module.scss';
import _ from 'lodash';
import { useWithPermissions } from '../../shared/with-permissions';
import { appConstants, moduleConstants } from '../../_constants';
import { FillTemplateModal } from '../../components/Layout/TemplateFiller/FillTemplateModal';
import PropTypes from 'prop-types';
import PdfPreviewModal from '../pdf-preview-modal/pdf-preview-modal';
import { useHistory } from 'react-router-dom';
import { createEstimateEmail } from '../../modules/projects';
import { getUserSettings } from '../../modules/settings';
import { selectAllJobScopes } from '../../selectors/jobScope';
import { useAppSelector } from '../../shared/hooks/use-app-selector';

type ChangeOrderModalProps = {
  isOpen: boolean;
  toggle: () => void;
  onSubmit?: (...args) => Promise<any>;
  //onSuggestProgress?: (data: any) => void;
  project: any;
  coId?: string;
};

export const ChangeOrderModal = ({
  isOpen = false,
  toggle,
  onSubmit: onSubmitCb = () => Promise.resolve(),
  //onSuggestProgress = () => null,
  project = {},
  coId = '',
}: ChangeOrderModalProps) => {
  const defaultValues = {
    coNumber: null,
    coCostAmount: 0,
    profitDollars: 0,
    coTotalAmount: 0,
    comment: '',
    changeOrderReason: '',
    exclusions: '',
    effectiveDate: null,
    scheduleImpact: 0,
    wasAccepted: false,
    acceptedDate: null,
    acceptedBy: '',
    existingChangeOrders: [],
    scopeArr: [],
    estTotalCost: 0,
    estTotalProfit: 0,
    estTotalContract: 0,
    estData: [] as any[],
    createEstimate: false,
    qbEstimateId: '',
    shouldGeneratePdf: false,
    pdfUrl: '',
  };

  const form = useForm<ChangeOrderModalTypeSchema>({
    mode: 'onChange',
    defaultValues,
    resolver: joiResolver(changeOrderModalValidationSchema),
  });

  const [currentChangeOrderId, setCurrentChangeOrderId] = useState<string>('');
  const [showProcessing, setShowProcessing] = useState(false);

  const allJobScopes = useAppSelector(selectAllJobScopes);

  useEffect(() => {
    if (coId?.length > 0) {
      setCurrentChangeOrderId(coId);
    }else{
      setCurrentChangeOrderId('');
    }
  }, [coId]);

  //const [isViewChangeOrdersLoading, setIsViewChangeOrdersLoading] = useState<boolean>(true);
  const [changeOrders, setChangeOrders] = useState<Array<any>>([]);
  const [currentCO, setCurrentCO] = useState<any>({
    ...defaultValues,
    coCostAmount: 0,
    profitDollars: 0,
    coTotalAmount: 0,
  });
  const [isLocked, setIsLocked] = useState<boolean>(false);

  const [projectId, setProjectId] = useState<string>(project?.objectId??'');
  const [pdfUrl, setPdfUrl] = useState('');
  const [pdfFileName, setPdfFileName] = useState('');
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
  //const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [clientData, setClientData] = useState<any[]>([]);
  const [emailData, setEmailData] = useState({});

  const history = useHistory();

  const dispatch = useAppDispatch();

  const PCOTitle = useMemo(
    () => `${currentCO.isPCO ? 'Potential ' : ''}Change Order #${currentCO.coNumber}`,
    [currentCO]
  );

  const isNew = !(currentChangeOrderId?.length > 0);
  const wasAccepted = form.watch('wasAccepted');
  const title = isNew ? 'Enter Change Order' : PCOTitle;
  const submitting = form.formState.isSubmitting;
  const allowedChangeApproved = useWithPermissions({ required: [moduleConstants.MANAGECONTRACT] });

  const [nextBestCO, setNextBestCO] = useState<number>(1);
  const [clientRoleId, setClientRoleId] = useState<string>('');

  useEffect(() => {
    dispatch(userRolesDropdown());
    dispatch(getUserSettings(['projectRoleTypes']))
      .unwrap()
      .then((roles) => {
        const clRoleId = roles.find((role: any) => role.code === 'CL')?._id || '';
        setClientRoleId(clRoleId);
      });
  }, [dispatch]);

  const [projectTeamClientArr, setProjectTeamClientArr] = useState<string[]>([]);

  useEffect(() => {
    if (project?.userAssignData?.length > 0 && clientRoleId) {
      const clientAffsIds = project.userAssignData
        .filter((team) => team.projectRoleId === clientRoleId)
        .map((team) => team.contactAffiliationId);
      setProjectTeamClientArr(clientAffsIds);
    }
  }, [clientRoleId, project?.objectId]);

  useEffect(() => {
    const fetchClients = async () => {
      const clientAffsIds = projectTeamClientArr || [];

      if (clientAffsIds.length > 0) {
        const clientsData = await dispatch(
          findContactAffiliations({
            criteria: `${clientAffsIds.join(' ')}`,
            page: 1,
            count: clientAffsIds.length,
            isAccount: null,
            includeDeleted: true,
            fields: ['_id'],
          })
        ).unwrap();

        setClientData(clientsData);
      }
    };

    fetchClients();
  }, [dispatch, projectTeamClientArr]);

  useEffect(() => {
    if (projectId?.length>0) {
      setProjectId(project.objectId);
    }
  }, [project.objectId]);

  useEffect(() => {
    if (isOpen && projectId?.length>0) {
      setIsLocked(!isNew && !currentCO.isPCO);

      (async () => {
        setShowProcessing(true);
        const { payload = [] } = await dispatch((viewChangeOrdersAsync as any)(project.objectId));
        setChangeOrders(payload);

        const coNumbers = payload.map(({ coNumber }) => coNumber);
        form.setValue('existingChangeOrders', coNumbers);
        //setIsViewChangeOrdersLoading(false);

        setNextBestCO(coNumbers.length ? Math.max(...coNumbers) + 1 : 1);

        if (currentChangeOrderId?.length>0) {
          const existingCO = payload.find((co: any) => co.objectId === currentChangeOrderId);

          setCurrentCO(existingCO || {});
        } else {
          const localScopeId = allJobScopes.find((scope) => !scope.isSystemMaster && scope.code === 'OTHR')?.value;
          defaultValues.estData = [
            { scopeId: localScopeId, lineItemId: null, unitCost: 0, unitProfit: 0, qty: 1, subTotalProfit:0, subTotalProfitPers: 0, subTotalCost:0,subTotalCtr:0, description: '' },
          ];
          setCurrentCO({ ...defaultValues, coCostAmount: 0, profitDollars: 0, coTotalAmount: 0 });
        }
        setShowProcessing(false);
      })();
    } 
    if (!isOpen) {
      setCurrentChangeOrderId('');
      setIsLocked(false);
      form.reset();
    }
  }, [isOpen, projectId, currentChangeOrderId, isNew, currentCO.isPCO]);

  useEffect(() => {
    if (_.isEmpty(currentCO)) {
      form.setValue('effectiveDate', formatDateObject());
      form.setValue(
        'existingChangeOrders',
        changeOrders.map(({ coNumber }) => coNumber)
      );
    } else {
      form.reset(
        {
          effectiveDate: currentCO?.objectId
            ? formatDateObjectOrNull(currentCO.effectiveDate)
            : formatDateObject(),
          existingChangeOrders: changeOrders
            .filter((co) => co.objectId !== currentCO.objectId)
            .map(({ coNumber }) => coNumber),
          coNumber: currentCO.coNumber ?? nextBestCO,
          comment: currentCO.comment,
          exclusions: currentCO.exclusions,
          changeOrderReason: currentCO.reasonCodeId ?? currentCO.reasonCode?.objectId,
          coCostAmount: currentCO.coCostAmount,
          //profitPercentage: +currentCO.profitPercentage.toFixed(2),
          profitDollars: currentCO.profitDollars,
          coTotalAmount: currentCO.coTotalAmount,
          estTotalContract: currentCO.coTotalAmount,
          estTotalCost: currentCO.coCostAmount,
          estTotalProfit: currentCO.profitDollars,
          estData: formatEstData(currentCO.estData, true),
          scheduleImpact: currentCO.scheduleImpact,
          wasAccepted: currentCO.wasAccepted,
          acceptedDate: formatDateObjectOrNull(currentCO.acceptedDate),
          acceptedBy: currentCO.acceptedBy || '',
          scopeArr: currentCO.scopeArr ?? [],
          createEstimate: false,
        },
        { keepDefaultValues: true }
      );
    }
  }, [currentCO, changeOrders]);

  const handleSendPdf = async () => {
    //setIsEmailModalOpen(true);
    setIsPdfModalOpen(false);

    const emailSubject = `Change Order for ${project.jobName || 'Project'}`;
    const emailBody = `Please find attached the change order for ${project.jobName || 'your project'}.`;

    const sendEmailData = {
      to: clientData
        .filter((client) => client?.primaryEmail?.email?.length > 0)
        .map((client) => ({
          value: client.primaryEmail.email,
          label: client.primaryEmail.email,
          contactId: client.contactId,
          companyId: client.companyId,
          _id: client._id,
        })),
      subject: emailSubject,
      body: emailBody,
      attachments: [{ fileName: pdfFileName, url: pdfUrl }],
    };

    const result = await dispatch(
      createEstimateEmail({
        projectId: projectId ?? currentCO.projectId,
        pdfUrl: pdfUrl,
        coId: currentCO.objectId,
        emailData: sendEmailData,
        type: 'changeOrder',
      })
    ).unwrap();

    if (!result.emailId) {
      return;
    }

    const url = '/project-details/' + result.projectId + '/email/edit/' + result.emailId;
    history.push(url);
    form.reset({ ...form.formState.defaultValues });
    toggle();
    onSubmitCb(true);
  };

  const formatPostData = (data: any, isPCO: boolean, notApproved: boolean) => {
    // const profitDollars = currencyToNumber(data.profitDollars || 0);
    //const coCostAmount = currencyToNumber(data.coCostAmount || 0);
    //const coTotalAmount = currencyToNumber(data.coTotalAmount || 0);

    return {
      objectId: currentCO.objectId,
      projectId: project.objectId ?? currentCO.projectId,
      reasonCode: data.changeOrderReason,
      exclusions: data.exclusions,
      coNumber: +(data.coNumber || 0),
      coCostAmount: data.estTotalCost,
      coTotalAmount: data.estTotalContract,
      //profitPercentage: +(data.profitPercentage || 0),
      profitDollars: data.estTotalProfit,
      projectStatus: {
        ObjectId: project.jobStatusCodes?.objectId || project.jobStatusCodes,
        jobStatusCodesCode: project.jobStatusCodesCode,
        jobStatusCodesName: project.jobStatusCodesName,
      },
      estData: formatEstData(data.estData, false),
      currentContractAmount: project.currentContractAmount + data.estTotalContract,
      currentGrossProfit: project.currentGrossProfit + data.estTotalProfit,
      comment: data.comment,
      effectiveDate: data.effectiveDate,
      acceptedBy: data.acceptedBy || '',
      wasAccepted: data.wasAccepted,
      acceptedDate: data.acceptedDate,
      scheduleImpact: data.scheduleImpact,
      createEstimate: data.createEstimate,
      trash: project.trash,
      changeOrderDate: formatDateUTC(),
      isPCO,
      notApproved,
      qbEstimateId: data.qbEstimateId,
      shouldGeneratePdf: data.shouldGeneratePdf,
      pdfUrl: data.pdfUrl,
    };
  };

  /*const processNewCOChanges = useCallback((data: any) => {
    const { scheduleImpact, coTotalAmount, profitDollars } = data;
    const isTotalChanged = coTotalAmount !== 0;
    const isScheduleChanged = scheduleImpact !== 0;
    const isProfitChanged = profitDollars !== 0;

    if (isScheduleChanged ||  isTotalChanged || isProfitChanged) {
      return { isCO: true, scheduleImpact, profitDollars, coTotalAmount, projectId: data.projectId};
    }

    return null;
  }, []);

  const processEditCOChanges = useCallback((data: any) => {
    const { scheduleImpact: newScheduleImpact, coTotalAmount: newCoTotalAmount, profitDollars: newProfitDollars } = data;
    const { scheduleImpact: prevScheduleImpact, coTotalAmount: prevCoTotalAmount, estTotalProfit: prevProfitDollars } = currentCO;

    const scheduleImpact = newScheduleImpact - prevScheduleImpact;
    const coTotalAmount = newCoTotalAmount - prevCoTotalAmount;
    const profitDollars = newProfitDollars - prevProfitDollars;

    const isTotalChanged = coTotalAmount !== 0;
    const isScheduleChanged = scheduleImpact !== 0;

    if (isScheduleChanged ||  isTotalChanged) {
      return { isCO: true, scheduleImpact, profitDollars, coTotalAmount, projectId: data.projectId};
    }

    return null;
  }, [currentCO]);*/

  /*const suggestUpdateProgress = useCallback(async (data: any) => {
    // if this is a new approved CO or we are approving PCO
    const changes = (isNew || currentCO.isPCO)
      ? processNewCOChanges(data)
      : processEditCOChanges(data);

    changes && onSuggestProgress && onSuggestProgress(changes);
  }, [isNew, currentCO]);*/

  const onFormSubmit = async (markAction: string) => {
    let data: any = null;

    setShowProcessing(true);

    await form.handleSubmit(async (result) => {
      data = result;
    }, console.log)();

    if (!data) {
      setShowProcessing(false);
      throw new Error('Invalid form data');
    }

    let postData = {} as any;
    switch (markAction) {
      case 'Save':
        postData = formatPostData(data, !wasAccepted, currentCO.notApproved ?? false);
        break;
      case 'Approve':
        data.wasAccepted = true;
        data.notApproved = false;
        data.isPCO = false;
        postData = formatPostData(data, false, false);
        break;
      case 'Decline':
        data.notApproved = true;
        data.isPCO = true;
        data.wasAccepted = false;
        postData = formatPostData(data, true, true);
        break;
      case 'Potential':
        data.notApproved = false;
        data.isPCO = true;
        data.wasAccepted = false;
        postData = formatPostData(data, true, false);
        break;
      case 'CreatePdf':
        postData = formatPostData(data, !wasAccepted, currentCO.notApproved ?? false);
        break;
      default:
        break;
    }

    const shouldGeneratePdf = markAction === 'CreatePdf';
    if (shouldGeneratePdf) {
      postData.shouldGeneratePdf = true;
    }



    const result = await dispatch((createChangeOrderAsync as any)(postData)).unwrap();

    if (!result.objectId) {
      setShowProcessing(false);
      return;
    }else{
      setCurrentChangeOrderId(result.objectId);
    }

    if (shouldGeneratePdf && result.objectId && result.pdfUrl) {
      setPdfUrl(result.pdfUrl);
      setPdfFileName(result.pdfFileName);
      setCurrentChangeOrderId(result.objectId);

      const clientEmail = clientData[0]?.primaryEmail?.email || '';
      const emailSubject = `Change Order for ${project.projectName || 'Project'}`;
      const emailBody = `Please find attached the change order for ${project.projectName || 'your project'}.`;

      setEmailData({
        to: [{ value: clientEmail, label: clientEmail }],
        subject: emailSubject,
        body: emailBody,
        attachments: [{ fileName: result.pdfFileName, url: result.pdfUrl }],
      });

      setIsPdfModalOpen(true);
    } else if(!shouldGeneratePdf) {
      form.reset({ ...form.formState.defaultValues });
      toggle();
      onSubmitCb();
    }

    setShowProcessing(false);
  };

  const [onSaveCO] = useSubmit(async () => await onFormSubmit('Save'), [project, currentCO]);

  const [onApproveCO] = useSubmit(async () => await onFormSubmit('Approve'), [project, currentCO]);

  const [onSubmitAsPCO] = useSubmit(async () => await onFormSubmit('Save'), [project, currentCO]);

  const [onDeclinePCO] = useSubmit(async () => await onFormSubmit('Decline'), [project, currentCO]);

  const [onSavePotential] = useSubmit(
    async () => await onFormSubmit('Potential'),
    [project, currentCO]
  );

  const mainAction = useMemo(() => {
    let label = 'Save';
    let action = onSaveCO;

    if (isNew) {
      label = `Save ${wasAccepted ? 'Approved' : 'Potential'} C/O`;
      action = wasAccepted ? onApproveCO : onSubmitAsPCO;
    } else {
      if (wasAccepted) {
        label = 'Save Approved C/O';
        action = onApproveCO;
      }
    }

    return (
      <button className='btn btn-primary' disabled={submitting} onClick={action}>
        {submitting ? 'Saving...' : label}
      </button>
    );
  }, [isNew, wasAccepted, submitting, currentCO]);

  /*const menuAction = useMemo(() => {
    if (isNew || wasAccepted) return null;

    const { notApproved } = currentCO;

    const label = notApproved ? 'Mark Potential' : 'Mark Declined';
    const action = notApproved ? onSubmitAsPCO : onDeclinePCO;

    return (
      <>
        <button
          type='button'
          className='btn btn-secondary dropdown-toggle dropdown-toggle-split'
          data-bs-toggle='dropdown'
          aria-expanded='false'
        >
          <span className='visually-hidden'>Toggle Dropdown</span>
        </button>
        <ul className='dropdown-menu ml-auto'>
          <li className='dropdown-item'>
            <a className='statusBtn' onClick={action}>
              {label}
            </a>
          </li>
        </ul>
      </>
    );
  }, [currentCO, wasAccepted, isNew]);*/

  const [detailedMode, setDetailedMode] = useState(true);

  const [fillTemplateOpen, setFillTemplateOpen] = useState(false);
  const toggleFillTemplate = () => {
    setFillTemplateOpen(!fillTemplateOpen);
  };

  const ModalHeader = ({ toggleFn, toggleFillTemplate }) => {
    return (
      <div className='modal-header'>
        <h5 className='modal-title'>{title}</h5>
        <div className='ms-auto align-right'>
          {!appConstants.IS_PRODUCTION && (
            <span
              onClick={toggleFillTemplate}
              //variant="outline"
              className='ms-auto px-2 py-1'
            >
              <i className='fa fa-file-text-o' />
            </span>
          )}
          <button className='btn-close' aria-label='Close' onClick={toggleFn} />
        </div>
      </div>
    );
  };

  ModalHeader.propTypes = {
    toggleFn: PropTypes.func.isRequired,
    toggleFillTemplate: PropTypes.func.isRequired,
  };

  return (
    <>
      <Modal
        className={styles.coForm}
        backdrop='static'
        isOpen={isOpen}
        toggle={toggle}
        size={detailedMode ? 'xl' : 'lg'}
      >
        {ModalHeader({ toggleFn: toggle, toggleFillTemplate })}
        <ModalBody style={{ padding: '16px 30px' }}>
          <FormProvider {...form}>
          {showProcessing && <div className='loading_bg'>
                        <img className='ajax-loader' src={loaderImage} width='100' height='100' />
                      </div>}
            <ChangeOrderFields
              isDisabled={isLocked}
              project={project}
              currentCO={currentCO}
              setDetailedModeCallback={setDetailedMode}
            />

            {/*<ChangeOrderTable
            project={project}
            changeOrders={changeOrders}
            isLoading={isViewChangeOrdersLoading}
            coId={coId}
          />*/}
          </FormProvider>
        </ModalBody>
        <ModalFooter>
          <button type='button' className='btn btn-primary' onClick={toggle}>
            {isLocked ? 'Close' : 'Cancel'}
          </button>
          {!isLocked && (
            <div className='btn-group'>
              {mainAction}
              <button
                type='button'
                className='btn btn-secondary dropdown-toggle dropdown-toggle-split'
                data-bs-toggle='dropdown'
                aria-expanded='false'
              >
                <span className='visually-hidden'>Toggle Dropdown</span>
              </button>
              <ul className='dropdown-menu ml-auto'>
                <li className='dropdown-item'>
                  <a className='statusBtn' onClick={() => onFormSubmit('CreatePdf')}>
                    Save & send to Client
                  </a>
                </li>
                {(currentCO.isPCO || isNew) &&
                  !wasAccepted &&
                  !currentCO.notApproved &&
                  !isLocked && (
                    <li className='dropdown-item'>
                      <a className='statusBtn' onClick={onDeclinePCO}>
                        Save as Declined
                      </a>
                    </li>
                  )}
                {currentCO.notApproved && !wasAccepted && !isLocked && (
                  <li className='dropdown-item'>
                    <a className='statusBtn' onClick={onSavePotential}>
                      Save as Potential Change Order
                    </a>
                  </li>
                )}
              </ul>
            </div>
          )}
          {isLocked && allowedChangeApproved && (
            <button type='button' className='btn btn-primary' onClick={() => setIsLocked(false)}>
              Unlock & Edit
            </button>
          )}
        </ModalFooter>
      </Modal>
      {fillTemplateOpen && currentCO.objectId && (
        <FillTemplateModal
          open={fillTemplateOpen}
          toggle={() => toggleFillTemplate()}
          objectId={currentCO.objectId}
          formType={'changeOrder'}
        ></FillTemplateModal>
      )}
      <PdfPreviewModal
        isOpen={isPdfModalOpen}
        toggle={() => setIsPdfModalOpen(false)}
        pdfUrl={pdfUrl}
        onSend={handleSendPdf}
        title='Change Order PDF Preview'
        onSendEmail={emailData}
      />
    </>
  );
};
