
import React, { useEffect, useState, Suspense } from 'react';

import { withTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import {
  createCompanyAction,
  deleteCompanyAction,
  getCompanyListAction,
  updateCompanyAction,
} from '../../actions/company';

import TitleComponent from '../Common/TitleComponent';
import Footer from '../Layout/Footer.js';
import Header from '../Layout/Header.js';
import store from '../../modules/store';
import RemotePagination from '../../shared/data-grid/remote-pagination/remote-pagination.jsx';
import SearchComponent from '../../shared/data-grid/search-component';
import useBasicRowSelection from '../../shared/data-grid/use-basic-row-selection/use-basic-row-selection.jsx';
import usePagination from '../../shared/data-grid/use-pagination';
import useSorting, { headerWithSort } from '../../shared/data-grid/use-sorting/use-sorting.jsx';
import CustomTable from '../Common/CustomTable';
import {Tooltip} from 'react-tooltip';
import { appConstants, isAllowed, moduleConstants, reportAccess } from '../../_constants';
import AddCompanyModal from '../../modals/add-company-modal/add-company-modal';
import { usePageTitle } from '../../layouts/title-context';
import { listCompanies } from '../../modules/companies';
import { getListCompanies } from '../../modules/companies/selectors';
import FormattedPhoneArr from '../../shared/formatted-phone/formatted-phone-arr';
import { DeleteCompanyModal } from './delete-company-modal';
import { UpperCaseName } from '../../components/Auth/Auth';

// TODO: apply rest refactoring
const RenderCompanies = ({
  importAddCompany = () => { },
  list = [],
  companyModalOpened = false,
  props,
  onSubmit: onSubmitCb = () => Promise.resolve(),
  externalTrigger,
}) => {

  usePageTitle('Companies list');

  const {
    data: { total, companies },
    loading,
  } = useSelector(getListCompanies);


  const pagination = usePagination({ total });

  const { limit, offset, setPage } = pagination;

  const rowSelection = useBasicRowSelection({ limit, data: list, keyField: 'objectId' });

  const sortingState = useSorting('companyName', 'asc');

  const { sortingField, sortingOrder } = sortingState;

  const [searchValue, setSearchValue] = useState('');

  const [selectedRows, setSelectedRows] = useState([]);

  const [trigger, setTrigger] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    store.dispatch(listCompanies({ limit, offset, sortingField, sortingOrder, searchValue }));
  }, [limit, offset, externalTrigger, sortingField, sortingOrder, searchValue]);

  useEffect(() => {
    setPage(1);
  }, [searchValue, limit, externalTrigger]);

  const onSelectedRows = (selectedRows) => {
    setSelectedRows(selectedRows);
    setSelectedCompanies(selectedRows?.map((row) => list[row]));
  };

  const onEmail = (cell, row) => {
    return <a href={'mailto:' + row.companyEmail}>{row.companyEmail ? row.companyEmail : '-'}</a>;
  };

  const cellButton = (cell, row) => {
    return (
      <Link to={{ pathname: `/company-details/` + row.objectId, state: { row } }}>
        {row.companyName}
      </Link>
    );
  };

  const columns = [
    {
      Header: headerWithSort('Company Name', 'companyName'),
      accessor: 'companyName',
      searchable: true,
      width: 260,
      disableSortBy: true,
      Cell: (props) => cellButton(props.column.id, props.row.original, props.value),
    },
    /*{{
      Header: headerWithSort('Website', 'companyWebsite'),
      accessor: 'companyWebsite',
      searchable: true,
      disableSortBy: true,
      width: 150,
    },*/
    {
      Header: 'Email',
      accessor: 'companyEmail',
      searchable: true,
      width: 150,
      disableSortBy: true,
      Cell: (props) => onEmail(props.column.id, props.row.original, props.value),
    },
    {
      Header: 'Phone',
      accessor: 'phoneArr',
      searchable: true,
      width: 150,
      disableSortBy: true,
      Cell: (props) => <FormattedPhoneArr phoneArr={props.value} />,
    },
    {
      Header: headerWithSort('# Contacts', 'numContacts'),
      accessor: 'numContacts',
      searchable: true,
      disableSortBy: true,
      width: 75,
      Cell: ({ value, row }) => (<>{(value != 0 ? <Link to={{ pathname: `/company-details/` + row.original.objectId, state: { row: row.original } }}>
        {value}
      </Link> : '')}</>)
    },
    {
      Header: headerWithSort('# Projects', 'numProjects'),
      accessor: 'numProjects',
      searchable: true,
      disableSortBy: true,
      width: 75,
      Cell: ({ value, row }) => (<>{(value != 0 ? (<Link
        className=''
        to={{
          pathname: `/company-job-list-report`,
          search: `?company=${row.original.objectId}`,
        }}
        data-tooltip-content
        data-tooltip-id='MENU4.SUBMENUS.SUBMENU4.Tooltip'
      >{value}</Link>) : '')}</>)
    },
    {
      Header: 'Tags',
      accessor: 'companyTags',
      searchable: true,
      width: 150,
      disableSortBy: true,
      Cell: ({ value }) => <>{value?.length > 0 ? value?.join(',') : ''}</>,
    },
  ];

  const { t } = props;
  return (
    <div className='ContactListReport'>
      <div className='d-flex '>
        <h2 className='heading2'>Companies</h2>

        <div className='ms-auto'></div>

        {isAllowed(moduleConstants.EC) && (
          <div className='p-2'>
            <button
              className='btn'
              disabled={selectedCompanies.length !== 1}
              onClick={() => setDeleteModal(!deleteModal)}
            >
              Delete
            </button>
            {selectedCompanies.length === 1 && (
              <DeleteCompanyModal
                companyId={selectedCompanies[0]?.objectId}
                onSubmit={() => setTrigger(!trigger)}
                onClose={() => {
                  setDeleteModal(false);
                }}
                open={deleteModal}
              />
            )}
          </div>
        )}
        {isAllowed(moduleConstants.IMPORTCONTACTS) && (
          <div className='p-2'>
            <button className='btn' onClick={() => importAddCompany('importCompany')}>
              Import
            </button>
          </div>
        )}
        {isAllowed(moduleConstants.EC) && (
          <div className='p-2'>
            <button className='btn ' onClick={() => importAddCompany('POST')}>
              Add Company
            </button>
          </div>
        )}
        {
          <div className='detailMenu p-2'>
            <li className=''>
              <a
                className='dropdown-toggle btn'
                data-bs-toggle='dropdown'
                href='#'
                role='button'
                aria-expanded='false'
              >
                <i className='fa fa-ellipsis-v' aria-hidden='true'></i>
              </a>
              <ul className='dropdown-menu dropdown-menu-end'>
                {isAllowed(reportAccess.COMLV, 'anyAssigned') && (
                  <li className='dropdown-item nav-item'>
                    <Link
                      className=''
                      to={{
                        pathname: `/company-list-view-report`,
                      }}
                      data-tooltip-content={t('MENU1.SUBMENUS.SUBMENU6.Tooltip')}
                      data-tooltip-id='MENU1.SUBMENUS.SUBMENU6.Tooltip'
                    >
                      {t('Companies (List View)')}
                    </Link>
                    <Tooltip
                      id='MENU1.SUBMENUS.SUBMENU6.Tooltip'
                      place='right'
                      delayShow={1000}
                    />
                    
                  </li>
                )}
              </ul>
            </li>
          </div>
        }
      </div>
      <div className='row'>
        <div className='col-md-12 sortable-table'>
          <SearchComponent onSearchSubmit={setSearchValue} />
          <CustomTable
            columns={columns}
            list={list}
            inlineEdit={false}
            canShowExport={false}
            canShowPagination={false}
            canShowGlobalFilter={false}
            canShowColumnSettings={false}
            canShowDateFilter={false}
            canShowRowSelection={true}
            isLoading={loading}
            onChangeRowSelection={onSelectedRows}
            useControlledState={(state) => ({ ...state, ...rowSelection, ...sortingState })}
          />
          <RemotePagination {...pagination} />
        </div>
      </div>
      <AddCompanyModal open={companyModalOpened} onClose={importAddCompany} onSubmit={onSubmitCb} />
    </div>
  );
};

class CompaniesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sessionToken: localStorage.getItem('sessionToken'),
      userId: localStorage.getItem('userId'),
      accountId: localStorage.getItem('accountId'),
      companies: [],
      formArr: [],
      trigger: false,
      modal: false,
      saveType: 'POST',
      successUploading: true,
      errorUploading: true,
      message: '',
      fetchRequest: true,
      successRedirect: false,
      companyModal: false,
      currentPage: 1,
      sizePerPage: appConstants.TableSizePerPageList[2].value,
      searchText: '',
      selectedRows: [],
      totalCount: 0,
    };
  }

  importAddCompany = (val) => {
    if (val === 'importCompany') {
      this.props.history.push({
        pathname: '/import-companies',
        state: { redirectOn: '/view-companies' },
      });
    } else if (val === 'companySubmitted') {
      this.setState({ trigger: !this.state.trigger });
      this.setState({ companyModal: false, formArr: [] });
    } else {
      this.setState({ trigger: !this.state.trigger });
      this.setState({ companyModal: !this.state.companyModal, formArr: [] });
    }
  };

  onPageChange = (page, sizePerPage) => {
    this.setState({ fetchRequest: true });
    let data = {};
    data['accountId'] = this.state.accountId;
    if (this.state.searchText === '') {
      data['page'] = page === -1 ? 0 : page - 1;
      data['displayLimit'] = sizePerPage;
    } else {
      data['page'] = page === -1 ? 0 : page - 1;
      data['displayLimit'] = sizePerPage;
      data['search'] = this.state.searchText;
    }

    this.setState({ sizePerPage: sizePerPage });
    this.setState({ currentPage: page });
  };




  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.companies && nextProps.companies.data && nextProps.companies.data.length >= 0) {
      this.setState({
        companies: nextProps.companies.data,
        totalCount: nextProps.companies.count,
        fetchRequest: false,
      });
    }
  }

  shouldComponentUpdate(prevProps, nextProps) {
    if (prevProps.location.state && prevProps.location.state.rand !== this.state.openPopUpState) {
      if (
        prevProps.location.state.openPopUp === 'COMPANY' &&
        (prevProps.history.action === 'PUSH' || prevProps.history.action === 'REPLACE')
      ) {
        this.importAddCompany('POST');
      }
      this.setState({ openPopUpState: prevProps.location.state.rand });

      return true;
    }
    return true;
  }

  handleAddCompanyModalSubmit = (_id) => {
    this.props.history.push(`/company-details/${_id}`);
  };

  render() {
    return (
      <>
        <div className='ContactListReport'>
          <TitleComponent data={{ title: 'Companies' }} />
          <Suspense fallback='loading'>
            <Header props={this.props} />
          </Suspense>

          <div className='contactBox ms-2 me-2 row'>
            <div className='container col-md-12'>
              <RenderCompanies
                importAddCompany={this.importAddCompany}
                list={this.state.companies}
                props={this.props}
                companyModalOpened={this.state.companyModal}
                onSubmit={this.handleAddCompanyModalSubmit}
                externalTrigger={this.state.trigger}
                onCompanySelect={(selectedCompanies) => this.setState({ selectedCompanies })}
              />
            </div>
          </div>
          <Footer />
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    companies: state.rtk.companies.listCompanies.data,
    count: state.rtk.companies.listCompanies.total,
    message: state.company.message,
    success: state.company.success,
    companySubmitted: state.company.submitted,
  };
}

export default withTranslation()(
  withRouter(
    connect(mapStateToProps, {
      getCompanyListAction,
      //createCompanyAction,
     // updateCompanyAction,
      deleteCompanyAction,
    })(CompaniesList)
  )
);
