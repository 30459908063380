import { useState, forwardRef, useImperativeHandle, useCallback, useEffect } from 'react';
import { useAppDispatch } from '../../../../shared/hooks/use-app-dispatch';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { useAppSelector } from '../../../../shared/hooks/use-app-selector';
import { selectAllJobScopes, selectOptionTemplateJobScope } from '../../../../selectors/jobScope';
//import { useConfirm } from '../../../../shared/confirmation-dialog';
import { isAllowed, moduleConstants } from '../../../../_constants';
import { notification } from 'antd';
import { TotalSection } from './total-section/total-section';
import { ScopeEstimateModule } from './scope-estimate-module/scope-estimate-module';
import styles from './style.module.scss';
import { getUserSettings } from '../../../../modules/settings';
import { HorizontalLayout } from '../../../../shared/form-controls/control-layouts/horizontal-layout';
//import { Menu} from 'antd';
//import { MenuOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import _ from 'lodash';
import FormInput from '../../../../shared/form-controls/form-input/form-input';
import { currencyToNumber } from '../../../../utils/number-formatters';

type JobScopeLineItemsProps = {
  projectData: any;
  projectBidData?: any;
  isLocked: boolean;
  isChangeOrder?: boolean;
  toggleDetailedModeCallback?: (newVal: boolean) => void;
};

export const JobScopeLineItems = forwardRef(
  (
    {
      projectData = {},
      projectBidData = {},
      isLocked,
      isChangeOrder = false,
      toggleDetailedModeCallback,
    }: JobScopeLineItemsProps,
    ref
  ) => {
    //const confirm = useConfirm();
    const { watch, control, setValue, getValues } = useFormContext();
    const estData = watch('estData', []);
    const estDataArray = useFieldArray({ name: 'estData', control: control });

    const [visibleColumns] = useState({
      description: true,
      serviceDate: false,
      qty: true,
      unitCost: true,
      cost: true,
      markup: true,
      unitPrice: true,
      total: true,
      taxable: true,
      billable: false,
    });

    /*useEffect(() => {
      const savedColumns = localStorage.getItem('estDataColumns');
      if (savedColumns) {
        setVisibleColumns(JSON.parse(savedColumns));
      }
    }, []);*/

    useEffect(() => {
      localStorage.setItem('estDataColumns', JSON.stringify(visibleColumns));
    }, [visibleColumns]);

    /*const toggleColumn = (column) => {
      setVisibleColumns(prev => ({ ...prev, [column]: !prev[column] }));
    };*/

    const [detailedMode, setDetailedMode] = useState(false);

    {
      /* const advancedMenu = (
      <>
    
    
      <Menu.Item key="mode" onClick={() => setDetailedMode(!detailedMode)}  >
          {detailedMode ? 'Show summary mode' : 'Show advanced mode'}
        </Menu.Item>
     
      <Menu>
        <Menu.Item key="mode" onClick={() => setDetailedMode(!detailedMode)}  >
          {detailedMode ? 'Show summary mode' : 'Show advanced mode'}
        </Menu.Item>
        {/*}
        <Menu.SubMenu key="columns" title="Show/hide columns" >
          {Object.entries(visibleColumns).map(([column, isVisible]) => (
            <Menu.Item key={column}  >
              <span onClick={(e) => { toggleColumn(column); e.preventDefault(); e.stopPropagation(); }}>
                <Checkbox checked={isVisible}>{column.charAt(0).toUpperCase() + column.slice(1)}</Checkbox></span>
            </Menu.Item>
          ))}
        </Menu.SubMenu>  </Menu>
    </>
    );*/
    }

    /*const simpleMenu = (
      <Menu>
        <Menu.Item key="mode" onClick={() => setDetailedMode(!detailedMode)}>
          {detailedMode ? 'Show summary mode' : 'Show advanced mode'}
        </Menu.Item>
      </Menu>
    );*/

    const updateEstDataFields = useCallback(
      (items) => {
        const estDataLocal = _.cloneDeep(items ?? getValues('estData'));
        estDataLocal.forEach((item, index) => {
          setValue(`estData.${index}.lineItemId`, item.lineItemId);
          setValue(`estData.${index}.qbItemId`, item.qbItemId);
          setValue(`estData.${index}.scopeId`, item.scopeId);
          setValue(`estData.${index}.subTotalCost`, item.subTotalCost);
          setValue(`estData.${index}.subTotalProfit`, item.subTotalProfit);
          setValue(`estData.${index}.subTotalCtr`, item.subTotalCtr);
          setValue(`estData.${index}.subTotalProfitPers`, item.subTotalProfitPers);
          setValue(`estData.${index}.description`, item.description ?? '');
          setValue(`estData.${index}.serviceDate`, item.serviceDate);
          setValue(`estData.${index}.qty`, item.qty ?? 0);
          setValue(`estData.${index}.unitCost`, item.unitCost ?? 0);
          setValue(`estData.${index}.unitPrice`, item.unitPrice ?? 0);
          setValue(`estData.${index}.taxable`, item.taxable ?? false);
          setValue(`estData.${index}.billable`, item.billable ?? true);
          setValue(`estData.${index}.items`, item.items);
          if (item?.items?.length > 0) {
            item.items.forEach((subItem, subIndex) => {
              setValue(`estData.${index}.items.${subIndex}.lineItemId`, subItem.lineItemId);
              setValue(`estData.${index}.items.${subIndex}.qbItemId`, subItem.qbItemId);
              setValue(`estData.${index}.items.${subIndex}.scopeId`, subItem.scopeId);
              setValue(`estData.${index}.items.${subIndex}.qty`, subItem.qty ?? 0);
              setValue(`estData.${index}.items.${subIndex}.unitCost`, subItem.unitCost ?? 0);
              setValue(`estData.${index}.items.${subIndex}.unitPrice`, subItem.unitPrice ?? 0);
              setValue(`estData.${index}.items.${subIndex}.cost`, subItem.cost);
              setValue(`estData.${index}.items.${subIndex}.profit`, subItem.profit);
              setValue(`estData.${index}.items.${subIndex}.total`, subItem.total);
              setValue(`estData.${index}.items.${subIndex}.profitPers`, subItem.profitPers);
              setValue(`estData.${index}.items.${subIndex}.description`, subItem.description ?? '');
              setValue(`estData.${index}.items.${subIndex}.serviceDate`, subItem.serviceDate);
              setValue(`estData.${index}.items.${subIndex}.taxable`, subItem.taxable ?? false);
              setValue(`estData.${index}.items.${subIndex}.billable`, subItem.billable ?? true);
            });
          }
        });
      },
      [getValues, setValue]
    );

    const onDragEnd = (result) => {
      if (!result.destination) {
        return;
      }

      const estDataLocal = getValues('estData');
      const items = _.cloneDeep(estDataLocal);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      updateEstDataFields(items);
    };

    const onDragEndNested = (scopeIndex, result) => {
      if (!result.destination) {
        return;
      }

      const estDataLocal = getValues('estData');
      const items = _.cloneDeep(estDataLocal[scopeIndex].items);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      //setValue(`estData.${scopeIndex}.items`, items);

      updateEstDataFields(items);
    };

    const hasBidAmountPermissions = isAllowed(moduleConstants.EBAGP);
    const [lineItemOptions, setLineItemOptions] = useState([]);

    const dispatch = useAppDispatch();

    useEffect(() => {
      dispatch(getUserSettings(['lineItemTypes']))
        .unwrap()
        .then((data) => {
          setLineItemOptions(data);
        });
    }, []);

    const jobScopes = useAppSelector(selectOptionTemplateJobScope);
    const allJobScopes = useAppSelector(selectAllJobScopes);

    useImperativeHandle(ref, () => ({ buildScope }), []);
    const [showAsPers, setShowAsPers] = useState<boolean>(true);
    const [isTotalLocked, setIsTotalLocked] = useState<boolean>(false);

    const getAvailableJobScopes = () => {
      const existingScopeIds = estData.map((scope: any) => scope.scopeId);
      return jobScopes.filter((scope: any) => !existingScopeIds.includes(scope.value));
    };

    const getAllAvailableJobScopes = () => {
      const existingScopeIds = estData.map((scope: any) => scope.scopeId);
      return allJobScopes.filter((scope: any) => !existingScopeIds.includes(scope.value));
    };

    const [hasRemovedScopes] = useState(false);

    const [estDataLoaded, setEstDataLoaded] = useState(false);

    useEffect(() => {
      // if line item is null or blank on estData[x].lineItemId initially, match lineItemId based on estData[x].jobScopeId

      const hasOriginalEstData = projectBidData?.estData?.length > 0;

      // if no estdata is available, add scopes based on project data or bid data
      if (!hasOriginalEstData && !estDataLoaded && !_.isEmpty(projectBidData)) {
        if (allJobScopes.length > 0) {
          if (
            (!projectBidData.estData || projectBidData.estData.length == 0) &&
            projectData?.scopeArr?.length > 0 &&
            estData.length === 0 &&
            estDataArray
          ) {
            //const availableJobScopes = getAvailableJobScopes();
            if (hasRemovedScopes) return;
            projectData.scopeArr.forEach((scope, idx) => onAddScope(scope.objectId, idx == 0));
          }
          // if estData is empty just add an empty scope
          if ((estData.length === 0 && estDataArray) || !estDataArray) {
            onAddScope('clearScope', true);
            setEstDataLoaded(true);
          }
        }
      } else if (
        !estDataLoaded &&
        !_.isEmpty(projectBidData) &&
        hasOriginalEstData &&
        estData.length === 0
      ) {
        setValue('estData', projectBidData.estData);
      }

      // if estData has loaded, fill all old job scopes with matching line item data (if available)
      if (
        hasOriginalEstData &&
        lineItemOptions.length > 0 &&
        estData.length > 0 &&
        !estDataLoaded
      ) {
        if (estData.filter((x: any) => x.lineItemId || x.scopeId)?.length > 0) {
          const estDataLocal = getValues('estData');
          const items = _.cloneDeep(estDataLocal);
          items.map((item) => {
            if (!item.lineItemId) {
              if (!item.scopeId) return;
              const lineItemJobScope = item.scopeId;
              const matchedJobScope = allJobScopes.find(
                (scope) => scope.value === lineItemJobScope
              );
              const lineItemObj = lineItemOptions.find(
                (li) => (li as any)._id === matchedJobScope.lineItemId
              ) as any;
              if (lineItemObj) {
                item.lineItemId = lineItemObj._id;
                item.qbItemId = lineItemObj.qbItemId;

                //setValue(`estData.${index}.unitCost`, lineItemObj.cost);
                //setValue(`estData.${index}.unitPrice`, lineItemObj.price);
                //setValue(`estData.${index}.taxable`, lineItemObj.taxable);
                //setValue(`estData.${index}.billable`, lineItemObj.billable);
              }
            }
          });

          setValue(`estData`, items);

          setEstDataLoaded(true);
        }
        //setValue('estData', items);

        //estDataArray.replace(items);
      }
    }, [lineItemOptions, estData.length, allJobScopes.length, projectBidData]);

    const getAddScopeLink = () => {
      const availableJobScopes = getAvailableJobScopes();
      const hasItems = estData.length > 0;

      if (isLocked || availableJobScopes.length === 0 || (hasItems && !hasBidAmountPermissions)) {
        return null;
      }

      const linkText = `${lineItemOptions?.length > 0 ? 'Add line item' : 'Add job scope'}`;

      return (
        <div className='row'>
          <div className='col-sm-4 mt-2'>
            <a onClick={() => onAddFirstLevelItem(-1)}>{linkText}</a>
          </div>
          <div className='col-sm-4 mt-2'>
            {' '}
            <a onClick={() => setDetailedMode(!detailedMode)}>
              {detailedMode ? 'Show summary' : 'Show expanded'}
            </a>
          </div>
        </div>
      );
    };

    const buildScope = useCallback(
      (
        id: string,
        isInitial: boolean,
        isCO = isChangeOrder,
        data = projectData,
        bidData = projectBidData
      ) => {
        const availableJobScopes: Array<any> = getAvailableJobScopes();
        const allJobScopes: Array<any> = getAllAvailableJobScopes();

        if (availableJobScopes.length === 0) {
          return;
        }

        const hasLegacyScopeArr = data?.scopeArr?.length > 0;

        const currentJobScopes = hasLegacyScopeArr
          ? data?.scopeArr?.map((scope) => scope.objectId)
          : data.scope?.length > 0
            ? data.scope
            : [];
        // filter available job scopes by current job scopes
        const notAddedJobScopes = currentJobScopes.filter((scope: any) => {
          return allJobScopes.find((availableScope: any) => availableScope.value === scope);
        });

        let scopeId =
          notAddedJobScopes.length > 0 ? notAddedJobScopes[0] : availableJobScopes[0].value;
        const scopeAvailable = allJobScopes.find((scope) => scope.value === id);
        if (id && scopeAvailable) {
          {
            scopeId = id;
          }
        } else if (id === 'clearScope') {
          if (!isInitial || isCO) {
            return {
              scopeId: null,
              subTotalCost: 0,
              subTotalProfit: 0,
              subTotalCtr: 0,
              subTotalProfitPers: 0,
              qty: 1,
              unitCost: 0,
              unitPrice: 0,
              taxable: false,
              billable: true,
              description: '',
              allowChangeScope: true,
              lineItemId: null,
            };
          } else {
            // use project data to assign subtotalcost, profit, ctr, profitpers
            const subTotalCost = data.contractAmount - data.grossProfit;
            const subTotalProfit = data.grossProfit;
            const subTotalCtr = data.contractAmount;
            const subTotalProfitPers = (subTotalProfit / subTotalCost) * 100;
            return {
              scopeId: null,
              subTotalCost: subTotalCost,
              subTotalProfit: subTotalProfit,
              subTotalCtr: subTotalCtr,
              subTotalProfitPers,
              qty: 1,
              unitCost: subTotalCost,
              unitPrice: subTotalCtr,
              taxable: false,
              billable: true,
              description: '',
              allowChangeScope: true,
              lineItemId: null,
            };
          }
        }

        const matchedLineItemId = lineItemOptions.find((li) => (li as any).jobScopeId === scopeId);

        let { grossProfit = 0, contractAmount = 0 } = data;

        const isOriginalContract = !isCO && bidData.originalContractAmount !== undefined;

        if (isCO && isInitial) {
          grossProfit = bidData.estTotalProfit ?? bidData.profitDollars;
          contractAmount = bidData.estTotalContract ?? bidData.coTotalAmount;
        } else if (isInitial && isOriginalContract) {
          grossProfit = bidData.originalGrossProfit;
          contractAmount = bidData.originalContractAmount;
        }

        if (bidData?.estData?.map((scope: any) => scope.scopeId).includes(scopeId)) {
          const selectedScope = bidData.estData.find((scope: any) => scope.scopeId === scopeId);
          const { subTotalCost, subTotalProfit, subTotalCtr, subTotalProfitPers } = selectedScope;

          return {
            scopeId,
            subTotalCost: subTotalCost,
            subTotalProfit: subTotalProfit,
            subTotalCtr: subTotalCtr,
            subTotalProfitPers,
            qty: 1,
            unitCost: subTotalCost,
            unitPrice: subTotalCtr,
            taxable: false,
            billable: true,
            description: '',

            allowChangeScope: !id,
            lineItemId: (matchedLineItemId as any)?._id,
          };
        } else {
          if (
            isInitial &&
            (!bidData.scopeArr || bidData.scopeArr.length === 0) &&
            (bidData.estTotalContract > 0 || bidData.coTotalAmount > 0)
          ) {
            //grossProfit = bidData.estTotalProfit ?? bidData.profitDollars;
            //contractAmount = bidData.originalContractAmount??bidData.estTotalContract ?? bidData.coTotalAmount;
          }
          const subTotalProfit = isInitial ? +grossProfit : 0;
          const subTotalCost = isInitial ? +contractAmount - subTotalProfit : 0;
          const subTotalCtr = isInitial ? subTotalCost + subTotalProfit : 0;
          const subTotalProfitPers = isInitial ? (subTotalProfit / subTotalCost) * 100 : 0;

          return {
            scopeId,
            subTotalCost: subTotalCost,
            subTotalProfit: subTotalProfit,
            subTotalCtr: subTotalCtr,
            subTotalProfitPers,
            qty: 1,
            unitCost: subTotalCost,
            unitPrice: subTotalCtr,
            taxable: false,
            billable: true,
            description: '',
            allowChangeScope: !id,
            lineItemId: (matchedLineItemId as any)?._id,
          };
        }
      },
      [projectData, estData, jobScopes]
    );

    const onAddScope = (id: string, isInitial = false, insertIndex?: number) => {
      const newScopes = buildScope(id, isInitial);

      if (!insertIndex || estData.length === 0) {
        //estDataArray.append(newScopes);
        setValue('estData', [newScopes]);
        updateEstDataFields([newScopes]);
      } else {
        const _localEstData = getValues('estData');
        const items = _.cloneDeep(_localEstData);
        items.splice(insertIndex, 0, newScopes);
        setValue('estData', items);
        updateEstDataFields(items);
      }
    };

    const [shouldRecalculateTotals, setShouldRecalculateTotals] = useState(false);

    const onAddFirstLevelItem = async (index: number) => {
      if (!hasBidAmountPermissions) {
        notification.error({ message: 'Insufficient permissions for adding line items' });
        return;
      }

      if (index === -1) {
        index = estData.length;
      }

      onAddScope('clearScope', false, index + 1);

      setShouldRecalculateTotals(true);
      if (!estDataLoaded) {
        setEstDataLoaded(true);
      }
    };

    const onRemoveFirstLevelItem = async (index: number) => {
      if (!hasBidAmountPermissions) {
        notification.error({ message: 'Insufficient permissions for removing scope' });
        return;
      }

      if (index <= estData.length) {
        const _localEstData = getValues('estData');
        const items = _.cloneDeep(_localEstData);
        items.splice(index, 1);
        setValue('estData', items);
        updateEstDataFields(items); // Update fields after removal
      }

      if (estData.length <= 1) {
        onAddScope('clearScope', false);
        setShouldRecalculateTotals(true);
      }
    };

    useEffect(() => {
      // if number of estdata array items changes, recalculate total of totals
      if (estData.length > 0) {
        const total = estData.reduce((acc, item) => {
          if (typeof item.subTotalCtr === 'string')
            return acc + +currencyToNumber(item.subTotalCtr);
          return acc + +item.subTotalCtr;
        }, 0);
        const profit = estData.reduce((acc, item) => {
          if (typeof item.subTotalProfit === 'string')
            return acc + +currencyToNumber(item.subTotalProfit);
          return acc + +item.subTotalProfit;
        }, 0);
        const cost = estData.reduce((acc, item) => {
          if (typeof item.subTotalCost === 'string')
            return acc + +currencyToNumber(item.subTotalCost);
          return acc + +item.subTotalCost;
        }, 0);

        setValue('estTotalCost', cost);
        setValue('estTotalProfit', profit);
        setValue('estTotalContract', total);

        setShouldRecalculateTotals(false);
      }
    }, [estData.length, estDataLoaded, shouldRecalculateTotals]);

    const currentGrossProfit = isChangeOrder
      ? projectBidData.estTotalProfit
      : projectData.currentGrossProfit;
    const currentContractAmount = isChangeOrder
      ? projectBidData.estTotalContract
      : projectData.currentContractAmount;

    const hasEstData = !!estData.length;

    useEffect(() => {
      toggleDetailedModeCallback && toggleDetailedModeCallback(detailedMode);
    }, [detailedMode]);

    const hasExistingQBestimate = projectBidData?.qbEstimateId;

    const canCreateQBestimate = () => {
      return lineItemOptions.filter((li: any) => li.qbItemId).length > 0;
    };

    const onAddNewLineItem = (lineItem: any) => {
      // updateLineItems
      dispatch(getUserSettings(['lineItemTypes']))
        .unwrap()
        .then((data) => {
          setLineItemOptions(data);

          // look for any estData entry that has no lineItemId but who'se text matches the lineItem text
          const estDataLocal = getValues('estData');
          const items = _.cloneDeep(estDataLocal);
          const lineItemValue = lineItem.code;
          const lineItemObj = data.find((li) => li.code === lineItemValue);

          const estDataIndex = items.findIndex((item) => item.lineItemId === 'add_new');

          if (estDataIndex > -1) {
            items[estDataIndex].lineItemId = lineItemObj._id;
            items[estDataIndex].qbItemId = lineItemObj.qbItemId;
            items[estDataIndex].unitCost = lineItemObj.cost;
            items[estDataIndex].unitPrice = lineItemObj.price;
            items[estDataIndex].qty = 1;
            items[estDataIndex].subTotalCtr = lineItemObj.price;
            items[estDataIndex].subTotalProfit = lineItemObj.price - lineItemObj.cost;
            items[estDataIndex].subTotalCost = lineItemObj.cost;
            items[estDataIndex].taxable = lineItemObj.taxable;
            items[estDataIndex].billable = lineItemObj.billable;

            setValue(`estData.${estDataIndex}`, items[estDataIndex]);
          } else {
            for (const x in items) {
              if (items[x].items) {
                const itemIndex = items[x].items.findIndex((item) => item.lineItemId === 'add_new');
                if (itemIndex > -1) {
                  items[x].items[itemIndex].lineItemId = lineItemObj._id;
                  items[x].items[itemIndex].qbItemId = lineItemObj.qbItemId;
                  items[x].items[itemIndex].unitCost = lineItemObj.cost;
                  items[x].items[itemIndex].unitPrice = lineItemObj.price;
                  items[x].items[itemIndex].qty = 1;
                  items[x].items[itemIndex].cost = lineItemObj.cost;
                  items[x].items[itemIndex].total = lineItemObj.price;
                  items[x].items[itemIndex].profit = lineItemObj.price - lineItemObj.cost;
                  items[x].items[itemIndex].profitPers =
                    (items[x].items[itemIndex].profit / lineItemObj.cost) * 100;
                  items[x].items[itemIndex].taxable = lineItemObj.taxable;
                  items[x].items[itemIndex].billable = lineItemObj.billable;
                  setValue(`estData.${x}.items.${itemIndex}`, items[x].items[itemIndex]);
                }
              }
            }
          }
        });
    };

    return (
      <>
        {(!isLocked || hasEstData) && <hr />}
        <div className={'flex-nowrap '+styles.scopeEstimateContainer}>
          {hasEstData && (
            <>
              <div className='row flex-nowrap col-md-12 pb-2'>
                <div className={`${detailedMode ? 'col-md-2 ms-4 ps-3 ' : 'col-md-4'}  fw-bold`}>
                  {'Item name'}
                </div>
                {detailedMode && visibleColumns.description && (
                  <div className={`ps-2 fw-bold ${styles.inputWidthDetailedLong}`}>Description</div>
                )}
                {detailedMode && visibleColumns.serviceDate && (
                  <div className={`ps-2 fw-bold ${styles.inputWidthDetailed}`}>Service date</div>
                )}
                {detailedMode && visibleColumns.qty && (
                  <div className={`ps-2 fw-bold ${styles.inputWidthDetailedShort}`}>Qty</div>
                )}
                {detailedMode && visibleColumns.unitCost && (
                  <div className={`ps-2 fw-bold ${styles.inputWidthDetailed}`}>Unit cost</div>
                )}
                {(!detailedMode || visibleColumns.cost) && (
                  <div
                    className={`ps-2 fw-bold ${detailedMode ? styles.inputWidthDetailed : styles.inputWidth}`}
                  >
                    Cost
                  </div>
                )}
                {(!detailedMode || visibleColumns.markup) && (
                  <div
                    className={`ps-2 fw-bold ${detailedMode ? styles.inputWidthDetailed : styles.inputWidth}`}
                  >
                    Markup (
                    <span
                      className={!showAsPers ? '' : styles.active}
                      onClick={() => setShowAsPers(true)}
                    >
                      %
                    </span>
                    /
                    <span
                      className={showAsPers ? '' : styles.active}
                      onClick={() => setShowAsPers(false)}
                    >
                      $
                    </span>
                    )
                  </div>
                )}
                {detailedMode && visibleColumns.unitPrice && (
                  <>
                    <div className={`ps-2 fw-bold ${styles.inputWidthDetailed}`}>Unit price</div>
                  </>
                )}
                {(!detailedMode || visibleColumns.total) && (
                  <div
                    className={`ps-2 fw-bold ${detailedMode ? styles.inputWidthDetailed : styles.inputWidthShort}`}
                  >
                    Total{' '}
                    {!isLocked && (
                      <i
                        role='button'
                        className={`fa-solid fa-${isTotalLocked ? 'lock' : 'unlock'} ${styles.grey}`}
                        onClick={() => setIsTotalLocked(!isTotalLocked)}
                      ></i>
                    )}
                  </div>
                )}

                {detailedMode && visibleColumns.taxable && (
                  <div className={`ps-1 fw-bold ${styles.inputWidthDetailedShort}`}>Taxable</div>
                )}
                {detailedMode && visibleColumns.billable && (
                  <div className={`ps-1 fw-bold ${styles.inputWidthDetailedShort}`}>Billable</div>
                )}

                <div className={`px-1 ${styles.toggleBtn} me-0`}>
                  <div className={`px-1 ${styles.menuBtn} me-0`}>
                    {/*}
                    <Dropdown overlay={detailedMode ? advancedMenu : simpleMenu} trigger={['click']} placement='topLeft' >
                      <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                        <MenuOutlined />
                      </a>
                    </Dropdown>*/}
                  </div>
                </div>
              </div>
            </>
          )}
          {hasEstData && estDataLoaded && (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId='scopes'>
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {estData.map((firstLevelLineItem: any, index: number) => (
                      <Draggable
                        key={index}
                        draggableId={
                          'level1:' + index.toString() + ':' + firstLevelLineItem.scopeId
                        }
                        index={index}
                        isDragDisabled={!detailedMode || isLocked}
                      >
                        {(provided) => (
                          <div ref={provided.innerRef} {...provided.draggableProps}>
                            <ScopeEstimateModule
                              dragHandleProps={provided.dragHandleProps}
                              id={firstLevelLineItem.scopeId}
                              itemIndex={index}
                              isLocked={isLocked}
                              onRemoveFirstLevelItem={onRemoveFirstLevelItem}
                              options={jobScopes}
                              allOptions={allJobScopes}
                              showProfitAsPers={showAsPers}
                              isTotalLocked={isTotalLocked}
                              lineItemOptions={lineItemOptions}
                              onAddNewLineItem={onAddNewLineItem}
                              onAddFirstLevelItem={onAddFirstLevelItem}
                              detailedMode={detailedMode}
                              //allowLineItems={isScopeBundle(scope.scopeId)}
                              visibleColumns={visibleColumns}
                              onDragEndNested={(result) => onDragEndNested(index, result)}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </div>
        {getAddScopeLink()}
        <TotalSection
          showProfitAsPers={showAsPers}
          initialValues={{ currentGrossProfit, currentContractAmount }}
        />

        {(hasExistingQBestimate || canCreateQBestimate()) && (
          <div className='row mt-4'>
            {hasExistingQBestimate && (
              <>
                {' '}
                <a href={`${projectBidData.qbEstimateLink}`} target='_blank' rel='noreferrer'>
                  View linked Quickbooks estimate
                </a>
                <br />
              </>
            )}

            {!isLocked && (
              <>
                {!hasExistingQBestimate && (
                  <FormInput
                    type='checkbox'
                    Layout={HorizontalLayout}
                    control={control}
                    inputClassName={`form-check-input  ` + styles.checkbox}
                    name='createEstimate'
                    label='Create Quickbooks estimate on save'
                    onChange={(value) => {
                      setValue('createEstimate', value);
                    }}
                  />
                )}

                {hasExistingQBestimate && (
                  <div className=''>
                    <FormInput
                      type='checkbox'
                      Layout={HorizontalLayout}
                      control={control}
                      inputClassName={`form-check-input  ` + styles.checkbox}
                      name='createEstimate'
                      label='Update Quickbooks estimate on save'
                      onChange={(value) => {
                        setValue('createEstimate', value);
                      }}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </>
    );
  }
);
