import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { SearchAffiliationModal } from '../search-affiliation-modal/search-affiliation-modal';
import { getCompanyContactAffiliations, getAccountAffiliation, saveCompany, validateCompanyName } from '../../modules/companies';
import { Input, Button, Radio, Space, Alert } from 'antd';
import _ from 'lodash';
import { levenshteinDistance } from './fuzzy-match-company';

type SearchCompanyModalProps = {
  open: boolean;
  onClose?: (...args: any) => unknown;
  onConfirm?: (...args: any) => unknown;
  onGetOriginalCompanyName: (...args: any) => string;
  onBulkMap?: (companyName: string, originalCompanyName: string) => void;
};

type DialogDefaultValues = {
  affiliations: Array<any>;
  formerAffiliations: Array<any>;
  affiliationsFetched: Array<any>;
};

export const SearchCompanyModal = ({
  open = false,
  onClose = () => void 0,
  onConfirm = () => void 0,
  onGetOriginalCompanyName,
  onBulkMap = () => void 0,
}: SearchCompanyModalProps) => {
  const defaultValues: DialogDefaultValues = {
    affiliations: [],
    formerAffiliations: [],
    affiliationsFetched: [],
  };

  const dispatch: any = useDispatch();
  const [originalCompanyName, setOriginalCompanyName] = useState<string>('');
  const [newCompanyName, setNewCompanyName] = useState<string>('');
  const [action, setAction] = useState<'map' | 'create' | null>(null);
  const [bestMatch, setBestMatch] = useState<string | null>(null);
  const form = useForm({ defaultValues });
  const { watch, setValue } = form;
  const companyAffiliation = watch('affiliations') || [];

  useEffect(() => {
    if (open) {
      dispatch(getAccountAffiliation());
      dispatch(getCompanyContactAffiliations());
      const original = onGetOriginalCompanyName();
      setOriginalCompanyName(original);
      setNewCompanyName(original);
      findBestMatch(original);
    }
  }, [open]);
  

const findBestMatch = async (companyName: string) => {
  // Fetch all company names
  const allCompanies = await dispatch(getCompanyContactAffiliations() as any).unwrap();
  
  let bestMatch = '';
  let bestScore = Infinity;

  allCompanies.forEach(company => {
    const distance = levenshteinDistance(companyName.toLowerCase(), company.companyName.toLowerCase());
    const score = distance / Math.max(companyName.length, company.companyName.length);

    if (score < bestScore) {
      bestScore = score;
      bestMatch = company.companyName;
    }
  });

  // Only set as best match if the score is below a certain threshold
  if (bestScore < 0.3) {
    setBestMatch(bestMatch);
  } else {
    setBestMatch(null);
  }
};

  const [invalidCompanyName, setInvalidCompanyName] = useState(false);

  const validateCompanyNameDebounced = async () => {
    if (!newCompanyName) return;

    if(action !== 'create'){
      setInvalidCompanyName(false);
      return;
    }
    const { valid } = await dispatch((validateCompanyName as any)({ companyName:newCompanyName })).unwrap();
    if (valid) {
      setInvalidCompanyName(false);
    } else {
      setInvalidCompanyName(true);
    }
  };

  useEffect(() => {
    const debounced = _.debounce(validateCompanyNameDebounced, 500);
    debounced();
    return debounced.cancel;
  }, [newCompanyName, dispatch,action]);

  const onCloseClick = () => {
    setValue('affiliations', []);
    setInvalidCompanyName(false);
    setNewCompanyName('');
    onClose();
  };
  

  const onConfirmClick = useCallback(() => {
    if (action === 'create') {
      const createCompanyArgs = {companyName:newCompanyName};
      dispatch((saveCompany as any)(createCompanyArgs)).unwrap().then((newCompany) => {
        onConfirm(newCompany, originalCompanyName);
      });
    } else if (action === 'map') {
      onConfirm(companyAffiliation[0],originalCompanyName);
    }
    onCloseClick();
  }, [action, newCompanyName, companyAffiliation]);

  const onBulkMapClick = () => {
    if(action === 'create'){
    const createCompanyArgs = {companyName:newCompanyName};
      dispatch((saveCompany as any)(createCompanyArgs)).unwrap().then((newCompany) => {
        onBulkMap(newCompany, originalCompanyName);
      });
    }else{
      onBulkMap(companyAffiliation[0],originalCompanyName);
    }
    onCloseClick();
  };

  const onCompanyButtonPress = (selectedCompanyName) => {
    if(selectedCompanyName === originalCompanyName) {
      setAction('create');
    }else{
      setAction('map');
    }
    setNewCompanyName(selectedCompanyName);


  };

  return (
    <Modal isOpen={open} toggle={onClose} size="lg">
      <ModalHeader toggle={onClose}>Match Company</ModalHeader>
      <ModalBody>
        <FormProvider {...form}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <div className={'d-flex'}>
              <strong>Original company name:</strong>&nbsp;{originalCompanyName}
              
              {newCompanyName!==originalCompanyName&&<div className={'ms-auto'}><Button className={'ms-auto'} onClick={() => onCompanyButtonPress(originalCompanyName)}>Use original</Button></div>}
            </div>
            {bestMatch && bestMatch?.length>0 && bestMatch!==newCompanyName && (
              <Alert
                message={`Did you mean "${bestMatch}"?`}
                type="info"
                showIcon
                action={
                  <Button size="small" onClick={() => onCompanyButtonPress(bestMatch)}>
                    Use this
                  </Button>
                }
              />
            )}
            <Radio.Group onChange={(e) => setAction(e.target.value)} value={action}>
              <Space direction="vertical">
                <Radio value="map">Map to existing company</Radio>
                <Radio value="create">Create new company</Radio>
              </Space>
            </Radio.Group>

            {action === 'map' && (
              <SearchAffiliationModal
                onAffiliationPush={(company) => setValue('affiliations', [company])}
                addCompanyText={'Select Company'}
                onChangeSelection={(company) => setValue('affiliations', [company])}
                initialSelectedName={newCompanyName}
                showSelected={true}
              />
            )}

            {action === 'create' && (
              <>
              <Input
                placeholder="Enter new company name"
                value={newCompanyName}
                onChange={(e) => setNewCompanyName(e.target.value)}
              />
              {invalidCompanyName && <span style={{color: 'red'}}>Company name already exists</span>}
              </>
            )}
          </Space>
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onCloseClick}>Cancel</Button>
        <Button onClick={onConfirmClick} type="primary" disabled={!action||invalidCompanyName}>
          Confirm for this contact
        </Button>
        <Button onClick={onBulkMapClick} type="primary" disabled={!action||invalidCompanyName}>
          Apply to all matching contacts
        </Button>
      </ModalFooter>
    </Modal>
  );
};