import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { getAllScheduleAction, cancelScheduleAction } from '../../actions/message';
import _ from 'lodash';
import { LightBoxComponent } from './LightBox.component';
import ScheduleEditor from '../Dialogs/schedule-editor-modal';
import { ScheduleView } from './RightSectionRender';
import ActivityHeader from './ActivityHeader.component';
import { appConstants } from '../../_constants';
import ReactLoading from 'react-loading';
//import SchedulePopUp from '../Common/SchedulePopUp';

const ScheduleTab = ({
  location,
  typeId,
  typeRef,
  getAllScheduleAction,
  cancelScheduleAction,
  allEvents,
  isfetched,
  data = {},
}) => {
  const [accountId] = useState(localStorage.getItem('accountId'));
  const [commentList, setCommentList] = useState([]);
  const [showEditSchedule, setShowEditSchedule] = useState(false);
  const [lightBoxOpen, setLightBoxOpen] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [attachmentIndex, setAttachmentIndex] = useState(-1);
  const [scheduleAction, setScheduleAction] = useState('new');
  const [scheduleObjectId, setScheduleObjectId] = useState(null);
  const [showScheduleDetailModal, setShowScheduleDetailModal] = useState(false);
  const [showScheduleDetailId, setShowScheduleDetailId] = useState(null);
  const [filterText, setFilterText] = useState('');
  const[ currentlyEditingScheduleId, setCurrentlyEditingScheduleId] = useState(null);
  const [sortDirection, setSortDirection] = useState('desc');
  const [filterType, setFilterType] = useState(['all']);
  const [isLoading, setIsLoading] = useState(true);

  const filterOptions = [
    {
      title: 'All',
      key: 'all',
      children: [
        { title: 'Regular Notes', key: 'regular_notes' },
        { title: 'Follow-up Notes', key: 'followup_notes' },
        {
          title: 'By Status',
          key: 'status',
          children: [
            { title: 'Pinned', key: 'pinned' },
            { title: 'Unpinned', key: 'unpinned' },
          ],
        },
      ],
    },
  ];

  useEffect(() => {
    if (location.pathname.includes('schedule/add')) {
      setShowEditSchedule(true);
    }else if(location.pathname.includes('schedule/view')){
      const scheduleId = location.pathname.split('/').pop();
      setShowScheduleDetailId(scheduleId);
      setShowScheduleDetailModal(true);
    }else if(location.pathname.includes('schedule/edit')){
      const scheduleId = location.pathname.split('/').pop();
      setCurrentlyEditingScheduleId(scheduleId);
      editEvent({ objectId: scheduleId });
      setShowEditSchedule(true);
    }
    getAllSchedule();
  }, [location.pathname, typeId, typeRef]);

  useEffect(() => {
    if (allEvents !== commentList && isfetched) {
      setCommentList(allEvents);
      setIsLoading(false);
    }
  }, [allEvents,isfetched ]);

  useEffect(() => {
    if(!showEditSchedule && currentlyEditingScheduleId){
      setCurrentlyEditingScheduleId(null);
    }
  }, [showEditSchedule]);

  const formatScheduleAttachments = (selectedSchedule) => {
    return selectedSchedule?.attachments?.map((attachment) => ({
      ...attachment,
      description:
        'Draft Schedule\n' +
        'to: ' +
        selectedSchedule.scheduleTo?.map((to) => to.value).join(', ') +
        '\n' +
        'sub: ' +
        selectedSchedule.scheduleSub +
        '\n' +
        selectedSchedule.scheduleBody?.replace(/(<([^>]+)>)/gi, ''),
    }));
  };

  const getAllSchedule = () => {
    const data = {
      accountId,
      typeId: typeId,
      typeRef: typeRef,
      userId: localStorage.getItem('userId'),
      page: 0,
      displayLimit: 99999999,
    };
    getAllScheduleAction(data);
    setIsLoading(true);
  };

  const cancelDelete = (scheduleData, confirm) => async () => {
    const confirmed =
      (await confirm?.({
        title: 'Delete draft',
        question: 'Are you sure you want to delete this draft?',
      })) ?? true;

    if (!confirmed) {
      return;
    }

    const formData = {
      objectId: scheduleData.objectId,
      typeRef: scheduleData.typeRef,
      typeId: scheduleData.typeId,
    };

    await cancelScheduleAction(formData);
    getAllSchedule();
  };

  const editEvent = (data) => {
    const objectId = data.objectId;
    setScheduleObjectId(objectId);

    setScheduleAction('EDIT');
    setShowEditSchedule(true);
    setCurrentlyEditingScheduleId(objectId);
  };

  const copyEvent = (scheduleData) => {
    const scheduleObjectId = scheduleData.objectId;
    setScheduleObjectId(scheduleObjectId);

    setScheduleAction('COPY');
    setShowEditSchedule(true);
  };

  const showEditScheduleCallback = () => {
    setShowEditSchedule(true);
    setScheduleObjectId('');
    setScheduleAction('new');
  };

  const closeWindowCallback = () => {
    setShowEditSchedule(false);
    setScheduleObjectId('');
    setScheduleAction('new');
  };

  const showHideLightBox = (schedule, attachmentIndex) => {
    setLightBoxOpen(!lightBoxOpen);
    setSelectedSchedule(schedule);
    setAttachmentIndex(attachmentIndex);
  };

  const closeDetailModal = () => {
    setShowScheduleDetailModal(false);
    setShowScheduleDetailId(null);
  };

  const sortEvents = (schedules, direction) => {
    let rootSchedules = schedules.filter((schedule) => !schedule.replyTo);

    let sortedSchedules = rootSchedules
      .map((schedule) => {
        let replies = schedules.filter((n) => n.parentScheduleId === schedule.objectId);
        replies.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        return [schedule, ...replies];
      })
      .flat();

    if(direction === 'asc'){
      sortedSchedules = sortedSchedules.revers();
    }
    return sortedSchedules;
  };

  const commentListData =
    commentList?.length > 0 ? (
      sortEvents(commentList).map((v) => (
        <ScheduleView
          scheduleData={v}
          editEvent={editEvent}
          copyEvent={copyEvent}
          cancelDelete={cancelDelete}
          showHideLightBox={showHideLightBox}
          filterText={filterText}
          isEditing={currentlyEditingScheduleId === v.objectId}
        ></ScheduleView>
      ))
    ) : (
      <div className='outputBox scheduleHtml'>No scheduled events</div>
    );

  const showEditScheduleComponent = showEditSchedule || scheduleObjectId;


  const handleSearch = (value) => {
    setFilterText(value);
  };

  const handleFilter = (filters) => {
    setFilterType(filters);
    // Implement filtering logic here
    const filteredEvents = allEvents;//filterNotes(allMessages, filters);
    setCommentList(filteredEvents);
  };

  const handleSort = (direction) => {
    setSortDirection(direction);
    // Implement sorting logic here
    const sortedEvents = sortEvents([...allEvents], direction);
    setCommentList(sortedEvents);
  };

  const handleAddNew = () => {
    showEditScheduleCallback();
  };


  const newButton =<button className='btn btn-primary d-flex p-1 pe-2 ps-2' onClick={showEditScheduleCallback}>
  New event
</button>

  return (
    <div>
      {!showEditScheduleComponent ? (
          <div className="container">
          <div className="row pt-3 pb-2">
            <div className="col ps-0 pe-2">
              <ActivityHeader
                onSearch={handleSearch}
                onSort={handleSort}
                onFilter={handleFilter}
                onAddNew={handleAddNew}
                canFilter={false}
                addNewLabel="Add event"
                filterOptions={filterOptions}
                sortDirection={sortDirection}
                additionalButtons={[newButton]}
              />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <br />
          <ScheduleEditor
            showEditSchedule={showEditScheduleComponent}
            typeId={typeId}
            typeRef={typeRef}
            objectId={scheduleObjectId}
            scheduleAction={scheduleAction}
            closeWindowCallback={closeWindowCallback}
            data={data}
          ></ScheduleEditor>
        </div>
      )}
      {isLoading ? (
        <ReactLoading
          className='table-loader'
          type={appConstants.LOADER_TYPE}
          color={appConstants.LOADER_COLOR}
          height={appConstants.LOADER_HEIGHT}
          width={appConstants.LOADER_WIDTH}
        />
      ) : (
      <Scrollbars style={{ height: 'calc(100vh)', minHeight:'500px' }}>{commentListData}</Scrollbars>
      )}
      <LightBoxComponent
        attachments={formatScheduleAttachments(selectedSchedule) ?? []}
        shouldOpen={lightBoxOpen}
        initialIndex={attachmentIndex}
        closeCallback={showHideLightBox}
      />
      {showScheduleDetailModal && showScheduleDetailId && (
        <ScheduleView
          scheduleId={showScheduleDetailId}
          typeId={typeId}
          typeRef={typeRef}
          asModal={true}
          editEvent={editEvent}
          copyEvent={copyEvent}
          cancelDelete={cancelDelete}
          showHideLightBox={showHideLightBox}
          toggle={closeDetailModal}
          showModal={true}
        ></ScheduleView>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { message } = state;
  const { allSchedule: allEvents, isScheduleFetched: isfetched } = message;
  return {
    allEvents: allEvents && allEvents.length ? allEvents : [],
    isfetched: isfetched,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getAllScheduleAction,
    cancelScheduleAction,
  })(ScheduleTab)
);
