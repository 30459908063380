import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import TitleComponent from "../Common/TitleComponent";

const LogInError = () => {
  const location = useLocation();
  const { error, from, userErrorInfo } = location.state || {};
  const errorMessage = error || "An unknown error occurred";
  const redirectPath = from || "/login";
  const { userEmail, companyName, companyLogo, adminEmail } = userErrorInfo || {};

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
      padding: '20px',
      textAlign: 'center'
    }}>
      <TitleComponent data={{title: 'Login Error'}} />
      
      {companyLogo && (
        <img src={companyLogo} alt={companyName} style={{ maxWidth: '300px', marginBottom: '10px' }} />
      )}
      
      {companyName && (
      <h1 style={{ fontSize: '24px', marginBottom: '20px' }}>{companyName}</h1>
      )}
      
      <h2 style={{ fontSize: '20px', marginBottom: '20px' }}>We encountered an error during login</h2>
      
      <p style={{ color: 'red', marginBottom: '20px' }} class="mb-4">{errorMessage}</p>
      
      {userEmail && (
        <p style={{ marginBottom: '20px' }}>User Email: {userEmail}</p>
      )}
      
      {adminEmail && (
        <p style={{ marginBottom: '20px' }}>
          If you continue to experience issues, please contact your account administrator at:{' '}
          <a href={`mailto:${adminEmail}`} style={{ color: 'blue' }}>{adminEmail}</a>
        </p>
      )}
      
      <Link
        to={redirectPath}
        class = "mt-4"
        style={{
          color: 'black',
          textDecoration: 'none',
          border: '1px solid black',
          padding: '10px 20px',
          borderRadius: '5px'
        }}
      >
        Back to Login
      </Link>
    </div>
  );
};

export default LogInError;