import { Tooltip } from 'antd';
import { ReactComponent as InfoIcon } from '../../../assets/svg/info.svg';
import { ReactComponent as CheckCircle } from '../../../assets/svg/check-circle.svg';
import { ReactComponent as CancelIcon } from '../../../assets/svg/cancel.svg';
import _ from 'lodash';

export const cellFormatter =
  (callback = (fieldValue, row) => fieldValue) =>
  ({ row, column }) => {
    const { id } = column;
    const duplicationError = !!row.original._rowErrors.__duplicationError;
    const fieldError = row.original._rowErrors[id];

    let fieldArrayErrors = '';
    if (column.arrayName) {
      const [arrayName, fieldName] = id.split('.');

      fieldArrayErrors = Object.entries(row.original._rowErrors)
        .filter(([key]) => key.startsWith(arrayName) && key.endsWith(fieldName))
        .map(([_, value]) => value)
        .reduce((acc, curr) => acc + curr?.message + '\n', '');
    }

    const fieldErrorMessage = fieldError?.message || fieldArrayErrors;

    const value = callback(row.values[id], row.original);

    return (
      <div className='d-flex align-items-center justify-content-between gap-3'>
        <div className='text-wrap' style={{ color: duplicationError ? '#808080' : '#000000' }}>
          {_.isString(value) ? _.truncate(value, { length: 40 }) : value}
        </div>

        {fieldErrorMessage ? (
          <Tooltip placement='right' title={<span>{fieldErrorMessage}</span>}>
            <InfoIcon style={{ width: 16 }} width={16} color='#ffcc00' />
          </Tooltip>
        ) : null}
      </div>
    );
  };

export const uploadDescriptor = {
  Header: '',
  accessor: '_loadResult',
  Cell: ({ row }) => {
    const loadResult = row.values._loadResult;

    if (!loadResult) return null;

    if (!loadResult.error) return <CheckCircle style={{ width: 16 }} width={16} color='green' />;

    if (loadResult.warning){
      return (
        <Tooltip
          placement='right'
          title={loadResult.error}
        >
          <InfoIcon style={{ width: 16 }} width={16} color='#ffcc00' />
        </Tooltip>
      );
    }

    return (
      <Tooltip
        placement='right'
        title={loadResult.error}
      >
      <CancelIcon style={{ width: 16 }} width={16} color='#dd0011'/>
      </Tooltip>
    );
  },
  width: 25,
};

export const errorDescriptor = {
  Header: '',
  accessor: '_rowErrors',
  Cell: ({ row }) => {
    const rowErrors = row.values._rowErrors;

    if (!rowErrors || _.isEmpty(rowErrors)) return null;

    const hasNoErrors = Object.values(rowErrors).filter((error) => !(error?.isWarning)).length === 0;

    if (hasNoErrors) return null;

    const errorText = Object.values(rowErrors).map((error) => (error?.message)).join(',\n ');

    if(!(errorText?.length>0))
      return null;

    return (
      <Tooltip
        placement='right'
        title={errorText}
      >
        <CancelIcon style={{ width: 16 }} width={16} color='#dd0011' />
      </Tooltip>
    );
  },
  width: 25,
};
