import * as actionTypes from '../actions/actionTypes';

const initState = {
  // message: {},
  allMessage: [],
  message: '',
  isfetched: false,
  isfetchedError: false,
  isSave: false,
  isSaveE: false,
  isSaveM: false,
  isSaveS: false,
  allEmail: [],
};

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.CREATE_NOTE_REQUEST:
      return {
        ...state,
        message: '',
        allMessage: [],
        isSaveM: false,
        buttonActive: true,
      };
    case actionTypes.CREATE_NOTE_SUCCESS:
      return {
        allMessage: state.allMessage.concat(action.payload.response.result.data),
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'Note Successfully Added!'
          : action.payload.response.result.msg,
        isSaveM: true,
        buttonActive: false,
      };

    case actionTypes.CREATE_NOTE_ERROR:
      return {
        ...state,
        message: action.payload.error.result.msg,
        success: action.payload.error.result.success,
        isSaveM: false,
        buttonActive: false,
      };

      case actionTypes.PIN_NOTE_REQUEST:
      return {
        ...state,
        message: '',
        //allMessage: [],
        //isSaveM: false,
        //buttonActive: true,
      };
    case actionTypes.PIN_NOTE_SUCCESS:
      return {
        //allMessage: state.allMessage.concat(action.payload.response.result.data),
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'Note successfully pinned!'
          : action.payload.response.result.msg,
        isSaveM: true,
        buttonActive: false,
      };

    case actionTypes.PIN_NOTE_ERROR:
      return {
        ...state,
        message: action.payload.error.result.msg,
        success: action.payload.error.result.success,
        isSaveM: false,
        buttonActive: false,
      };

    case actionTypes.GET_ALL_NOTES_REQUEST:
      return {
        ...state,
        allMessage: [],
        isfetched: false,
        message: '',
        isSaveM: false,
        buttonActive: true,
      };

    case actionTypes.GET_ALL_NOTES_SUCCESS:
      return {
        ...state,
        allMessage: action.payload.response.result.data,
        message: '',
        isSaveM: false,
        buttonActive: false,
        isfetched: true,
      };

    case actionTypes.GET_ALL_NOTES_ERROR:
      return {
        ...state,
        message: '',
        isSaveM: false,
        buttonActive: false,
        isfetched: false,
      };

    case actionTypes.DELETE_NOTES_REQUEST:
      return {
        ...state,
        message: '',
        buttonActive: true,
      };
    case actionTypes.DELETE_NOTES_SUCCESS:
      return {
        ...state,
        allMessage: state.allMessage,
        message: '',
        buttonActive: false,
      };

    case actionTypes.DELETE_NOTES_ERROR:
      return {
        ...state,
        message: '',
        buttonActive: false,
      };

    case actionTypes.CREATE_EMAIL_REQUEST:
      return {
        ...state,
        message: '',
        isSaveE: false,
        buttonActive: true,
      };
    case actionTypes.CREATE_EMAIL_SUCCESS:
      return {
        allEmail:
          action.payload.response.result && action.payload.response.result.data
            ? action.payload.response.result.data
            : [],
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? action.payload.response.result.data.isDraft
            ? 'Email Successfully Saved!'
            : 'Email Successfully Sent!'
          : action.payload.response.result.msg,
        isSaveE: true,
        buttonActive: false,
      };

    case actionTypes.CREATE_EMAIL_ERROR:
      return {
        ...state,
        message: action.payload.error,
        success: 0,
        isSaveE: false,
        buttonActive: false,
      };

    case actionTypes.GET_ALL_EMAIL_REQUEST:
      return {
        ...state,
        allEmail: [],
        message: '',
        isSaveE: false,
        success: 0,
        isEmailFetched: false,
      };

    case actionTypes.GET_ALL_EMAIL_SUCCESS:
      return {
        ...state,
        allEmail: action.payload.response.result.data,
        message: '',
        isSaveE: false,
        success: 1,
        isEmailFetched: true,
      };

    case actionTypes.GET_ALL_EMAIL_ERROR:
      return {
        ...state,
        message: '',
        isSaveE: false,
        isEmailFetched: false,
      };

    case actionTypes.DELETE_EMAIL_REQUEST:
      return {
        ...state,
        allEmail: [],
        message: '',
        isSave: false,
        success: 0,
        buttonActive: true,
      };

    case actionTypes.DELETE_EMAIL_SUCCESS:
      return {
        ...state,
        allEmail: action.payload.response.result.data,
        message: 'Email Successfully Deleted!',
        isSave: true,
        buttonActive: false,
      };

    case actionTypes.DELETE_EMAIL_ERROR:
      return {
        ...state,
        message: '',
        isSave: false,
        buttonActive: false,
      };

    case actionTypes.CREATE_SCHEDULE_REQUEST:
      return {
        ...state,
        message: '',
        success: '',
        isSaveS: false,
        buttonActive: true,
        schedule: { status: false, data: [] },
      };
    case actionTypes.CREATE_SCHEDULE_SUCCESS:
      return {
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'Schedule Successfully Added!'
          : action.payload.response.result.msg,
        isSaveS: true,
        buttonActive: false,
        
        schedule: { status: false, data: [] },
      };

    case actionTypes.CREATE_SCHEDULE_ERROR:
      return {
        ...state,
        message: action.payload.response.result.msg,
        success: action.payload.response.result.success,
        isSaveS: false,
        buttonActive: false,
        schedule: { status: false, data: [] },
      };

    case actionTypes.GET_ALL_SCHEDULE_REQUEST:
      return {
        ...state,
        allSchedule: [],
        message: '',
        isSaveS: false,
        isScheduleFetched: false,
        schedule: { status: false, data: [] },
      };

    case actionTypes.GET_ALL_SCHEDULE_SUCCESS:
      return {
        ...state,
        allSchedule: action.payload.response.result.data,
        message: '',
        isSaveS: false,
        isScheduleFetched: true,
        schedule: { status: false, data: [] },
      };

    case actionTypes.GET_ALL_SCHEDULE_ERROR:
      return {
        ...state,
        message: '',
        isSaveS: false,
        schedule: { status: false, data: [] },
      };

    case actionTypes.GET_TIME_LINE_REQUEST:
      return {
        ...state,
        timelineData: [],
        timelineDataFetched: false,
        message: '',
        isSave: false,
      };
    case actionTypes.GET_TIME_LINE_SUCCESS:
      return {
        ...state,
        timelineData: action.payload.response.result.data,
        timelineDataFetched: true,
        message: '',
        isSave: false,
      };
    case actionTypes.GET_TIME_LINE_ERROR:
      return {
        ...state,
        message: '',
        timelineDataFetched: false,
        isSave: false,
      };

    case actionTypes.CANCEL_SCHEDULE_REQUEST:
      return {
        ...state,
        schedule: { status: false, data: [] },
      };
    case actionTypes.CANCEL_SCHEDULE_SUCCESS:
      return {
        ...state,
        schedule: { status: true, data: action.payload.response },
      };
    case actionTypes.CANCEL_SCHEDULE_ERROR:
      return {
        ...state,
        schedule: { status: false, data: [] },
      };
    default:
      return state;
  }
};
