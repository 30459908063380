import React, { useCallback, useEffect, useState  } from 'react';
import { useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { updateCompanyAction } from '../../actions/company';
import { getContactDetailAction } from '../../actions/contact';
import AddCompanyModal from '../../modals/add-company-modal/add-company-modal';
import TitleComponent from '../Common/TitleComponent';
import DetailsRightSection from '../Layout/DetailsRightSection';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import { DeleteCompanyModal } from './delete-company-modal';
import { useDispatch } from 'react-redux';
import { getCompanyAffiliations } from '../../modules/companies';
import { isAllowed, reportAccess } from '../../_constants';
import loaderImage from '../static/images/loading_i.gif';
import CompanyNotFound from './company-not-found';
import { Tooltip } from 'react-tooltip';
import { ViewCompanyHeader } from './view-company-header';
import { ViewCompanyDetailInfo } from './view-company-detail-info';
import { ViewCompanyContacts } from './view-company-contacts';
import styles from './view-company-contacts/style.module.css';
import { ViewCompanyProjects } from './view-company-projects';
import { CompanyCustomFields } from '../../pages/company-detail-page/custom-fields/company-custom-fields';
import {  selectGetCompanyAffiliations, selectUpdateCompany } from '../../modules/companies/selectors';
import { useParams } from 'react-router-dom';

const ViewCompanyDetails = () => {
  const { companyId } = useParams();

  const [accountId] = useState(localStorage.getItem('accountId'));
  const [userId] = useState(localStorage.getItem('userId'));
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [fetchRequest, setFetchRequest] = useState(true);
  const dispatch = useDispatch();

  const companyDataRequest = useSelector(state => state.contact.response);
  const companySubmitted = useSelector(selectUpdateCompany);
  const companyAffiliations = useSelector(selectGetCompanyAffiliations);

  const companyData = companyDataRequest?.companiesData || {};

  const fetchCompanyData = useCallback(() => {
    if (companyId) {
      dispatch(getContactDetailAction({
        type: 'companies',
        id: companyId,
        accountId,
        userId
      }));
      dispatch(getCompanyAffiliations({ companyId }));
      setFetchRequest(true);
    }
  }, [companyId, accountId, userId, dispatch]);

  useEffect(() => {
    if (companySubmitted?.data?._id === companyId) {
      fetchCompanyData();
    }else if(companyData?.objectId !== companyId){
      fetchCompanyData();
    }
  }, [companySubmitted, companyId, companyData?.objectId,fetchCompanyData]);

  useEffect(() => {
    if (companyDataRequest) {
      setFetchRequest(false);
    }
  }, [companyDataRequest]);



  const isCompanyFound = companyDataRequest?.isCompanyFound;
  const isLoading = !companyDataRequest || companyDataRequest.objectId !== companyId;
  const isDeactivated = companyData?.trash;



  const toggle = () => setEditModalOpen(!editModalOpen);
  const toggleDeleteModal = () => setDeleteModal(!deleteModal);

  if (!isCompanyFound && !isLoading) {
    return <CompanyNotFound />;
  }

  const onClose = () => {
    setEditModalOpen(false);
  };


  if (!isCompanyFound && !fetchRequest) {
    return <CompanyNotFound />;
  }

  return (
    <div className='ContactListReport'>
      <TitleComponent data={{ title: `Company Details - ${companyData.companyName}` }} />

      <Header props={{}} />

      <div className='detailsPage'>
        <div className='container-xxl'>
          {companyId?.length ? (
            <div className='row mb-0 mt-2 mb-md-0'>
              <div className='col-md-6 mb-0 mb-md-0'>
                <ViewCompanyHeader company={companyData} />
              </div>
              <div className='col-md-6'>
                <div className='d-flex flex-wrap mt-md-2 mt-sm-0 justify-content-end gap-2 detailMenu'>
                  {!isDeactivated && (
                    <>
                      {!companyData.isAccountCompany && (
                        <button className='btn btn-primary' onClick={() => toggle('POST')}>
                          Edit company details
                        </button>
                      )}
                      <li className='dropdown px-2'>
                        <a
                          className='dropdown-toggle btn'
                          data-bs-toggle='dropdown'
                          href='#'
                          role='button'
                          aria-expanded='false'
                        >
                          <i className='fa fa-ellipsis-v' aria-hidden='true'></i>
                        </a>
                        <ul className='dropdown-menu  ml-auto'>
                          {isAllowed(reportAccess.CJL, 'anyAssigned') && (
                            <li className='dropdown-item'>
                              <Link
                                className=''
                                to={{
                                  pathname: `/company-job-list-report`,
                                  search: `?company=${companyId}`,
                                }}
                                data-tooltip-content={
                                  'List all jobs associated with current company'
                                }
                                data-tooltip-id='MENU4.SUBMENUS.SUBMENU4.Tooltip'
                              >
                                Company Job List
                              </Link>
                              <Tooltip
                                id='MENU4.SUBMENUS.SUBMENU4.Tooltip'
                                place='right'
                                delayShow={1000}
                              />
                            </li>
                          )}

                          {!companyData.isAccountCompany && (
                            <>
                              <div className='dropdown-divider'></div>
                              <li className='dropdown-item'>
                                <a className='link link-danger' onClick={toggleDeleteModal}>
                                  Delete Company
                                </a>
                                <DeleteCompanyModal
                                  companyId={companyId}
                                  open={deleteModal}
                                  onSubmit={fetchCompanyData}
                                  onClose={toggleDeleteModal}
                                />
                              </li>
                            </>
                          )}
                        </ul>
                      </li>
                    </>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className='row'>
            {fetchRequest || !companyId.length ? (
              <div className='loading_bg'>
                <img className='ajax-loader' src={loaderImage} width='100' height='100' />
              </div>
            ) : (
              <>
                <div className='col-md-6 col-lg-7 col-xl-7 mt-2'>
                  <div className='contactDetailSidebar d-flex flex-column mt-2'>
                    <ViewCompanyDetailInfo company={companyData} />
                    <CompanyCustomFields data={companyData} />

                    <ViewCompanyContacts
                      companyId={companyId}
                      companyAffiliations={companyAffiliations}
                      onAfterAddContact={fetchCompanyData}
                    />
                    <ViewCompanyProjects companyId={companyId} />
                  </div>
                </div>
                <div className='col-md-6 col-lg-5 col-xl-5 mt-2'>
                  {companyId?.length > 0 && (
                    <DetailsRightSection
                      typeRef={'COMPANY'}
                      typeId={companyId}
                      data={companyData}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {companyId?.length > 0 && (
        <AddCompanyModal
          onClose={onClose}
          open={editModalOpen}
          onCompanyUpdate={fetchCompanyData}
          companyId={companyId}
        />
      )}

      <Footer />
    </div>
  );
};

export default ViewCompanyDetails;