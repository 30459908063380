import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useMatchingContactsContext } from '../../forms/affiliation-form/matching-contacts-context';
import React, { useMemo } from 'react';
import FormattedPhone from '../../shared/formatted-phone';
import _ from 'lodash';

type ConfirmMatchingContactsModalProps = {
  open: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
};

export const ConfirmMatchingContactsModal = ({
  open,
  onClose = () => void 0,
  onConfirm = () => void 0,
}: ConfirmMatchingContactsModalProps) => {
  const matchingContactsContext = useMatchingContactsContext();
  const { groupedValidationData } = matchingContactsContext;

  const list = useMemo(
    () =>
      // name first, then email, then phone
      groupedValidationData.sort((a, b) => {
        if (a.type === 'name') return -1;
        if (b.type === 'name') return 1;
        if (a.type === 'email') return -1;
        if (b.type === 'email') return 1;
        return 0;
      })
    .map((element) => {
        let header: any = null;
        let key: any = null;

        if (element.type === 'phone') {
          header = <>Contacts matching phone #: <FormattedPhone phoneObject={element.value} /></>;
          key = element.value.phoneClean;
        }

        if (element.type === 'email') {
          header = 'Contacts matching email: ' + element.value.email;
          key = element.value.email;
        }

        if (element.type === 'name') {
          header = 'Contacts matching name: ' + element.value.firstName + ' ' + element.value.lastName;
          key = 'name';
        }

        const isName = element.type === 'name';
        const isEmail = element.type === 'email';
        //const isPhone = element.type === 'phone';

        const elementData = _.cloneDeep(element.data);

        const dataSorted = elementData.sort((a, b) => {
          // sort by full name, then by company name
          if (isName) {
            return (a.contactFullName < b.contactFullName) ? -1 : 1;
          }

          if (a.contactFullName !== b.contactFullName)
            return (a.contactFullName < b.contactFullName) ? -1 : 1;
          else if (a.selfAffiliated || b.selfAffiliated)
            return a.selfAffiliated ? -1 : 1;
          else
            return (a.companyName < b.companyName) ? -1 : 1;
        });


        const subList = dataSorted.map((contact, index) => {
          const isSameContactAsPrevious = index > 0 && contact.contactId === dataSorted[index - 1].contactId;
          return (
            <div className={'row '+(isSameContactAsPrevious?' mb-2':'')} key={contact.contactId}>
              <div className='col-4'>
                {isSameContactAsPrevious ? '""' : <a target='_blank' rel='noreferrer' href={`/contact-details/${contact.contactId}`}>{contact.contactFullName}</a>}
                
              </div>
              <div className='col-4'>
                {!isName && (!contact.selfAffiliated ? (
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href={`/company-details/${contact.companyId}`}
                  >
                    {contact.companyName}
                  </a>
                ) : (
                  '(Personal)'
                ))}
                {isName && contact.affiliations.map((affiliation) => {return <React.Fragment key={affiliation.companyId}>
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href={`/company-details/${affiliation.companyId}`}
                  >
                    {affiliation.companyName}
                  </a></React.Fragment>
                })}
              </div>
              <div className='col-4'>{isName ? contact.affiliations.map((affiliation) => {return <React.Fragment key={affiliation.companyId+'_title'}>
                  <div
                  >
                    {affiliation.title}
                  </div></React.Fragment>
                }) : contact.label}</div>
            </div>
          );
        });

        return (
          <div className='mb-4 d-flex flex-column gap-2' key={key}>
            <div className='row'>
              <div className='col-12 h6 fw-bold'>{header}</div>
            </div>

            <div className='row'>
              <div className='col-4 fw-bold'>Name</div>
              <div className='col-4 fw-bold'>Company</div>
              <div className='col-4 fw-bold'>{isName ? 'Title' : isEmail ? 'Email type' : 'Phone type'}</div>
            </div>

            {subList}
          </div>
        );
      }),
    [groupedValidationData]
  );

  const matchesSameContact = (data) => {
    if(data.length < 2) return false;
    const matchingNameContact = (data.find((element) => element.type === 'name')??{data:[]}).data.map((element) => element.contactId);
    const matchingEmailContact = (data.find((element) => element.type === 'email')??{data:[]}).data.map((element) => element.contactId);
    const matchingPhoneContact = (data.find((element) => element.type === 'phone')??{data:[]}).data.map((element) => element.contactId);

    return matchingNameContact.some((id) => matchingEmailContact.includes(id) || matchingPhoneContact.includes(id));
  }

  const veryLikelyMatch = groupedValidationData.length > 1 && matchesSameContact(groupedValidationData);

  return (
    <Modal isOpen={open} toggle={onClose} size={'lg'}>
      <ModalHeader toggle={onClose}>Duplicate contact warning</ModalHeader>
      <ModalBody>
        <div>We've found existing contacts in our system that share some information with the contact you're trying to add or edit. This could indicate a duplicate entry. </div>
        <div className='mt-2'>Please review the details below to ensure you're not creating a duplicate contact. If this is indeed a new, unique contact, you can proceed with saving.</div>
        <hr />
        <div className='container'>{list}</div>

        {veryLikelyMatch && (
          <div className='alert alert-warning mt-4'>
            <strong>Warning:</strong> The contacts listed above are very likely the same person. Please review the details carefully to avoid creating a duplicate contact.
          </div>
        )}

      </ModalBody>
      <ModalFooter>
        <button className='btn btn-secondary' onClick={onClose}>
          Cancel
        </button>
        <button className='btn btn-primary' disabled={veryLikelyMatch} onClick={onConfirm}>
          Save Anyway
        </button>
      </ModalFooter>
    </Modal>
  );
};
