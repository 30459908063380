import Joi from 'joi';
import { Moment } from 'moment';
import moment from 'moment';
import { joiMoment, joiProjectTeam } from '../../utils/validation';


// Helper function to convert string to number
const stringToNumber = (value: string | number): number => {
  if (typeof value === 'number') return value;
  return Number(value.replace(/,/g, ''));
};

// Custom Joi extension
const joiStringOrNumber = Joi.alternatives().try(
  Joi.string().custom((value, helpers) => {
    const number = stringToNumber(value);
    if (isNaN(number)) {
      return helpers.error('any.invalid');
    }
    return number;
  }),
  Joi.number()
);


export type BidTypeSchema = {
  bidId: string;
  bidInternalId: string | null;
  comment: string;
  exclusions: string;
  estTotalCost: number;
  estTotalProfit: number;
  estTotalContract: number;
  bidStart: Moment | null;
  bidDue: Moment | null;
  bidSubmittedDate: Moment | null;
  startDate: Moment | null;
  endDate: Moment | null;
  bidCount: number;
  bidRev: number;
  bidURL: string;
  pendingComment: string | null;
  resolvedComment: string | null;
  pendingReasonCode: string | null;
  pendingReasonCodeId: string | null;
  resolvedReasonCode: string | null;
  resolvedReasonCodeId: string | null;
  estData: Array<any>;
  bidDuration: string | null;
  projectDuration: string | null;
  projectTeam: { [projectRole: string]: string };
  createEstimate: boolean;
  acceptedBy?: string | null;
  acceptedDate?: Moment | null;
  qbEstimateId?: string | null;
  wasAccepted?: boolean;
};

const validationSchemaBase = {
  bidId: Joi.string().allow('').label('Bid Label'),
  bidInternalId: Joi.string().allow(null).label('Bid Id'),
  comment: Joi.string().allow('').label('Description'),
  exclusions: Joi.string().allow('').label('Exclusions'),
  estTotalCost: Joi.number().label('Total Cost'),
  estTotalProfit: Joi.number().label('Total Profit'),
  estTotalContract: Joi.number().label('Total Contract'),
  bidStart: joiMoment()
    .allow(null)
    .label('Estimating Start Date')
    .custom((value, helper) => {
      const bidDue = helper.state.ancestors[0].bidDue;
      if (bidDue && value && moment(value).isAfter(bidDue)) {
        return helper.message({
          custom: '{#label} should be less than "Bid Due Date"',
        });
      }

      return value;
    }),
  bidDue: joiMoment({ required: true }).label('Bid Due'),
  startDate: joiMoment()
    .label('Start Date')
    .custom((value, helper) => {
      const endDate = helper.state.ancestors[0].endDate;
      if (endDate && value && moment(value).isAfter(endDate)) {
        return helper.message({
          custom: '{#label} should be less than "End Date"',
        });
      }

      return value;
    }),
  endDate: joiMoment().label('End Date'),
  bidCount: Joi.number().failover(0).label('Bid Count'),
  bidRev: Joi.number().failover(0).label('Bid Revision'),
  bidURL: Joi.string().allow('',null).label('Bid URL').optional(),
  pendingComment: Joi.string().allow('', null).label('Pending Comment'),
  resolvedComment: Joi.string().allow('', null).label('Resolved Comment'),
  pendingReasonCode: Joi.string().allow('', null).label('Pending Reason Code'),
  pendingReasonCodeId: Joi.string().allow('', null).label('Pending Reason Code Id'),
  resolvedReasonCode: Joi.string().allow('', null).label('Resolved Reason Code'),
  resolvedReasonCodeId: Joi.string().allow('', null).label('Resolved Reason Code Id'),
  estData: Joi.array()
    .items(
      Joi.object({
        scopeId: Joi.string().optional().allow('', null),
        subTotalCost: joiStringOrNumber.optional().allow('', null),
        subTotalCtr: joiStringOrNumber.optional().allow('', null),
        subTotalProfit: joiStringOrNumber.optional().allow('', null),
        subTotalProfitPers: joiStringOrNumber.optional().allow('', null),
        qty: joiStringOrNumber.optional().allow('', null),
        unitPrice: joiStringOrNumber.optional().allow('', null),
        unitCost: joiStringOrNumber.optional().allow('', null),
        unitProfit: joiStringOrNumber.optional().allow('', null),
        allowChangeScope: Joi.boolean().optional().allow(null),
        description: Joi.string().optional().allow('', null),
        serviceDate: joiMoment({ required: false }).allow(null).empty(null),
        items: Joi.array().items(Joi.object().unknown(true)).optional().allow(null),
        billable: Joi.boolean().optional().allow(null),
        taxable: Joi.boolean().optional().allow(null),
        qbItemId: Joi.string().optional().allow('', null),
        lineItemId: Joi.string().optional().allow('', null),
      })
    )
    .optional(),
  bidDuration: Joi.string().trim().empty('').label('Bid Duration'),
  projectDuration: Joi.string().trim().empty('').label('Project Duration'),
  projectTeam: joiProjectTeam().label('Team'),
  createEstimate: Joi.boolean().optional().failover(false).label('Create Estimate'),
  acceptedBy: Joi.string().optional().allow('', null).label('Accepted By'),
  acceptedDate: joiMoment().optional().allow(null).label('Accepted Date'),
  qbEstimateId: Joi.string().optional().allow('', null).label('QuickBooks Estimate Id'),
  wasAccepted: Joi.boolean().optional().allow(null).label('Was Accepted'),
};

export const bidBiddingValidationSchema = Joi.object({ ...validationSchemaBase });

export const bidPendingValidationSchema = Joi.object({
  ...validationSchemaBase,
  bidSubmittedDate: joiMoment({ required: true }).label('Bid Submitted Date'),
});
