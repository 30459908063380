import { end } from '@popperjs/core';
import _ from 'lodash';

export const formatMoney = (
  amount,
  decimalCount = 0,
  prefix = '$',
  decimal = '.',
  thousands = ','
) => {
  if (
    amount === null ||
    amount === undefined ||
    amount === '' ||
    (typeof amount === 'string' && amount.trim() === '')
  ) {
    return '';
  }

  let amountInt, amountFloat;

  if (typeof amount === 'string') {
    amountFloat = parseFloat(amount);
    amountInt = parseInt(amount);
  } else if (typeof amount === 'number') {
    amountFloat = amount;
    amountInt = Math.floor(amount);
  }

  if (isNaN(amountInt) || isNaN(amountFloat)) {
    return '';
  }

  decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

  const negativeSign = amountInt < 0 ? '-' : '';

  amountInt = Math.abs(amountInt);
  amountFloat = Math.abs(amountFloat);

  const commaCount = Math.floor(Math.log10(Math.abs(amountInt)) / 3);

  let output = '';
  for (let i = 0; i < commaCount; i++) {
    const divisor = Math.pow(1000, i);
    const nextDivisor = Math.pow(1000, i + 1);
    const part = Math.floor((amountInt % nextDivisor) / divisor);
    output = `${thousands}${_.padStart(part, 3, '0')}${output}`;
  }

  const lastPart = Math.floor(amountInt / Math.pow(1000, commaCount));
  output = `${lastPart}${output}`;

  if (amountInt == 0) {
    output = '0';
  }

  if (decimalCount > 0) {
    const floatPart = Math.abs(amountFloat - amountInt)
      .toFixed(decimalCount)
      .slice(2);
    output += `${decimal}${floatPart}`;
  } else if (decimalCount < 0) {
    const autoFloatPart = Math.abs(amountFloat - amountInt)
      .toFixed(2)
      .slice(2);

    if (autoFloatPart !== '00') {
      output += `${decimal}${autoFloatPart}`;
    }
  }

  return `${negativeSign}${prefix}${output}`;
};

export const formatMoneyParens = (amount, decimalCount = 0, decimal = '.', thousands = ',') => {
  return (
    formatMoney(amount, decimalCount, decimal, thousands).replace('-', '(') +
    (amount < 0 ? ')' : '')
  );
};

export const roundFormatMoney = (n, c, d, t) => {
  return formatMoney(Math.round(n), c, d, t);
};

export const numberFormat = (amount, decimalCount = 0, decimal = '.', thousands = ',') => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';
    if (typeof amount === 'staring') {
      amount = parseInt(amount);
    }
    amount = Math.round(amount);
    let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    console.log(e);
  }
};

export const currencyToNumber = (value) => {
  if (value == null) {
    return 0;
  }

  const cleanValue = String(value)
    .replace(/[^\d.-]/g, '') // Remove non-digit characters except for dots and dashes
    .replace(/(?!^)-/g, '') // Remove any dash that's not at the beginning
    .replace(/^\./, '0.') // Add leading zero if the string starts with a decimal point
    .replace(/^(-?)0+(?=\d)/, '$1'); // Remove leading zeros, preserving the negative sign if present

  const parsed = parseFloat(cleanValue);

  if (isNaN(parsed)) {
    return 0;
  }

  return Math.round(parsed * 100) / 100;
};

export const numberToCurrency = (value, options = {}) => {
  const {
    prefix = '',
    locale = 'en-US',
    //currency = 'USD',
    minimumFractionDigits = 2,
    maximumFractionDigits = 2,
  } = options;

  // If value is null, undefined, or NaN, return an empty string
  if (value == null || isNaN(value)) {
    return '';
  }

  const parseInput = (input) => {
    if (typeof input === 'number') return input;
    if (typeof input !== 'string') return NaN;

    // Remove the prefix if it exists at the start of the string
    const cleanInput = input.startsWith(prefix) ? input.slice(prefix.length) : input;

    // Replace thousands separators and convert decimal separator to dot
    const normalized = cleanInput
      .replace(/[,\s]/g, '')
      .replace(/\.(?=.*\.)/g, '')
      .replace(/,/g, '.');

    return parseFloat(normalized);
  };

  const numValue = parseInput(value);

  const formatter = new Intl.NumberFormat(locale, {
    style: 'decimal',
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits,
    useGrouping: true, // Ensures thousand separators are used
  });

  // Format the number and replace the currency symbol with the prefix
  const formatted = formatter.format(numValue);

  if (formatted.endsWith('.00')) {
    return formatted.replace(/^[\p{Sc}\s]+/u, prefix).replace('.00', '');
  }

  return formatted.replace(/^[\p{Sc}\s]+/u, prefix);
};

const formatMoneyAuto = (amount) => {
  return formatMoney(amount, -1, '');
};

export const formatEstData = (estData = [], asCurrency = true) => {
  const estDataClone = _.cloneDeep(estData);

  estDataClone.forEach((scope) => {
    const formatter = asCurrency ? formatMoneyAuto : currencyToNumber;
    const {
      subTotalCost,
      subTotalCtr,
      subTotalProfit,
      subTotalProfitPers,
      qty,
      unitPrice,
      unitProfit,
      unitCost,
      items = [],
    } = scope;

    if (typeof qty === undefined || !qty) {
      scope.qty = formatter(asCurrency ? 1 : '1');
      scope.unitPrice = formatter(subTotalCtr);
      scope.unitCost = formatter(subTotalCost);
      scope.unitProfit = formatter(subTotalProfit);
    } else {
      scope.qty = formatter(qty);
      scope.unitPrice = formatter(unitPrice);
      scope.unitCost = formatter(unitCost);
      scope.unitProfit = formatter(unitProfit);
    }

    scope.subTotalCost = formatter(subTotalCost);
    scope.subTotalCtr = formatter(subTotalCtr);
    scope.subTotalProfit = formatter(subTotalProfit);

    if (asCurrency && typeof subTotalProfitPers === 'string') {
      scope.subTotalProfitPers = subTotalProfitPers;
    } else if (asCurrency && typeof subTotalProfitPers === 'number') {
      scope.subTotalProfitPers = subTotalProfitPers.toFixed(1);
    } else if (!asCurrency && typeof subTotalProfitPers === 'string') {
      scope.subTotalProfitPers = currencyToNumber(subTotalProfitPers);
    } else if (!asCurrency && typeof subTotalProfitPers === 'number') {
      scope.subTotalProfitPers = subTotalProfitPers;
    }

    if (items) {
      items.forEach((item) => {
        const { cost, total, profit, qty, unitPrice, unitCost, profitPers } = item;

        item.cost = formatter(cost);
        item.total = formatter(total);
        item.profit = formatter(profit);
        item.qty = formatter(qty);
        item.unitPrice = formatter(unitPrice);
        item.unitCost = formatter(unitCost);
        if (asCurrency && typeof profitPers === 'string') {
          item.profitPers = profitPers;
        } else if (asCurrency && typeof profitPers === 'number') {
          item.profitPers = profitPers.toFixed(1);
        } else if (!asCurrency && typeof profitPers === 'string') {
          item.profitPers = currencyToNumber(profitPers);
        } else if (!asCurrency && typeof profitPers === 'number') {
          item.profitPers = profitPers;
        }
      });
    }
  });

  return estDataClone;
};

export const calculateEstTotals = (estData) => {
  const cost = estData.reduce((acc, curr) => acc + currencyToNumber(curr.subTotalCost), 0);
  const profit = estData.reduce((acc, curr) => acc + currencyToNumber(curr.subTotalProfit), 0);
  const amount = estData.reduce((acc, curr) => acc + currencyToNumber(curr.subTotalCtr), 0);

  return { cost, profit, amount };
};

export const formatPercent = (value, decimalCount = 2) => {
  return `${value.toFixed(decimalCount)}%`;
};
