import { useState, useEffect } from 'react';
import { FileDropZone } from '../../../shared/file-upload';
import { Dropdown, notification } from 'antd';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, DropdownItem, DropdownToggle, DropdownMenu, ButtonGroup } from 'reactstrap';
import { useAppDispatch } from '../../../shared/hooks/use-app-dispatch';
import { useAppSelector } from '../../../shared/hooks/use-app-selector';
import { processTemplateFile, fetchProjectTemplateTags } from '../../../modules/reports/index';
import { getFormTemplates } from '../../../modules/settings';
import PizZip from 'pizzip';
import Select from 'react-select';
import Docxtemplater from 'docxtemplater';
import InspectModule from 'docxtemplater/js/inspect-module';
import { selectFormTemplates } from '../../../modules/settings/selectors';

const projectVariables = [
  { name: 'projectName', description: 'The name of the project' },
  { name: 'jobNumber', description: 'The formatted project/job number' },
  { name: 'altJobNumber', description: 'The alternate job number' },
  { name: 'alternateJobNumber', description: 'The alternate job number' },
  { name: 'jobNumberOnly', description: 'The project/job number without formatting' },
  { name: 'address', description: 'The full formatted job address' },
  { name: 'address1', description: 'The first line of the job address' },
  { name: 'address2', description: 'The second line of the job address' },
  { name: 'address12', description: 'The combined first and second lines of the job address' },
  {
    name: 'cityStateZip',
    description: 'The city, state, and zip code of the job location, separated by commas',
  },
  { name: 'city', description: 'The city of the job location' },
  { name: 'state', description: 'The state of the job location' },
  { name: 'zip', description: 'The zip code of the job location' },
  { name: 'zipCode', description: 'The zip code of the job location' },
  { name: 'country', description: 'The country of the job location' },
  { name: 'description', description: 'The marketing description of the project' },
  { name: 'originalContractAmount', description: 'The original contract amount' },
  { name: 'originalGrossProfit', description: 'The original gross profit' },
  { name: 'currentGrossProfit', description: 'The current gross profit' },
  { name: 'currentContractAmount', description: 'The current contract amount' },
  { name: 'currentContractProfit', description: 'The current contract profit' },
  { name: 'contractAmount', description: 'The current contract amount' },
  { name: 'contractTotal', description: 'The current contract amount' },
  { name: 'profit', description: 'The current contract profit' },
  { name: 'cost', description: 'The current contract cost' },
  { name: 'costTotal', description: 'The current contract cost' },
  { name: 'grossProfit', description: 'The current gross profit' },
  { name: 'grossProfitTotal', description: 'The current gross profit' },
  { name: 'contractProfit', description: 'The current contract profit' },
  { name: 'originalContractCost', description: 'The original contract cost' },
  { name: 'originalContractProfit', description: 'The original gross profit' },
  { name: 'leadCohort', description: 'The lead cohort for the project' },
  { name: 'leadSource', description: 'The source of the lead for the project' },

  // Project Date Fields
  { name: 'startDate', description: 'The actual/projected start date of the project' },
  { name: 'endDate', description: 'The actual/projected end date of the project' },
  { name: 'baselineStartDate', description: 'The baseline start date of the project' },
  {
    name: 'contractStartDate',
    description: 'The contract start date (same as baseline start date)',
  },
  { name: 'baselineEndDate', description: 'The baseline end date of the project' },
  {
    name: 'originalContractEndDate',
    description: 'The original contract end date (same as baseline end date)',
  },
  { name: 'currentContractEndDate', description: 'The current contract end date' },
  { name: 'currentBaselineEndDate', description: 'The current baseline end date' },
  { name: 'deliveredDate', description: 'The date the project was delivered' },
  { name: 'lienExpirationDate', description: 'The expiration date of the lien' },
  { name: 'warrantyExpirationDate', description: 'The expiration date of the warranty' },
  { name: 'bidDate', description: 'The due date for the bid' },
  { name: 'bidSubmitted', description: 'The date the bid was submitted' },
  { name: 'today', description: 'The current date' },
  { name: 'leadDate', description: 'The date the lead was created' },
  { name: 'followUpDone', description: 'The date the follow-up was completed' },
];

const contactVariables = [
  { name: 'name', description: 'The assigned contact or company name' },
  { name: 'contactNameCompany', description: "The contact's name together with company name" },
  { name: 'lastName', description: "The contact's last name" },
  { name: 'firstName', description: "The contact's first name" },
  { name: 'middleName', description: "The contact's middle name" },
  { name: 'fullName', description: "The contact's full name" },
  { name: 'phone', description: "The contact's primary phone number" },
  { name: 'address', description: "The contact's primary address" },
  { name: 'cell', description: "The contact's cell phone number" },
  { name: 'fax', description: "The contact's fax number" },
  { name: 'title', description: "The contact's job title" },
  { name: 'email', description: "The contact's primary email address" },
  { name: 'dba', description: "The 'Doing Business As' name  (if a company)" },
  { name: 'department', description: "The contact's department" },
  { name: 'taxId', description: "The contact's tax ID (if a company)" },
  { name: 'company.name', description: 'The company name associated with any project role' },
  {
    name: 'company.phone',
    description: 'The company phone number associated with any project role',
  },
  { name: 'company.email', description: 'The company email associated with any project role' },
];

export const FillTemplateModal = ({ open = false, toggle, objectId, formType = 'project' }) => {
  const dispatch = useAppDispatch();

  const [fileUpload, setFileUpload] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const templates = useAppSelector(selectFormTemplates);

  const [templateOptions, setTemplateOptions] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const formTypeMap = {
    project: 'Project',
    changeOrder: 'Change Order',
    bid: 'Bid',
    contract: 'Contract',
  };

  useEffect(() => {
    if (open) {
      dispatch(getFormTemplates());
    }
  }, [open, dispatch]);

  const handleFileUpload = (files) => {
    if (files && files.length > 0) {
      setFileUpload(files[0]);
      setSelectedTemplate(null); // Clear selected template when a new file is uploaded
    }
  };

  useEffect(() => {
    const newTemplates =
      templates?.map((template) => ({
        value: template._id,
        label: template.name,
        url: template.presignedUrl,
        fileName: template.formFileName,
        fileType: template.fileType?.toLowerCase(),
        isPrimary: template.isPrimary,
        formType: template.type,
        isMatchingType: template.type === formType,
      })) ?? [];

    newTemplates.sort((a, b) => {
      // First, prioritize matching types
      if (a.isMatchingType && !b.isMatchingType) return -1;
      if (!a.isMatchingType && b.isMatchingType) return 1;

      // For matching types, prioritize primary templates
      if (a.isMatchingType && b.isMatchingType) {
        if (a.isPrimary && !b.isPrimary) return -1;
        if (!a.isPrimary && b.isPrimary) return 1;
      }

      // For non-matching types, still consider primary status
      if (!a.isMatchingType && !b.isMatchingType) {
        if (a.isPrimary && !b.isPrimary) return -1;
        if (!a.isPrimary && b.isPrimary) return 1;
      }

      // If primary status is the same, sort by form type
      if (a.formType < b.formType) return -1;
      if (a.formType > b.formType) return 1;

      // If everything else is equal, sort alphabetically by label
      return a.label.localeCompare(b.label);
    });



    setTemplateOptions(newTemplates.filter((x) => x.fileType === 'docx' || x.fileType === 'html'));
    

    if (newTemplates.length > 0) {
      const defaultTemplate = newTemplates.find((x) => x.isPrimary && x.isMatchingType);
      if (defaultTemplate) {
        setSelectedTemplate(defaultTemplate);
      }

    }
  }, [templates]);

  const handleTemplateSelect = (value) => {
    setSelectedTemplate(value);
    setFileUpload(null); // Clear file upload when a template is selected
  };

  const processTemplate = async (outputFormat) => {
    if (!fileUpload && !selectedTemplate) {
      notification.error({ message: 'Please select a template or upload a file!' });
      return;
    }

    setProcessing(true);

    try {
      if (selectedTemplate && selectedTemplate.fileType === 'html') {
        // Process HTML template
        const result = await dispatch(
          processTemplateFile({
            templateId: selectedTemplate.value,
            templateType: formType,
            objectId: objectId,
            returnPdf: outputFormat === 'pdf',
            userId: localStorage.getItem('userId'),
          })
        ).unwrap();

        // Assuming the API returns a blob or a URL to download the file
        if (result.contentType) {
          // If the API returns a blob directly

          if (result.url) {
            window.open(result.url, '_blank');
            return;
          } else {
            const blob = new Blob([result.content], { type: result.contentType });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = result.fileName;
            a.click();
            URL.revokeObjectURL(url);
          }
        } else if (result.url) {
          // If the API returns a URL to download the file
          window.open(result.url, '_blank');
        }
      } else {
        let filePath, fileName;

        if (fileUpload) {
          filePath = await fileUpload.arrayBuffer();
          fileName = fileUpload.name;
        } else {
          // Fetch the selected template file
          const response = await fetch(selectedTemplate.url);
          filePath = await response.arrayBuffer();
          fileName = selectedTemplate.label;
        }

        let zip = new PizZip(filePath);
        const iModule = InspectModule();
        let doc = new Docxtemplater(zip, {
          modules: [iModule],
          paragraphLoop: true,
          linebreaks: true,
        });

        const tags = iModule.getAllTags();
        const fieldResults = await dispatch(
          fetchProjectTemplateTags({
            objectId: objectId,
            templateType: formType,
            tags,
            userId: localStorage.getItem('userId'),
          })
        ).unwrap();

        doc.setData(fieldResults);
        doc.render();

        const out = doc.getZip().generate({
          type: 'blob',
          mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        });

        const url = URL.createObjectURL(out);
        const a = document.createElement('a');
        a.href = url;
        const fileNameWithoutExtension = fileName.includes('.')
          ? fileName.split('.').slice(0, -1).join('.')
          : fileName;
        a.download = `${fileNameWithoutExtension}_filled.docx`;
        a.click();
        URL.revokeObjectURL(url);

        notification.success({ message: 'Template filled successfully!' });
      }
    } catch (error) {
      console.error(error);
      notification.error({ message: 'Error processing template. Please try again.' });
    } finally {
      setProcessing(false);
    }
  };

  const allowedExtension = '.docx';

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  return (
    <Modal backdrop='static' isOpen={open} toggle={toggle} size={expanded ? 'lg' : 'md'}>
      <ModalHeader toggle={toggle}>Fill {formTypeMap[formType]} template</ModalHeader>
      <ModalBody>
        <div className='row'>
          <div className='col ms-2'>
            <h5>How to use this template filler:</h5>
            <ol className='pl-3'>
              {templateOptions?.length > 0 ? (
                <li>Choose an existing template or upload a new .docx template file.</li>
              ) : (
                <li> Select a .docx template file to upload.</li>
              )}
              <li>Click "Process Template" to fill in the template with project data.</li>
              <li>The filled template will automatically download when ready.</li>
            </ol>
            <div className='row mt-4'>
              {templateOptions?.length > 0 && (
                <div className='col-lg-12 mb-3'>
                  <Select
                    options={templateOptions}
                    value={selectedTemplate}
                    onChange={handleTemplateSelect}
                    placeholder='Select an existing template'
                    isClearable
                    isSearchable
                    className='react-select-container'
                    classNamePrefix='react-select'
                    formatOptionLabel={({ label, isPrimary, formType, isMatchingType, fileType }) => (
                      <div style={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'space-between',
                        width: '100%'
                      }}>
                        <div style={{ 
                          display: 'flex', 
                          alignItems: 'center', 
                          minWidth: 0,
                          flex: 1
                        }}>
                          <span 
                            className={`${!isMatchingType ? 'text-muted' : 'font-weight-bold'}`}
                            style={{ 
                              marginRight: '8px',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                            }}
                          >
                            {label}
                          </span>
                          {isPrimary && isMatchingType && (
                            <i className='fas fa-star text-warning' title='Primary template' />
                          )}
                        </div>
                        <div style={{ 
                          display: 'flex', 
                          alignItems: 'center',
                          marginLeft: '8px'
                        }}>
                          <span 
                            className={`${!isMatchingType ? 'text-muted' : ''}`}
                            style={{ 
                              marginRight: '8px',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            {formType.charAt(0).toUpperCase() + formType.slice(1)}
                          </span>
                          <span 
                            className={`${!isMatchingType ? 'text-muted' : ''}`}
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            ({fileType.toUpperCase()})
                          </span>
                        </div>
                      </div>
                    )}
                  />
                </div>
              )}
              <div className='col-lg-12'>
                <FileDropZone onFileDrop={handleFileUpload}>
                  <div className='text-center p-4 border rounded'>
                    <p>
                      {' '}
                      {templateOptions?.length > 0 ? 'Or drag' : 'Drag'} and drop your .docx
                      template here, or choose a file
                    </p>
                    <input
                      className='csv-input'
                      type='file'
                      name='files'
                      id='files'
                      accept={allowedExtension}
                      onChange={(e) => handleFileUpload(e.target.files)}
                      multiple={false}
                    />
                  </div>
                </FileDropZone>
                {fileUpload && (
                  <p className='mt-2'>
                    Selected file: <strong>{fileUpload.name}</strong>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <h5>Setting up your template:</h5>
        <ol className='pl-3'>
          <li>
            Create a .docx template with placeholders for project information in curly brackets,
            e.g., {'{projectName}'}.
          </li>
          <li>
            You can use role-based variables like {'{client.name}'} or {'{projectManager.email}'}{' '}
            and access associated company info with {'{role.company.name}'} or{' '}
            {'{role.company.phone}'}.
          </li>
          <br />
        </ol>
        <Button color='link' className='p-0 mt-2' onClick={() => setExpanded(!expanded)}>
          {expanded ? 'Hide definitions' : 'Show variable definitions'}
        </Button>

        {expanded && (
          <div className='variable-list' style={{ maxHeight: '60vh', overflowY: 'auto' }}>
            <h6>Project Variables:</h6>
            <table className='table table-sm'>
              <thead>
                <tr>
                  <th>Variable</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {projectVariables.map((variable, index) => (
                  <tr key={index}>
                    <td>
                      <code>{'{' + variable.name + '}'}</code>
                    </td>
                    <td>{variable.description}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            <h6 className='mt-4'>Contact Variables:</h6>
            <p>Use these with role-based access, e.g., {'{client.contactName}'}</p>
            <table className='table table-sm'>
              <thead>
                <tr>
                  <th>Variable</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {contactVariables.map((variable, index) => (
                  <tr key={index}>
                    <td>
                      <code>{'{role.' + variable.name + '}'}</code>
                    </td>
                    <td>{variable.description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color='secondary' onClick={toggle}>
          Cancel
        </Button>
        {selectedTemplate && selectedTemplate.fileType === 'html' ? (
           <div className='btn-group'>
            <Button
              color='primary'
              onClick={() => processTemplate('html')}
              disabled={(!fileUpload && !selectedTemplate) || processing}
            >
              {processing ? 'Processing...' : 'Process Template'}
            </Button>
            <button
                type='button'
                className='btn btn-secondary dropdown-toggle dropdown-toggle-split'
                data-bs-toggle='dropdown'
                aria-expanded='false'
              >
                <span className='visually-hidden'>Toggle Dropdown</span>
              </button>
              <ul className='dropdown-menu ml-auto'>
                <li className='dropdown-item'>
                  <a className='statusBtn' onClick={() => processTemplate('pdf')}>Process as PDF
                  
                  </a>
                </li>
              </ul>
          </div>
        ) : (
          <Button
            color='primary'
            onClick={() => processTemplate()}
            disabled={(!fileUpload && !selectedTemplate) || processing}
          >
            {processing ? 'Processing...' : 'Process Template'}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};
