import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { loginAction } from '../../../actions/Admin/user.actions.js';
import cpostLogo from '../../static/images/cpostIcon.png';

const AdminSidebar = ({ location, loginAction }) => {
  useEffect(() => {
    require('./AdminSideBar.css');
  }, []);

  const { pathname } = location;

  return (
    <div className='p-4 admin-sidebar'>
      <nav id=''>
        <ul className='nav list-unstyled components nav-flush flex-column'>
          <div className='admin nav-brand'>
            <Link to='/admin/home'>
              <img className='adminLogo' src={cpostLogo} alt='Contractor Command Post' />
              <br />
              Contractor's Command Post
            </Link>
          </div>
          <hr />
          <div className=''>
            <li className=' nav-item'>
              <Link className='nav-link' to='/admin/accounts'>
                ACCOUNTS
              </Link>
            </li>
            <li className=' nav-item'>
              <Link className='nav-link' to='/admin/projects'>
                PROJECTS
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/admin/users'>
                USERS
              </Link>
            </li>
            <li className='nav-item'>
              <div className='accordion'>
                <div className='' id='masterSubmenu'>
                  <a
                    href='#'
                    data-toggle='collapse'
                    aria-expanded='false'
                    className='accordion-button collapsed '
                    data-bs-toggle='collapse'
                    data-bs-target='#masterSubmenuCollapse'
                    role='button'
                  >
                    MASTERS
                  </a>
                </div>
                <ul
                  id='masterSubmenuCollapse'
                  className='accordion-collapse collapse bg-light'
                  aria-labelledby='masterSubmenu'
                >
                
                  <li>
                    <Link
                      className={`nav-link ${
                        pathname && pathname.includes('user-types') ? 'active' : 'not-active'
                      }`}
                      to='/admin/user-types'
                    >
                      User Types
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`nav-link ${
                        pathname && pathname.includes('default-role-permissions')
                          ? 'active'
                          : 'not-active'
                      }`}
                      to='/admin/default-role-permissions/status-change'
                    >
                      User Permissions
                    </Link>
                  </li>
                  <li className='nav-item dropdown-item'>
                    <Link
                      className={`nav-link ${
                        pathname && pathname.includes('report-settings') ? 'active' : 'not-active'
                      }`}
                      to='/admin/report-settings'
                    >
                      Report Settings
                    </Link>
                  </li>

                  <li>
                    <Link
                      className={`nav-link ${
                        pathname && pathname.includes('project-fields') ? 'active' : 'not-active'
                      }`}
                      to='/admin/project-fields'
                    >
                      Project Fields
                    </Link>
                  </li>
                  
                  <li>
                    <Link
                      className={`nav-link ${
                        pathname && pathname.includes('job-status') ? 'active' : 'not-active'
                      }`}
                      to='/admin/job-status'
                    >
                      Job Status Codes
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`nav-link ${
                        pathname && pathname.includes('job-scope') ? 'active' : 'not-active'
                      }`}
                      to='/admin/job-scope'
                    >
                      Job Scopes
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`nav-link ${
                        pathname && pathname.includes('building-type') ? 'active' : 'not-active'
                      }`}
                      to='/admin/building-type'
                    >
                      Building Types
                    </Link>
                  </li>
                  
                  <li>
                    <Link
                      className={`nav-link ${
                        pathname && pathname.includes('reason-codes') ? 'active' : 'not-active'
                      }`}
                      to='/admin/reason-codes'
                    >
                      Reason Codes
                    </Link>
                  </li>

                
                  <li>
                    <Link
                      className={`nav-link ${
                        pathname && pathname.includes('project-role-types')
                          ? 'active'
                          : 'not-active'
                      }`}
                      to='/admin/project-role-types'
                    >
                      Project Role Types
                    </Link>
                    
                  </li>
                  <li className='nav-item dropdown-item'>
                    <Link
                      className={`nav-link ${
                        pathname && pathname.includes('contact-role') ? 'active' : 'not-active'
                      }`}
                      to='/admin/contact-role'
                    >
                      Contact Roles
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`nav-link ${
                        pathname && pathname.includes('negotiation-method')
                          ? 'active'
                          : 'not-active'
                      }`}
                      to='/admin/negotiation-method'
                    >
                      Negotiating Methods
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`nav-link ${
                        pathname && pathname.includes('form-templates') ? 'active' : 'not-active'
                      }`}
                      to='/admin/form-templates'
                    >
                      Form Templates
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`nav-link ${
                        pathname && pathname.includes('project-actions') ? 'active' : 'not-active'
                      }`}
                      to='/admin/project-actions'
                    >
                      Project Status Actions
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
          </div>
          <li className='active'>
            <Link className='nav-link' to='/admin/plans'>
              SUBSCRIPTION LIST
            </Link>
          </li>
          <li className='active'>
            <Link className='nav-link' to='/admin/notification-types'>
              Notification Types
            </Link>
          </li>
          <li className='active'>
            <Link className='nav-link' to='/admin/widgets'>
              Widgets
            </Link>
          </li>
          <li className='active'>
            <Link className='nav-link' to='/admin/reset-password'>
              CHANGE PASSWORD
            </Link>
          </li>
          <li className='active'>
            <Link className='nav-link' to='/admin/help-list'>
              HELP
            </Link>
          </li>
          <li className='active'>
            <Link className='nav-link' to='/admin/module-options'>
              MODULES
            </Link>
          </li>
          <li className='active'>
            <Link className='nav-link' to='/admin/termsCondition'>
              TERMS &amp; CONDITIONS
            </Link>
          </li>
          <li className='active'>
            <Link className='nav-link' to='/admin/logout'>
              <i className='fa fa-sign-out log'></i>LOGOUT
            </Link>
          </li>
        </ul>
      </nav>
      <div className='top-margin'></div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    stateData: state,
  };
}

export default withRouter(connect(mapStateToProps, { loginAction })(AdminSidebar));
