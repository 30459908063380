import { Button, Popover, Tooltip as AntdTooltip } from 'antd';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import DatePickerWrapper from '../../shared/datepicker-wrapper/datepicker-wrapper';

import ReactLoading from 'react-loading';
import {
  useAsyncDebounce,
  useColumnOrder,
  useExpanded,
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  useGroupBy,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import { useExportData } from 'react-table-plugins';
import ReactToPrint from 'react-to-print';
import { Tooltip } from 'react-tooltip';
import styled from 'styled-components';
import { utils as xlsxUtils, writeFile as xlsxWriteFile } from 'xlsx';
import { DatePickerWrapperStyle, styles, TableStyles } from '../../styles';
import { appConstants } from '../../_constants';
import { DefaultColumnFilter } from './CustomTableComponents/DefaultColumnFilter';
import _, { set } from 'lodash';
import { ReOrderMenu } from './ReOrderMenu';

const Styles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;

  /* This will make the table scrollable when it gets too small */
  .tableWrap {
    display: block;
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    border-bottom: 1px solid black;
  }

  table {
    /* Make sure the inner table is always as wide as needed */
    width: 99%;
    border-spacing: 0;
    margin: 0px;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    tr {
      cursor: pointer;
    }

    th {
      border-bottom: 1px solid black;
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      overflow: hidden;

      /* The secret sauce */
      /* Each cell should grow equally */
      width: 1%;
      /* But "collapsed" cells should be as small as possible */
      &.collapse {
        width: 0.0000000001%;
      }

      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }

  .rowControl {
    opacity: 0.5;
    transition: opacity 0.1s ease-in-out;
  }

  .rowControl:hover {
    opacity: 1;
  }
`;

const dateRangeOptions = [
  {
    label: 'Next 1 year',
    value: 365,
  },

  {
    label: 'Next 90 days',
    value: 90,
  },
  {
    label: 'Next 30 days',
    value: 30,
  },
  {
    label: 'Next 7 days',
    value: 7,
  },
  {
    label: 'Tomorrow',
    value: 1,
  },
  {
    label: 'Today',
    value: 0,
  },
  {
    label: 'Yesterday',
    value: -1,
  },
  {
    label: 'Last 7 days',
    value: -7,
  },
  {
    label: 'Last 30 days',
    value: -30,
  },
  {
    label: 'Last 90 days',
    value: -90,
  },
  {
    label: 'Last 1 year',
    value: -365,
  },
  {
    label: 'Custom',
    value: 'custom',
  },
];


const isTouchDevice = () => {
  return (('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    (navigator.msMaxTouchPoints > 0));
}

export const getConditionalSelectHeaderCheckboxProps = ({
  headerProps,
  checkIfRowIsSelectable,
  shouldSelectPage = true,
}) => {
  const checkIfAllSelectableRowsSelected = (rows) =>
    rows.filter(checkIfRowIsSelectable).every((row) => row.isSelected);
  const isSelectPage =
    shouldSelectPage &&
    headerProps.page.filter(checkIfRowIsSelectable).some((row) => !row.isSelected);
  const checkboxProps = isSelectPage
    ? headerProps.getToggleAllPageRowsSelectedProps()
    : headerProps.getToggleAllRowsSelectedProps();

  const disabled = headerProps.rows.filter(checkIfRowIsSelectable).length === 0;
  const checked = !disabled && checkIfAllSelectableRowsSelected(headerProps.rows);
  const indeterminate = !checked && headerProps.rows.some((row) => row.isSelected);

  const onChange = () => {
    if (!isSelectPage && checkIfAllSelectableRowsSelected(headerProps.rows)) {
      headerProps.rows.forEach((row) => {
        headerProps.toggleRowSelected(row.id, false);
      });
    } else {
      const rows = isSelectPage ? headerProps.page : headerProps.rows;
      rows.forEach((row) => {
        const checked = checkIfRowIsSelectable(row);
        headerProps.toggleRowSelected(row.id, checked);
      });
    }
  };

  return {
    ...checkboxProps,
    checked,
    indeterminate,
    onChange,
    disabled,
  };
};

export function multiSelectFilter(rows, columnIds, filterValue) {
  // Filters only if filters are selected
  // Label must be in list
  return filterValue === 'All'
    ? rows
    : filterValue.length > 0
      ? rows.filter((row) =>
        row.values[columnIds]?.map((obj) => obj.Label ?? obj).includes(filterValue)
      )
      : rows.filter((row) => !row.values[columnIds]);
}

export function customSortByKey(rowA, rowB, id, desc) {
  let aMissing = false;
  if (!rowA.values[id] || rowA.values[id].length == 0) {
    aMissing = true;
  }
  let bMissing = false;
  if (!rowB.values[id] || rowB.values[id].length == 0) {
    bMissing = true;
  }

  if (aMissing && bMissing) {
    return 0;
  } else if (aMissing) {
    return desc ? -1 : 1;
  } else if (bMissing) {
    return desc ? 1 : -1;
  }

  const embeddedLabel = !!rowA.values[id][0]?.Label;

  let rowValsA = rowA.values[id];
  let rowValsB = rowB.values[id];

  if (embeddedLabel) {
    rowValsA = rowValsA.map((x) => x.Label).filter(Boolean);
    rowValsA = rowValsA[0];

    rowValsB = rowValsB.map((x) => x.Label).filter(Boolean);
    rowValsB = rowValsB[0];
  }

  if (rowValsB instanceof String) {
    rowValsB = rowValsB.toLowerCase().trim();
  }

  if (!rowValsA) {
    aMissing = true;
  }

  if (!rowValsB) {
    bMissing = true;
  }

  if (aMissing && bMissing) {
    return 0;
  } else if (aMissing) {
    return desc ? -1 : 1;
  } else if (bMissing) {
    return desc ? 1 : -1;
  }

  let sortCompare;

  if (rowValsA instanceof String) {
    rowValsA = rowValsA.toLowerCase().trim();

    sortCompare = rowValsA.localeCompare(rowValsB);
  } else {
    sortCompare = rowValsA == rowValsB ? 0 : rowValsA > rowValsB ? 1 : -1;
  }

  return sortCompare; //desc ? sortCompare: sortCompare*-1;
}

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <input type='checkbox' ref={resolvedRef} {...rest} checked={rest.checked && !rest.disabled} />
    </>
  );
});

IndeterminateCheckbox.displayName = 'IndeterminateCheckbox';

/* Global Filter*/
function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter, onGlobalSearch }) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
    if (typeof onGlobalSearch === 'function') {
      onGlobalSearch(value || undefined);
    }
  }, 200);

  return (
    <span
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        minWidth: 50,
      }}
      className='no-print mx-2 ms-auto table-global-search'
    >
      <input
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={
          typeof onGlobalSearch === 'function' ? `Search records...` : `Search ${count} records...`
        }
        style={{
          outlineColor: 'gray',
          padding: 10,
          border: '1px solid #ddd',
          fontSize: 17,
          cursor: 'pointer',
          borderRadius: 8,
          minWidth: 50,
        }}
      />
    </span>
  );
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

/**
 *
 * @param {{
 *  list?: any[],
 * columns?: any[],
 * preferences?: any,
 * onSavePreferences?: (preferences?: any) => void,
 * dateFilterableFields?: any[],
 * onEditCell?: () => void,
 * className?: string,
 * canShowRowSelection?: boolean,
 * canGroupBy?: boolean,
 * canSort?: boolean,
 * canFilter?: boolean,
 * groupUp?:boolean
 * [key: string]: any
 * reportId?: string
 * }} props
 * @returns
 */
export function CustomTable(props) {
  const {
    list,
    columns,
    preferences = {},
    onSavePreferences = () => { },
    dateFilterableFields,
    onEditCell,
    className = '',
    canShowRowSelection = false,
    canGroupBy = false,
    canSort = true,
    canFilter = true,
    reportId = '',
    onSortChange = () => { },
    onFilterChange = () => { },
    loading,
    ...otherProps
  } = props;

  columns.forEach((col) => {
    if (col.canSort !== undefined && !col.canSort) {
      col.disableSortBy = !col.canSort;
    }
  });

  return (
    <TableStyles className={`${className} ${canShowRowSelection ? 'table-with-selection' : ''}`}>
      <Table
        columns={columns}
        data={list || []}
        preferences={preferences}
        onSavePreferences={onSavePreferences}
        dateFilterableFields={dateFilterableFields}
        onEditCell={onEditCell}
        canShowRowSelection={canShowRowSelection}
        canGroupBy={canGroupBy}
        canFilter={canFilter}
        canSort={canSort}
        reportId={reportId}
        onSortChange={onSortChange}
        onFilterChange={onFilterChange}
        loading={loading}


        {...otherProps}
      />
    </TableStyles>
  );
}

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const isEqualArrays = (firstArray, secondArray) => {
  if (firstArray.length !== secondArray.length) {
    return false;
  }
  return JSON.stringify(firstArray) === JSON.stringify(secondArray);
};

const TableSettingsModal = ({
  allColumns,
  originalColumnOrder,
  setColumnOrder,
  setHiddenColumns,
  preferences,
  onSavePreferences,
  resetTable,
  reportId = '',
}) => {
  const [preferencesLoaded, setPreferencesLoaded] = useState(false);

  const { visibleIds: prefVisibleIds = [], columnSequence: prefColumnSequence = [] } =
    preferences || {};
  const previousPref = usePrevious({ prefVisibleIds, prefColumnSequence });
  const [columnData, setColumnData] = useState(
    allColumns.map((col) => ({ id: col.id ?? col.accessor, ...col }))
  );
  const [open, setVisible] = useState(false);
  const [visibleIds, setVisibleIds] = useState(prefVisibleIds);
  const [columnSequence, setColumnSequence] = useState(prefColumnSequence);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const allCols = reorder(columnData, result.source.index, result.destination.index).filter(
      Boolean
    );
    setColumnData(allCols);

    const ids = allCols.map((m) => m.id);
    setColumnSequence(ids);

    const visibleColIds = allCols.filter((c) => c.isVisible === true).map((m) => m.id);
    setVisibleIds(visibleColIds);
  };
  const handleOnToggleColumn = (e, col) => {
    const isSelectionCol = col.id === 'selection';
    const allCols = [...columnData];
    if (isSelectionCol) {
      allCols.forEach((f) => {
        f.isVisible = e.target.checked;
      });
    } else {
      const foundIndex = allCols.findIndex((f) => f.id === col.id);
      allCols[foundIndex].isVisible = e.target.checked;
    }
    setColumnData(allCols);

    const visibleColIds = allCols.filter((c) => c.isVisible === true).map((m) => m.id);
    setVisibleIds(visibleColIds);
    setColumnSequence(allCols.map((col) => col.id));
  };
  const handleOnPushTop = (e, index) => {
    const allCols = reorder(columnData, index, 0);
    setColumnData(allCols);
    const ids = allCols.map((m) => m.id);
    setColumnSequence(ids);

    const visibleColIds = allCols.filter((c) => c.isVisible === true).map((m) => m.id);
    setVisibleIds(visibleColIds);
  };

  const handleApplySettings = (visibleColumns) => {
    const columnSequenceLocal = visibleColumns.map((col) => col.id);
    const visibleIdsLocal = visibleColumns.filter((col) => col.isVisible).map((col) => col.id);

    const originalColumnIds = allColumns.map((col) => col.id ?? col.accessor);

    const hasSelection = originalColumnIds.includes('selection');
    const hasExpander = originalColumnIds.includes('expander');
    const hasAction = originalColumnIds.includes('action');

    const newColumnSequence = [
      hasSelection ? 'selection' : '',
      hasExpander ? 'expander' : '',
      ...columnSequenceLocal,
      hasAction ? 'action' : '',
    ].filter(Boolean);

    setColumnSequence(newColumnSequence);
    // include selection or action column (if in all columns)
    setVisibleIds(newColumnSequence);
    onSavePreferences({
      ...preferences,
      columnSequence: newColumnSequence,
      visibleIds: newColumnSequence,
    });
    setColumnOrder(newColumnSequence);

    const hiddenColumnIds = columnData
      .filter((col) => !newColumnSequence.includes(col.id) || col.alwaysHidden)
      .map((col) => col.id);
    setHiddenColumns(hiddenColumnIds);
    setVisible(!open);
  };

  const handlePopover = (isVisible) => {
    setVisible(isVisible);
    if (!isVisible) {
      setColumnData(
        allColumns.map((col) => ({ id: col.id ?? col.accessor, ...col, isVisible: !col.hidden }))
      );
    }
  };

  const handleReset = () => {
    const origColIds = originalColumnOrder.map((col) => col.id);
    setColumnSequence(origColIds);

    const originalColumns = allColumns.map((col) => ({
      id: col.id ?? col.accessor,
      ...col,
      isVisible: !col.hidden,
    }));
    const currentColumnIds = columnData.map((col) => col.id);
    const missingColumnIds = currentColumnIds.filter((colId) => !origColIds.includes(colId));

    let newColumnData = origColIds
      .map((colId) => {
        return originalColumns.find((col) => col.id == colId);
      })
      .filter(Boolean);

    newColumnData = newColumnData.map((col) => ({
      hidden: originalColumnOrder.find((col2) => col2.id == col.id)?.hidden ?? true,
      ...col,
    }));
    newColumnData = newColumnData
      .concat(columnData.filter((col) => missingColumnIds.includes(col.id)))
      .filter(Boolean);

    newColumnData = newColumnData.map((col) => ({ ...col, isVisible: !col.hidden }));

    setColumnData(newColumnData);

    const hiddenColumnIds = newColumnData.filter((col) => !col.isVisible).map((col) => col.id);
    const visibleColumnIds = newColumnData.filter((col) => col.isVisible).map((col) => col.id);

    setVisibleIds(visibleColumnIds);

    setColumnOrder(newColumnData.map((col) => col.id));

    setHiddenColumns(hiddenColumnIds);

    newColumnData.sort((a, b) => (a.selection == 'selection' || a.id == 'expander' ? 1 : 0));
  };

  useEffect(() => {
    if (!preferencesLoaded && !_.isEmpty(preferences)) {
      setPreferencesLoaded(true);
    }

    const alwaysShown = ['selection', 'expander', 'action'];

    if (
      previousPref?.prefVisibleIds &&
      !isEqualArrays(prefVisibleIds, previousPref?.prefVisibleIds)
    ) {
      const cols = [...columnData];
      cols.forEach((f) => {
        f.isVisible = prefVisibleIds.includes(f.id);
      });
      setColumnData(cols);
    } else if (!previousPref && prefVisibleIds?.length) {
      const cols = [...columnData];
      cols.forEach((f) => {
        f.isVisible = prefVisibleIds.includes(f.id) && !f.alwaysHidden || alwaysShown.includes(f.id);
      });
      setColumnData(cols);
      const hiddenColumnIds = columnData.filter((col) => !col.isVisible).map((col) => col.id);
      setHiddenColumns(hiddenColumnIds);
      setVisibleIds(prefVisibleIds);
    } else if (
      !previousPref &&
      !prefVisibleIds?.length &&
      !visibleIds?.length &&
      !preferencesLoaded
    ) {
      resetTable(false);
    }
  }, [preferences]);

  useEffect(() => {
    if (
      previousPref?.prefColumnSequence &&
      !isEqualArrays(prefColumnSequence, previousPref.prefColumnSequence)
    ) {
      const cols = [...columnData];
      let newCols = [];
      if (prefColumnSequence?.length > 0) {
        prefColumnSequence.forEach((f) => {
          const newCol = cols.find((col) => col.id === f);
          if (newCol) {
            newCols.push(newCol);
          }
        });
        if (newCols.length) {
          setColumnData(newCols);
        }
      }
    }
  }, [JSON.stringify(prefColumnSequence)]);

  useEffect(() => {
    if ((preferencesLoaded, prefColumnSequence?.length !== 0)) {
      setColumnOrder(prefColumnSequence);
    } else if (preferencesLoaded && prefColumnSequence?.length === 0) {
      setColumnOrder(originalColumnOrder.map((col) => col.id));
      setColumnData(
        allColumns.map((col) => ({
          id: col.id ?? col.accessor,
          ...col,
          isVisible: !col.hidden && !col.alwaysHidden,
        }))
      );
    }
  }, [JSON.stringify(prefColumnSequence), preferencesLoaded]);

  return (
    <>
      <Popover
        onOpenChange={handlePopover}
        open={open}
        content={
          <ReOrderMenu
            allColumns={columnData
              .filter((column) => !column.alwaysHidden)
              .map((col) => ({
                ...col,
                isVisible: visibleIds.includes(col.id),
              }))}
            //onDragEnd={onDragEnd}
            onToggleCheckbox={handleOnToggleColumn}
            onPushTop={handleOnPushTop}
            handleApplySettings={handleApplySettings}
            resetTable={handleReset}
            handleToggle={() => setVisible(!open)}
            reportId={preferences?.reportId || reportId}
          />
        }
        title='Settings'
        trigger='click'
        placement='bottom'
        titleMinWidth='100px'
      >

        <button
          className='btn btn-outline px-2'
          data-tooltip-content='Settings'
          data-tooltip-id='report-settings'
        >
          <i className='fa fa-ellipsis-v' />
        </button>

      </Popover>
    </>
  );
};

const Filters = ({
  dateFields = [],
  handleDateRange,
  handleDateRangeReset,
  preferences,
  isDataReady,
  dateRangeOptions,
  filterLabel,
}) => {
  const localTimeZone = moment.tz.guess();
  const [visible, setVisible] = useState(false);
  const [dateField, setDateField] = useState(preferences?.dateRange?.field || '');
  const [dateRange, setDateRange] = useState(preferences?.dateRange?.predefinedValue || '');
  const [startDate, setStartDate] = useState(
    preferences?.dateRange?.start ? moment(preferences?.dateRange?.start) : null
  );
  const [endDate, setEndDate] = useState(
    preferences?.dateRange?.end ? moment(preferences?.dateRange?.end) : null
  );

  const handlePredefinedRange = (val) => {
    setDateRange(val);
    if (val === 'custom') {
      const dateFrom = moment().startOf('day');
      const dateTo = moment().endOf('day');
      setStartDate(dateFrom);
      setEndDate(dateTo);
    } else {
      const selected = Number(val);
      let dateTo;
      let dateFrom;
      if (selected > 1) {
        dateFrom = moment().startOf('day');
        dateTo = moment().endOf('day').add(selected, 'd');
      } else if (selected < -1) {
        dateFrom = moment().startOf('day').add(selected, 'd');
        dateTo = moment().endOf('day');
      } else if (selected == 1 || selected == -1) {
        dateFrom = moment().startOf('day').add(selected, 'd');
        dateTo = moment().endOf('day').add(selected, 'd');
      } else {
        dateFrom = moment().startOf('day');
        dateTo = moment().endOf('day');
      }
      setStartDate(dateFrom);
      setEndDate(dateTo);
    }
  };

  const handleDateField = (e) => {
    handleClear();
    setDateField(e.target.value);
  };

  const handlePopover = (isVisible) => {
    setVisible(isVisible);
  };

  useEffect(() => {
    if (typeof handleDateRange === 'function' && startDate && endDate && dateField && dateRange) {
      handleDateRange(dateField, startDate, endDate, dateRange);
    }
  }, [dateField, dateRange, startDate, endDate]);

  useEffect(() => {
    if (!preferences?.dateRange) {
      setDateField('');
      setDateRange('');
      setStartDate(null);
      setEndDate(null);
    }
    if (isDataReady) {
      if (preferences?.dateRange?.field) {
        setDateField(preferences?.dateRange?.field);
      }
      if (preferences?.dateRange?.predefinedValue) {
        if (preferences?.dateRange?.predefinedValue === 'custom') {
          if (preferences?.dateRange?.start) {
            setStartDate(moment(preferences?.dateRange?.start));
          }
          if (preferences?.dateRange?.end) {
            setEndDate(moment(preferences?.dateRange?.end));
          }
        } else {
          handlePredefinedRange(preferences?.dateRange?.predefinedValue);
        }
      }
    }
  }, [JSON.stringify(preferences), isDataReady]);

  const selectedDateLabel = dateField ? dateFields?.find((f) => f.value === dateField)?.label : '';

  const handleClear = () => {
    handleDateRangeReset(dateField);
    setDateField('');
    setDateRange('');
    setStartDate(null);
    setEndDate(null);
  };

  const toolbarButtonClass = `${(startDate && endDate) || visible ? 'btn-primary active' : 'btn-outline-secondary'
    } btn mx-3 px-3`;

  return (
    <>
      <Popover
        onOpenChange={handlePopover}
        placement='bottom'
        content={
          <div style={styles.dateFilters} className='no-print'>
            <div style={styles.dateFieldGroup}>
              <label style={styles.dateFieldLabel}>Filter on:</label>
              <select onChange={handleDateField} value={dateField} style={styles.dateDropdown}>
                <option value='' disabled>
                  Select Date Field
                </option>
                {dateFields &&
                  dateFields.length &&
                  dateFields.map((field) => {
                    return (
                      <option key={'dateFilter' + field.value} value={field.value}>
                        {field.label}
                      </option>
                    );
                  })}
              </select>
            </div>
            <select
              onChange={(e) => handlePredefinedRange(e.target.value)}
              value={dateRange}
              style={selectedDateLabel === '' ? styles.dateDropdownDisabled : styles.dateDropdown}
              disabled={selectedDateLabel === ''}
            >
              <option value='' disabled>
                Select Date Range
              </option>
              {dateRangeOptions &&
                dateRangeOptions.length &&
                dateRangeOptions.map((dF) => {
                  return (
                    <option key={'dateRange' + dF.value} value={dF.value}>
                      {dF.label}
                    </option>
                  );
                })}
            </select>
            <div style={styles.dateFieldGroup}>
              <DatePickerWrapperStyle>
                <DatePickerWrapper
                  dateFormat={appConstants.DATEFORMATPICKER}
                  selected={startDate}
                  todayButton='Today'
                  showMonthDropdown
                  onChange={(date) => {
                    setStartDate(date.startOf('day'));
                    setDateRange('custom');
                    if (startDate && endDate) {
                      const diff = date.diff(endDate, 'days');
                      const isStartDateGreater = diff > 0;
                      if (isStartDateGreater) {
                        const end = date.clone().add(diff, 'd').endOf('day');
                        setEndDate(end);
                      }
                    }
                  }}
                  selectsStart
                  endDate={endDate}
                  placeholderText='Start Date'
                  disabled={selectedDateLabel === ''}
                />
              </DatePickerWrapperStyle>

              <DatePickerWrapperStyle>
                <DatePickerWrapper
                  dateFormat={appConstants.DATEFORMATPICKER}
                  selected={endDate}
                  onChange={(date) => {
                    setEndDate(date.endOf('day'));
                    setDateRange('custom');
                  }}
                  selectsEnd
                  todayButton='Today'
                  showMonthDropdown
                  placeholderText='End Date'
                  minDate={startDate}
                  startDate={startDate}
                  disabled={selectedDateLabel === ''}
                />
              </DatePickerWrapperStyle>
            </div>
            <div style={styles.clearButton} className='mt-4 mb-0'>
              <button
                className='btn btn-primary'
                onClick={handleClear}
                disabled={selectedDateLabel === ''}
              >
                Clear
              </button>
            </div>
          </div>
        }
        title='Date Filter'
        trigger='click'
      >
        <AntdTooltip title='Date Filters'>
          <button
            className={toolbarButtonClass}
            data-tooltip-content='Date Filters'
            data-tooltip-id='report-date-filters'
          >
            <i className='fa fa-calendar fa-2x' aria-hidden='true'></i>
          </button>
        </AntdTooltip>
      </Popover>
    </>
  );
};

const PrintOptions = ({ children }) => {
  const [visible, setVisible] = useState(false);

  const handlePopover = (isVisible) => {
    setVisible(isVisible);
  };
  return (
    <Popover
      onOpenChange={handlePopover}
      placement='bottom'
      content={<div className='no-print export-options-wrapper'>{children}</div>}
      title='Print Report'
      trigger='click'
    >
      <Button type='text' shape='circle' size='large' className='no-print'>
        <i className='fa fa-print fa-2x' aria-hidden='true'></i>
      </Button>
    </Popover>
  );
};

const ExportOptions = ({ children }) => {
  const [visible, setVisible] = useState(false);

  const handlePopover = (isVisible) => {
    setVisible(isVisible);
  };
  return (
    <>
      <Popover
        onOpenChange={handlePopover}
        placement='bottom'
        content={<div className='no-print export-options-wrapper'>{children}</div>}
        title='Export to Excel'
        trigger='click'
      >
        <AntdTooltip title='Export to Excel'>
          <button
            className='btn btn-outline-secondary mx-3 px-3'
            data-tooltip-content='Export Report'
            data-tooltip-id='report-export-options'
          >
            <i className='fa fa-download' aria-hidden='true' />
          </button>
        </AntdTooltip>
      </Popover>
    </>
  );
};

function Table({
  columns,
  data,
  preferences,
  inlineEdit = false,
  onSavePreferences,
  onEditCell = () => { },
  canShowGlobalFilter = true,
  onGlobalSearch,
  globalSearchTerm = null,
  canShowColumnSettings = true,
  canShowDateFilter = true,
  canShowExport = true,
  canShowRowSelection,
  canShowFooter = false,
  onChangeRowSelection,
  canShowPagination = true,
  canShowHeaderItems = true,
  manualSortBy = false,
  manualFilters = false,
  maxPageSize = 1000,
  pageSizeArray = [50, 100, 200],
  isLoading = false,
  exportActions,
  onRowClicked,
  initialSorted,
  initialExpanded,
  customTableTitle,
  renderRowSubComponent,
  onSortChange = () => { },
  onFilterChange = () => { },
  onGroupByChange = () => { },
  useControlledState = (state) => ({ ...state }),
  componentRef,
  canResizeColumns = true,
  canGroupBy = false,
  canFilter = true,
  canSort = true,
  groupUp = false,
  rowProps = () => ({}),
}) {

  const [isTouch, setIsTouch] = useState(false);
  const [persistentFilters, setPersistentFilters] = useState(preferences?.dateRange?.field ? [
    {
      ...(preferences?.dateRange?.start && {
        id: preferences?.dateRange?.field,
        value: [moment(preferences?.dateRange?.start), moment(preferences?.dateRange?.end)],
      }),
    }
  ] : []);
  const [persistentSortBy, setPersistentSortBy] = useState(initialSorted ? [initialSorted] : preferences?.sortBy || []);

  useEffect(() => {
    setIsTouch(isTouchDevice());
  }, []);

  const updateMyData = (rowIndex, columnId, value) => {
    if (typeof onEditCell === 'function') {
      onEditCell(rowIndex, columnId, value);
    }
  };
  // Create an editable cell renderer
  const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData, // This is a custom function that we supplied to our table instance
  }) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue);

    const onChange = (e) => {
      setValue(e.target.value);
    };

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
      updateMyData(index, id, value);
    };

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    return <input value={value} onChange={onChange} onBlur={onBlur} />;
  };
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30, // minWidth is only used as a limit for resizing
      width: 45, // width is used for both the flex-basis and flex-grow
      maxWidth: 350, // maxWidth is only used as a limit for resizing
      Filter: DefaultColumnFilter,
      ...(inlineEdit && {
        Cell: EditableCell,
      }),
    }),
    []
  );

  const dateFields = columns
    .filter((f) => f?.allowDateRangeFilter === true)
    .map((r) => ({
      label: r['filterName'] || r['Header'],
      value: r.id || r.accessor,
    }));

  function getExportFileBlob({ columns, data, fileType, fileName }) {
    if (fileType === 'xlsx') {
      const header = columns.filter((c) => c.id !== 'Action').map((c) => c.exportValue);
      const exportField = columns.filter((c) => c.id !== 'Action').map((c) => c.exportField);
      const compatibleData = data.map((row) => {
        const obj = {};
        header?.forEach((col, index) => {
          if (Array.isArray(row[index]) && row[index].length > 0) {
            if (exportField[index]) {
              obj[col] = row[index].flatMap((item) => item[exportField[index]]).join(', ');
            } else {
              obj[col] = row[index]
                .flatMap((item) => item?.Label ?? item)
                .filter(Boolean)
                .join(', ');
            }
          } else if (row[index] instanceof Date) {
            if (row[index] > new Date(1900, 0, 0)) {
              obj[col] = row[index];
            } else {
              obj[col] = null;
            }
          } else {
            obj[col] = row[index];
          }
        });
        return obj;
      });

      let wb = xlsxUtils.book_new();
      let ws1 = xlsxUtils.json_to_sheet(compatibleData, {
        header,
      });
      xlsxUtils.book_append_sheet(wb, ws1, 'React Table Data');
      xlsxWriteFile(wb, `${fileName}.xlsx`);
      return false;
    }
    return false;
  }

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    allColumns,
    visibleColumns,
    getToggleHideAllColumnsProps,
    toggleAllRowsExpanded,
    isAllRowsExpanded,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    rows,
    setColumnOrder,
    preGlobalFilteredRows,
    resetResizing,
    setFilter,
    setAllFilters,
    setSortBy,
    setGroupBy,
    setGlobalFilter,
    setHiddenColumns,
    toggleAllRowsSelected,
    exportData,
    reportId,
    state: { sortBy, filters },
  } = useTable(
    {
      useControlledState,
      columns,
      data,
      defaultColumn,
      getExportFileBlob,
      initialState: {
        ...(canShowPagination && {
          pageIndex: 0,
          pageSize: pageSizeArray && pageSizeArray.length ? pageSizeArray[0] : maxPageSize,
        }),
        ...(!canShowPagination && {
          pageIndex: 0,
          pageSize: maxPageSize,
        }),
        hiddenColumns: preferences?.hiddenIds || [],
        columnOrder: preferences?.columnSequence || [],
        isAllRowsExpanded: initialExpanded,
        manualSortBy: manualSortBy,
        manualFilters: manualFilters,
        filters: persistentFilters,
        ...(initialExpanded && {
          expanded: initialExpanded,
        }),
        sortBy: persistentSortBy,
        groupBy: preferences?.groupBy || [],
        reportId: ''
      },
      updateMyData,
    },
    useFilters,
    useGlobalFilter,
    useColumnOrder,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useResizeColumns,
    useFlexLayout,

    // Only use resizing if it's not a touch device

    useExportData,
    (hooks) => {

      if (canShowRowSelection) {
        hooks.visibleColumns.push((columns) => [
          {
            id: 'selection',
            Header: (props) => {
              const checkboxProps = getConditionalSelectHeaderCheckboxProps({
                headerProps: props,
                checkIfRowIsSelectable: (row) => row.original.disabled !== true,
              });
              return <IndeterminateCheckbox {...checkboxProps} className='no-print' />;
            },
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => {
              return (
                <IndeterminateCheckbox
                  className='no-print'
                  disabled={row?.original?.disabled}
                  {...row.getToggleRowSelectedProps()}
                />
              );
            },
            width: 20,
          },
          ...columns,
        ]);
      }
    },
  );

  useEffect(() => {
    if (!_.isEqual(sortBy, persistentSortBy)) {
      const validSortBy = sortBy.filter((s) => s && s.id);
      onSortChange(validSortBy);
      setPersistentSortBy(validSortBy);
    }
  }, [sortBy]);

  useEffect(() => {
    if (!_.isEqual(filters, persistentFilters)) {
      const validFilters = filters.filter((f) => f && f.value !== null && f.value !== undefined);
      setPersistentFilters(validFilters);
      onFilterChange(validFilters);
    }
  }, [filters]);

  useEffect(() => {
    if (pageSizeArray && pageSizeArray.length && !pageSizeArray.includes(state.pageSize)) {
      setPageSize(pageSizeArray && pageSizeArray.length ? pageSizeArray[0] : maxPageSize);
    }
  }, [pageSizeArray, state.pageSize]);

  useEffect(() => {
    setGlobalFilter(globalSearchTerm);
  }, [globalSearchTerm]);

  const [filterLabel, setFilterLabel] = useState(null);
  const [groupByLabel, setGroupByLabel] = useState(null);

  const [originalColumnOrder, setOriginalColumnOrder] = useState(
    columns?.map((col) => {
      return { id: col.id ?? col.accessor, hidden: col.hidden === true };
    }) ?? []
  );

  useEffect(() => {
    if (columns?.length > 0 && !(originalColumnOrder?.length > 0)) {
      setOriginalColumnOrder(
        columns?.map((col) => {
          return { id: col.id ?? col.accessor, hidden: col.hidden === true };
        })
      );
    }
  }, [columns]);

  useEffect(() => {
    if (!isAllRowsExpanded) {
      toggleAllRowsExpanded(initialExpanded);
    }

    if (state.groupBy?.length > 0 && canGroupBy) {
      setGroupByLabel(buildGroupingLabel(state.groupBy));
    } else {
      setGroupByLabel('');
    }
  }, [initialExpanded, state.groupBy]);

  const handleDateRange = (field, start, end, predefinedValue) => {
    setFilter(`${field}`, [start, end]);
    setFilterLabel(buildFilterLabel(field, start, end, predefinedValue));
    onSavePreferences({
      ...preferences,
      dateRange: { start: start.toISOString(), end: end.toISOString(), predefinedValue, field },
    });
  };

  const handleDateRangeReset = (field) => {
    if (field) {
      setFilter(`${field}`, null);
      setFilterLabel('');
    }
    onSavePreferences({
      ...preferences,
      dateRange: { start: null, end: null, predefinedValue: null, field: null },
    });
  };

  const buildFilterLabel = (field, start, end, dateRange) => {
    const selectedField = dateFields.find((dateField) => dateField.value === field);
    const selectedDateRange = dateRangeOptions.find((f) =>
      dateRange === 'custom' ? f.value === dateRange : f.value === Number(dateRange)
    );

    if (selectedField && selectedDateRange) {
      return `${selectedField.label} : ${selectedDateRange.label} (${start.format(
        appConstants.DATEFORMAT
      )} - ${end.format(appConstants.DATEFORMAT)})`;
    }

    return '';
  };

  const buildGroupingLabel = (groupBy) => {
    const removeAggregateColumn = groupBy
      .filter((f) => f !== 'aggregateColumn' && f?.length > 0 && f?.toLowerCase() !== 'total')
      .filter(Boolean);
    if (removeAggregateColumn?.length > 0) {
      return (
        'Grouped by: ' +
        removeAggregateColumn
          .map((f) => {
            const selectedField = columns.find((column) => column.id === f);
            return selectedField?.LongHeader ?? selectedField?.Header;
          })
          .join(', ')
      );
    }

    return '';
  };

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (allColumns.length > 0 && !isInitialized && rows) {
      setIsInitialized(true);
      // resetTable(false);
      setDefaultGroupBy(allColumns?.filter((col) => col.defaultGrouped).map((col) => col.id) ?? []);
    } else if (isInitialized && (allColumns.length == 0 || !rows)) {
      setIsInitialized(false);
    }

    if (
      isInitialized &&
      rows?.length > 0 &&
      !(state.groupBy?.length > 0) &&
      defaultGroupBy?.length > 0
    ) {
      setGroupBy(defaultGroupBy);
    }
  }, [allColumns, rows?.length]);

  const resetTable = (shouldSave = true) => {
    setAllFilters([]);
    setColumnOrder(originalColumnOrder.map((col) => col.id));
    setSortBy([]);
    setGroupBy(allColumns?.filter((col) => col.defaultGrouped).map((col) => col.id) ?? []);
    setGlobalFilter('');
    setPageSize((pageSizeArray && pageSizeArray.length ? pageSizeArray[0] : maxPageSize) ?? 50);
    gotoPage(1);
    resetResizing();
    toggleAllRowsSelected(false);
    setHiddenColumns(
      allColumns
        .filter(function (col) {
          return col.hidden || col.alwaysHidden;
        })
        .map((col) => col.id ?? col.accessor)
    );
    if (shouldSave) {
      onSavePreferences({
        visibleIds: allColumns
          .filter((col) => !col.hidden && !col.alwa)
          .map((col) => col.id ?? col.accessor),
        columnSequence: originalColumnOrder.map((col) => col.id),
        sortBy: [],
        groupBy: allColumns.filter((col) => col.defaultGrouped).map((col) => col.id),
      });
    }
  };

  useEffect(() => {
    if (typeof onSortChange === 'function') {
      onSortChange(state.sortBy);
    }
  }, [JSON.stringify(state.sortBy)]);

  useEffect(() => {
    if (typeof onGroupByChange === 'function') {
      onGroupByChange(state.groupBy);
    }
  }, [JSON.stringify(state.groupBy)]);

  useEffect(() => {
    if (state?.sortBy?.length) {
      onSavePreferences({ ...preferences, sortBy: state.sortBy });
    }
  }, [JSON.stringify(state.sortBy)]);

  useEffect(() => {
    if (state?.groupBy?.length >= 0) {
      onSavePreferences({ ...preferences, groupBy: state.groupBy.filter(Boolean) });
    }
  }, [JSON.stringify(state.groupBy)]);

  useEffect(() => {
    if (preferences?.sortBy?.length !== 0 && typeof onSortChange === 'function') {
      setSortBy(preferences?.sortBy || []);
    }
  }, [JSON.stringify(preferences)]);

  const [defaultGroupBy, setDefaultGroupBy] = useState(
    allColumns?.filter((col) => col.defaultGrouped).map((col) => col.id) ?? []
  );

  useEffect(() => {
    const defaultGroupBy =
      allColumns?.filter((col) => col.defaultGrouped).map((col) => col.id) ?? [];
    if (preferences?.groupBy?.length !== 0 && typeof onGroupByChange === 'function') {
      if (defaultGroupBy?.length > 0 && preferences?.groupBy?.length === 0)
        setGroupBy(defaultGroupBy);
      else setGroupBy(preferences?.groupBy || []);
    } else {
      setGroupBy(defaultGroupBy ?? []);
    }
  }, [JSON.stringify(preferences)]);

  useEffect(() => {
    if (state?.selectedRowIds && typeof onChangeRowSelection === 'function') {
      const ids = Object.keys(state?.selectedRowIds);
      onChangeRowSelection(ids);
    }
  }, [JSON.stringify(state.selectedRowIds)]);

  const flipUpGroupers = (rowsToFlip) => {
    let lastMember = -1;
    let groupIdx = -1;

    const clonedRows = _.cloneDeep(rowsToFlip);

    for (let r in rowsToFlip) {
      r = parseInt(r);
      const curRow = rowsToFlip[r];

      if (!curRow.isGrouped) {
        lastMember = r;
      }

      if (curRow.isGrouped && groupIdx < 0) {
        groupIdx = r;
      } else if ((curRow.isGrouped || r == rowsToFlip.length - 1) && lastMember >= 0) {
        let groupRow = _.cloneDeep(rowsToFlip[groupIdx]);

        // insert the group row after the last member
        clonedRows.splice(lastMember + 1, 0, groupRow);
        // remove group row from original location
        clonedRows.splice(groupIdx, 1);

        lastMember = -1;

        groupIdx = r;
      }
    }

    return clonedRows;
  };

  const hasHeaderItems =
    canShowHeaderItems &&
    (canShowPagination ||
      canShowGlobalFilter ||
      canShowDateFilter ||
      canShowExport ||
      customTableTitle);

  // Render the UI for your table
  return (
    <Styles>
      <div className={rows?.length ? '' : 'react-table-empty'}>

        <div
          className='no-print table-actions row ps-2'
        >

          {customTableTitle && (
            <div className='mr-1 p-2 reportHead col-md-4 col-sm-6'>
              <h2>{customTableTitle}</h2>
              <br />
              <span>{filterLabel}</span>
              {groupByLabel?.length > 0 && (
                <span>
                  {filterLabel?.length > 0 && <span> | </span>}
                  {groupByLabel}
                </span>
              )}
            </div>
          )}

          <div className='mr-auto me-0 pe-0 col-md d-flex justify-content-end no-gutters flex-wrap' >
            <div className='d-flex jusify-content-end mt-2'>
              {hasHeaderItems && canShowPagination && data?.length > 0 && pageOptions.length > 1 && (
                <div
                  className='table-pagination'
                >
                  <span style={{ display: 'contents', justifyContent: 'center', minWidth: '80px' }}>
                    {state.pageIndex + 1} of {pageOptions.length}
                  </span>
                  <div
                    className='pagination-buttons'
                    style={{ display: 'contents', justifyContent: 'center', marginLeft: '10px' }}
                  >
                    {' '}
                    <button
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage || !state.pageIndex}
                      style={{
                        ...styles.paginationButtons,
                        ...((!canPreviousPage || !state.pageIndex) && { ...styles.disabledButton }),
                      }}
                    >
                      <i className='fa fa-chevron-left fa-lg' aria-hidden='true'></i>
                    </button>{' '}
                    <button
                      onClick={() => nextPage()}
                      disabled={!canNextPage || state.pageIndex + 1 === pageOptions.length}
                      style={{
                        ...styles.paginationButtons,
                        ...((!canNextPage || state.pageIndex + 1 === pageOptions.length) && {
                          ...styles.disabledButton,
                        }),
                      }}
                    >
                      <i className='fa fa-chevron-right fa-lg' aria-hidden='true'></i>
                    </button>{' '}
                  </div>
                </div>
              )}
              {hasHeaderItems && canShowGlobalFilter && (
                <div className=''>
                  <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    onGlobalSearch={onGlobalSearch}
                  />
                </div>
              )}
            </div><div className='d-flex jusify-content-end mt-2' >
              {hasHeaderItems && canShowDateFilter && dateFields?.length > 0 && (
                <div className=''>
                  <Filters
                    handleDateRange={handleDateRange}
                    preferences={preferences}
                    dateFields={dateFields}
                    isDataReady={data?.length > 0}
                    handleDateRangeReset={handleDateRangeReset}
                    dateRangeOptions={dateRangeOptions}
                    filterLabel={filterLabel}
                  />
                </div>
              )}
              {hasHeaderItems && canShowExport && (
                <div className=''>
                  <ReactToPrint
                    trigger={() => (
                      <button
                        type='text'
                        className='btn no-print btn-outline-secondary px-3'
                        data-tooltip-content='Print Report'
                        data-tooltip-id='report-print'
                      >
                        <i className='fa fa-lg fa-print' />
                      </button>
                    )}
                    content={() => componentRef}
                  />
                  <Tooltip id='report-print' place='top' />
                </div>
              )}
              {hasHeaderItems && canShowExport && (
                <div className=''>
                  <ExportOptions>
                    <button
                      className='btn pdfPrintBtn  no-print'
                      onClick={() => {
                        exportData('xlsx', false, customTableTitle);
                      }}
                    >
                      <i className='fa fa-file-excel'></i> Current View
                    </button>
                    <button
                      className='btn pdfPrintBtn no-print'
                      onClick={() => {
                        exportData('xlsx', true, customTableTitle);
                      }}
                    >
                      <i className='fa fa-file-excel'></i> Default View
                    </button>
                  </ExportOptions>
                </div>
              )}
              {hasHeaderItems && canShowColumnSettings && (
                <div className=''>
                  <TableSettingsModal
                    allColumns={allColumns}
                    originalColumnOrder={originalColumnOrder}
                    visibleColumns={visibleColumns}
                    setColumnOrder={setColumnOrder}
                    setHiddenColumns={setHiddenColumns}
                    preferences={preferences}
                    reportId={reportId}
                    onSavePreferences={onSavePreferences}
                    resetTable={resetTable}
                    getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {hasHeaderItems && <br className='no-print' />}
        <div className='table-responsive reportTableSection'>
          <table
            {...getTableProps()}
            style={{ padding: 0 + 'px' }}
            className={rows?.length > 0 ? 'table' : 'empty-table'}
            dataSource={rows.map(row => {
              prepareRow(row);
              return {
                ...row.original,
                key: row.id,
              };
            })}
            onChange={(pagination, filters, sorter) => {
              if (sorter.field && sorter.order) {
                onSortChange(sorter.field, sorter.order === 'ascend' ? 'asc' : 'desc');
              }
            }}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps([
                        {
                          className:
                            column.className +
                            (column.isGrouped ? ' groupedHeaderCell' : 'headerCell'),
                          style: { ...column.style, overflow: 'visible' },
                        },
                        !isTouch && column?.canResize && column?.getResizerProps(),
                      ])}
                    >
                      {/* {' '} */}
                      <div className='row flex-row'>
                        <span className='col flex' style={{ display: 'flex' }}>
                          <div {...column.getSortByToggleProps()}>
                            <AntdTooltip title={column.LongHeader}>
                              {column.render('Header')}
                            </AntdTooltip>
                          </div>
                          <div className='ms-auto' />

                          <div {...column.getSortByToggleProps()}>
                            {column.id !== 'selection' &&
                              column.id !== 'action' &&
                              canSort &&
                              !(column.disableSortBy) && (
                                <span className='sort-icon-wrapper ms-auto'>
                                  <i
                                    className={`fa fa-sort${column.isSorted
                                      ? column.isSortedDesc
                                        ? '-desc'
                                        : '-asc'
                                      : ''
                                      }`}
                                    aria-hidden='true'
                                  />
                                </span>
                              )}
                          </div>

                          {/*
                          {(column.id !== 'selection' && column.id !== 'action') && ((canGroupBy && column.canGroupBy === true) || (column.canSort && canSort)) && (!column.hideMenu) && (
                            <span className='dropdown'>
                              <span role='button' data-bs-toggle='dropdown' aria-expanded='false'>
                                <i className='fa fa-ellipsis-v sort-icon-wrapper' />{' '}
                              </span>
                              <ul className='dropdown-menu'>

                                {column.canSort && (
                                  <li
                                    className='dropdown-item'
                                    onClick={() => {
                                      column.toggleSortBy(false);
                                    }}
                                    disabled={column.isSorted}
                                  >
                                    Sort ascending
                                  </li>
                                )}
                                {column.canSort && (
                                  <li
                                    className='dropdown-item'
                                    onClick={() => {
                                      column.toggleSortBy(true);
                                    }}
                                    disabled={column.isSortedDesc}
                                  >
                                    Sort descending
                                  </li>
                                )}
                                {column.canSort && column.isSorted ? (
                                  <li
                                    className='dropdown-item'
                                    onClick={() => {
                                      column.toggleSortBy();
                                    }}
                                  >
                                    {column.isSortedDesc ? 'Remove Sort' : 'Remove Sort'}
                                  </li>
                                ) : null}

                                {canGroupBy && column.canGroupBy === true ? (
                                  <>
                                    <li className='dropdown-divider' />
                                    <li
                                      {...column.getGroupByToggleProps({
                                        className: 'dropdown-item',
                                      })}
                                    >
                                      {((column.isGrouped ? 'Stop grouping by ' : 'Group by ') + (column.LongHeader ?? column.Header))}
                                    </li>
                                  </>
                                ) : null}
                                
                          {column.canFilter && column.Filter?.name !== 'DefaultColumnFilter' ? (
                            <li className='dropdown-item'>
                              {column.render('Filter')}
                            </li>
                          ) : null}
                            (column.id !== 'selection' &&column.id !=='action') && !column.alwaysHidden && !column.isGrouped && (
                                  <>
                                    <li className='dropdown-divider' />
                                    <li className='dropdown-item' {...column.getToggleHiddenProps()}>
                                      Hide column
                                    </li></>)

                              </ul>

                            </span>
                          )}

*/}
                        </span>
                      </div>
                      <div
                        {...(canResizeColumns && !isTouch ? column.getResizerProps() : undefined)}
                        className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      />
                      <div>
                        {canFilter &&
                          column.canFilter &&
                          column.Filter?.name !== 'DefaultColumnFilter'
                          ? column.render('Filter')
                          : null}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {isLoading ? (
                <tr>
                  <td colSpan={50} style={{ width: '100%', textAlign: 'center' }}>
                    <ReactLoading
                      className='table-loader'
                      type={appConstants.LOADER_TYPE}
                      color={appConstants.LOADER_COLOR}
                      height={appConstants.LOADER_HEIGHT}
                      width={appConstants.LOADER_WIDTH}
                    />
                  </td>
                </tr>
              ) : (
                <>
                  {rows?.length > 0 && !isLoading ? (
                    (groupUp ? flipUpGroupers(page) : page).map((row, i) => {
                      prepareRow(row);
                      return (
                        <>
                          <tr key={'row_' + row.id + '-' + i} {...row.getRowProps(rowProps(row))}>
                            {row.cells.map((cell, i2) => {
                              return (
                                (!row?.isGrouped || !(cell?.column?.groupedColSpan < 0)) && (
                                  <td
                                    colSpan={row?.isGrouped ? cell.column.groupedColSpan ?? 1 : 1}
                                    className={
                                      (['selection', 'expander', 'Action'].includes(cell.column.id)
                                        ? 'no-print'
                                        : 'defaultCustomTableRow') +
                                      ' ' +
                                      (row.isGrouped ? 'aggregatedRow' : 'normalRow')
                                    }
                                    {...cell.getCellProps({
                                      style: {
                                        color: 'inherit',
                                        a: 'inherit',
                                      },
                                      onClick: (e) => {
                                        cell?.column?.id !== 'selection' &&
                                          onRowClicked &&
                                          onRowClicked(cell, row, e);
                                      },
                                    })}
                                  >
                                    {cell.isGrouped ? (
                                      // If it's a grouped cell, add an expander and row count
                                      <div style={{ display: 'inline-flex' }}>
                                        {!cell.column?.hideGroupByExpand && (
                                          <span
                                            {...row.getToggleRowExpandedProps()}
                                            className='groupedColumnCell'
                                          >
                                            {row.isExpanded ? (
                                              <i className='fa fa-chevron-down expander' />
                                            ) : (
                                              <i className='fa fa-chevron-right expander' />
                                            )}
                                          </span>
                                        )}
                                        {((cell.value == 'string' || Array.isArray(cell.value)) &&
                                          cell.value?.length == 0) ||
                                          !cell.value
                                          ? '(None)'
                                          : cell.render('Cell')}{' '}
                                        <span className='expander'>
                                          &nbsp;({row.subRows.length})
                                        </span>
                                      </div>
                                    ) : cell.isAggregated ? (
                                      // If the cell is aggregated, use the Aggregated
                                      // renderer for cell
                                      cell.render('Aggregated')
                                    ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                                      // Otherwise, just render the regular cell
                                      cell.render('Cell')
                                    )}
                                  </td>
                                )
                              );
                            })}
                          </tr>
                          {row?.isExpanded && renderRowSubComponent && (
                            <tr>
                              <td colSpan={visibleColumns.length} style={{ width: '100%' }}>
                                {renderRowSubComponent({ row })}
                              </td>
                            </tr>
                          )}
                        </>
                      );
                    })
                  ) : (
                    <>
                      {!globalSearchTerm && !isLoading ? (
                        'No Results Found'
                      ) : (
                        <tr>
                          <td colSpan={visibleColumns.length}>
                            <ReactLoading
                              className='table-loader'
                              type={appConstants.LOADER_TYPE}
                              color={appConstants.LOADER_COLOR}
                              height={appConstants.LOADER_HEIGHT}
                              width={appConstants.LOADER_WIDTH}
                            />
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </>
              )}
            </tbody>
            {canShowFooter && rows?.length > 0 && !isLoading && (
              <tfoot>
                {footerGroups.map((group) => (
                  <tr {...group.getFooterGroupProps()}>
                    {group.headers.map((column) => (
                      <td className={'tableFooter'} {...column.getFooterProps()}>
                        {column.render('Footer')}
                      </td>
                    ))}
                  </tr>
                ))}
              </tfoot>
            )}
          </table>
        </div>
        {hasHeaderItems && canShowPagination && data?.length > state.pageSize ? (
          <div
            className='table-pagination no-print'
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              margin: 10,
            }}
          >
            <div
              className='pagination-buttons'
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <button
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
                style={styles.paginationButtons}
              >
                {'<<'}
              </button>{' '}
              <button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                style={styles.paginationButtons}
              >
                {'Prev'}
              </button>{' '}
              <button
                onClick={() => nextPage()}
                disabled={!canNextPage}
                style={styles.paginationButtons}
              >
                {'Next'}
              </button>{' '}
              <button
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
                style={styles.paginationButtons}
              >
                {'>>'}
              </button>{' '}
            </div>
            <span style={{ display: 'flex', justifyContent: 'center' }}>
              {state.pageIndex + 1} of {pageOptions.length}
            </span>

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <select
                value={state.pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                style={{ ...styles.dropdownFilter, width: 100, marginLeft: 20 }}
              >
                {pageSizeArray.map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        ) : (
          hasHeaderItems &&
          canShowPagination && (
            <div
              className='table-pagination no-print'
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                margin: 10,
              }}
            >
              <span style={{ display: 'flex', justifyContent: 'center' }}></span>

              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <select
                  value={state.pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                  style={{ ...styles.dropdownFilter, width: 100, marginLeft: 20 }}
                >
                  {pageSizeArray.map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )
        )}
      </div>
    </Styles>
  );
}

export default CustomTable;
