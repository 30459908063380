import { joiResolver } from '@hookform/resolvers/joi';
import { notification } from 'antd';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { appConstants } from '../../_constants';
import { useEffect, useRef, useState, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import {
  getCompanyEditInfo,
  getTags,
  removeCompanyProfilePicture,
  saveCompany,
  saveCompanyProfilePicture,
  validateCompanyName,
  getCompanyCustomFieldsValuesForm,
} from '../../modules/companies/index';
import AddressArray from '../../shared/form-controls/address-array/address-array';
import { HorizontalLayout } from '../../shared/form-controls/control-layouts/horizontal-layout';
import EmailArray from '../../shared/form-controls/email-array/email-array';
import { AvatarComponent } from '../../shared/form-controls/form-avatar/form-avatar';
import FormInput from '../../shared/form-controls/form-input';
import PhoneArray from '../../shared/phone-array/phone-array';
import { saveCompanySchema } from './save-company-schema';
import FormMultiSelect from '../../shared/form-controls/form-multiselect/form-multiselect';
import { CompanyCustomFields } from './company-custom-fields';
import {Userpilot} from 'userpilot';

const MemoizedAvatar = ({ pictureRef, image, onPictureUrlChange }) => {
  const [avatarUrl, setAvatarUrl] = useState('');

  useEffect(() => {
    setAvatarUrl(image);
  }, [image]);

  useEffect(() => {
    pictureRef.current.url = avatarUrl;
  }, [avatarUrl]);

  return (
    <AvatarComponent
      picture={avatarUrl}
      onPictureSelect={(picture) => {
        setAvatarUrl(picture);
        onPictureUrlChange(picture);
      }}
      onFileSelect={(file) => (pictureRef.current.file = file)}
      onFileRemove={() => {
        pictureRef.current.file = null;
        pictureRef.current.url = null;
        onPictureUrlChange('');
      }}
      isRectangular={true}
    />
  );
};

const defaultFormValues = {
  phoneArr: [{ ...appConstants.defaultFieldValues.phone, _id: uuidv4() }],
  emailArr: [{ ...appConstants.defaultFieldValues.email, _id: uuidv4() }],
  addressArr: [{ ...appConstants.defaultFieldValues.address, _id: uuidv4() }],
  companyName: '',
  companyWebsite: '',
  taxId: '',
  companyId: '',
  dba: '',
};

const AddCompanyModal = ({
  open = false,
  onCompanyUpdate = () => {},
  onClose = () => {},
  isQuickAdd = false,
  companyId = undefined,
  defaultValues = defaultFormValues,
}) => {
  const dispatch = useDispatch();
  const isUpdate = !!companyId;

  defaultValues = { ...defaultFormValues, ...defaultValues };

  const form = useForm({
    defaultValues,
    resolver: joiResolver(saveCompanySchema),
  });

  const customFieldsForm = useForm();
  const { control, reset, handleSubmit, watch, formState: { errors, isSubmitting } } = form;

  const [pictureUrl, setPictureUrl] = useState('');
  const [isMoreInfoVisible, setIsMoreInfoVisible] = useState(false);
  const pictureRef = useRef({ url: '', file: null });
  const [tags, setTags] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [customFieldsDefaultValues, setCustomFieldsDefaultValues] = useState({});

  const companyName = watch('companyName');

  useEffect(() => {
    if (open) {
      dispatch(getTags({ type: ['company'] }))
        .unwrap()
        .then(setTags);

      dispatch(getCompanyCustomFieldsValuesForm())
        .unwrap()
        .then(processCustomFields);
    }
  }, [open, dispatch]);

  useEffect(() => {
    if (companyId && open) {
      dispatch(getCompanyEditInfo({ companyId }))
        .unwrap()
        .then(updateFormWithCompanyData);
    } else {
      resetForms();
    }
  }, [companyId, open, dispatch, reset, customFieldsForm]);

  useEffect(() => {
    const debounced = _.debounce(validateCompanyNameDebounced, 500);
    debounced();
    return debounced.cancel;
  }, [companyName, companyId, dispatch]);

  const processCustomFields = (data) => {
    const defaultValuesCustomValues = data.reduce((acc, field) => {
      acc[field._id] = field.valueDefinition.isUrl
        ? { label: field.valueDefinition.defaultValue, url: field.valueDefinition.defaultValueUrl }
        : field.valueDefinition.defaultValue ?? null;
      return acc;
    }, {});

    if (!isUpdate) customFieldsForm.reset(defaultValuesCustomValues);
    setCustomFieldsDefaultValues(defaultValuesCustomValues);
    setCustomFields(data);
  };

  const updateFormWithCompanyData = (company) => {
    const formData = {
      companyId,
      companyName: company.companyName,
      companyWebsite: company.companyWebsite,
      taxId: company.taxId,
      phoneArr: company.phoneArr?.length ? company.phoneArr : defaultValues.phoneArr,
      emailArr: company.emailArr?.length ? company.emailArr : defaultValues.emailArr,
      addressArr: company.addressArr?.length ? company.addressArr : defaultValues.addressArr,
      dba: company.dba,
      companyTags: company.companyTags,
    };

    reset(formData);
    if (company.customFields) {
      customFieldsForm.reset(company.customFields);
    }else{
      customFieldsForm.reset(customFieldsDefaultValues);
    }
    setPictureUrl(company.pictureUrl);
  };

  const [resetKey, setResetKey] = useState(uuidv4());

  const resetForms = () => {
    reset(defaultValues);
    customFieldsForm.reset(customFieldsDefaultValues);
    setPictureUrl('');
    setIsMoreInfoVisible(false);
    pictureRef.current = { url: '', file: null };
    setResetKey(uuidv4());
  };

  const validateCompanyNameDebounced = async () => {
    if (!companyName) return;
    const { valid } = await dispatch(validateCompanyName({ companyName, companyId })).unwrap();
    if (valid) {
      form.clearErrors('companyName');
    } else {
      form.setError('companyName', { type: 'manual', message: 'Company name already exists' });
    }
  };

  const onSaveCompany = async (data) => {
    const cleanedData = cleanFormData(data);
    let customFieldsData = {};
    await customFieldsForm.handleSubmit(async (data) => (customFieldsData = data))();
    const saveData = { ...cleanedData, customFields: customFieldsData };

    const { _id } = await dispatch(saveCompany(saveData)).unwrap();
    await handleCompanyPicture(_id);

    notification.success({ message: 'Company saved!' });
    Userpilot.track(`${isUpdate ? 'Edited' : 'Added'} Company`);

    return _id;
  };

  const cleanFormData = (data) => {
    const { emailArr=[], phoneArr=[], addressArr=[] } = data;
    return {
      ...data,
      emailArr: emailArr.filter((item) => item.email),
      phoneArr: phoneArr.filter((item) => item.phone || item.ext),
      addressArr: addressArr.filter(doesAddressExist),
    };
  };

  const tagsMultiselectStyles = {
    control: (baseStyles) => ({
      ...baseStyles,
      "& > div:nth-of-type(2)": {
        display: "none"
      },
      "&:focus-within > div:nth-of-type(2)": {
        display: "flex"
      },
    }),
  };

  const addressArr = watch('addressArr', []);
  const dba = watch('dba');
  const companyWebsite = watch('companyWebsite');
  const taxId = watch('taxId');

  const doesAddressExist = (address) => 
    address.address1 || address.address2 || address.city || address.country || address.state || address.zipCode;

  const doesAdditionalInfoExist = useMemo(
    () => (addressArr.length > 0 && doesAddressExist(addressArr[0])) || dba || companyWebsite || taxId,
    [addressArr, dba, companyWebsite, taxId]
  );

  useEffect(() => {
    if (doesAdditionalInfoExist && !isMoreInfoVisible) {
      setIsMoreInfoVisible(true);
    }
  }, [doesAdditionalInfoExist, isMoreInfoVisible]);

  const handleCompanyPicture = async (companyId) => {
    if (pictureRef.current.file) {
      await dispatch(saveCompanyProfilePicture({ companyId, file: pictureRef.current.file }));
    } else if (!pictureRef.current.url) {
      await dispatch(removeCompanyProfilePicture(companyId));
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      const companyId = await onSaveCompany(data);
      await onCompanyUpdate({ companyId });
      onClose();
    } catch (error) {
      notification.error({ message: 'Failed to save company' });
    }
  });

  const onSubmitAndAdd = handleSubmit(async (data) => {
    try {
      await onSaveCompany(data);
      resetForms();
    } catch (error) {
      notification.error({ message: 'Failed to save company' });
    }
  });

  const onCloseClick = () => {

    onClose();
    resetForms();
  };


  const addMoreInfoLinkStyles = { color: '#0d6efd' };

  const validationError = useMemo(() => {
    const errors = [
      ...Object.values(form.formState.errors),
      ...Object.values(customFieldsForm.formState.errors),
    ];

    return <span className='text-danger'>{errors[0]?.message}</span>;
  }, [form.formState.errors, customFieldsForm.formState.errors]);

  const [visibleFields, hiddenFields] = useMemo(() => {
    const visibleFields = customFields.filter(({ hideInitially }) => !hideInitially);
    const hiddenFields = customFields.filter(({ hideInitially }) => hideInitially);

    return [visibleFields, hiddenFields];
  }, [customFields]);


  return (
    <>
      <Modal
        style={{ maxWidth: 800, width: '100%' }}
        backdrop='static'
        isOpen={open}
        toggle={onCloseClick}
      >
        <ModalHeader toggle={onCloseClick}>
          {isUpdate ? 'Edit company details' : 'Add company'}
        </ModalHeader>
        <ModalBody>
          <FormProvider {...form}>
            <div className='container d-flex flex-column gap-2' key={resetKey}>
              <div className='row mb-3'>
                <div className='col-sm-3 col mb-2'>
                  <MemoizedAvatar pictureRef={pictureRef} image={pictureUrl} onPictureUrlChange={(picture) => setPictureUrl(picture)} />
                </div>
                <div className='col-md-8 fs-5 d-flex flex-column gap-4 align-items-start'>
                  <span className='fw-bolder fs-4'>{companyName || '\u200b'}</span>
                  <div className='col-md-12 col-12'>
                    <FormMultiSelect
                      options={tags}
                      name='companyTags'
                      creatable
                      control={control}
                      placeholder="Categories"
                      styles={tagsMultiselectStyles}
                      getOptionLabel={(opt) => opt.__isNew__ ? opt.label : opt.tagName}
                      getOptionValue={(opt) => opt.tagName}
                      getExtraValue={(input) => ({ tagName: input, label: '' })}
                    />
                  </div>
                </div>
              </div>
              <FormInput name='companyName' label='Company Name' placeholder='Company Name' control={control} labelWidth={3} controlWidth={8} Layout={HorizontalLayout} />
              <PhoneArray name='phoneArr' />
              <EmailArray name='emailArr' />
              <AddressArray name='addressArr' />
              <FormProvider {...customFieldsForm}>
                <CompanyCustomFields fields={visibleFields} />
              </FormProvider>
              {isMoreInfoVisible
                ? <>
                    <FormInput name='dba' label='DBA' placeholder='DBA' control={control} labelWidth={3} controlWidth={8} Layout={HorizontalLayout} />
                    <FormInput name='companyWebsite' label='Website' placeholder='Website' control={control} labelWidth={3} controlWidth={8} Layout={HorizontalLayout} />
                    <FormInput name='taxId' label='Tax ID (EIN)' placeholder='Tax ID (EIN)' control={control} labelWidth={3} controlWidth={8} Layout={HorizontalLayout} />
                    <FormProvider {...customFieldsForm}>
                      <CompanyCustomFields fields={hiddenFields} />
                    </FormProvider>
                  </>
                : <div role='button' className='my-2' style={addMoreInfoLinkStyles} onClick={() => setIsMoreInfoVisible(!isMoreInfoVisible)}>
                    Add more company information
                    <i className='fa-solid fa-chevron-down ms-2'></i>
                  </div>
              }
            </div>
          </FormProvider>
        </ModalBody>
        <ModalFooter>
          {validationError}
          <Button disabled={isSubmitting} color='secondary' onClick={onCloseClick}>
            Cancel
          </Button>
          <Button color='primary' onClick={onSubmit} disabled={isSubmitting}>
            {isSubmitting ? 'Submitting' : 'Save'}
          </Button>
          {!isUpdate && !isQuickAdd && <Button color='primary' onClick={onSubmitAndAdd} disabled={isSubmitting}>
            {isSubmitting ? 'Submitting' : 'Save & Add Next Company'}
          </Button>}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddCompanyModal;
