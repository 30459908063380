import { Provider } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { useAuth as useAuth } from '../components/Auth/Auth';
import Callback from '../components/Callback/Callback';
import CallbackAuth from '../components/Callback/Callback-auth';
import CompaniesList from '../components/Company/companies-list';
import ContactsList from '../components/Contact/contacts-list';
import Home from '../components/Home/Home';
import NotificationsActivity from '../pages/notifications-page/notifications-page';
import TeamActivity from '../pages/team-activity-page/team-activity-page';
import JobStatusLegacy from '../components/Masters/jobStatusLegacy/list';
import ReasonCodes from '../components/Masters/reasonCodes/list';
import ChangeUserPassword from '../components/Profile/change-password';
import UserInfoPage from '../pages/settings/user-info-page/user-info-page';
import DashboardPage from '../pages/dashboard-page';
import NotificationPreferences from '../pages/preferences/notification-preferences-page/notification-preferences';
import ProjectLeadList from '../components/Projects/projects-list';
import ViewProjectDetails from '../components/Projects/view-project-details';
import history from '../routes/history';
import UserList from '../components/Admin/AccountListPage/UsersListPage';
import AppStoreDemo from '../components/Company/app-store-demo';
import EmployeeList from '../components/Company/employees-list';
import LoginRedirection from '../components/Company/login-redirection';
import ViewCompanyDetails from '../components/Company/view-company-detail';
import ViewContactDetails from '../components/Contact/view-contact-detail';
import { VerifyPhonePage } from '../pages/verify-phone-page/verify-phone-page';
import PageNotFound from '../components/Layout/PageNotFound';
import LogInError from '../components/Layout/LogInError';
import SearchPage from '../components/Layout/search-list';
import CustomFields from '../components/Masters/customFields/list';
import HelpViewList from '../components/Masters/help/helpView';
import OnboardCompanyAdd from '../components/Onboarding/CompanyAdd';
import OnboardContactAdd from '../components/Onboarding/ContactAdd';
//import ViewContactOutlookView from '../components/Onboarding/outlookContactView';
import { ImportProjectPage } from '../pages/import-projects-page';
import { EditPendingProjectImportPage } from '../pages/edit-pending-project-import-page';
//import SocialContactView from '../components/Onboarding/socialContactView';
import OnboardHome from '../components/Onboarding/Welcome';
import TermAndConditions from '../components/ProjectDemo/TermAndConditions';
import ProjectTask from '../components/Projects/tasks';
import ViewLeadDetails from '../components/Projects/view-lead-details';
import CompanyJobListReport from '../components/Reports/active/company-job-list-report';
import ContactJobListReport from '../components/Reports/active/contact-job-list-report';
import ProfitPerWeek from '../components/Reports/active/profit-per-week';
import ProfitProjectionReport from '../components/Reports/active/profit-projection-report';
import ProfitSalesReport from '../components/Reports/active/profit-sales-report';
import ProjectHistoryReport from '../components/Reports/active/project-history-report';
import ProjectInfoHotSheetReport from '../components/Reports/active/project-info-hotsheet';
import ProjectPrelimInfoReport from '../components/Reports/active/project-prelim-info-report';
import ProjectTimelineReport from '../components/Reports/active/project-timeline-report';
import ResourceScheduleReportClassic from '../components/Reports/active/resource-schedule-report-classic';
import TimeSheetReport from '../components/Reports/active/timesheet-report';
import BidResultRecapReport from '../components/Reports/bid-pending/bid-result-recap-report';
import BidHistoryReport from '../components/Reports/bidding/bid-history-report';
import ClientReferenceReport from '../components/Reports/completed/client-references-report';
import DataCheckReport from '../components/Reports/completed/data-check-report'
import MasterProjectLogReport from '../components/Reports/completed/master-project-log-report';
import AddressLabelReport from '../components/Reports/lead/address-label-report';
import ClientListReport from '../components/Reports/lead/client-list-report';
import LeadSourceReport from '../components/Reports/lead/lead-source-report';
import PlanPage from '../components/webPages/planPage';
import WipViewList from '../components/wip/wipView';
import MainLayout from '../layouts/main-layout';
import SettingsLayout from '../layouts/settings-layout/settings-layout';
import { TitleProvider } from '../layouts/title-context';
import store from '../modules/store';
import CalendarPage from '../pages/calendar-page';
import { EstimatingScheduleReportPage } from '../pages/estimating-schedule-report-page';
import ImportCompaniesView from '../pages/import-companies-view';
import ImportContactsView from '../pages/import-contacts-view';
import ImportProjectsView from '../pages/import-projects-view';
import PermissionsPage from '../pages/permissions-page';
import { BillingInfoPage } from '../pages/settings/billing-info-page';
import { CompanyInfoPage } from '../pages/settings/company-info-page';
import { OverheadCostsPage } from '../pages/settings/company-finance-page';
import { JobStatusCodesPage } from '../pages/settings/job-status-codes-page';
import { ProjectProfitSettings } from '../pages/settings/project-profit-settings';
import { ConfirmationDialogProvider } from '../shared/confirmation-dialog';
import { ActiveScheduleReportPage } from '../pages/active-schedule-report-page';
import { AppConnectionsPage } from '../pages/settings/app-connections-page/app-connections-page';
import { ProjectCustomFieldsPage } from '../pages/settings/project-custom-fields-page';
import { ContactCustomFieldsPageNext } from '../pages/settings/contact-custom-fields-page/contact-custom-fields-page-next';
import { ContactRolesPage } from '../pages/settings/contact-roles-page/contact-roles-page';
import { JobScopesPage } from '../pages/settings/job-scopes-page';
import { BuildingTypesPage } from '../pages/settings/building-types-page';
import { NegotiatingMethodsPage } from '../pages/settings/negotiating-methods-page';
import { OccupancyTypesPage } from '../pages/settings/occupancy-types-page';
import { PreferencesLayout } from '../layouts/preferences-layout';
import { ProjectRolesPage } from '../pages/settings/project-roles-page';
import { LineItemsPage } from '../pages/settings/line-items-page';
import { UserRolesPage } from '../pages/settings/user-roles-page';
import { ReportSettingsPage } from '../pages/settings/report-settings-page';
import { NavigationProvider } from '../shared/navigation-provider';
import { ReportViewPage } from '../pages/report-view-page/report-view-page';
import { isAllowed, reportAccess } from '../_constants';
import AccessDenied from '../components/Layout/access-denied';

import { HelmetProvider } from 'react-helmet-async';
import { FormTemplatesPage } from '../pages/settings/form-templates-page';

const handleAuthentication = ({ location }) => {
  if (/access_token|id_token|error/.test(location.hash)) {
    const { handleAuthentication } = useAuth();
    handleAuthentication();
  }
};

export const makeMainRoutes = () => {
  const { isAuthenticated, login } = useAuth();

  return (
    <Provider store={store}>
      <ConfirmationDialogProvider>
        <NavigationProvider>
          <HelmetProvider>
            <Router history={history}>
              <div className='routesBox'>
                <Switch>
                  <Route
                    exact
                    path='/'
                    render={(props) =>
                      isAuthenticated(props) ? (
                        <Redirect to='/home' />
                      ) : (
                        <Redirect to='/login' />
                      )
                    }
                  />
                  <Route
                    exact
                    path='/index.html'
                    render={(props) =>
                      isAuthenticated(props) ? (
                        <Redirect to='/home' />
                      ) : (
                        <Redirect to='/login' />
                      )
                    }
                  />

                  
                  <Route
                    exact
                    path='/home'
                    render={(props) =>
                      isAuthenticated(props) ? (
                        <Home useAuth={useAuth} {...props} />
                      ) : (
                        <Redirect to='/login' />
                      )
                    }
                  />
                  <Route path='/login' render={(props) => login(props)} />
                  <Route
                    path='/logout'
                    render={(props) => (useAuth().logout(props) ? <Redirect to='/' /> : '')}
                  />
                  <Route
                    path='/error'
                    render={(props) => <LogInError useAuth={useAuth} {...props} />}
                  />
                  <Route
                    path='/callback-auth'
                    render={(props) => <CallbackAuth useAuth={useAuth} {...props} />}
                  />
                  <Route
                    path='/callback'
                    render={(props) => {
                      handleAuthentication(props);
                      return <Callback useAuth={useAuth} {...props} />;
                    }}
                  />
                  <Route
                    path='/notifications'
                    render={(props) => <NotificationsActivity useAuth={useAuth} {...props} />}
                  />
                  <Route
                    path='/team-activity'
                    render={(props) => <TeamActivity useAuth={useAuth} {...props} />}
                  />
                  <Route
                    path='/view-contacts'
                    render={(props) => <ContactsList useAuth={useAuth} {...props} />}
                  />
                  <Route
                    path='/view-companies'
                    render={(props) => <CompaniesList useAuth={useAuth} {...props} />}
                  />

                  <Route
                    path='/view-employees'
                    render={(props) => <EmployeeList useAuth={useAuth} {...props} />}
                  />

                  <Route
                    path='/calendar/:viewMode/:year/:month/:date'
                    render={(props) => <CalendarPage {...props} />}
                  />
                  <Route
                    path='/calendar/:viewMode/:year/:month'
                    render={(props) => <CalendarPage {...props} />}
                  />
                  <Route
                    path='/calendar/:viewMode/:year'
                    render={(props) => <CalendarPage {...props} />}
                  />
                  <Route path='/calendar' render={(props) => <CalendarPage {...props} />} />

                  <Route
                    path='/legacy/tasks'
                    render={(props) => <ProjectTask useAuth={useAuth} {...props} />}
                  />

                  <Route
                    path='/projects-list/:viewMode'
                    render={(props) => <ProjectLeadList {...props} />}
                  />
                  <Route path='/projects-list' render={(props) => <ProjectLeadList {...props} />} />
                  <Route path='/lead-list' render={(props) => <ProjectLeadList {...props} />} />

                  <Route
                    path='/project-details/:objectId?'
                    render={(props) => (
                      <ViewProjectDetails
                        key={props.match.params.objectId}
                        useAuth={useAuth}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path='/lead-details/:objectId?'
                    render={(props) => (
                      <ViewLeadDetails
                        key={props.match.params.objectId}
                        useAuth={useAuth}
                        {...props}
                      />
                    )}
                  />

                  <Route
                    path='/search/contacts/:searchText'
                    render={(props) => (
                      <SearchPage
                        searchType={'CONTACT'}
                        searchText={props.match.params.searchText}
                        useAuth={useAuth}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path='/search/companies/:searchText'
                    render={(props) => (
                      <SearchPage
                        searchType={'COMPANY'}
                        searchText={props.match.params.searchText}
                        useAuth={useAuth}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path='/search/projects/:searchText'
                    render={(props) => (
                      <SearchPage
                        searchType={'PROJECT'}
                        searchText={props.match.params.searchText}
                        useAuth={useAuth}
                        {...props}
                      />
                    )}
                  />

                  <Route
                    path='/contact-details/:objectId?'
                    render={(props) => (
                      <ViewContactDetails objectId={props.match.params.objectId} {...props} />
                    )}
                  />


                  <Route
                    path='/client-list-report'
                    render={(props) => <ClientListReport useAuth={useAuth} {...props} />}
                  />
                  <Route
                    path='/address-label-report'
                    render={(props) => <AddressLabelReport useAuth={useAuth} {...props} />}
                  />
                  <Route
                    path='/lead-source-report'
                    render={(props) => <LeadSourceReport useAuth={useAuth} {...props} />}
                  />

                  <Route
                    path='/contact-job-list-report'
                    render={(props) => <ContactJobListReport useAuth={useAuth} {...props} />}
                  />
                  <Route
                    path='/company-job-list-report'
                    render={(props) => <CompanyJobListReport useAuth={useAuth} {...props} />}
                  />
                  <Route
                    path='/project-prelim-info/:objectId?'
                    render={(props) => (
                      <ProjectPrelimInfoReport
                        useAuth={useAuth}
                        projectId={props.match.params.objectId}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path='/project-info-hot-sheet/:objectId?'
                    render={(props) => (
                      <ProjectInfoHotSheetReport
                        useAuth={useAuth}
                        projectId={props.match.params.objectId}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path='/bid-history-report/:objectId?'
                    render={(props) => (
                      <BidHistoryReport
                        useAuth={useAuth}
                        projectId={props.match.params.objectId}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path='/timesheet-report'
                    render={(props) => <TimeSheetReport useAuth={useAuth} {...props} />}
                  />
                  <Route
                    path='/project-history-report/:objectId?'
                    render={(props) => (
                      <ProjectHistoryReport
                        useAuth={useAuth}
                        projectId={props.match.params.objectId}
                        {...props}
                      />
                    )}
                  />


                  <Route
                    path='/welcome'
                    render={(props) => <OnboardHome useAuth={useAuth} {...props} />}
                  />
                  <Route
                    path='/import-companies'
                    render={(props) => <OnboardCompanyAdd useAuth={useAuth} {...props} />}
                  />
                  <Route
                    path='/import-contacts'
                    render={(props) => <OnboardContactAdd useAuth={useAuth} {...props} />}
                  />
                  <Route
                    path='/import-projects'
                    render={(props) => <ImportProjectPage useAuth={useAuth} {...props} />}
                  />

                  <Route path='/import-contacts-view' component={() => <ImportContactsView />} />
                  <Route path='/import-companies-view' component={() => <ImportCompaniesView />} />

                  <Route path='/import-projects-view' component={() => <ImportProjectsView />} />

                  <Route
                    path='/edit-pending-project-uploads'
                    component={() => <EditPendingProjectImportPage />}
                  />

                  {/*<Route
                    path='/legacy/social-contact-view'
                    render={(props) => <SocialContactView useAuth={useAuth} {...props} />}
                  />
                  <Route
                    path='/legacy/view-contact-outlook'
                    render={(props) => <ViewContactOutlookView useAuth={useAuth} {...props} />}
                  />*/}

                  <Route
                    path='/legacy/help-view'
                    render={(props) => <HelpViewList useAuth={useAuth} {...props} />}
                  />
                  <Route
                    path='/legacy/wip-view'
                    render={(props) => <WipViewList useAuth={useAuth} {...props} />}
                  />
                  <Route
                    path='/legacy/plans'
                    render={(props) => <PlanPage useAuth={useAuth} {...props} />}
                  />
                  <Route
                    exact
                    path='/legacy/app-store-demo'
                    render={(props) => <AppStoreDemo useAuth={useAuth} {...props} />}
                  />
                  <Route
                    path='/terms-and-conditions'
                    render={(props) => <TermAndConditions useAuth={useAuth} {...props} />}
                  />

                  <Route
                    path='/auth0-login'
                    render={(props) => <LoginRedirection useAuth={useAuth} {...props} />}
                  />

                  {/* Legacy reports section */}
                 
                 
                  <Route
                    path='/bid-result-recap-report'
                    render={(props) => <BidResultRecapReport useAuth={useAuth} {...props} />}
                  />
                 
                  <Route
                    path='/project-timeline-report'
                    render={(props) => <ProjectTimelineReport {...props} />}
                  />
                  <Route
                    path='/resource-schedule-report-classic'
                    render={(props) => (
                      <ResourceScheduleReportClassic useAuth={useAuth} {...props} />
                    )}
                  />
                  <Route
                    path='/profit-projection-report'
                    render={(props) => <ProfitProjectionReport useAuth={useAuth} {...props} />}
                  />
                  <Route
                    path='/profit-sales-report'
                    render={(props) => <ProfitSalesReport {...props} />}
                  />
                  <Route
                    path='/profit-per-week'
                    render={(props) => <ProfitPerWeek useAuth={useAuth} {...props} />}
                  />
                  <Route
                    path='/client-references-report'
                    render={(props) => <ClientReferenceReport useAuth={useAuth} {...props} />}
                  />
                  <Route
                    path='/data-check-report'
                    render={(props) => <DataCheckReport useAuth={useAuth} {...props} />}
                  />

                  <Route
                    path='/master-project-report'
                    render={(props) => <MasterProjectLogReport useAuth={useAuth} {...props} />}
                  />

                  <Route
                    path={[
                      '/estimating-schedule-report',
                      '/active-schedule-report',
                      '/resource-schedule-report',
                    ]}
                    render={() => (
                      <TitleProvider>
                        <MainLayout showFooter={false}>
                          <Switch>
                            <Route
                              path='/estimating-schedule-report'
                              render={(props) => <EstimatingScheduleReportPage />}
                            />
                            <Route
                              path='/active-schedule-report'
                              render={(props) => <ActiveScheduleReportPage />}
                            />
                            <Route
                              path='/resource-schedule-report'
                              render={(props) => <ActiveScheduleReportPage />}
                            />
                          </Switch>
                        </MainLayout>
                      </TitleProvider>
                    )}
                  />

                  <Route
                    path={['/reports/:menuSection/:reportId_params']}
                    render={() => (
                      <TitleProvider>
                        <MainLayout>
                          <Switch>
                            <Route
                              path='/reports/:menuSection/:reportId_params'
                              render={(props) => <ReportViewPage />}
                            />
                          </Switch>
                        </MainLayout>
                      </TitleProvider>
                    )}
                  />

                  <Route
                    path={['/report-view/:menuSection/:reportId_params']}
                    render={(props) => {
                      const { menuSection } = props.match.params;

                      return (
                        <TitleProvider>
                          <MainLayout menuSection={menuSection}>
                            <Switch>
                              <Route
                                path='/report-view/:menuSection/:reportId_params'
                                render={(props) => <ReportViewPage />}
                              />
                            </Switch>
                          </MainLayout>
                        </TitleProvider>
                      );
                    }}
                  />

                  <Route
                    path={['/report-view/:reportId_params']}
                    render={() => (
                      <TitleProvider>
                        <MainLayout>
                          <Switch>
                            <Route
                              path='/report-view/:reportId_params'
                              render={(props) => <ReportViewPage />}
                            />
                          </Switch>
                        </MainLayout>
                      </TitleProvider>
                    )}
                  />

                  <Route
                    path={[
                      '/settings/company-info',
                      '/settings/company-finance',
                      '/settings/project-profit-settings',
                      '/settings/contact-role',
                      '/settings/contact-role-test',
                      '/settings/occupancy-type',
                      '/settings/negotiation-method',
                      '/settings/building-type',
                      '/settings/job-scope',
                      '/settings/role-types',
                      '/settings/permissions',
                      '/settings/project-roles',
                      '/settings/line-items',
                      '/settings/job-status',
                      '/settings/job-status-legacy',
                      '/settings/billing',
                      '/settings/app-connections',
                      '/settings/project-custom-fields',
                      '/settings/contact-custom-fields',
                      '/settings/contact-custom-fields-test',
                      '/settings/report-settings',
                      '/settings/custom-field',
                      '/settings/reason-codes',
                      '/settings/users-role',
                      '/settings/form-templates'
                    ]}
                    render={() =>
                      !isAllowed(reportAccess.ACCOUNTSETTINGS) ? (
                        <AccessDenied />
                      ) : (
                        <TitleProvider>
                          <MainLayout>
                            <Switch>
                              <SettingsLayout>
                                <Route
                                  path='settings/job-status-legacy'
                                  render={(props) => (
                                    <JobStatusLegacy useAuth={useAuth} {...props} />
                                  )}
                                />
                                <Route
                                  path='/settings/company-info'
                                  render={(props) => (
                                    <CompanyInfoPage pageTitle='Company Profile' />
                                  )}
                                />
                                <Route
                                  path='/settings/company-finance'
                                  render={(props) => (
                                    <OverheadCostsPage pageTitle='Overhead & Variable Cost Settings' />
                                  )}
                                />
                                <Route
                                  path='/settings/billing'
                                  render={(props) => (
                                    <BillingInfoPage pageTitle='Billing & Subscription' />
                                  )}
                                />
                                <Route
                                  path='/settings/project-profit-settings'
                                  render={(props) => (
                                    <ProjectProfitSettings pageTitle='Project Settings' />
                                  )}
                                />
                                <Route
                                  path='/settings/contact-role'
                                  render={(props) => <ContactRolesPage />}
                                />
                                <Route
                                  path='/settings/occupancy-type'
                                  render={(props) => <OccupancyTypesPage />}
                                />
                                <Route
                                  path='/settings/negotiation-method'
                                  render={(props) => <NegotiatingMethodsPage />}
                                />
                                <Route
                                  path='/settings/building-type'
                                  render={(props) => <BuildingTypesPage />}
                                />
                                <Route
                                  path='/settings/job-scope'
                                  render={(props) => <JobScopesPage />}
                                />
                                <Route
                                  path='/settings/role-types'
                                  render={(props) => <UserRolesPage />}
                                />
                                <Route
                                  path='/settings/project-roles'
                                  render={() => <ProjectRolesPage />}
                                />
                                <Route
                                  path='/settings/line-items'
                                  render={() => <LineItemsPage />}
                                />
                                <Route
                                  path='/settings/job-status'
                                  render={() => <JobStatusCodesPage />}
                                />
                                <Route
                                  path='/settings/permissions'
                                  render={() => <PermissionsPage />}
                                />
                                <Route
                                  path='/settings/app-connections'
                                  render={() => <AppConnectionsPage />}
                                />
                                <Route
                                  path='/settings/project-custom-fields'
                                  render={() => <ProjectCustomFieldsPage />}
                                />
                                <Route
                                  path='/settings/contact-custom-fields'
                                  render={() => <ContactCustomFieldsPageNext />}
                                />
                                <Route
                                  path='/settings/report-settings'
                                  render={() => <ReportSettingsPage />}
                                />
                                <Route
                                  path='/settings/report-settings/:reportId_params'
                                  render={(props) => <ReportSettingsPage initialReportId= {props.match.params.reportId_params} />}
                                />
                                <Route
                                  path='/settings/custom-field'
                                  render={(props) => <CustomFields useAuth={useAuth} {...props} />}
                                />
                                <Route
                                  path='/settings/reason-codes'
                                  render={(props) => <ReasonCodes useAuth={useAuth} {...props} />}
                                />
                                <Route
                                  path='/settings/users-role'
                                  render={(props) => <UserList useAuth={useAuth} {...props} />}
                                />
                                <Route
                                  path='/settings/form-templates'
                                  render={(props) => <FormTemplatesPage {...props} />}
                                />
                              </SettingsLayout>
                            </Switch>
                          </MainLayout>
                        </TitleProvider>
                      )
                    }
                  />

                  <Redirect exact from='/preferences' to={`/preferences/account`} />
                  <Route
                    path='/preferences/change-password'
                    render={(props) => <ChangeUserPassword useAuth={useAuth} {...props} />}
                  />
                  <Route
                    path='/preferences/account'
                    render={(props) => <UserInfoPage useAuth={useAuth} {...props} />}
                  />

                  <Route
                    path={['/preferences/notifications', '/preferences/dashboard']}
                    render={() => (
                      <TitleProvider>
                        <MainLayout>
                          <Switch>
                            <PreferencesLayout>
                              <Route
                                path='/preferences/notifications'
                                render={(props) => (
                                  <NotificationPreferences useAuth={useAuth} {...props} />
                                )}
                              />
                              <Route
                                path='/preferences/dashboard'
                                render={(props) => <DashboardPage useAuth={useAuth} {...props} />}
                              />
                            </PreferencesLayout>
                          </Switch>
                        </MainLayout>
                      </TitleProvider>
                    )}
                  />
                 <Route path="/company-details/:companyId/:tab?/:itemId?/:action?">
                  <ViewCompanyDetails />
                </Route>

                  <Route path='/verify-phone/:token' render={() => <VerifyPhonePage />} />

                  <Route exact path='*' render={() => <PageNotFound />} />
                </Switch>
              </div>
            </Router>
          </HelmetProvider>
        </NavigationProvider>
      </ConfirmationDialogProvider>
    </Provider>
  );
};
