import _, {set} from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ReactLoading from 'react-loading';
import { useBlockLayout, useSortBy, useTable } from 'react-table';
import '../data-grid/data-grid-table.css';
import useBoundingRect from '../hooks/use-bounding-rect';
import { appConstants } from '../../_constants';
import styles from './settings-table.module.scss';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { getScrollbarWidth } from '../../utils/get-scrollbar-width';

const dndColumn = {
  id: 'dnd',
  width: 40,
  Cell: ({ row, dragHandleProps }) => {
    if (row.original.isGroup) return null;

    return (
      <span {...dragHandleProps} className='w-100 h-100 d-flex align-items-center ms-2'>
        <i className='fa fa-bars' aria-hidden='true' />
      </span>
    );
  },
};

/**
 *
 * @param {{
 *   columns: any[],
 *   data: any[],
 *   itemHeight?: number,
 *   isLoading?: boolean,
 *   limitHeight?: boolean,
 *   onRowClicked?: (row: any) => void,
 *   searchKeyword?: string,
 *   onDragEnd?: ({ item, section, index }) => void,
 *   dndOrder?: (row: any) => number,
 *   getSubRows?: (row: any) => any[],
 *   rowClassName?: (row: any) => string,
 *   controlledState?: any,
 *  }} props
 */
export const EditableTable = ({
  columns = [],
  data = [],
  itemHeight = 40,
  isLoading = false,
  limitHeight = false,
  onRowClicked = (row) => {},
  searchKeyword = '',
  onDragEnd,
  dndOrder = (row) => row.order,
  getSubRows,
  rowClassName = () => '',
  controlledState = {},
}) => {
  const [preparedData, setPreparedData] = useState([]);

  const { wrapperRef, height } = useBoundingRect();

  const subRowModeEnabled = useMemo(() => {
    return !!getSubRows;
  }, [getSubRows]);

  const totalRowsHeight = useMemo(() => {
    if (!subRowModeEnabled) return preparedData.length * itemHeight;

    return preparedData.reduce((acc, section) => {
      return acc + getSubRows(section).length * itemHeight;
    }, 0);
  }, [preparedData, subRowModeEnabled]);

  const bodyHeight = useMemo(() => {
    return height - itemHeight - 2;
  }, [height, itemHeight]);

  const shouldShowScrollbar = useMemo(() => {
    return totalRowsHeight > bodyHeight;
  }, [totalRowsHeight, bodyHeight]);

  const dndEnabled = useMemo(() => {
    return !!onDragEnd;
  }, [onDragEnd]);

  // prepare sorted data for dnd
  useEffect(() => {
    if (!dndEnabled) return;

    const newData = _.cloneDeep(data);

    if (!subRowModeEnabled) {
      newData.sort((a, b) => {
        return dndOrder(a) - dndOrder(b);
      });
    } else {
      newData.forEach((section) => {
        getSubRows(section).sort((a, b) => {
          return dndOrder(a) - dndOrder(b);
        });
      });
    }

    setPreparedData(newData);
  }, [data]);

  // allow filtering by search keyword if dnd is disabled
  useEffect(() => {
    if (dndEnabled) return;

    const newData = _.cloneDeep(data);

    if (!searchKeyword) return setPreparedData(newData);

    const filterRow = (row) =>
      Object.entries(row)
        .map(([key, value]) => {
          const column = columns.find((c) => c.accessor === key);

          if (!column?.searchable) return false;

          const getSearchValue = column.getSearchValue ?? ((value) => value);

          return getSearchValue(value)
            .toString()
            .toLowerCase()
            .includes(searchKeyword.toLowerCase());
        })
        .some((isTrue) => isTrue);

    if (!subRowModeEnabled) {
      const filteredData = newData.filter(filterRow);

      setPreparedData(filteredData);
    } else {
      newData.forEach((section) => {
        const filteredData = getSubRows(section).filter(filterRow);

        set(section, 'subRows', filteredData);
      });

      setPreparedData(newData);
    }
  }, [searchKeyword, data, columns, subRowModeEnabled, dndEnabled]);

  const preparedColumns = useMemo(() => {
    const result = [...columns];

    if (dndEnabled) {
      result.unshift(dndColumn);
    }

    return result;
  }, [columns, dndEnabled]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, totalColumnsWidth } =
    useTable(
      {
        columns: preparedColumns,
        data: preparedData,
        useControlledState: (state) => ({
          ...state,
          ...controlledState,
        }),
        getSubRows,
      },
      useSortBy,
      useBlockLayout
    );

  const bodyWidth = useMemo(() => {
    return (shouldShowScrollbar && limitHeight) ? totalColumnsWidth + getScrollbarWidth() : totalColumnsWidth;
  }, [shouldShowScrollbar, totalColumnsWidth, limitHeight]);

  const dragElement = (props) => {
    const { source, destination } = props;

    if (
      !destination ||
      (source.droppableId === destination.droppableId && source.index === destination.index)
    ) {
      return;
    }

    let item = null;

    if (!subRowModeEnabled) {
      setPreparedData((prev) => {
        const newPreparedData = _.cloneDeep(prev);

        [item] = newPreparedData.splice(source.index, 1);

        newPreparedData.splice(destination.index, 0, item);

        return newPreparedData;
      });
    } else {
      setPreparedData((prev) => {
        const newPreparedData = _.cloneDeep(prev);

        const sourceSectionName = source.droppableId;
        const destinationSectionName = destination.droppableId;

        const sourceSection = newPreparedData.find(
          (section) => section.section === sourceSectionName
        );
        const destinationSection = newPreparedData.find(
          (section) => section.section === destinationSectionName
        );

        [item] = getSubRows(sourceSection).splice(source.index, 1);
        getSubRows(destinationSection).splice(destination.index, 0, item);

        return newPreparedData;
      });
    }

    onDragEnd({
      item,
      source,
      destination,
    });
  };

  const getSubRowContent = useCallback(
    (subRow, i) => {
      prepareRow(subRow);

      const subRowProps = subRow.getRowProps();

      const renderer = (provided) => {
        return (
          <div
            className={`is-edit-table ${styles.row} ${rowClassName(subRow)}`}
            {...subRowProps}
            {...provided?.draggableProps}
            ref={provided?.innerRef}
          >
            {subRow.cells.map((cell, index) => {
              const cellProps = cell.getCellProps({
                style: {
                  height: 40,
                },
                onClick: (e) => onRowClicked(subRow.original),
              });

              return (
                <div
                  {...cellProps}
                  className={`${styles.cell} is-edit-table-cell`}
                  data-last-cell={index === subRow.cells.length - 1}
                >
                  {cell.render('Cell', {
                    dragHandleProps: provided?.dragHandleProps,
                  })}
                </div>
              );
            })}
          </div>
        );
      };

      if (dndEnabled)
        return (
          <Draggable draggableId={`${subRow.id}`} key={subRow.id} index={subRow.index}>
            {renderer}
          </Draggable>
        );

      return renderer();
    },
    [dndEnabled, onRowClicked, prepareRow, rows, subRowModeEnabled]
  );

  const getRowContent = useCallback(
    (row, i) => {
      prepareRow(row);

      const rowProps = row.getRowProps();

      const rowContent = (
        <div className={`is-edit-table ${styles.row}`} {...rowProps}>
          {row.cells.map((cell, index) => {
            const cellProps = cell.getCellProps({
              style: {
                height: 40,
              },
            });

            return (
              <div
                {...cellProps}
                className={`${styles.cell} border-0`}
                data-last-cell={index === row.cells.length - 1}
              >
                {cell.render('Cell')}
              </div>
            );
          })}
        </div>
      );

      const subRowsContent = row.subRows.map(getSubRowContent);

      const renderer = (provided) => (
        <div ref={provided?.innerRef} {...provided?.droppableProps}>
          {rowContent}
          {subRowsContent}
          {provided?.placeholder}
        </div>
      );

      if (dndEnabled)
        return (
          <Droppable droppableId={`${row.original.section}`} key={row.original.section}>
            {renderer}
          </Droppable>
        );

      return renderer();
    },
    [dndEnabled, getSubRowContent, prepareRow, rows, subRowModeEnabled]
  );

  const getHeaderContent = useCallback(() => {
    return (
      <thead style={{ backgroundColor: 'rgb(245,245,245)' }}>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps({
                  style: {
                    paddingLeft: 6,
                  },
                  className: column.headerClassName,
                })}
              >
                <span>{column.render('Header')}</span>
                {!dndEnabled && (
                  <>
                    <span {...column.getSortByToggleProps()}>
                      {column.id !== 'trash' && column.canSort && (
                        <span className='ms-1'>
                          <i
                            className={`fa fa-sort${
                              column.isSorted ? (column.isSortedDesc ? '-desc' : '-asc') : ''
                            }`}
                            aria-hidden='true'
                          />
                        </span>
                      )}
                    </span>
                  </>
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
    );
  }, [headerGroups, dndEnabled]);

  const getTableBodyContent = useCallback(() => {
    const renderer = (provided) => (
      <div ref={provided?.innerRef} {...provided?.droppableProps}>
        {rows.map(subRowModeEnabled ? getRowContent : getSubRowContent)}
        {provided?.placeholder}
      </div>
    );

    const bodyContent = dndEnabled ? (
      <DragDropContext onDragEnd={dragElement}>
        <Droppable droppableId='edit-table'>{renderer}</Droppable>
      </DragDropContext>
    ) : (
      renderer()
    );

    const limitHeightStyles = limitHeight ? { maxHeight: bodyHeight, overflowX: 'hidden' } : {};

    return (
      <div
        {...getTableBodyProps({
          style: {
            overflowY: 'auto',
            width: bodyWidth,
            ...limitHeightStyles,
          },
        })}
      >
        {isLoading ? (
          <tr>
            <td colSpan={50} style={{ width: '100%', textAlign: 'center' }}>
              <div>
                <ReactLoading
                  className='table-loader'
                  type={appConstants.LOADER_TYPE}
                  color={appConstants.LOADER_COLOR}
                  height={appConstants.LOADER_HEIGHT}
                  width={appConstants.LOADER_WIDTH}
                />
              </div>
            </td>
          </tr>
        ) : (
          <>{rows?.length ? <>{bodyContent}</> : <p>No data found!</p>}</>
        )}
      </div>
    );
  }, [rows, isLoading, dndEnabled, getRowContent, getSubRowContent, subRowModeEnabled]);

  return (
    <div className={styles.tableWrapper} ref={wrapperRef}>
      <div className={`${rows?.length ? 'react-table-empty' : ''}`}>
        <table className='ms-auto me-auto mt-0' {...getTableProps()}>
          {getHeaderContent()}
          {getTableBodyContent()}
        </table>
      </div>
    </div>
  );
};
