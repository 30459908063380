import React, { useState } from 'react';
import { Tree, Dropdown } from 'antd';
import { Button } from 'react-bootstrap';
import styles from './right-section.module.scss';

const ActivityHeader = ({
  onSearch,
  onSort,
  onFilter,
  canFilter = true,
  onAddNew,
  addNewLabel,
  defaultFilter = ['all'],
  filterOptions,
  sortDirection,
  additionalButtons = [],
}) => {
  const [filterDropdownVisible, setFilterDropdownVisible] = useState(false);
  const [tempFilterType, setTempFilterType] = useState(defaultFilter);

  const toggleSortDirection = () => {
    onSort(sortDirection === 'asc' ? 'desc' : 'asc');
  };

  const handleFilterChange = (checkedKeys) => {
    setTempFilterType(checkedKeys);
  };

  const resetFilter = () => {
    setTempFilterType(['all']);
    onFilter(['all']);
    setFilterDropdownVisible(false);
  };

  const applyFilter = () => {
    onFilter(tempFilterType);
    setFilterDropdownVisible(false);
  };

  const filterMenu = (
    <div className={styles.filterMenu}>
      <Tree
        className={styles.filterTree}
        checkable
        onCheck={handleFilterChange}
        checkedKeys={tempFilterType}
        treeData={filterOptions}
        defaultExpandAll
        showLine
      />
      <div className={styles.filterActions}>
        <Button onClick={resetFilter}>Reset</Button>
        <Button variant="primary" onClick={applyFilter}>
          Apply
        </Button>
      </div>
    </div>
  );

  return (
    <div className={`${styles.activityHeader} d-flex justify-content-between align-items-center mb-2`}>
      <div className="d-flex align-items-center flex-grow-1 me-2">
        <input
          type="text"
          className={`form-control ${styles.searchInput}`}
          placeholder="Search activities"
          onChange={(e) => onSearch(e.target.value)}
        />
      </div>
    
      <button 
        className={"btn btn-outline-secondary me-2 p-1 " + styles.sortButton}
        onClick={toggleSortDirection}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            transform: sortDirection === 'asc' ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s ease'
          }}
        >
          <path
            d="M8 3V13M8 13L13 8M8 13L3 8"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      {canFilter && (<>
      <Dropdown
        overlay={filterMenu}
        trigger={['click']}
        placement="bottomRight"
        open={filterDropdownVisible}
        onOpenChange={setFilterDropdownVisible}
      >
        <button className={`btn btn-outline-secondary p-1 me-2 ${styles.filterButton}`} style={{ width: '32px', height: '32px' }}>
          <span className='fa fa-filter'></span>
        </button>
      </Dropdown></>
      )}
      {additionalButtons.map((button, index) => (
        <React.Fragment key={index}>{button}</React.Fragment>
      ))}
    </div>
  );
};

export default ActivityHeader;