import Joi from 'joi';
import { Moment } from 'moment';
import { joiMoment } from '../../utils/validation';

// Helper function to convert string to number
const stringToNumber = (value: string | number): number => {
  if (typeof value === 'number') return value;
  return Number(value.replace(/,/g, ''));
};

// Custom Joi extension
const joiStringOrNumber = Joi.alternatives().try(
  Joi.string().custom((value, helpers) => {
    const number = stringToNumber(value);
    if (isNaN(number)) {
      return helpers.error('any.invalid');
    }
    return number;
  }),
  Joi.number()
);


export type ChangeOrderModalTypeSchema = {
  coNumber: number | null,
  coCostAmount: number | null,
  profitPercentage: number | null,
  profitDollars: number | null,
  coTotalAmount: number | null,
  comment: string,
  changeOrderReason: string,
  effectiveDate: Moment | null,
  scheduleImpact: number,
  wasAccepted: boolean,
  acceptedDate: Moment | null,
  acceptedBy: string,
  existingChangeOrders: Array<number>,
  estData: any[] | null,
  estTotalContract: number,
  estTotalCost: number,
  estTotalProfit: number,
  exclusions: string,
  scopeArr: any[] | null,
  createEstimate: boolean,
  qbEstimateId: string,
};

export const changeOrderModalValidationSchema = Joi.object({
  coNumber: Joi.number().positive().allow(0)
    .custom((value, helper) => {
      if (helper.state.ancestors[0].existingChangeOrders.includes(+value)) {
        return helper.message({
          custom: `{#label} ${value} already exists`,
        });
      }

      return value;
    })
    .label('Change Order Number'),
  coCostAmount: Joi.number().label('Change Order Cost Amount'),
  profitPercentage: Joi.number().label('Profit Percentage'),
  profitDollars: Joi.number().label('Profit Dollars'),
  coTotalAmount: Joi.number().label('Change Order Amount'),
  comment: Joi.string().label('Description/Inclusions'),
  changeOrderReason: Joi.string().allow('').label('Change Order Reason'),
  exclusions: Joi.string().allow('').label('Exclusions'),
  effectiveDate: joiMoment({ required: true }).empty(null).required().label('Date'),
  scheduleImpact: Joi.number().failover(0).label('Schedule Impact'),
  wasAccepted: Joi.boolean().label('Was Accepted'),
  acceptedDate: joiMoment().custom((value, helper) => {
    if (helper.state.ancestors[0].wasAccepted && !value) {
      return helper.message({
        custom: `{#label} is required for approved change orders`,
      });
    }

    return value;
  }).label('Accepted Date'),
  acceptedBy: Joi.string().allow('').label('Accepted By'),
  existingChangeOrders: Joi.array().items(Joi.number()).optional(),
  estData: Joi.array().optional().items(Joi.object({
    scopeId: Joi.string().optional().allow('', null),
    subTotalCost: joiStringOrNumber.optional().allow('', null),
    subTotalCtr: joiStringOrNumber.optional().allow('', null),
    subTotalProfit: joiStringOrNumber.optional().allow('', null),
    subTotalProfitPers: joiStringOrNumber.optional().allow('', null),
    qty: joiStringOrNumber.optional().allow('', null),
    unitPrice: joiStringOrNumber.optional().allow('', null),
    unitCost: joiStringOrNumber.optional().allow('', null),
    unitProfit: joiStringOrNumber.optional().allow('', null),
    allowChangeScope: Joi.boolean().optional().allow(null),
    description: Joi.string().optional().allow('', null),
    serviceDate: joiMoment({ required: false }).allow(null).empty(null),
    items: Joi.array().items(Joi.object().unknown(true)).optional().allow(null),
    billable: Joi.boolean().optional().allow(null),
    taxable: Joi.boolean().optional().allow(null),
    qbItemId: Joi.string().optional().allow('', null),
    lineItemId: Joi.string().optional().allow('', null),
  })),
  estTotalContract: Joi.number().optional(),
  estTotalCost: Joi.number().optional(),
  estTotalProfit: Joi.number().optional(),
  scopeArr: Joi.array().optional(),
  createEstimate: Joi.boolean().failover(false).label('Create Estimate'),
  qbEstimateId: Joi.string().optional().allow('', null).label('QuickBooks Estimate Id'),
  shouldGeneratePdf: Joi.boolean().optional().failover(false).label('Generate PDF'),
  pdfUrl: Joi.string().optional().allow('', null).label('PDF URL'),
});
