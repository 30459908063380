import React, { useState, useEffect, useMemo, useRef } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd';
import { Input } from 'reactstrap';
import _ from 'lodash';
import { isAllowed, moduleConstants } from '../../_constants';

interface Column {
  id: string;
  Header: string;
  isVisible: boolean;
  LongHeader? : string;
  alwaysHidden?: boolean;
  hidden?: boolean;
}

interface ReOrderMenuProps {
  allColumns: Column[];
  reportId?: string;
  allowedEdit: boolean;
  onDragEnd: (result: DropResult) => void;
  handleApplySettings: (visibleColumns: Column[], hiddenColumns: Column[]) => void;
  resetTable: () => void;
  handleToggle: () => void;
}

export const ReOrderMenu: React.FC<ReOrderMenuProps> = ({
  allColumns,
  onDragEnd,
  handleApplySettings,
  resetTable,
  handleToggle,
  reportId = '',
  
}) => {

  const menuRef = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    const preventScroll = (e: Event) => {

      const isInMenu = menuRef.current && menuRef.current.contains(e.target as Node);
      // get current height of menu from document
      const documentClientHeight = document.getElementsByClassName(menuRef.current?.className as string)?.[0]?.clientHeight??0 as number;

      if(documentClientHeight>0 && !isInMenu){
        e.preventDefault();
      }
      // allow scroll if the target is not in the menu
      // and the menu is not open
    };


    document.addEventListener('wheel', preventScroll, { passive: false });
    document.addEventListener('touchmove', preventScroll, { passive: false });

  }, []);
  
  const [visibleColumns, setVisibleColumns] = useState<Column[]>([]);
  const [hiddenColumns, setHiddenColumns] = useState<Column[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  const [lastAllColumns, setLastAllColumns] = useState('');

  // get userinfo to determine if user can edit report settings

  const canEditGlobalReport = isAllowed(moduleConstants.ACCOUNTSETTINGS);

  useEffect(() => {
    const filteredColumns = _.clone(
      allColumns
        .filter((column) => !['action', 'selection', 'expander'].includes(column.id))
        .map((column) => {
          return {
            id: column.id,
            Header: column.Header,
            LongHeader: column.Header!==column.LongHeader?column.LongHeader:undefined,
            isVisible: column.isVisible,
            hidden: column.hidden,
            alwaysHidden: column.alwaysHidden,
          };
        })
    );
    const visible = _.cloneDeep(filteredColumns.filter((column) => column.isVisible));
    const hidden = _.cloneDeep(
      filteredColumns
        .filter((column) => !column.isVisible)
        .sort((a, b) => a.Header.localeCompare(b.Header))
    );

    // convert to json and compare
    const localAllColumns = JSON.stringify(
      visible.map((column) => {
        return { id: column.id, isVisible: column.isVisible };
      })
    );
    if (localAllColumns !== lastAllColumns) {
      setLastAllColumns(localAllColumns);
    } else if (visible.length !== 0) {
      return;
    }
    // if no visible columns, set defaults
    if (visible.length === 0) {
      const defaultVisible = _.cloneDeep(
        filteredColumns.filter((column) => !(column.hidden || column.alwaysHidden))
      );
      const defaultHidden = _.cloneDeep(
        filteredColumns
          .filter((column) => column.hidden || column.alwaysHidden)
          .sort((a, b) => a.Header.localeCompare(b.Header))
      );
      setVisibleColumns(defaultVisible);
      setHiddenColumns(defaultHidden);
      return;
    }
    setVisibleColumns(visible);
    setHiddenColumns(hidden);
  }, [allColumns]);

  const filteredHiddenColumns = useMemo(() => {
    return hiddenColumns.filter((column) =>
      column.Header.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [hiddenColumns, searchTerm]);

  const handleSearchKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && filteredHiddenColumns.length > 0) {
      handleAdd(filteredHiddenColumns[0]);
    }
  };

  const handleAdd = (column: Column) => {
    setHiddenColumns(hiddenColumns.filter((c) => c.id !== column.id));
    setVisibleColumns([...visibleColumns, { ...column, isVisible: true }]);
    setSearchTerm('');
  };

  const handleRemove = (column: Column) => {
    setVisibleColumns(visibleColumns.filter((c) => c.id !== column.id));
    setHiddenColumns([...hiddenColumns, { ...column, isVisible: false }]);
  };

  const onLocalDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination) return;

    const sourceList = source.droppableId === 'hidden' ? hiddenColumns : visibleColumns;
    const destList = destination.droppableId === 'hidden' ? hiddenColumns : visibleColumns;

    const [reorderedItem] = sourceList.splice(source.index, 1);
    destList.splice(destination.index, 0, reorderedItem);

    if (source.droppableId !== destination.droppableId) {
      if (source.droppableId === 'hidden') {
        setHiddenColumns(_.cloneDeep(sourceList));
        setVisibleColumns(destList);
      } else {
        setVisibleColumns(sourceList);
        setHiddenColumns(destList.map((col) => ({ ...col, isVisible: false })));
      }
    } else {
      if (source.droppableId === 'hidden') {
        setHiddenColumns(destList);
      } else {
        setVisibleColumns(destList);
      }
    }

    if (onDragEnd) {
      onDragEnd(result);
    }
  };

  const handleLocalApplySettings = () => {
    handleApplySettings(visibleColumns, hiddenColumns);
  };

  return (
    <div ref={menuRef} className={'no-print ColumnReorderMenu '+ (filteredHiddenColumns.length == 0 ? ' half-width' : '')}>
      <div className='row mt-0 ms-4 me-4'>
        <span>
        Drag and drop to reorder columns on this report. Changes affect only your view of this report on this device.
        <br/> {reportId?.length>0&&(canEditGlobalReport?(<>If you would like to change the default columns for this report, please contact your administrator.</>):
        (<>If you would like to customize this report for everybody, please click <a href={'/settings/report-settings/'+reportId} target='_blank' rel='noreferrer noopener' style={{ whiteSpace: 'nowrap' }}>here</a>.</>))}
        </span>
      </div>
      <DragDropContext onDragEnd={onLocalDragEnd}>
        <div className='row mt-3'>
        {filteredHiddenColumns.length > 0 && (
          <div className='col-6 ps-4 pe-4'>
            <h5 className='mb-2'>Available columns ({filteredHiddenColumns.length})</h5> 
            <Input
              type='text'
              placeholder='Search columns...'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={handleSearchKeyDown}
              className='mb-2'
            />
            <Droppable droppableId='hidden'>
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className='list-group reorder-list additional-items'
                >
                  {filteredHiddenColumns.map((column, index) => (
                    <Draggable key={column.id} draggableId={column.id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className='list-group-item list-group-item-action d-flex justify-content-between align-items-center'
                        >
                          {column.LongHeader?(column.LongHeader +' ('+column.Header+')'):column.Header}
                          <span
                            className='badge bg-primary rounded-pill'
                            onClick={() => handleAdd(column)}
                            style={{ cursor: 'pointer' }}
                          >
                            <span className='fa fa-plus' />
                          </span>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>)}
          <div className='col-6 ps-4 pe-4'>
            <h5 className='mb-2'>Visible columns ({visibleColumns.length})</h5>
            <Droppable droppableId='visible'>
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className='list-group reorder-list'
                  
                >
                  {visibleColumns.map((column, index) => (
                    <Draggable key={column.id} draggableId={column.id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className='list-group-item list-group-item-action d-flex justify-content-between align-items-center'
                        >
                          <span className='fa fa-bars me-2' />
                          <span className='text-start me-auto'>{column.LongHeader?(column.LongHeader +' ('+column.Header+')'):column.Header}</span>
                          <span
                            className='fa fa-remove'
                            onClick={() => handleRemove(column)}
                            style={{ cursor: 'pointer' }}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </div>
      </DragDropContext>
      <div className='table-settings-action-block mt-3'>
        <button className='btn btn-outline-secondary mx-2' onClick={resetTable}>
          Reset
        </button>
        <button className='btn btn-secondary mx-2 ms-auto' onClick={handleToggle}>
          Cancel
        </button>
        <button className='btn btn-primary' onClick={handleLocalApplySettings}>
          Apply
        </button>
      </div>
    </div>
  );
};
