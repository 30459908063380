import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import bannerImage from '../../components/static/images/cpostLogo.png';
import { EmailVerificationAction } from '../../actions/user';
import { Userpilot } from 'userpilot';
import { useAuth } from '../Auth/Auth';
import Footer from '../Layout/Footer';

Userpilot.initialize(process.env.REACT_APP_USER_PILOT_KEY);

const LoginRedirection = () => {
  const [state, setState] = useState({
    isHidden: false,
    fetchRequest: false,
    isFetched: false,
    processing: false,
    isVerified: false,
    userId: '',
    loginEmail: '',
    activationCode: '',
    emailVerifyResult: false,
    ticketURL: null,
    verifyComplete: false,
    activationMsg: '',
  });

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { login, logout } = useAuth();

  const userEmail = useSelector(state => state.user?.emailData);
  const isVerified = useSelector(state => state.user?.emailVerifyStatus);
  const emailTicketURL = useSelector(state => state.user?.ticketURL);
  const activationMsg = useSelector(state => state.user?.message);

  useEffect(() => {
    console.log('*********** user name', navigator.userAgent);

    const { objectId, code: activationCode } = queryString.parse(location.search);
    setState(prev => ({ ...prev, verifyComplete: false }));

    if (objectId) {
      setState(prev => ({ ...prev, userId: objectId }));
      let userData = { userId: objectId, objectId: objectId };

      if (activationCode) {
        setState(prev => ({
          ...prev,
          activationCode,
          fetchRequest: true,
          processing: true
        }));
        userData.activationCode = activationCode;
        dispatch(EmailVerificationAction(userData));
      }
    }

    if (state.userId?.length !== 0 && state.loginEmail?.length === 0) {
      setState(prev => ({ ...prev, processing: true }));
    } else if (state?.userId !== 0 && state.loginEmail?.length > 3) {
      // login();
    } else {
      if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i)) {
        setState(prev => ({ ...prev, isHidden: true }));
      } else if(!objectId || !activationCode) {
        setState(prev => ({ ...prev, isHidden: false }));
       
      }else{
        //logout();
      }
    }
  }, []);

  useEffect(() => {
    if (activationMsg?.length > 0 && !state.verifyComplete) {
      setState(prev => ({
        ...prev,
        verifiedResult: isVerified === true,
        ticketURL: emailTicketURL,
        activationMsg,
        loginEmail: userEmail,
        verifyComplete: true
      }));
    }
  }, [activationMsg, isVerified, emailTicketURL, userEmail]);

  const loginRedirect = (loginEmail) => {
    login(false, loginEmail);
  };

  const isProcessing = !state.verifyComplete;
  const hideURL_and_redirect = false; // state.isHidden;
  const verifiedResult = state.verifiedResult;
  const ticketURL = state.ticketURL;
  const continueURL = ticketURL ? ticketURL : "https://commandpost.ai/login";
  const alreadyVerified = verifiedResult && !ticketURL;
  const errorMsg = state.activationMsg ? state.activationMsg : 'Unknown Error';

  return (
    <>
      <header className="frontLoginHeader">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <div className="logo">
                <a href="/home">
                  <img alt="logo" src={bannerImage} />
                </a>
                <span className="tagline"></span>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="mt-5 mb-5 position-relative d-flex justify-content-center align-items-center">
        <div className="row">
          <div className="col-md-12">
            <div className="text-center">
              <h1><strong>Command Post Activation</strong></h1>
              <div className="signFieldBox">
                {isProcessing
                  ? (<h2>Verifying your account...</h2>)
                  : (<>
                    {verifiedResult ? !alreadyVerified
                      ? (<h2>Thank you for verifying your contact <br/> information!</h2>)
                      : (<h2>Account already verified!</h2>) : ''}
                  
                    <div>
                      {!verifiedResult
                        ? <h2>
                            Error verifying account!
                            <br/>
                            An error occurred while verifying your account. 
                            <br/><br/>
                            {errorMsg}
                            <br/><br/>
                            Please contact support or try again later.
                          </h2>
                        : (hideURL_and_redirect 
                          ? <h2>We are redirecting you to Auth0 to create a new password.</h2>
                          : <h2>
                              {!alreadyVerified
                                ? (<>Please create a password to continue.</>)
                                : (<>This account has already been verified, if you need to set a new password please select 'Forgot Password' at the login screen.</>)}
                            </h2>)
                      }
                    </div>
                  </>)
                }
              </div>
            </div>
          </div>
        </div>
        
      </div>

      <div className="row">
        <div className="col text-center">
        {!isProcessing && verifiedResult &&  (alreadyVerified && ticketURL?.length>0
          && (<><a className='btn' href={continueURL}>Create Password</a></>))}
          
        {!isProcessing && verifiedResult && !hideURL_and_redirect && (!alreadyVerified && ticketURL
          ? (<><a className='btn' href={continueURL}>Create Password</a></>)
          : (<><a className='btn' onClick={() => loginRedirect(state.loginEmail)}>Continue to Log In</a></>))}
        {!isProcessing && !verifiedResult && !hideURL_and_redirect && (<><a className='btn' href='/login'>Back to Login</a></>)}
        </div>
        </div>

      <Footer />
    </>
  );
};

export default LoginRedirection;