import _, { set } from 'lodash';
import Papa from 'papaparse';
import { notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useState, useCallback, useRef, useEffect } from 'react';
import { loadContacts, uploadCsvContacts, convertRowToContact,validateContactName } from '../../modules/contacts';
import { getImportingContacts } from '../../modules/contacts/selectors';
import useRowSelection from '../../shared/data-grid/use-row-selection';
import DataGridMatrix from '../../shared/data-grid/data-grid-matrix/data-grid-matrix';
import { SearchCompanyModal } from '../../modals/search-company-modal/search-company-modal';
import AddContactModal from '../../modals/add-contact-modal/add-contact-modal';
import { formatDateObjectOrNull } from '../../utils/date-formatters.js';
import { gridColumns } from './columnHelper';
import styles from './styles.module.scss';
import { cellFormatter } from '../../shared/data-grid/import-components';

const ImportContactsView = () => {
  const { data: jsonRows } = useSelector(getImportingContacts);
  const fileParseConfig = {
    delimiter: '', // auto-detect
    newline: '', // auto-detect
    header: true,
    skipEmptyLines: true,
  };

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const addContactModal = useRef(null);
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [dataFile, setDataFile] = useState(null);
  const [contactRows, setContactRows] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSearchCompanyModalOpen, setSearchCompanyModalOpen] = useState(false);
  const [currentRowIndex, setCurrentRowIndex] = useState(null);

  useEffect(() => {
    if (location.state?.contactsCsv) {
      setDataFile(location.state.contactsCsv);
    }
  }, [location]);

  const [matchingContacts, setMatchingContacts] = useState({});

  const scanForMatchingContacts = useCallback(async (contacts) => {
    const newContactRows = _.cloneDeep(contacts);
    const matches = {};

    await Promise.all(contacts.map(async (contact, index) => {
      if(contact._loadResult)  
        return;

      const { firstName, lastName, middleName, prefix, suffix } = contact;
      
      try {
        const result = await dispatch(validateContactName({
          firstName: firstName?.toLowerCase()?.trim(),
          lastName: lastName?.toLowerCase()?.trim(),
          middleName: middleName?.toLowerCase()?.trim(),
          suffix: suffix?.toLowerCase()?.trim(),
          prefix: prefix?.toLowerCase()?.trim(),
        })).unwrap();

        if (result && result.length > 0) {
          matches[index] = result;
          //newContactRows[index]._loadResult = { warning: true, error: 'Potential duplicate contacts found' };
          //newContactRows[index]._isAvailable = true;
          newContactRows[index]._rowErrors = { firstName: { 
            message: 'Contact by this name already exists', 
            data: contact.firstName, 
            rowIndex: index 
          }, ...newContactRows[index]._rowErrors, 
          };
        }
      } catch (error) {
        console.error('Error validating contact name:', error);
      }
    }));

    setMatchingContacts(matches);
    setContactRows(newContactRows);
  }, [dispatch]);

  useEffect(() => {
    if (!jsonRows?.length) {
      history.push('/import-contacts');
    }

    const newContactRows = _.cloneDeep(jsonRows);
    // on update do not drop _loadResult (_rowErrors and _isAvailable) if it was set
    contactRows.forEach((row, index) => {
      if (row._loadResult) {
        newContactRows[index]._loadResult = row._loadResult;
        newContactRows[index]._isAvailable = row._isAvailable;
        newContactRows[index]._rowErrors = row._rowErrors;
      }
    });

    setLoading(false);
    setContactRows(newContactRows);
    if(contactRows.length == 0)
      scanForMatchingContacts(newContactRows);
  }, [jsonRows]);

  const updatedGridColumns = [
    ...gridColumns,
    {
      Header: 'Potential Matches',
      id: 'potentialMatches',
      accessor: (row, rowIndex) => matchingContacts[rowIndex],
      Cell: ({ value }) => value ? `${value.length} potential match(es)` : '',
      width: 150,
    },
    {
      Header: 'Actions',
      id: 'actions',
      Cell: ({ row }) => {
        const rowIndex = row.index;
        const contact = contactRows[rowIndex];
        const isAvailable = contact._isAvailable;
        const loadResult = contact._loadResult;

        if(!isAvailable)
          return null;
        
        return (
          <div className='d-flex gap-2'>
            <button
              className='btn btn-primary'
              onClick={() => handleEditRow(rowIndex)}
              disabled={!isAvailable}
            >
              Edit
            </button>
            </div>
        );},
      width: 100,
    }
  ];

   updatedGridColumns.find((column) => column.id === 'companyName').Cell= cellFormatter((_, row) => {
    const { affiliations = [] } = row;
    const affCompanyNames = affiliations
      .map((aff) => {
        if (!aff.companyName) {
          return '<No Company Selected>';
        }

        if (typeof aff.companyName === 'string') {
          return aff.companyName;
        }

        return aff.companyName.companyName;
      });

    const cellValue = affCompanyNames.filter((val) => val).join(', ');

    return <span onClick= {() => handleEditRow(row._rowIndex)}>{ cellValue === '' ? '<No Company Selected>' : cellValue}</span>
  });


    
  const selectionState = useRowSelection({ data: contactRows });
  const { selected, getSelectedSomething, setSelected } = selectionState;
  const isSomethingSelected = getSelectedSomething();

  const onContinueClick = () => history.push('/view-contacts');
  const onBackClick = () => history.goBack();
  const getOriginalCompanyName = () => {
    let companyName = '<No Company Selected>';
    if (currentRowIndex !== null) {
      const error = contactRows[currentRowIndex]._rowErrors.companyName;
      if (error && error.data) {
        companyName = error.data;
      }
    }
    return companyName;
  };

  const onUploadClick = async () => {
    try {
      setLoading(true);
      const { successCount, total } = await dispatch(
        loadContacts([contactRows, selected])
      ).unwrap();

      notification.success({
        message: `Uploaded ${successCount} / ${total} contacts`,
      });

      setSelected({});
      scanForMatchingContacts(contactRows);
    } catch (e) {
    } finally {
      setDone(true);
      setLoading(false);
    }
  };

  const handleEditRow = useCallback(async (rowIndex) => {
    const selectedRowData = { ...contactRows[rowIndex] };

    if (selectedRowData._rowErrors?.companyName) {
      setSearchCompanyModalOpen(true);
    } else if (selectedRowData._isAvailable) {
      setIsModalOpen(true);

      const { contact, affiliations } = await dispatch(
        convertRowToContact(selectedRowData)
      ).unwrap();
      contact.dateOfBirthday = formatDateObjectOrNull(contact.dateOfBirthday);
      addContactModal.current.setContact(contact);
      addContactModal.current.setAffiliations(affiliations);
    }

    setCurrentRowIndex(rowIndex);
  }, [contactRows, dispatch]);

  const closeModal = useCallback(() => {
    setCurrentRowIndex(null);
    setIsModalOpen(false);
  }, []);

  const onMatchCompany = (company, applyToAll, originalCompanyName) => {
    if(!company)
      return;

    const newContactRows = _.cloneDeep(contactRows);

    if (applyToAll) {
      // apply to all rows with the same original company name
      newContactRows.forEach((row, index) => {
        if (row._rowErrors?.companyName?.data === originalCompanyName) {
          newContactRows[index]._rowErrors = _.omit(newContactRows[index]._rowErrors, 'companyName');
      if(_.isEmpty(newContactRows[index]._rowErrors)&&!newContactRows[index]._loadResult){
        newContactRows[index]._isAvailable = true;
      }
          newContactRows[index].affiliations.forEach((aff) => {
            if(aff.companyName === originalCompanyName){
              aff.companyName = { _id: company._id, companyName: company.companyName };
            }
          });
        }
      });
    }else{
      // remove companyName from row errors
      newContactRows[currentRowIndex]._rowErrors = _.omit(newContactRows[currentRowIndex]._rowErrors, 'companyName');
      if(_.isEmpty(newContactRows[currentRowIndex]._rowErrors)&&!newContactRows[currentRowIndex]._loadResult){
        newContactRows[currentRowIndex]._isAvailable = true;
      }
      newContactRows[currentRowIndex].affiliations.forEach((aff) => {
        if(aff.companyName === originalCompanyName || aff.companyName?.companyName === originalCompanyName){
          aff.companyName = { _id: company._id, companyName: company.companyName };
        }
      });
    }

    setContactRows(newContactRows);
  };

  const onAfterAddContact = (contactData) => {
    const newContactRows = _.cloneDeep(contactRows);

    contactData.forEach((contact,idx) => {
      if(contact.companyId==0){
        newContactRows[currentRowIndex].firstName = contact.firstName;
        newContactRows[currentRowIndex].lastName = contact.lastName;
        newContactRows[currentRowIndex].middleName = contact.middleName;
      }
      newContactRows[currentRowIndex].affiliations[idx] = {};
      newContactRows[currentRowIndex].affiliations[idx]= {addressArr: contact.addressArr, phoneArr: contact.phoneArr, emailArr: contact.emailArr, title: contact.title, companyName: {_id: contact.companyId, companyName: contact.companyName}};
    });

    // show row as uploaded
    newContactRows[currentRowIndex]._loadResult = { error: null };
    newContactRows[currentRowIndex]._isAvailable = false;

    setDone(true);
    setContactRows(newContactRows);
    scanForMatchingContacts(newContactRows);
  };

  const revalidateRows = (company) => {
    Papa.parse(dataFile, {
      ...fileParseConfig,
      complete: async (results) => {
        const changedRowIndex = contactRows[currentRowIndex]._rowErrors.companyName.rowIndex;
        const rowData = results.data[changedRowIndex];
        if (rowData) {
          rowData['Company Name'] = company.companyName;

          const formData = new FormData();
          const csv = Papa.unparse({ fields: results.meta.fields, data: results.data });
          const csvData = new Blob([csv], { type: 'text/csv' });
          formData.append('contactsCsv', csvData);

          setLoading(true);
          setDataFile(csvData);

          await dispatch(uploadCsvContacts(formData));
        }
      },
    });
  };

  return (
    <div className='ms-2 me-2'>
      <div className='d-flex justify-content-between py-3 gap-2'>
        <div className='d-flex gap-3'>
          <button className='btn btn-primary mr-auto' onClick={onBackClick}>
            Back
          </button>
          <span className='mr-auto align-self-center'>
            <b>Import Contacts</b>&emsp;&emsp; Total records: {contactRows?.length || '0'}
          </span>
        </div>
        <div className='d-flex gap-3 align-items-center'>
          <button
            className='btn btn-primary'
            disabled={!isSomethingSelected || loading}
            onClick={onUploadClick}
          >
            {loading ? 'Loading ...' : 'Upload'}
          </button>
          <button className='btn btn-primary' disabled={!done} onClick={onContinueClick}>
            Continue
          </button>
        </div>
      </div>

      {currentRowIndex !== null && (
        <SearchCompanyModal
          open={isSearchCompanyModalOpen}
          onClose={() => setSearchCompanyModalOpen(false)}
          onConfirm={(companyInfo, originalCompanyName)=> onMatchCompany(companyInfo,false,originalCompanyName)}
          onBulkMap={(companyInfo, originalCompanyName)=> onMatchCompany(companyInfo,true,originalCompanyName)}
          onGetOriginalCompanyName={getOriginalCompanyName}
        />
      )}

      <AddContactModal
        onSubmit={onAfterAddContact}
        isAddToCompany={false}
        open={isModalOpen}
        contact={{}}
        preAffiliations={[]}
        isQuickAdd={true}
        isImport={true}
        onClose={closeModal}
        ref={addContactModal}
      />

      <DataGridMatrix
        useControlledState={(state) => ({
          ...state,
          ...selectionState,
        })}
        columns={updatedGridColumns}
        data={contactRows}
        //onRowClick={handleEditRow}
      />

      {loading && (
        <div className={styles.spinnerWrapper}>
          <div className='spinner-border text-primary' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImportContactsView;
