import { createSelector } from '@reduxjs/toolkit';
import { appConstants } from '../_constants';

const selectAllJobStatus = (state) => state.jobStatusCode?.allJobStatus??[];

export const selectAllJobStatusOptions = createSelector(selectAllJobStatus, (allJobStatus) =>
  allJobStatus
    ?.filter(
      (val) =>
        val.objectId !== '' &&
        val.jobStatusCodesName !== '' &&
        val.jobStatusCodesCode !== appConstants.jobStatusCode.L &&
        val.jobStatusCodesCode !== appConstants.jobStatusCode.NP
    )
    ?.map((val) => ({
      label: val.jobStatusCodesName,
      value: val.objectId,
      code: val.jobStatusCodesCode,
      permissionsGroupName: val.permissionsGroupName,
    }))??[]
);

export const selectAllLeadJobStatusOptions = createSelector(selectAllJobStatus, (allJobStatus) =>
  allJobStatus
    .filter(
      (val) =>
        val.objectId !== '' &&
        val.jobStatusCodesName !== '' &&(
        val.jobStatusCodesCode === appConstants.jobStatusCode.L ||
        val.jobStatusCodesCode === appConstants.jobStatusCode.NP)
    )
    .map((val) => ({
      label: val.jobStatusCodesName,
      value: val.objectId,
      code: val.jobStatusCodesCode,
      permissionsGroupName: val.permissionsGroupName,
    }))
);

export const allUsedJobStatus = (state) => state.jobStatusCode?.allUsedJobStatus;
export const allBiddingJobStatus = (state) => state.jobStatusCode.allBiddingJobStatus;
export const allBidPendingJobStatus = (state) => state.jobStatusCode.allBidPendingJobStatus;

export const selectAllUsedJobStatus = createSelector(
    allUsedJobStatus,
    (allUsedJobStatus) =>
    allUsedJobStatus?.map(({ jobStatusCodesName, objectId }) => ({
        label: jobStatusCodesName,
        value: objectId,
      }))
  );

export const selectAllUsedJobStatusOptions = createSelector(
  allUsedJobStatus
  , (allUsedJobStatus) =>
    allUsedJobStatus?.filter(
      (val) =>
        val.objectId !== '' &&
        val.jobStatusCodesName !== '' &&
        val.jobStatusCodesCode !== appConstants.jobStatusCode.L &&
        val.jobStatusCodesCode !== appConstants.jobStatusCode.NP
    )
    .map((val) => ({
      label: val.jobStatusCodesName,
      value: val.objectId,
      code: val.jobStatusCodesCode,
      permissionsGroupName: val.permissionsGroupName,
    }))
  );

export const selectAllBiddingJobStatus = createSelector(
    allBiddingJobStatus,
    (allBiddingJobStatus) =>
    allBiddingJobStatus?.map(({ jobStatusCodesName, objectId }) => ({
        label: jobStatusCodesName,
        value: objectId,
      }))
  );

  export const selectAllBidPendingJobStatus = createSelector(
    allBidPendingJobStatus,
    (allBidPendingJobStatus) =>
    allBidPendingJobStatus?.map(({ jobStatusCodesName, objectId }) => ({
        label: jobStatusCodesName,
        value: objectId,
      }))
  );
