import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

type NameMatchingContactsModalProps = {
  open: boolean;
  onClose: () => void;
  matchingContacts: any[];
  currentName: {
    firstName: string;
    lastName: string;
    middleName?: string;
    prefix?: string;
    suffix?: string;
  };
};

export const NameMatchingContactsModal = ({
  open,
  onClose = () => void 0,
  matchingContacts = [],
  currentName,
}: NameMatchingContactsModalProps) => {
  const list = matchingContacts.map((contact) => {

    const fullName = [contact.prefix, contact.firstName, contact.middleName, contact.lastName, contact.suffix].filter(Boolean).join(' ');
    return (
      <div className='row py-2' key={contact._id}>
        <div className='col-4'>
          <a target='_blank' rel='noreferrer' href={`/contact-details/${contact._id}`}>
            {fullName}
          </a>
          {contact.affiliations.map((affiliation, index) => {
            if(index>0){
                return <React.Fragment key={affiliation.companyId+'space'}><br/></React.Fragment>
            }
          })}
        </div>
        <div className='col-4'>
        {contact.affiliations.map((affiliation, index) => (
        <React.Fragment key={affiliation.companyId}>
          <a
            target="_blank"
            rel="noreferrer"
            href={`/company-details/${affiliation.companyId}`}
          >
            {affiliation.companyName}
          </a>
          {index < contact.affiliations.length - 1 && <br />}
        </React.Fragment>
      ))}</div>
        <div className='col-4'>  {contact.affiliations.map((affiliation) => {
                return  <React.Fragment key={affiliation.companyId+'space'}>{affiliation.title??'-'}<br/></React.Fragment>
           })}
        </div>
      </div>
    );
  });

  const headerValue = () => {
    const { prefix, firstName, middleName, lastName, suffix } = currentName;
    return [prefix, firstName, middleName, lastName, suffix].filter(Boolean).join(' ');
  };

  return (
    <Modal toggle={onClose} isOpen={open} size={'lg'}>
      <ModalHeader toggle={onClose}>Contacts matching "{headerValue()?.trim()}"</ModalHeader>
      <ModalBody>
        <div className='container'>
          <div className='row'>
            <div className='col-4 fw-bold'>Full Name</div>
            <div className='col-4 fw-bold'>Company</div>
            <div className='col-4 fw-bold'>Title</div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <hr />
            </div>
          </div>
          {list}
        </div>
      </ModalBody>
      <ModalFooter>
        <button className='btn btn-primary' onClick={onClose}>
          Close
        </button>
      </ModalFooter>
    </Modal>
  );
};