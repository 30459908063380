import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SettingsCodesLayoutNext } from '../../../layouts/settings-codes-layout/settings-codes-layout-next';
import { usePageTitle } from '../../../layouts/title-context';
import { disableUserSetting, enableUserSetting, getUserSettings } from '../../../modules/settings';
import { useAppDispatch } from '../../../shared/hooks/use-app-dispatch';
import { useConfirm } from '../../../shared/confirmation-dialog';
import { EditFormTemplateModal } from './edit-form-template-modal';
import { useSubmit } from '../../../shared/hooks/use-submit';
import { notification } from 'antd';

export const FormTemplatesPage: React.FC = () => {
  usePageTitle('Form Templates Settings');
  const modelName = 'formTemplates';

  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const [open, setOpen] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(null);
  const [data, setData] = useState<any[]>([]);
  const [droppedFile, setDroppedFile] = useState<File | null>(null);

  const [refreshTable, refreshing] = useSubmit(
    () =>
      dispatch(getUserSettings([modelName]))
        .unwrap()
        .then((data) => {
          // sort by isPrimary, then by type, then by name
          data=data.filter((d: any) => d.name && d.type && !d.placeholder);
          data.sort((a, b) => {
            if (a?.isPrimary && !b?.isPrimary) return -1;
            if (!a?.isPrimary && b?.isPrimary) return 1;
            if (a.type < b.type) return -1;
            if (a.type > b.type) return 1;
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
          });
          setData(data);
        }),
    []
  );

  useEffect(() => {
    refreshTable();
  }, []);

  const onToggle = useCallback(async (trash: boolean, rowValue: any) => {
    if (trash) {
      const ok = await confirm({
        title: 'Disable Form Template',
        question: 'Are you sure you want to disable this template?',
      });

      if (!ok) return;

      await dispatch(disableUserSetting([modelName, rowValue._id])).unwrap();
    } else {
      await dispatch(enableUserSetting([modelName, rowValue._id])).unwrap();
    }

    refreshTable();
  }, []);

  const onRowClick = (row: any) => {
    setSelectedTemplateId(row._id);
    setOpen(true);
  };

  const onAddNewItemClick = () => {
    setSelectedTemplateId(null);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setDroppedFile(null);
  };

  const onSubmit = () => {
    refreshTable();
  };

  const columns = useMemo(() => [
    {
      Header: 'Primary',
      accessor: 'isPrimary',
      Cell: ({ value }: { value: boolean }) => (value ?  <i className="fas fa-star" />  : null),
      width: 70,
    },
    
    { Header: 'Type', accessor: 'type', width: 200, Cell: ({ value }: { value: string }) => {
      // return value with capitals and space
      return value.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());  
    }
    },
    { Header: 'Name', accessor: 'name', width: 200 },
    { Header: 'File Name', accessor: 'formFileName', width: 200 },
    
  ], []);

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || file.type === 'text/html') {
        setDroppedFile(file);
        setSelectedTemplateId(null);
        setOpen(true);
      } else {
        notification.error({
          message: 'Invalid file type',
          description: 'Please drop a .docx or .html file',
          duration: 3,
        });
      }
    }
  };

  return (
    <div onDragOver={handleDragOver} onDrop={handleDrop}>
      <SettingsCodesLayoutNext
        tableTitle='Form Templates'
        columns={columns}
        rows={data}
        loading={refreshing}
        onToggle={onToggle}
        onRowClick={onRowClick}
        onAddNewItemClick={onAddNewItemClick}
      />

      <EditFormTemplateModal
        open={open}
        onClose={onClose}
        templateId={selectedTemplateId}
        onSubmit={onSubmit}
        initialFile={droppedFile}
      />
    </div>
  );
};