import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from 'react-select';
import DatePickerWrapper from '../../shared/datepicker-wrapper/datepicker-wrapper';
import { formatDateObjectOrNull, formatDateMMDDYY } from '../../utils/date-formatters';
import { appConstants } from '../../_constants';
import moment from 'moment';
import { renderStatusHeader } from '../../components/Common/UpdateProjectStatusModal/update-project-status-modal';
import { useAppDispatch } from '../../shared/hooks/use-app-dispatch';
import { updateProjectStatusAction } from '../../actions/projectStatus';
import { selectGeneralInfo } from '../../selectors/accountSettings';
import { getReasonCodesByType } from '../../modules/settings';
import { useSelector } from 'react-redux';
import styles from './update-project-dates-modal.module.scss';

const UpdateProjectDatesModal = ({ isOpen, toggle, projectData, onUpdate }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [selectDateBox, setSelectDateBox] = useState({
    startDate: projectData.startDate?.iso,
    endDate: projectData.endDate?.iso,
  });
  const [selectedReasons, setSelectedReasons] = useState({
    startDateReasonCode: null,
    endDateReasonCode: null
  });
  const [statusUpdateProject, setStatusUpdateProject] = useState({
    ...projectData.updateStatus,
    projectId: projectData.objectId,
    startDate: projectData.startDate?.iso,
    endDate: projectData.endDate?.iso,
    nextStatus: projectData.jobStatusCodes,
    comment: '',
    updateType: 'UpdateDates',
    startDateReasonCode: null,
    endDateReasonCode: null
  });

  
  const isPostDelivered = projectData.jobStatusCodesCode === 'D'|| projectData.jobStatusCodesCode === 'C' || projectData.jobStatusCodesCode === 'W';

  const [dateChangeReasonCodes, setDateChangeReasonCodes] = useState([]);

  useEffect(() => {
    if(projectData?.projectId !== statusUpdateProject.projectId){
      setStatusUpdateProject({
        ...projectData.updateStatus,
        projectId: projectData.objectId,
        startDate: projectData.startDate?.iso,
        endDate: projectData.endDate?.iso,
        nextStatus: projectData.jobStatusCodes,
        comment: '',
        updateType: 'UpdateDates',
        startDateReasonCode: null,
        endDateReasonCode: null
      });
    }
    dispatch(getReasonCodesByType('DateChange'))
      .unwrap()
      .then((data) => {
        setDateChangeReasonCodes(
          data.map((reason) => ({ label: reason.reasonName, value: reason._id }))
        );
      });

    setIsLoading(false);
  }, [projectData?.projectId]);

  const dispatch = useAppDispatch();

  const handleDatePickerChange = (name, date) => {
    setSelectDateBox(prev => ({ ...prev, [name]: date }));
    setStatusUpdateProject(prev => ({ ...prev, [name]: date }));
  };

  const handleReasonChange = (name, selectedOption) => {
    setSelectedReasons(prev => ({ ...prev, [name]: selectedOption }));
    setStatusUpdateProject(prev => ({ ...prev, [name]: selectedOption?.label }));
  };

  const isDateChanged = (originalDate, newDate) => {
    if (!originalDate || !newDate) return false;
    return moment(originalDate).format('YYYY-MM-DD') !== moment(newDate).format('YYYY-MM-DD');
  };

  const shouldShowReasonCodes = {
    startDate: isDateChanged(projectData.startDate?.iso, selectDateBox.startDate) && dateChangeReasonCodes.length > 0,
    endDate: isDateChanged(projectData.endDate?.iso, selectDateBox.endDate) && dateChangeReasonCodes.length > 0
  };

  // Rest of the existing functions...
  const handleCommentChange = (e) => {
    setStatusUpdateProject(prev => ({ ...prev, comment: e.target.value }));
  };

  const calculateDuration = (start, end) => {
    if(!start || !end){
      return '-';
    }
    return moment(end).diff(moment(start), 'days') + 1;
  };

  const onSubmit = async (updateData) => {
    try {
      setIsLoading(true);
      await dispatch(
        updateProjectStatusAction(updateData)
      );
      onUpdate(updateData);
      toggle();
    } catch (err) {
      setErrorMsg('Failed to update status');
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = () => {
    onSubmit(statusUpdateProject);
  };

  const renderDateIfExists = (date_object) => {
    if(!date_object)
      return '-';

    return formatDateMMDDYY(formatDateObjectOrNull(date_object));
  }

  const accountInfo = useSelector(selectGeneralInfo);
  const restrictContractAdjustments = accountInfo?.restrictContractAdjustments;

  const renderVariance = (original, current, positiveIsBad=true) => {
    if(!original || !current){
      return '-';
    }
    const diff = moment(current).diff(moment(original), 'days');

    if(diff === 0){
      return '-';
    }

    if(diff > 0){
      return (
      <span className={positiveIsBad ? 'text-danger' : 'text-success'}>
        +{diff}
      </span>);
    }else{
      return (
      <span className={positiveIsBad ? 'text-success' : 'text-danger'}>
        {diff}
      </span>);
    }
  }

  const renderDurationDiff = (original, current) => {
    if(original === '-' || current === '-'){
      return '-';
    }
    const diff = current - original;
    if(diff === 0){
      return '-';
    }

    if(diff > 0){
      return (
      <span className="text-danger">
        +{diff}
      </span>);
    }
    return (
      <span className="text-success">
        {diff}
      </span>);
  }

  const currentDuration = calculateDuration(selectDateBox.startDate, selectDateBox.endDate);

  useEffect(() => {
    if(currentDuration !== '-' && currentDuration < 0)
      {
        setErrorMsg('End date cannot be before start date');
      }
      else if(isPostDelivered && selectDateBox.endDate && moment(selectDateBox.endDate).isAfter(moment())){
        setErrorMsg('Delivered date cannot be after today');
      }
      else{
        setErrorMsg('');
      }

  }, [currentDuration,isPostDelivered]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-dialog modal-lg">
      <ModalHeader toggle={toggle}>Update actual / projected dates</ModalHeader>
      <ModalBody>
        <div className={styles.wrapper}>
          {renderStatusHeader(projectData)}

          <table className="table">
            <thead>
              <tr>
                <th>Dates</th>
                <th>Original Contract</th>
                <th>Current Contract</th>
                <th>Actual / projected*</th>
                <th>Variance (days)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Start date</td>
                <td>{renderDateIfExists(projectData.baselineStartDate)}</td>
                <td>{renderDateIfExists(projectData.baselineStartDate)}</td>
                <td>
                  <DatePickerWrapper
                    selected={formatDateObjectOrNull(selectDateBox.startDate)}
                    onChange={(date) => handleDatePickerChange('startDate', date)}
                    dateFormat={appConstants.DATEFORMATPICKER}
                    placeholderText="Click to select a date"
                    todayButton="Today"
                    showMonthDropdown
                    selectsStart
                    endDate={formatDateObjectOrNull(selectDateBox.endDate)}
                  />
                </td>
                <td className="">
                  {renderVariance(projectData.baselineStartDate?.iso, selectDateBox.startDate)}
                </td>
              </tr>
              {shouldShowReasonCodes.startDate && (
                <tr>
                  <td colSpan="5">
                    <div className="form-group">
                      <label><strong>Start Date change reason:</strong></label>
                      <Select
                        value={selectedReasons.startDateReasonCode}
                        onChange={(option) => handleReasonChange('startDateReasonCode', option)}
                        options={dateChangeReasonCodes}
                        className="mt-2"
                      />
                    </div>
                  </td>
                </tr>
              )}
              <tr>
                <td>End date</td>
                <td>{renderDateIfExists(projectData.baselineEndDate)}</td>
                {restrictContractAdjustments ?
                  <td>{renderDateIfExists(projectData.currentBaselineEndDate)}</td> : 
                  <td>
                    <DatePickerWrapper
                      selected={formatDateObjectOrNull(selectDateBox.endDate)}
                      onChange={(date) => handleDatePickerChange('endDate', date)}
                      dateFormat={appConstants.DATEFORMATPICKER}
                      placeholderText="Click to select a date"
                      todayButton="Today"
                      showMonthDropdown
                      selectsEnd
                      startDate={formatDateObjectOrNull(selectDateBox.startDate)}
                      
                    />
                  </td>
                }
                <td>
                  <DatePickerWrapper
                    selected={formatDateObjectOrNull(selectDateBox.endDate)}
                    onChange={(date) => handleDatePickerChange('endDate', date)}
                    dateFormat={appConstants.DATEFORMATPICKER}
                    placeholderText="Click to select a date"
                    todayButton="Today"
                    showMonthDropdown
                    selectsEnd
                    startDate={formatDateObjectOrNull(selectDateBox.startDate)}
                  />
                </td>
                <td className="">
                  {renderVariance(projectData.currentBaselineEndDate?.iso, selectDateBox.endDate)}
                </td>
              </tr>
              {shouldShowReasonCodes.endDate && (
                <tr>
                  <td colSpan="5">
                    <div className="form-group">
                      <label><strong>End Date change reason:</strong></label>
                      <Select
                        value={selectedReasons.endDateReasonCode}
                        onChange={(option) => handleReasonChange('endDateReasonCode', option)}
                        options={dateChangeReasonCodes}
                        className="mt-2"
                      />
                    </div>
                  </td>
                </tr>
              )}
              <tr>
                <td>Duration (days)</td>
                <td>{calculateDuration(projectData.baselineStartDate?.iso, projectData.baselineEndDate?.iso)}</td>
                <td>{calculateDuration(projectData.baselineStartDate?.iso, projectData.currentBaselineEndDate?.iso)}</td>
                <td>{calculateDuration(selectDateBox.startDate, selectDateBox.endDate)}</td>
                <td className="">
                  {renderDurationDiff(
                   calculateDuration(projectData.baselineStartDate?.iso, projectData.currentBaselineEndDate?.iso),calculateDuration(selectDateBox.startDate, selectDateBox.endDate))}
                </td>
              </tr>
            </tbody>
          </table>

          {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}

          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label><strong>Comment:</strong></label>
                <textarea
                  className="form-control"
                  rows="2"
                  onChange={handleCommentChange}
                  name="comment"
                  value={statusUpdateProject.comment}
                  placeholder="Comment"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="modalFooter">
        <button className="btn btn-primary" onClick={toggle}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={handleUpdate} disabled={isLoading||errorMsg?.length>0}>
          Update
        </button>
      </ModalFooter>
    </Modal>
  );
};

export {UpdateProjectDatesModal};