import { useEffect, useMemo, useState, useCallback } from 'react';
import { useConfirm } from '../../../shared/confirmation-dialog';
import { useAppDispatch } from '../../../shared/hooks/use-app-dispatch';
import {
  getContactFieldsSettings,
  disableCustomField,
  enableCustomField,
  reorderContactCustomFields,
} from '../../../modules/settings';
import { useAppSelector } from '../../../shared/hooks/use-app-selector';
import { selectGetContactFieldsSettings } from '../../../modules/settings/selectors';
import { EditContactFieldModal } from './edit-contact-field-modal';
import { inputTypeOptions } from '../../../shared/custom-fields-controls/constants';
import { usePageTitle } from '../../../layouts/title-context';
import { SettingsCodesLayoutNext } from '../../../layouts/settings-codes-layout/settings-codes-layout-next';

export const ContactCustomFieldsPageNext = () => {
  const confirm = useConfirm();
  const dispatch = useAppDispatch();

  usePageTitle('Contact Custom Fields');

  useEffect(() => {
    refreshTable();
  }, []);

  const getLabelByValue = (list: any[], value) => list.find((item) => item.value === value)?.label;

  const { data: settings } = useAppSelector(selectGetContactFieldsSettings);

  const [open, setOpen] = useState(false);
  const [selectedFieldId, setSelectedFieldId] = useState<string | null>(null);

  const refreshTable = () => {
    dispatch(getContactFieldsSettings()).unwrap();
  };

  const onToggle = useCallback(async (trash: boolean, rowValue: any) => {
    if (trash) {
      const ok = await confirm({
        title: 'Disabling Custom Field',
        question: 'Are you sure you want to disable this custom field?',
      });

      if (!ok) return;

      await dispatch(disableCustomField({ fieldId: rowValue._id })).unwrap();
    } else {
      await dispatch(enableCustomField({ fieldId: rowValue._id })).unwrap();
    }

    refreshTable();
  }, []);

  const onRowClick = (row: any) => {
    setSelectedFieldId(row._id);
    setOpen(true);
  };

  const onAddNewItemClick = () => {
    setSelectedFieldId(null);
    setOpen(true);
  };

  const columns = useMemo(() => {
    return [
      {
        Header: 'Name',
        accessor: 'name',
        width: 200,
        searchable: true,
      },
      {
        Header: 'Short Name',
        accessor: 'shortName',
        width: 150,
        searchable: true,
      },
      {
        Header: 'Type',
        accessor: 'controlType',
        width: 100,
        Cell: ({ value }: any) => {
          return <div>{getLabelByValue(inputTypeOptions, value)}</div>;
        },
        searchable: true,
        getSearchValue: (value) => {
          return getLabelByValue(inputTypeOptions, value);
        },
      },
      {
        Header: 'Definition',
        accessor: 'definition',
        width: 400,
        searchable: true,
        Cell: ({ value }: any) => {
          return <div className='text-truncate'>{value}</div>;
        },
      },
    ];
  }, []);

  const onDragEnd = async (result) => {
    const { destination, item } = result;

    await dispatch(
      reorderContactCustomFields({ itemId: item._id, index: destination.index })
    ).unwrap();

    await refreshTable();
  };

  return (
    <>
      <SettingsCodesLayoutNext
        tableTitle='Contact Custom Fields'
        columns={columns}
        rows={settings}
        loading={false}
        onToggle={onToggle}
        onRowClick={onRowClick}
        onAddNewItemClick={onAddNewItemClick}
        onDragEnd={onDragEnd}
      />

      <EditContactFieldModal
        open={open}
        onClose={() => setOpen(false)}
        onAfterSubmit={refreshTable}
        selectedFieldId={selectedFieldId}
      />
    </>
  );
};
