import React, { useState, useEffect } from 'react';
import auth0 from 'auth0-js';
import history from '../../routes/history';
import axios from 'axios';
import { appConstants, AUTH_CONFIG } from '../../_constants';
import { notification } from 'antd';
import { clearLoggedInUser } from '../../api/helper.js';
import { initPdfJs } from '../../pdfjs-config';

export function useAuth ()  {

  initPdfJs();

  const auth0Instance = new auth0.WebAuth({
    domain: AUTH_CONFIG.customDomain,
    clientID: AUTH_CONFIG.clientId,
    redirectUri: AUTH_CONFIG.callbackUrl,
    audience: `https://${AUTH_CONFIG.domain}/userinfo`,
    responseType: 'token id_token',
    scope: 'openid profile email phone',
  });

  const login = (isSignUp, loginHint) => {
    let auth0_options = {};

    if (!loginHint) {
      loginHint = '';
    }

    const isRedirectTo = new URLSearchParams(history.location.search).get('redirectToAfterLogin');
    const redirectTo = isRedirectTo ? isRedirectTo : '/';

    if (isSignUp === true) {
      auth0_options = {
        login_hint: loginHint,
        screen_hint: 'signup',
        prompt: 'signup',
        state: redirectTo,
      };
    } else {
      auth0_options = {
        login_hint: loginHint,
        screen_hint: 'signin',
        prompt: 'login',
        state: redirectTo,
      };
    }

    auth0Instance.authorize(auth0_options);
  };

  const handleAuthentication = async () => {
    await auth0Instance.parseHash(async (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        await setSession(authResult);
        await updatePermissions();
        const redirectTo = decodeURIComponent(authResult?.state);
        history.push('/callback-auth', { redirectTo });
      } else if (err) {
        history.replace('/');
        alert(`Error: ${err.error}. Session timeout, please login again!`);
      }
    });
  };

  const setSession = (authResult) => {
    let expiresAt = JSON.stringify(authResult.expiresIn * 5000 + new Date().getTime());
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
  };

  const getAccessToken = () => {
    let accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      history.replace('/');
    }
    return accessToken;
  };

  const getIdToken = () => {
    let idToken = localStorage.getItem('id_token');
    return idToken;
  };

  const getProfile = (cb) => {
    let accessToken = getAccessToken();
    if (accessToken) {
      auth0Instance.client.userInfo(accessToken, (err, profile) => {
        if (profile) {
          // this.userProfile = profile; // Handle userProfile
        }
        cb(err, profile);
      });
    }
  };

  const logout = (reset = true) => {
    axios({
      method: 'POST',
      url: appConstants.WEB_SERVICE_URL + '/functions/cpostLogout',
      headers: {
        'X-Parse-Application-Id': appConstants.PARSE_APPLICATION_ID,
        'X-Parse-REST-API-Key': appConstants.PARSE_REST_API_KEY,
        authorization: localStorage.getItem('JWTTOKEN'),
      },
      data: {
        userId: localStorage.getItem('userId'),
        sessionToken: localStorage.getItem('sessionToken'),
        loginPlatform: 'web',
      },
    }).then((response) => {
      clearLoggedInUser();

      let redirectToAfterLogin;
      if (reset) {
        if (window.location.hostname === 'app.commandpost.ai') {
          window.location.href = 'https://commandpost.ai/';
        } else {
          history.replace('/');
        }
      } else {
        const { pathname, search, hash } = history.location;
        if (pathname !== ('/' || '/logout' || '/login'))
          redirectToAfterLogin = encodeURIComponent(pathname + search + hash);
      }

      auth0Instance.logout({
        returnTo: AUTH_CONFIG.logoutUrl(
          redirectToAfterLogin ? `?redirectToAfterLogin=${redirectToAfterLogin}` : ''
        ),
        clientId: AUTH_CONFIG.clientId,
      });
    });
  };

  const isAuthenticated = () => {
    let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    if (expiresAt !== null) return new Date().getTime() < expiresAt;
    else return '';
  };

  return {
    login,
    logout,
    isAuthenticated,
    handleAuthentication,
    getAccessToken,
    getIdToken,
    getProfile,
  };
};

export default useAuth;

export const recentSearch = (searchKey) => {
  if(!searchKey)
    return;
  var tempData = {};
  if (
    localStorage.getItem('searchData') !== 'undefined' &&
    localStorage.getItem('searchData') !== null
  ) {
    tempData = JSON.parse(localStorage.getItem('searchData'));
    tempData[searchKey] = searchKey;
  } else {
    tempData = {};
    tempData[searchKey] = searchKey;
  }
  localStorage.setItem('searchData', JSON.stringify(tempData));
};

export const updatePermissions = async () => {
  const sessionToken = localStorage.getItem('sessionToken');
  const JWTTOKEN = localStorage.getItem('JWTTOKEN');

  if (!sessionToken || !JWTTOKEN) {
    return;
  }

  axios
    .post('/parse/functions/getUserPermissions', {
      baseURL: appConstants.WEB_SERVICE_URL_WITHOUT_PARSE,
      headers: {
        'X-Parse-Application-Id': appConstants.PARSE_APPLICATION_ID,
        'X-Parse-REST-API-Key': appConstants.PARSE_REST_API_KEY,
        'X-Parse-Session-Token': sessionToken,
        authorization: JWTTOKEN,
      },
    })
    .then((response) => {
      if (response.data?.result) {
        localStorage.setItem('modulePermissions', JSON.stringify(response.data.result));
      }
    })
    .catch((error) => {
      return;
    });
};

export const callAlertMessage = (messageType, message) => {
  if (messageType === 'success') {
    successNotification(message);
  } else {
    errorNotification(message);
  }
};

export const UpperCaseName = (str) => {
  var splitStr = str.split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
};

export const sortAsc = (data, name) => {
  if (data && data.length === 0) {
    data = Object.keys(data);
  }
  if (data && data.length) {
    data.sort(function (a, b) {
      var aName = a[name].toLowerCase();
      var bName = b[name].toLowerCase();
      if (aName < bName) {
        return -1;
      }
      if (aName > bName) {
        return 1;
      }
      return 0;
    });
  }
  return data;
};

const successNotification = (message) => {
  notification.success({
    message: message,
    duration: 3,
  });
};

const errorNotification = (message) => {
  notification.error({
    message: message,
    duration: 10,
  });
};
