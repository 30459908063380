import React, { useEffect, useState } from 'react';
import loading from './loading.svg';
import history from '../../routes/history';
import {
  SignInUserAction,
} from '../../actions/user';
import { withRouter, useLocation} from 'react-router-dom';
import { connect } from 'react-redux';
import { AUTH_CONFIG } from '../../_constants';
import { Userpilot } from 'userpilot';
import {useAuth} from '../Auth/Auth';
import { callAlertMessage } from '../Auth/Auth';
import { getAccountSettingsAction } from '../../actions/accountSettings';

Userpilot.initialize(process.env.REACT_APP_USER_PILOT_KEY);

const CallbackAuth = ({
  SignInUserAction,
  signinUser,
  isSignInError,
  success,
  message,
  userErrorInfo,
}) => {
  const [profile, setProfile] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const { userProfile, getProfile, getIdToken } = useAuth();
    if (!userProfile) {
      getProfile((err, profile) => {
        if (err) {
          console.error('Error getting user profile:', err);
          history.replace('/error', { error: 'Error getting user profile' });
          return;
        }
        setProfile(profile);
        const auth0_AccessToken = getIdToken();
        SignInUserAction({
          username: profile.username,
          userEmail: profile.email,
          authId: profile.sub,
          aud: AUTH_CONFIG.clientId,
          auth0token: auth0_AccessToken,
        });
      });
    }
  }, [SignInUserAction, history]);

  useEffect(() => {
    if (isSignInError) {
      if (success === 0) {
        callAlertMessage('error', message);
        history.replace('/error', { error: message, from: location.state?.redirectTo, userErrorInfo});
      }
    } else {
      if (success === 1) {
        callAlertMessage('success', message);
      }

      if (signinUser?.data){
        const userData = signinUser.data;
        const accountData = userData.accountId;
        const chargebeeStatus = accountData?.chargebeeStatus ?? {};
        const fullName = [userData.firstName, userData.lastName].filter(Boolean).concat(' ');
        Userpilot.identify(userData.objectId, {
          name: fullName,
          email: userData.username,
          created_at: userData.createdAt,
          accountId: String(accountData.objectId),
          userRoleName: userData.roleName,
          subscriptionStatus: chargebeeStatus?.subscriptionStatus,
          trialEnds: chargebeeStatus?.trialEnd,
          company: {
            id: String(accountData.objectId),
            name: accountData.companyName,
            created_at: accountData.created_at,
            adminUserId: accountData.adminUserId,
          },
          plan: chargebeeStatus
            ? {
                id: chargebeeStatus?.chargebeeId,
                subscriptionStatus: chargebeeStatus?.subscriptionStatus,
                planId: chargebeeStatus?.planId,
                created_at: chargebeeStatus?.created_at,
                trialEnd: chargebeeStatus?.trialEnd,
                nextBillingAt: chargebeeStatus?.nextBillingAt,
                card_status: chargebeeStatus?.card_status,
                addonIds: chargebeeStatus?.addonIds,
              }
            : { status: 'No account' },
        });
        if (userData.onboardingStatus === 0 || !userData.onboardingStatus) {
          //
          getAccountSettingsAction();
          history.replace('/welcome');
        } else {
          history.replace(
            history.location.state?.redirectTo ? history.location.state.redirectTo : '/home'
          );
        }
      }
    }
  }, [isSignInError, success, message, signinUser]);

  const style = {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'white',
  };

  return (
    <div style={style}>
      <img src={loading} alt='loading' />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { user } = state;

  return {
    signinUser: user?.signinUser,
    isSignInError: user?.isSignInError,
    success: user?.success,
    message: user?.message,
    userErrorInfo: { ...user?.signInUserInfo, ...user?.signInAccountInfo },
    };
};

export default withRouter(
  connect(mapStateToProps, {
    SignInUserAction,
    getAccountSettingsAction,
  })(CallbackAuth)
);
