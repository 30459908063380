import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SettingsCodesLayoutNext } from '../../../layouts/settings-codes-layout/settings-codes-layout-next';
import { usePageTitle } from '../../../layouts/title-context';
import {
  disableUserSetting,
  enableUserSetting,
  getJobStatusCodesSettings,
  getUserSettings,
} from '../../../modules/settings';
import { useAppDispatch } from '../../../shared/hooks/use-app-dispatch';
import { useConfirm } from '../../../shared/confirmation-dialog';
import { EditProjectActionModal } from './edit-project-action-modal';
import { useSubmit } from '../../../shared/hooks/use-submit';
import { selectAllUsedJobStatus } from '../../../selectors/jobStatusCode';
import { useAppSelector } from '../../../shared/hooks/use-app-selector';
export const ProjectActionsPage: React.FC = () => {
  usePageTitle('Project Actions Settings');
  const modelName = 'projectActions';

  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const [open, setOpen] = useState(false);
  const [selectedProjectActionId, setSelectedProjectActionId] = useState<string | null>(null);
  const [data, setData] = useState<any[]>([]);

  const [jobStatusCodes, setJobStatusCodes] = useState<any[]>([]);
  const allJobStatuses = useAppSelector(selectAllUsedJobStatus);

  const [refreshTable, refreshing] = useSubmit(
    () =>
      dispatch(getUserSettings([modelName]))
        .unwrap()
        .then((data) => {
          // sort by isPrimary, then by type, then by name
          data = data.filter((d: any) => d.name);
          data.sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
          });
          setData(data);
        }),
    []
  );

  useEffect(() => {
    refreshTable();

    dispatch(getJobStatusCodesSettings({ includeDisabled: true }))
      .unwrap()
      .then((data) => {
        setJobStatusCodes(data);
      });
  }, []);

  useEffect(() => {
    if (allJobStatuses) {
      setJobStatusCodes(allJobStatuses);
    }
  }, [allJobStatuses]);

  const onToggle = useCallback(async (trash: boolean, rowValue: any) => {
    if (trash) {
      const ok = await confirm({
        title: 'Disable project action',
        question: 'Are you sure you want to disable this action?',
      });

      if (!ok) return;

      await dispatch(disableUserSetting([modelName, rowValue._id])).unwrap();
    } else {
      await dispatch(enableUserSetting([modelName, rowValue._id])).unwrap();
    }

    refreshTable();
  }, []);

  const onRowClick = (row: any) => {
    setSelectedProjectActionId(row._id);
    setOpen(true);
  };

  const onAddNewItemClick = () => {
    setSelectedProjectActionId(null);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    refreshTable();
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Action Name',
        accessor: 'name',
        width: 200,
      },
      {
        Header: 'Title',
        accessor: 'title',
        width: 200,
      },
    ],
    []
  );

  return (
    <div>
      <SettingsCodesLayoutNext
        tableTitle='Project actions'
        columns={columns}
        rows={data}
        loading={refreshing}
        onToggle={onToggle}
        onRowClick={onRowClick}
        onAddNewItemClick={onAddNewItemClick}
      />
      <EditProjectActionModal
        open={open}
        onClose={onClose}
        onSubmit={onSubmit}
        settingId={selectedProjectActionId}
        jobStatusCodes={jobStatusCodes as any}
      />
    </div>
  );
};
