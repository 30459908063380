import Avatar from 'react-avatar';
import renderHTML from 'html-react-parser';
import { Link } from 'react-router-dom';
import {
  formatBidDueDate,
  formatDate,
  //fromNow,
  getDifferenceBetweenDates,
} from '../../utils/date-formatters';
import { useState, useEffect } from 'react';
import { Confirm } from '../../shared/confirmation-dialog';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { Tooltip } from 'react-tooltip';
import { t } from 'i18next';
import axios from 'axios';
import { appConstants } from '../../_constants';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import styles from './right-section.module.scss';
const maxLength = 1000; // max length of message to show

function getFileExtension(fileName) {
  if (!fileName) return '';
  fileName = fileName.toLowerCase().trim();
  const lastIndex = fileName.lastIndexOf('.');
  return fileName.substring(lastIndex + 1);
}

const getSearchText = (items) => {
  const str2searchAll = [];

  if (!items) return '';

  if (!Array.isArray(items)) {
    items = [items];
  }

  items.forEach((item) => {
    if (item) {
      const str2search = [];

      if (item.emailSub) {
        str2search.push(item.emailSub);
      }
      if (item.emailBody) {
        str2search.push(item.emailBody);
      }
      if (item.userName) {
        str2search.push(item.userName);
      }
      if (item.emailTo) {
        str2search.push(item.emailTo.map((to) => to.value).join(' '));
      }
      if (item.attachments) {
        str2search.push(item.attachments.map((attachment) => attachment.fileName).join(' '));
      }

      if (item.tags) {
        str2search.push(item.tags.map((tag) => tag.value).join(' '));
      }
      if (item.description) {
        str2search.push(item.description);
      }

      if (item.title) {
        str2search.push(item.title);
      }
      if (item.comment) {
        str2search.push(item.comment);
      }
      if (item.location) {
        str2search.push(item.location);
      }

      if (item.updateType) {
        str2search.push(item.updateType);
      }

      str2searchAll.push(
        str2search
          .filter(Boolean)
          .join(' ')
          .replace(/<[^>]*>?/gm, '')
      );
    }
  });
  return str2searchAll.join(' ');
};

const MessageComponent = ({ message, maxLength }) => {
  const [showFullMessage, setShowFullMessage] = useState(false);

  const toggleMessage = () => {
    setShowFullMessage(!showFullMessage);
  };

  const truncatedMessage =
    message.length > maxLength ? `${message.slice(0, maxLength)}...` : message;

  return (
    <div className='noteMessageText'>
      <div dangerouslySetInnerHTML={{ __html: showFullMessage ? message : truncatedMessage }} />

      {message.length > maxLength && (
        <>
          <a onClick={toggleMessage}>{showFullMessage ? 'Show less' : 'Show all'}</a>
        </>
      )}
      {message.length === 0 && <span>&nbsp;</span>}
    </div>
  );
};

export const ProjectActivityView = ({ activityData, projectId, filterText }) => {
  const {
    eventChangeByUser,
    comment,
    updateType,
    statusReasonCode,
    jobStatusCodesName,
    changeOrderReasonCode,
    changeOrderNum,
  } = activityData;

  const userFullName = eventChangeByUser.firstName + ' ' + eventChangeByUser.lastName;

  // update types are EditProject LeadPromotion, BidACTION

  const hiddenUpdates = ['EditProject', 'BidCreate', 'BidUpdate', 'ProjectCreated'];
  const showUpdate = !hiddenUpdates.includes(updateType);

  const [shouldHideUpdate, setShouldHideUpdate] = useState(false);
  const [activityTextStripped, setActivityTextStripped] = useState('');

  const nextStatusName = () => {
    return jobStatusCodesName;
  };

  const activityName = () => {
    if (updateType === 'EditProject') {
      return 'Project details edited';
    } else if (updateType === 'ProjectCreated') {
      return 'Project created';
    } else if (updateType === 'LeadPromotion') {
      return 'Lead promoted' + ' to ' + nextStatusName();
    } else if (updateType === 'BidCreate') {
      return 'Bid created';
    }else if(updateType === 'ToggleLead'){
      return (nextStatusName() === 'Lead' ? 'Returned to leads' : 'Moved to ' + nextStatusName());
    } else if (
      updateType === 'ProjectOrigContractUpdate' ||
      updateType === 'UpdateOriginalContract'
    ) {
      return 'Original contract updated';
    } else if (updateType === 'BidUpdate') {
      return 'Bid edited';
    } else if (updateType === 'Submitted') {
      return 'Submitted, moved' + ' to ' + nextStatusName();
    } else if (updateType === 'Rebid') {
      return 'Returned to ' + nextStatusName();
    } else if (updateType === 'Lost') {
      return 'Bid marked ' + nextStatusName();
    } else if (updateType === 'DirectLost') {
      return 'Bid marked ' + nextStatusName();
    } else if (updateType === 'DirectRebid') {
      return 'Bid directly rebid to ' + nextStatusName();
    } else if (updateType === 'Approved') {
      return 'Accepted, moved to ' + nextStatusName();
    } else if (updateType === 'DirectPromote') {
      return 'Accepted, moved to ' + nextStatusName();
    } else if (updateType === 'ProceedToActive') {
      return 'NTP received, moved to ' + nextStatusName();
    } else if (updateType === 'reopenProject') {
      return 'Project reopened';
    } else if (updateType === 'On Hold') {
      return 'Moved to ' + nextStatusName();
    } else if (updateType === 'ResumeFromHold') {
      return 'Project resumed';
    } else if (updateType === 'Suspended') {
      return 'Moved to ' + nextStatusName();
    } else if (updateType === 'Unsuspend') {
      return 'Project resumed';
    } else if (updateType === 'Delivered') {
      return 'Project delivered';
    } else if (updateType === 'ChangeOrder') {
      return 'Change order added';
    } else if (updateType === 'OverrideStatus') {
      return 'Status overridden to ' + nextStatusName();
    } else if (updateType === 'UpdateWIP') {
      return 'Project progress updated';
    } else if (updateType === 'Closeout') {
      return 'Project closed out';
    } else if (updateType === 'Closed') {
      return 'Project closed';
    } else if (updateType === 'ProjectCreated') {
      return 'Project created';
    } else if (updateType === 'BidStatusReasonCode') {
      return 'Bid Status Change Reason Code';
    } else {
      return 'Project updated';
    }
  };

  const activityHeader = () => {
    return (
      <div className='clearfix headBox'>
        <div className='outputPic'>
          <Link
            to={{
              pathname: `/contact-details/` + eventChangeByUser.contactId,
              state: { objectId: eventChangeByUser.contactId },
            }}
          >
            {eventChangeByUser.userImage !== '' ? (
              <img src={eventChangeByUser.userImage} alt='Avatar' />
            ) : (
              <Avatar size='40' colors={['red', 'green', 'blue']} name={userFullName} />
            )}
          </Link>
        </div>
        <div className='timelineTitleBox'>
          <div className='username'>
            <i className='fa fa-tasks'></i>&nbsp;
            {activityName()}
          </div>
          <br />
          <div className='dateBox'>
            {formatBidDueDate(activityData.createdAt)} by{' '}
            <Link
              to={{
                pathname: `/contact-details/` + eventChangeByUser.contactId,
                state: { objectId: eventChangeByUser.contactId },
              }}
            >
              {userFullName}
            </Link>
          </div>
        </div>
      </div>
    );
  };

  const reasonCodeSection = () => {
    return (
      <>
        {statusReasonCode?.length > 0 && (
          <div className='sdata'>
            <strong>Reason code:</strong> {statusReasonCode}
          </div>
        )}
        {changeOrderReasonCode?.length > 0 && (
          <div className='sdata'>
            <strong>
              {changeOrderNum > 0 ? 'CO#' + changeOrderNum.toString() : 'Reason code'}:
            </strong>{' '}
            {changeOrderReasonCode}
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    if (activityData) {
      setActivityTextStripped(getSearchText(activityData));
    }
  }, [activityData]);

  useEffect(() => {
    if (filterText?.length > 0) {
      if (activityTextStripped?.toLowerCase().includes(filterText?.toLowerCase())) {
        setShouldHideUpdate(false);
      } else {
        setShouldHideUpdate(true);
      }
    } else {
      setShouldHideUpdate(false);
    }
  }, [filterText]);

  const getSearchText = () => {
    const str2search = [
      activityName(),
      statusReasonCode,
      comment,
      changeOrderReasonCode,
      userFullName,
    ]
      .filter(Boolean)
      .join(' ')
      .replace(/<[^>]*>?/gm, '');
    return str2search;
  };

  return (
    showUpdate &&
    !shouldHideUpdate && (
      <div className={'outputBox notesHtml'} key={projectId + 'activity'}>
        <div className='editCloseBox rightSectionOverflowMenu'>{}</div>

        <div className='outputInner'>
          {activityHeader()}
          {reasonCodeSection()}
          {comment?.length > 0 && <MessageComponent message={comment} maxLength={maxLength} />}
        </div>
      </div>
    )
  );
};

export const NoteView = ({
  noteData,
  noteId,
  typeId,
  typeRef,
  editNote,
  replyToNote,
  pinNote,
  cancelDelete,
  showHideLightBox,
  asModal,
  modalOpen,
  toggle,
  modalClassName = 'modal-lg',
  filterText,
  isEditing,
}) => {
  const [noteDataLocal, setNoteData] = useState(noteData);
  const [noteDataLoaded, setNoteDataLoaded] = useState(false);
  const [shouldHideNote, setShouldHideNote] = useState(false);
  const [noteTextStripped, setNoteTextStripped] = useState('');

  useEffect(() => {
    if (noteData) {
      setNoteData(noteData);
      setNoteDataLoaded(true);
    }
  }, [noteData]);

  useEffect(() => {
    if (noteId && typeId) {
      fetchNoteData();
    }
  }, [noteId]);

  useEffect(() => {
    if (noteDataLoaded) {
      setNoteTextStripped(getSearchText(noteData));
    }
  }, [noteDataLoaded]);

  useEffect(() => {
    if (filterText?.length > 0 && noteDataLoaded) {
      const filterTextLower = filterText?.toLowerCase().trim();
      if (
        noteDataLocal?.title?.toLowerCase().includes(filterTextLower) ||
        noteTextStripped?.toLowerCase().includes(filterTextLower)
      ) {
        setShouldHideNote(false);
      } else {
        setShouldHideNote(true);
      }
    } else {
      setShouldHideNote(false);
    }
  }, [filterText]);

  const fetchNoteData = async () => {
    const userId = localStorage.getItem('userId');
    const accountId = localStorage.getItem('accountId');

    const { data } = await axios.post(
      'parse/functions/getNotes',
      {
        userId: userId,
        accountId: accountId,
      },
      {
        baseURL: appConstants.WEB_SERVICE_URL_WITHOUT_PARSE,
        headers: {
          'X-Parse-Application-Id': appConstants.PARSE_APPLICATION_ID,
          'X-Parse-REST-API-Key': appConstants.PARSE_REST_API_KEY,
          'X-Parse-Session-Token': localStorage.getItem('sessionToken'),
          authorization: localStorage.getItem('JWTTOKEN'),
        },
        params: {
          accountId: accountId,
          typeId: typeId,
          typeRef: typeRef,
          objectId: noteId,
          onlyTags: false,
        },
      }
    );
    setNoteData(data?.result.data?.[0]);
    setNoteDataLoaded(true);
  };

  const noteActions = () => {
    return (
      <>
        {noteDataLocal.isPinned === true ? (
          <i className='fa fa-thumbtack p-2' style={{ color: 'red' }} aria-hidden='true'></i>
        ) : (
          <></>
        )}

      {(editNote || replyToNote || cancelDelete) && (
        <li className='dropdown px-2' key={noteDataLocal.objectId + 'dropdownAction'}>
          <div
            className='rightSectionOverflowMenu'
            data-bs-toggle='dropdown'
            href='#'
            role='button'
            aria-hidden='true'
            aria-expanded='false'
          >
            <i className='fa fa-ellipsis-v p-2' aria-hidden='false'></i>
          </div>
          <ul className='dropdown-menu dropdown-menu-end ml-auto'>
            {noteDataLocal?.isCanceled !== true && editNote && (
              <li className='dropdown-item' key={noteDataLocal.objectId + 'editNote'}>
                <div
                  title='Edit'
                  onClick={() => editNote(noteDataLocal)}
                  data-tooltip-content={t('Edit Note')}
                  data-tooltip-id='EditNote'
                >
                  Edit Note
                </div>
                <Tooltip id='EditNote' place='right' delayShow={1000} />
              </li>
            )}
            {replyToNote && (
              <li className='dropdown-item' key={noteDataLocal.objectId + 'replyNote'}>
                <div
                  title='Reply'
                  onClick={() => replyToNote(noteDataLocal)}
                  data-tooltip-content={t('Reply to this note')}
                  data-tooltip-id='ReplyNote'
                >
                  Reply to
                </div>
                <Tooltip id='ReplyNote' place='right' delayShow={1000} />
              </li>
            )}
            {pinNote && !(noteDataLocal.isPinned === true) ? (
              <li className='dropdown-item' key={noteDataLocal.objectId + 'pinNote'}>
                <div
                  title='Pin'
                  onClick={() => pinNote(noteDataLocal, true)}
                  data-tooltip-content={t('Pin this note')}
                  data-tooltip-id='PinNote'
                >
                  Pin note
                </div>
                <Tooltip id='PinNote' place='right' delayShow={1000} />
              </li>
            ) : (
              <li className='dropdown-item' key={noteDataLocal.objectId + 'pinNote'}>
                <div
                  title='Pin'
                  onClick={() => pinNote(noteDataLocal, false)}
                  data-tooltip-content={t('Unpin this note')}
                  data-tooltip-id='UnpinNote'
                >
                  Unpin note
                </div>
                <Tooltip id='PinNote' place='right' delayShow={1000} />
              </li>
            )}

            {noteDataLocal?.isCanceled !== true && cancelDelete && (
              <>
                <li>
                  <div className='dropdown-divider'></div>
                </li>
                <li className='dropdown-item' key={noteDataLocal.objectId + 'deleteNote'}>
                  <Confirm
                    render={(confirm) => (
                      <div
                        title='Delete'
                        onClick={cancelDelete(noteDataLocal, confirm)}
                        data-tooltip-content={t('Delete note')}
                        data-tooltip-id='DeleteNote'
                      >
                        Delete Note
                      </div>
                    )}
                  />
                  <Tooltip id='DeleteNote' place='right' delayShow={1000} />
                </li>
              </>
            )}
          </ul>
        </li>
      )}
      </>
    );
  };

  const isSubItem = noteDataLocal?.originName?.length > 0;

  const noteHeader = () => {
  
    const typeRef = noteDataLocal?.typeRef;

    const getLinkPath = () => {

        switch (typeRef) {
          case 'PROJECT':
            return `/project-details/${noteDataLocal.typeId}`;
          case 'CONTACT':
            return `/contact-details/${noteDataLocal.typeId}`;
          case 'COMPANY':
            return `/company-details/${noteDataLocal.typeId}`;
          default:
            return '#';
        }
      };
    
    return (
      <div>{isSubItem && (
        <div className={styles.originBar}>
          Note on: &nbsp;
          <Link to={getLinkPath()} className={styles.originLink}>
            {noteDataLocal.originName}
          </Link>
        </div>
      )}
      <div className='clearfix headBox'>
        <div className='outputPic'>
          <Link
            to={{
              pathname: `/contact-details/` + noteDataLocal.contactId,
              state: { objectId: noteDataLocal.contactId },
            }}
          >
            {noteDataLocal.userImage !== '' ? (
              <img src={noteDataLocal.userImage} alt='Avatar' />
            ) : (
              <Avatar size='40' colors={['red', 'green', 'blue']} name={noteDataLocal.userName} />
            )}
          </Link>
        </div>
        <div className='timelineTitleBox'>
          <div className='username'>
            <i className='fa-regular fa-message'></i>&nbsp;
            {noteDataLocal.title ?? noteDataLocal.comment}
          </div>
          <br />
          <div className='dateBox'>
            {formatBidDueDate(noteDataLocal.createdAt)} by{' '}
            <Link
              to={{
                pathname: `/contact-details/` + noteDataLocal.contactId,
                state: { objectId: noteDataLocal.contactId },
              }}
            >
              {noteDataLocal.userName}
            </Link>
          </div>
        </div>

        {noteDataLocal.followUpStatus === true && (
          <div className='dateBox fDateBox'>
            Follow-up date: {formatDate(noteDataLocal.createdAt)}
          </div>
        )}
      </div>
      </div>
    );
  };

  return (
    noteDataLoaded &&
    !shouldHideNote &&
    !isEditing &&
    (!asModal ? (
      <div
        className={(noteDataLocal?.replyTo ? 'outputBox notesHtml ms-4 ' : 'outputBox notesHtml ')+ (isSubItem? styles.subItem : '')}
        key={noteDataLocal.objectId + 'note'}
      >
        <div className='editCloseBox rightSectionOverflowMenu d-flex'>
          {noteActions()}
        </div>

        <div className='outputInner'>
          {noteHeader()}
          {noteDataLocal && noteDataLocal.description ? (
            <MessageComponent message={noteDataLocal.description} maxLength={maxLength} />
          ) : (
            <>&nbsp;</>
          )}
          {attachmentList(
            noteDataLocal,
            noteDataLocal?.attachments,
            showHideLightBox,
            noteDataLocal.objectId
          )}
          {tagList(noteDataLocal?.tags)}
        </div>
      </div>
    ) : (
      <Modal
        backdrop='static'
        //keyboard={true}
        isOpen={true}
        toggle={toggle}
        className={modalClassName}
      >
        <ModalBody>
          <div
            className={noteDataLocal?.replyTo ? 'outputBox notesHtml ms-4' : 'outputBox notesHtml'}
            key={noteDataLocal.objectId + 'note'}
          >
            <div className='editCloseBox rightSectionOverflowMenu'>{noteActions()}</div>

            <div className='outputInner'>
              {noteHeader()}
              {noteDataLocal && noteDataLocal.description && (
                <MessageComponent message={noteDataLocal.description} maxLength={maxLength} />
              )}
              {attachmentList(
                noteDataLocal,
                noteDataLocal?.attachments,
                showHideLightBox,
                noteDataLocal.objectId
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className='btn btn-primary' onClick={toggle}>
            Close
          </button>
        </ModalFooter>
      </Modal>
    ))
  );
};

const tagList = (tags) => {
  return (
    <>
      {tags?.length > 0 && <strong>Tags:</strong>}
      <ul className='clearfix w1'>
        {tags?.map((tag) => (
          <li key={tag.value}>
            <span className='tagIcon'>{tag.value}</span>
          </li>
        ))}
      </ul>
    </>
  );
};

const attachmentList = (rootObject, attachments, showHideLightBox, objectId) => {
  if (!attachments?.length > 0 || !showHideLightBox) {
    return <></>;
  }

  return (
    <div className='attachmentList'>
      <ul className='clearfix w1 mt-2'>
        {rootObject &&
          typeof attachments !== 'undefined' &&
          attachments.map((w, index) =>
            w?.fileName ? (
              <li key={objectId + w.fileName + index}>
                {w.fileName.includes('.pdf') ? (
                  <div onClick={() => showHideLightBox(rootObject, index)}>
                    <span
                      className='attachmentIcon'
                      data-tooltip-id={objectId + index + 'hover'}
                      data-tooltip-content={w.fileName}
                    >
                      <FileIcon
                        extension={getFileExtension(w.fileName)}
                        {...defaultStyles[getFileExtension(w.fileName)]}
                      />
                      <br></br>
                      {w.fileName}
                    </span>
                  </div>
                ) : w.fileType.includes('image') ? (
                  <div onClick={() => showHideLightBox(rootObject, index)}>
                    <span
                      className='attachmentIcon'
                      data-tooltip-id={objectId + index + 'hover'}
                      data-tooltip-content={w.fileName}
                    >
                      <img src={w.thumbnailUrl}></img>
                      <br></br>
                      {w.fileName}
                    </span>
                  </div>
                ) : (
                  <div onClick={() => showHideLightBox(rootObject, index)}>
                    <span
                      className='attachmentIcon'
                      data-tooltip-id={objectId + index + 'hover'}
                      data-tooltip-content={w.fileName}
                    >
                      <FileIcon extension={getFileExtension(w.fileName)} />
                      <br></br>
                      {w.fileName}
                    </span>
                  </div>
                )}
                <Tooltip id={objectId + index + 'hover'} place={'bottom'} />
              </li>
            ) : (
              ''
            )
          )}
      </ul>
    </div>
  );
};

export const EmailView = ({
  emailData,
  emailId,
  typeId,
  typeRef,
  editDraft,
  copyEmail,
  forwardEmail,
  replyEmail,
  cancelDelete,
  showHideLightBox,
  asModal,
  filterText,
  modalOpen,
  toggle,
  modalClassName = 'modal-lg',
  isEditing,
}) => {
  const [emailDataLocal, setEmailData] = useState(emailData);
  const [emailDataLoaded, setEmailDataLoaded] = useState(false);
  const [shouldHideEmail, setShouldHideEmail] = useState(false);
  const [emailTextStripped, setEmailTextStripped] = useState('');

  useEffect(() => {
    if (emailData) {
      setEmailData(emailData);
      setEmailDataLoaded(true);
      setEmailTextStripped(getSearchText(emailData));
    }
  }, [emailData]);

  useEffect(() => {
    if (emailDataLoaded) {
      if (filterText?.length > 0) {
        if (emailTextStripped?.toLowerCase().includes(filterText?.toLowerCase())) {
          setShouldHideEmail(false);
        } else {
          setShouldHideEmail(true);
        }
      } else {
        setShouldHideEmail(false);
      }
    }
  }, [filterText]);

  const [wasNotFound, setWasNotFound] = useState(false);

  useEffect(() => {
    if (emailId && typeId) {
      setWasNotFound(false);
      setEmailDataLoaded(false);
      fetchEmailData();
    }
  }, [emailId]);

  const fetchEmailData = async () => {
    const userId = localStorage.getItem('userId');
    const accountId = localStorage.getItem('accountId');

    const { data } = await axios.post(
      'parse/functions/getSectionEmail',
      {
        userId: userId,
        accountId: accountId,
      },
      {
        baseURL: appConstants.WEB_SERVICE_URL_WITHOUT_PARSE,
        headers: {
          'X-Parse-Application-Id': appConstants.PARSE_APPLICATION_ID,
          'X-Parse-REST-API-Key': appConstants.PARSE_REST_API_KEY,
          'X-Parse-Session-Token': localStorage.getItem('sessionToken'),
          authorization: localStorage.getItem('JWTTOKEN'),
        },
        params: {
          accountId: accountId,
          typeId: typeId,
          typeRef: typeRef,
          objectId: emailId,
        },
      }
    );

    if (!data?.result?.data?.length > 0) {
      setWasNotFound(true);
      setEmailDataLoaded(true);
    } else {
      setEmailData(data?.result.data?.[0]);
      setEmailDataLoaded(true);
    }
  };

  const maxLength = 1000; // max length of message to show

  const emailActions = () => {
    return (
      (editDraft || copyEmail || cancelDelete) && (
        <li className='dropdown px-2' key={emailDataLocal.objectId + 'dropdownAction'}>
          <a
            className='dropdown-toggle rightSectionOverflowMenu'
            data-bs-toggle='dropdown'
            href='#'
            role='button'
            aria-hidden='true'
            aria-expanded='false'
          >
            <i className='fa fa-ellipsis-emailDataLocal' aria-hidden='true'></i>
          </a>
          <ul className='dropdown-menu dropdown-menu-end ml-auto'>
            {emailDataLocal?.isDraft == true && editDraft && (
              <li className='dropdown-item' key={emailDataLocal.objectId + 'editEmail'}>
                <a
                  title='Edit'
                  onClick={() => editDraft(emailDataLocal)}
                  data-tooltip-content={t('Edit draft')}
                  data-tooltip-id='EditEmail'
                >
                  Edit draft
                </a>
                <Tooltip id='EditEmail' place='right' delayShow={1000} />
              </li>
            )}
            {copyEmail && emailDataLocal.status !== 'received' && (
              <li className='dropdown-item' key={emailDataLocal.objectId + 'copyEmail'}>
                <a
                  title='Copy'
                  onClick={() => copyEmail(emailDataLocal)}
                  data-tooltip-content={t('Create new email from this')}
                  data-tooltip-id='CopyEmail'
                >
                  Copy email
                </a>
                <Tooltip id='CopyEmail' place='right' delayShow={1000} />
              </li>
            )}
            {!emailDataLocal.isDraft && replyEmail && emailDataLocal.status === 'received' && (
              <li className='dropdown-item' key={emailDataLocal.objectId + 'replyEmail'}>
                <a
                  title='Reply'
                  onClick={() => replyEmail(emailDataLocal)}
                  data-tooltip-content={t('Reply to this email')}
                  data-tooltip-id='replyEmail'
                >
                  Reply to email
                </a>
                <Tooltip id='replyEmail' place='right' delayShow={1000} />
              </li>
            )}
            {!emailDataLocal.isDraft && forwardEmail && (
              <li className='dropdown-item' key={emailDataLocal.objectId + 'forwardEmail'}>
                <a
                  title='Forward'
                  onClick={() => forwardEmail(emailDataLocal)}
                  data-tooltip-content={t('Forward this email')}
                  data-tooltip-id='forwardEmail'
                >
                  Forward email
                </a>
                <Tooltip id='forwardEmail' place='right' delayShow={1000} />
              </li>
            )}

            {emailDataLocal.isDraft == true && cancelDelete && (
              <li className='dropdown-item' key={emailDataLocal.objectId + 'deleteEmail'}>
                <Confirm
                  render={(confirm) => (
                    <a
                      title='Delete'
                      onClick={cancelDelete(emailDataLocal, confirm)}
                      data-tooltip-content={t('Delete email draft')}
                      data-tooltip-id='DeleteEmail'
                    >
                      Delete Draft
                    </a>
                  )}
                />
                <Tooltip id='DeleteEmail' place='right' delayShow={1000} />
              </li>
            )}
          </ul>
        </li>
      )
    );
  };

  const emailHeader = () => {
    return (
      <div className='clearfix headBox'>
        <div className='outputPic'>
          <Link
            to={{
              pathname: `/contact-details/` + emailDataLocal.contactId,
              state: { objectId: emailDataLocal.contactId },
            }}
          >
            {emailDataLocal.userImage !== '' ? (
              <img src={emailDataLocal.userImage} alt='Avatar' />
            ) : (
              <Avatar size='40' colors={['red', 'green', 'blue']} name={emailDataLocal.userName} />
            )}
          </Link>
        </div>
        <div className='timelineTitleBox'>
          <div className='username'>
            {emailDataLocal.isDraft == 0 ? (
              <i className='fa-regular fa-envelope'></i>
            ) : (
              <>
                {' '}
                <span className='me-2 emailStatus badge bg-warning'>Draft</span>
              </>
            )}
            &nbsp;
            {emailDataLocal.emailSub ?? emailDataLocal.emailBody}
            {emailDataLocal.isDraft == 0 ? (
              <span className='emailStatus'>{emailDataLocal.status === 'received' ? '' : ''}</span>
            ) : (
              <></>
            )}
          </div>
          <br />
          <div className='dateBox'>
            {formatBidDueDate(emailDataLocal.createdAt)} by{' '}
            <Link
              to={{
                pathname: `/contact-details/` + emailDataLocal.contactId,
                state: { objectId: emailDataLocal.contactId },
              }}
            >
              {emailDataLocal.userName}
            </Link>
          </div>
        </div>
      </div>
    );
  };

  return wasNotFound ? (
    !asModal ? (
      <div className='outputBox notesHtml' key={emailId + 'email'}>
        <div className='email-not-found'>
          <h3>Email Not Found</h3>
          <p>
            We're sorry, but the email you're looking for couldn't be located. This could be due to
            one of the following reasons:
          </p>
          <ul>
            <li>The email may have been deleted</li>
            <li>You might not have permission to view this email</li>
            <li>There could be a temporary system issue</li>
          </ul>
          <p>Please try the following:</p>
          <ul>
            <li>Check if you have the correct email ID</li>
            <li>Refresh the page and try again</li>
            <li>If the problem persists, contact your system administrator</li>
          </ul>
        </div>
      </div>
    ) : (
      <Modal>
        <ModalHeader>Email Not Found</ModalHeader>
        <ModalBody>
          <div className='outputBox notesHtml' key={emailId + 'email'}>
            <div className='email-not-found'>
              <p>
                We're sorry, but the email you're looking for couldn't be located. This could be due
                to one of the following reasons:
              </p>
              <ul>
                <li>The email may have been deleted</li>
                <li>You might not have permission to view this email</li>
                <li>There could be a temporary system issue</li>
              </ul>
              <p>Please try the following:</p>
              <ul>
                <li>Check if you have the correct email ID</li>
                <li>Refresh the page and try again</li>
                <li>If the problem persists, contact your system administrator</li>
              </ul>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className='btn btn-primary' onClick={toggle}>
            Close
          </button>
        </ModalFooter>
      </Modal>
    )
  ) : (
    emailDataLoaded &&
      !shouldHideEmail &&
      !isEditing &&
      (!asModal ? (
        <div
          className={emailDataLocal?.replyTo ? 'outputBox notesHtml ms-4' : 'outputBox notesHtml'}
          key={emailDataLocal.objectId + 'email'}
        >
          <div className='editCloseBox rightSectionOverflowMenu'>{emailActions()}</div>

          <div className='outputInner'>
            {emailHeader()}
            <b>To:</b>&nbsp;
            {emailDataLocal.emailTo.map((to, idx) => (
              <span className='ToEmail'>
                {to.value}
                {idx < emailDataLocal.emailTo.length ? ',' : ''}
              </span>
            ))}{' '}
            {emailDataLocal.emailCC?.length > 0 ? (
              <>
                <br />
                <strong>CC: </strong>
              </>
            ) : (
              ''
            )}
            {emailDataLocal.emailCC?.map((cc) => (
              <span className='ToEmail'>{cc.value}</span>
            ))}{' '}
            <br />
            <strong>Message:</strong>&nbsp;
            {emailDataLocal && emailDataLocal.emailBody ? (
              <MessageComponent message={emailDataLocal.emailBody} maxLength={maxLength} />
            ) : (
              <>&nbsp;</>
            )}
            {attachmentList(
              emailDataLocal,
              emailDataLocal?.attachments,
              showHideLightBox,
              emailDataLocal.objectId
            )}
          </div>
        </div>
      ) : (
        <Modal
          backdrop='static'
          //keyboard={true}
          isOpen={true}
          toggle={toggle}
          className={modalClassName}
        >
          <ModalBody>
            <div
              className={
                emailDataLocal?.replyTo ? 'outputBox emailsHtml ms-4' : 'outputBox emailsHtml'
              }
              key={emailDataLocal.objectId + 'email'}
            >
              <div className='editCloseBox rightSectionOverflowMenu'>{emailActions()}</div>

              <div className='outputInner'>
                {emailHeader()}
                {emailDataLocal && emailDataLocal.emailBody && (
                  <MessageComponent message={emailDataLocal.emailBody} maxLength={maxLength} />
                )}
                {attachmentList(
                  emailDataLocal,
                  emailDataLocal?.attachments,
                  showHideLightBox,
                  emailDataLocal.objectId
                )}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button className='btn btn-primary' onClick={toggle}>
              Close
            </button>
          </ModalFooter>
        </Modal>
      ))
  );
};

export const ScheduleView = ({
  scheduleData,
  scheduleId,
  typeId,
  typeRef,
  editEvent,
  copyEvent,
  cancelDelete,
  showHideLightBox,
  asModal,
  modalOpen,
  toggle,
  modalClassName = 'modal-lg',
  filterText,
  isEditing,
}) => {
  const [scheduleDataLocal, setScheduleData] = useState(scheduleData);
  const [scheduleDataLoaded, setScheduleDataLoaded] = useState(false);
  const [shouldHideSchedule, setShouldHideSchedule] = useState(false);
  const [scheduleTextStripped, setScheduleTextStripped] = useState('');

  useEffect(() => {
    if (scheduleDataLoaded) {
      if (filterText?.length > 0) {
        if (scheduleTextStripped?.toLowerCase().includes(filterText?.toLowerCase())) {
          setShouldHideSchedule(false);
        } else {
          setShouldHideSchedule(true);
        }
      } else {
        setShouldHideSchedule(false);
      }
    }
  }, [filterText]);

  useEffect(() => {
    if (scheduleData) {
      setScheduleData(scheduleData);
      setScheduleDataLoaded(true);
      setScheduleTextStripped(getSearchText(scheduleData));
    }
  }, [scheduleData]);

  useEffect(() => {
    if (scheduleId && typeId) {
      fetchScheduleData();
    }
  }, [scheduleId]);

  const fetchScheduleData = async () => {
    const userId = localStorage.getItem('userId');
    const accountId = localStorage.getItem('accountId');

    const { data } = await axios.post(
      'parse/functions/getCpostSchedule',
      {
        userId: userId,
        accountId: accountId,
      },
      {
        baseURL: appConstants.WEB_SERVICE_URL_WITHOUT_PARSE,
        headers: {
          'X-Parse-Application-Id': appConstants.PARSE_APPLICATION_ID,
          'X-Parse-REST-API-Key': appConstants.PARSE_REST_API_KEY,
          'X-Parse-Session-Token': localStorage.getItem('sessionToken'),
          authorization: localStorage.getItem('JWTTOKEN'),
        },
        params: {
          accountId: accountId,
          typeId: typeId,
          typeRef: typeRef,
          objectId: scheduleId,
        },
      }
    );
    setScheduleData(data?.result.data);
    setScheduleDataLoaded(true);
  };

  const maxLength = 1000; // max length of message to show

  const scheduleActions = () => {
    return (
      (editEvent || copyEvent || cancelDelete) && (
        <li className='dropdown px-2' key={scheduleDataLocal.objectId + 'dropdownAction'}>
          <a
            className='dropdown-toggle rightSectionOverflowMenu'
            data-bs-toggle='dropdown'
            href='#'
            role='button'
            aria-hidden='true'
            aria-expanded='false'
          >
            <i className='fa fa-ellipsis-scheduleDataLocal' aria-hidden='true'></i>
          </a>
          <ul className='dropdown-menu dropdown-menu-end ml-auto'>
            {editEvent && (
              <li className='dropdown-item' key={scheduleDataLocal.objectId + 'editEvent'}>
                <a
                  title='Edit'
                  onClick={() => editEvent(scheduleDataLocal)}
                  data-tooltip-content={t('Edit event')}
                  data-tooltip-id='EditEvent'
                >
                  Edit event
                </a>
                <Tooltip id='EditEvent' place='right' delayShow={1000} />
              </li>
            )}
            {copyEvent && (
              <li className='dropdown-item' key={scheduleDataLocal.objectId + 'copyEvent'}>
                <a
                  title='Copy'
                  onClick={() => copyEvent(scheduleDataLocal)}
                  data-tooltip-content={t('Create new event from this')}
                  data-tooltip-id='CopyMeeting'
                >
                  Copy meeting
                </a>
                <Tooltip id='CopyMeeting' place='right' delayShow={1000} />
              </li>
            )}

            {cancelDelete && scheduleDataLocal.isCancelled !== true && (
              <li className='dropdown-item' key={scheduleDataLocal.objectId + 'cancelSchedule'}>
                <Confirm
                  render={(confirm) => (
                    <a
                      title='Cancel'
                      onClick={cancelDelete(scheduleDataLocal, confirm)}
                      data-tooltip-content={t('Cancel scheduled event')}
                      data-tooltip-id='CancelEvent'
                    >
                      Cancel event
                    </a>
                  )}
                />
                <Tooltip id='CancelEvent' place='right' delayShow={1000} />
              </li>
            )}
          </ul>
        </li>
      )
    );
  };

  const scheduleHeader = () => {
    return (
      <div className='clearfix headBox'>
        <div className='outputPic'>
          <Link
            to={{
              pathname: `/contact-details/` + scheduleDataLocal.contactId,
              state: { objectId: scheduleDataLocal.contactId },
            }}
          >
            {scheduleDataLocal.userImage !== '' ? (
              <img src={scheduleDataLocal.userImage} alt='Avatar' />
            ) : (
              <Avatar
                size='40'
                colors={['red', 'green', 'blue']}
                name={scheduleDataLocal.userName}
              />
            )}
          </Link>
        </div>
        <div className='timelineTitleBox'>
          <div className='username  d-flex justify-content-between'>
            <i className='fa-regular fa-calendar'></i>
            &nbsp;
            {scheduleDataLocal.title}
            <div className='ms-auto statusSchedule'>
              &nbsp;Status: &nbsp;
              {/*<span className={v.isCanceled===true?"alert-danger":(status)?"alert-success":"alert-danger"}>{v.isCanceled===true?" Cancelled ":(status)?" Active ":" Completed "}</span> */}
              <span
                className={
                  scheduleDataLocal.isCanceled === true
                    ? 'cancelledStatus'
                    : getDifferenceBetweenDates('', scheduleDataLocal.endDate)
                      ? 'scheduledStatus'
                      : 'completeStatus'
                }
              >
                {scheduleDataLocal.isCanceled === true
                  ? 'Canceled'
                  : getDifferenceBetweenDates('', scheduleDataLocal.endDate)
                    ? 'Active'
                    : 'Completed'}
              </span>
            </div>
          </div>

          <div className='d-flex dateBox'>
            {formatBidDueDate(scheduleDataLocal.createdAt)} by &nbsp;{' '}
            <Link
              to={{
                pathname: `/contact-details/` + scheduleDataLocal.contactId,
                state: { objectId: scheduleDataLocal.contactId },
              }}
            >
              {scheduleDataLocal.userName}
            </Link>
          </div>
        </div>
      </div>
    );
  };

  return (
    scheduleDataLoaded &&
    !shouldHideSchedule &&
    !isEditing &&
    (!asModal ? (
      <div
        className={scheduleDataLocal?.replyTo ? 'outputBox notesHtml ms-4' : 'outputBox notesHtml'}
        key={scheduleDataLocal.objectId + 'schedule'}
      >
        <div className='editCloseBox rightSectionOverflowMenu'>{scheduleActions()}</div>

        <div className='outputInner'>
          {scheduleHeader()}
          <div className='sdata'>
            <strong>To:</strong>{' '}
            {scheduleDataLocal.scheduleTo?.map((to, idx) => (
              <>
                <span
                  key={to.value}
                  className='ToSchedule'
                  data-tooltip-id={to.value}
                  data-tooltip-content={to.label + ' (' + to.value + ')'}
                >
                  {renderToLink(to)}
                  {idx + 1 < scheduleDataLocal.scheduleTo.length ? ', ' : ''}
                </span>
                <Tooltip id={to.value} place='top' delayShow={500} />
              </>
            ))}{' '}
          </div>
          <div className='sdata'>
            <strong>Starts:</strong> {formatBidDueDate(scheduleDataLocal.startDate)}{' '}
          </div>
          <div className='sdata'>
            <strong>Ends:</strong> {formatBidDueDate(scheduleDataLocal.endDate)}{' '}
          </div>
          {scheduleDataLocal.reminder > 0 && (
            <div className='sdata'>
              <strong>Reminder:</strong>{' '}
              {!scheduleDataLocal.reminder ||
              scheduleDataLocal.reminder === -999 ||
              scheduleDataLocal.reminder < 0
                ? 'No Reminder'
                : scheduleDataLocal.reminder + ' Minutes'}{' '}
            </div>
          )}
          {/*<div className="sdata"><strong>Meeting Repeat :</strong>{ (scheduleDataLocal.meetingRepeat === 10)? 'Does not repeat':((scheduleDataLocal.meetingRepeat ===1)?'Every Day':(scheduleDataLocal.meetingRepeat+ 'Days')) }</div>*/}
          {scheduleDataLocal.location?.length > 0 && (
            <div className='sdata'>
              <strong>Location:</strong> {scheduleDataLocal.location}{' '}
            </div>
          )}
          <strong>Details:</strong>&nbsp;
          {scheduleDataLocal && scheduleDataLocal.description ? (
            <MessageComponent message={scheduleDataLocal.description} maxLength={maxLength} />
          ) : (
            <>&nbsp;</>
          )}
          {attachmentList(
            scheduleDataLocal,
            scheduleDataLocal?.attachments,
            showHideLightBox,
            scheduleDataLocal.objectId
          )}
        </div>
      </div>
    ) : (
      <Modal
        backdrop='static'
        //keyboard={true}
        isOpen={true}
        toggle={toggle}
        className={modalClassName}
      >
        <ModalBody>
          <div
            className={
              scheduleDataLocal?.replyTo
                ? 'outputBox schedulesHtml ms-4'
                : 'outputBox schedulesHtml'
            }
            key={scheduleDataLocal.objectId + 'schedule'}
          >
            <div className='editCloseBox rightSectionOverflowMenu'>{scheduleActions()}</div>

            <div className='outputInner'>
              {scheduleHeader()}
              <div className='sdata'>
                <strong>To:</strong>{' '}
                {scheduleDataLocal.scheduleTo?.map((to, idx) => (
                  <>
                    <span
                      key={to.value}
                      className='ToSchedule'
                      data-tooltip-id={to.value}
                      data-tooltip-content={to.label + ' (' + to.value + ')'}
                    >
                      {renderToLink(to)}
                      {idx + 1 < scheduleDataLocal.scheduleTo.length ? ',' : ''}
                    </span>
                    <Tooltip id={to.value} place='top' delayShow={500} />
                  </>
                ))}{' '}
              </div>
              <div className='sdata'>
                <strong>Starts:</strong> {formatBidDueDate(scheduleDataLocal.startDate)}{' '}
              </div>
              <div className='sdata'>
                <strong>Ends:</strong> {formatBidDueDate(scheduleDataLocal.endDate)}{' '}
              </div>
              {scheduleDataLocal.reminder > 0 && (
                <div className='sdata'>
                  <strong>Reminder:</strong>{' '}
                  {!scheduleDataLocal.reminder ||
                  scheduleDataLocal.reminder === -999 ||
                  scheduleDataLocal.reminder < 0
                    ? 'No Reminder'
                    : scheduleDataLocal.reminder + ' Minutes'}{' '}
                </div>
              )}
              {/*<div className="sdata"><strong>Meeting Repeat :</strong>{ (scheduleDataLocal.meetingRepeat === 10)? 'Does not repeat':((scheduleDataLocal.meetingRepeat ===1)?'Every Day':(scheduleDataLocal.meetingRepeat+ 'Days')) }</div>*/}
              {scheduleDataLocal.location?.length > 0 && (
                <div className='sdata'>
                  <strong>Location:</strong> {scheduleDataLocal.location}{' '}
                </div>
              )}
              <strong>Details:</strong>&nbsp;
              {scheduleDataLocal && scheduleDataLocal.description ? (
                <MessageComponent message={scheduleDataLocal.description} maxLength={maxLength} />
              ) : (
                <>&nbsp;</>
              )}
              {attachmentList(
                scheduleDataLocal,
                scheduleDataLocal?.attachments,
                showHideLightBox,
                scheduleDataLocal.objectId
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className='btn btn-primary' onClick={toggle}>
            Close
          </button>
        </ModalFooter>
      </Modal>
    ))
  );
};

const renderToLink = (to) => {
  if (to.companyId && !to.contactId) {
    return (
      <Link
        to={{
          pathname: `/company-details/` + to.companyId,
          state: { objectId: to.companyId },
        }}
      >
        {to.value}
      </Link>
    );
  }
  return (
    <Link
      to={{
        pathname: `/contact-details/` + to.contactId ?? to.objectId,
        state: { objectId: to.contactId ?? to.objectId },
      }}
    >
      {to.value}
    </Link>
  );
};

export const scheduleHtml = (v) => {
  let status = getDifferenceBetweenDates('', v.endDate);
  return (
    <div className='outputBox scheduleHtml'>
      <div className='outputInner'>
        <div className='statusSchedule'>
          <b>Status:</b>
          {/*<span className={v.isCanceled===true?"alert-danger":(status)?"alert-success":"alert-danger"}>{v.isCanceled===true?" Cancelled ":(status)?" Active ":" Completed "}</span> */}
          <span
            className={
              v.isCanceled === true
                ? 'cancelledStatus'
                : status
                  ? 'scheduledStatus'
                  : 'completedStatus'
            }
          >
            {v.isCanceled === true ? ' Canceled ' : status ? ' Active ' : ' Completed '}
          </span>
        </div>
        <div className='clearfix headBox'>
          <div className='outputPic'>
            <Link
              to={{
                pathname: `/contact-details/` + v.contactId,
                state: { objectId: v.contactId },
              }}
            >
              {v.userImage ? (
                <img src={v.userImage} alt='Avatar' />
              ) : (
                <Avatar size='40' colors={['red', 'green', 'blue']} name={v.userName} />
              )}
            </Link>
          </div>
          <div className='timelineTitleBox'>
            <div className='username'>
              <i className='fa-regular fa-calendar' aria-hidden='true'></i>&nbsp;{v.title}
            </div>
            <br />
            <div className='dateBox'>
              {formatBidDueDate(v.createdAt)} by{' '}
              <Link
                to={{
                  pathname: `/contact-details/` + v.contactId,
                  state: { objectId: v.contactId },
                }}
              >
                {v.userName}
              </Link>
            </div>
          </div>
        </div>
        <div className='sdata'>
          <strong>To:</strong>{' '}
          {v.scheduleTo?.map((to, idx) => (
            <>
              <span
                key={to.value}
                className='ToSchedule'
                data-tooltip-id={to.value}
                delayShow={100}
                data-tooltip-content={to.label + ' (' + to.value + ')'}
              >
                {renderToLink(to)}
                {idx + 1 < v.scheduleTo.length ? ',' : ''}
              </span>
              <Tooltip id={to.value} place='top' delayShow={500} />
            </>
          ))}{' '}
        </div>

        <div className='sdata'>
          <strong>Starts:</strong> {formatBidDueDate(v.startDate)}{' '}
        </div>
        <div className='sdata'>
          <strong>Ends:</strong> {formatBidDueDate(v.endDate)}{' '}
        </div>
        {v.reminder > 0 && (
          <div className='sdata'>
            <strong>Reminder:</strong>{' '}
            {!v.reminder || v.reminder === -999 || v.reminder < 0
              ? 'No Reminder'
              : v.reminder + ' Minutes'}{' '}
          </div>
        )}
        {/*<div className="sdata"><strong>Meeting Repeat :</strong>{ (v.meetingRepeat === 10)? 'Does not repeat':((v.meetingRepeat ===1)?'Every Day':(v.meetingRepeat+ 'Days')) }</div>*/}
        {v.location?.length > 0 && (
          <div className='sdata'>
            <strong>Location:</strong> {v.location}{' '}
          </div>
        )}
        <div className='sdata'>
          <strong>Description:</strong> {v.description && renderHTML(v.description)}{' '}
        </div>
      </div>
    </div>
  );
};
