import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import TitleComponent from "../Common/TitleComponent";

const PageNotFound = () => {
  const location = useLocation();
  
  const divStyle = { padding: '10%' };
  const colorRed = { color: 'red' };

  return (
    <div>
      <TitleComponent data={{title: 'Page Not Found'}} />
      
      <div className="page-error text-center">
        <div className="container" style={divStyle}>
          <div className="text-center mb-5">
            <h1>We are unable to locate the page you requested</h1>
          </div>
          <h1 style={colorRed}>404 - Page Not Found</h1>
          <p>
            The page you are looking for does not exist at this location. 
            Please try another or go back to the app to continue.
          </p>
          <Link 
            className="" 
            to={{ 
              pathname: "/", 
              state: { ...location.state } 
            }}
          >
            BACK TO HOME
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;