import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { moduleConstants, isAllowed } from '../../../_constants';
import { formatBidDueDate, formatDate } from '../../../utils/date-formatters';
import { formatMoney } from '../../../utils/number-formatters';

function LeadDatesAndDollars({ projectData, onOpenEditModal, isHideFields = false }) {
  const [projectId, setProjectId] = useState('');

  const [state] = useState({
    userId: localStorage.getItem('userId'),
  });

  useEffect(() => {
    if (projectData?.objectId) {
      setProjectId(projectData.objectId);
    } else if (projectData?.projectId?.objectId) {
      setProjectId(projectData.projectId.objectId);
    }
  }, [projectData?.objectId]);

  const userAssignData = projectData?.userAssignData ?? [];

  const isCreator = projectData?.createdBy?.objectId === state.userId;
  const canEditDetails = isAllowed(
    moduleConstants.EDITPROJDETAILS,
    isCreator ? 'anyAssigned' : 'assigned',
    userAssignData
  );

  return (
    <div className=''>
      <div className='pDetail'>
        <h6>Dates</h6>
        <div className='row'>
          <div className='col-lg-3 col-md-4 col-sm-5 col-xs-6 mb-1'>Bid Due</div>
          <div className='col'>
            {projectData?.bidDue?.iso ? formatBidDueDate(projectData.bidDue.iso) : '-'}
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-3 col-md-4 col-sm-5 col-xs-6 mb-1'>Start Date</div>
          <div className='col'>
            {projectData?.startDate?.iso ? formatDate(projectData.startDate.iso) : '-'}
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-3 col-md-4 col-sm-5 col-xs-6 mb-1'>End Date</div>
          <div className='col'>
            {projectData?.endDate?.iso ? formatDate(projectData.endDate.iso) : '-'}
          </div>
        </div>
        {canEditDetails && (
          <a className='editBtn' onClick={() => onOpenEditModal('lead-project-dates')}>
            <i className='fa fa-pencil' aria-hidden='true'></i>
          </a>
        )}
      </div>

      {(isAllowed(moduleConstants.VIEWCONTRACTVAL, 'assigned', userAssignData) ||
        isAllowed(moduleConstants.VIEWPROFIT, 'assigned', userAssignData)) && (
        <>
          <div className='pDetail'>
            <h6>Dollars</h6>
            {isAllowed(moduleConstants.VIEWCONTRACTVAL, 'assigned', userAssignData) && (
              <div className='row'>
                <div className='col-lg-3 col-md-4 col-sm-5 col-xs-6 mb-1'>
                  Bid Amount [Estimate]:
                </div>
                <div className='col'>
                  {projectData?.estimateBidAmount
                    ? formatMoney(projectData?.estimateBidAmount)
                    : '-'}
                </div>
              </div>
            )}
            {isAllowed(moduleConstants.VIEWPROFIT, 'assigned', userAssignData) && (
              <div className='row'>
                <div className='col-lg-3 col-md-4 col-sm-5 col-xs-6 mb-1'>
                  Gross Profit [Estimate]:
                </div>
                <div className='col'>
                  {projectData?.estimateGrossProfit
                    ? formatMoney(projectData?.estimateGrossProfit)
                    : '-'}
                </div>
              </div>
            )}
            {canEditDetails && (
              <a className='editBtn' onClick={() => onOpenEditModal('lead-project-dates')}>
                <i className='fa fa-pencil' aria-hidden='true'></i>
              </a>
            )}
          </div>
        </>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    projectData: state.project.projectData,
    isProjectUpdated: state.project.isProjectUpdated,
  };
}

export default withRouter(connect(mapStateToProps, {})(LeadDatesAndDollars));
