import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import FormInput from '../../../shared/form-controls/form-input';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import { reportSections, reportTypes } from './constants';
import FormSelect from '../../../shared/form-controls/form-select';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '../../../shared/hooks/use-app-dispatch';
import ReportQueryBuilderModal from './report-query-modal';
import HighlightRulesModal from './highlight-rules-modal';
import {
  addReport,
  disableReport,
  enableReport,
  getJobStatusCodesSettings,
  getReportFieldsOptions,
  getReportForm,
  getReportPermissionsModules,
  updateReport,
  resetReport,
} from '../../../modules/settings';
import FormMultiSelect from '../../../shared/form-controls/form-multiselect/form-multiselect';
import FormCheckbox from '../../../shared/form-controls/form-checkbox';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import _ from 'lodash';
import { ManageFieldsModal } from './manage-fields-modal';
import { useConfirm } from '../../../shared/confirmation-dialog';
import type { RuleGroupType } from 'react-querybuilder';
import { reportAccess } from '../../../_constants';

type EditReportSettingsModalProps = {
  open: boolean;
  onClose: () => unknown;
  settingId?: string | null;
  onSubmit?: (...args: any[]) => unknown;
};

type HighlightRuleGroup = {
  highlightColor: { r: number; g: number; b: number; a: number };
  highlightTextColor?: { r: number; g: number; b: number; a: number };
  enabled: boolean;
  rules: RuleGroupType;
  name: string;
};

const getDefaultValues = () => ({
  name: '',
  definition: '',
  section: '',
  reportType: '',
  permissions: [],
  jobStatusCodes: [],
  selectedFields: [] as any[],
  isExcludedJobStatus: false,
  filterRules: { combinator: 'AND', rules: [] } as RuleGroupType,
  highlightRules: [] as HighlightRuleGroup[],
  isShowAdditionalColumns: false,
  showAggregateRow: false,
  isEnableWidget: false,
  actions: [] as any[],
  sortBy: null as any,
});

export const EditReportSettingsModal = ({
  open,
  onClose,
  settingId,
  onSubmit,
}: EditReportSettingsModalProps) => {
  const isUpdate = !!settingId;

  const [permissions, setPermissions] = useState<any[]>([]);

  const [jobStatusCodes, setJobStatusCodes] = useState<any[]>([]);
  const [fieldOptions, setFieldOptions] = useState<any[]>([]);

  const [isTrash, setIsTrash] = useState(false);
  const [isDefaultReport, setIsDefaultReport] = useState(false);
  const [shouldUpdateDisplayOnWidget, setShouldUpdateDisplayOnWidget] = useState(true);
  const [isStatic, setIsStatic] = useState(false);
  const [url, setUrl] = useState<string | null>(null);

  const [actionOptions, setActionOptions] = useState<any[]>([]);
  const [manageFieldsModalOpen, setManageFieldsModalOpen] = useState(false);

  const [filteringRules, setFilteringRules] = useState<RuleGroupType>({
    combinator: 'AND',
    rules: [],
  });
  //const [colorRules,setColorRules] = useState<RuleGroupType[]>([]);

  const [queryBuilderModalOpen, setQueryBuilderModalOpen] = useState(false);

  const handleQueryBuilderModalToggle = () => {
    setQueryBuilderModalOpen(!queryBuilderModalOpen);
  };

  const [highlightRules, setHighlightRules] = useState<HighlightRuleGroup[]>([]);

  const [highlightRulesModalOpen, setHighlightRulesModalOpen] = useState(false);

  const handleHighlightRulesModalToggle = () => {
    setHighlightRulesModalOpen(!highlightRulesModalOpen);
  };

  const dispatch = useAppDispatch();

  const confirm = useConfirm();

  const refreshForm = () => {
    if (isUpdate) {
      dispatch(getReportForm({ reportId: settingId }))
        .unwrap()
        .then((data) => {
          setIsStatic(data.isStatic);
          setUrl(data.url);
          setIsTrash(data.trash);
          setFilteringRules(
            data?.filterRules ?? ({ combinator: 'AND', rules: [] } as RuleGroupType)
          );
          setHighlightRules(data?.highlightRules ?? ([] as HighlightRuleGroup[]));
          setIsDefaultReport(data.originalId != null);
          form.reset({
            name: data.name,
            definition: data.definition,
            reportType: data.reportType,
            section: data.section,
            permissions: data.permissions,
            jobStatusCodes: data.jobStatusCodes,
            selectedFields: data.selectedFields,
            isExcludedJobStatus: data.isExcludedJobStatus,
            filterRules: data.filteringRules ?? ({ combinator: 'AND', rules: [] } as RuleGroupType),
            isShowAdditionalColumns: data.isShowAdditionalColumns,
            showAggregateRow: data.showAggregateRow,
            isEnableWidget: data.isEnableWidget,
            actions: data.actions,
            sortBy: data.sortBy,
            highlightRules: data?.highlightRules ?? [],
          });
        });
    } else {
      setIsDefaultReport(false);
      form.reset({ ...getDefaultValues() });
    }
  };

  const form = useForm({
    defaultValues: getDefaultValues(),
  });

  const selectedFields = form.watch('selectedFields', []) as any[];
  const isExcludingStatus = form.watch('isExcludedJobStatus', false);
  const reportType = form.watch('reportType', 'project');
  const isEnableWidgetWatch = form.watch('isEnableWidget', false);

  const getSortByOptionLabel = useCallback(
    (value: any) => {
      const { id, desc } = value;
      const option = fieldOptions.find((opt) => opt.value === id);
      const type = option?.type;

      let orderLabel = desc ? 'descending' : 'ascending';
      if(type === 'date') {
        orderLabel = `${desc ? 'later-earlier' : 'earlier-later'}`;
      }else if(type === 'number') {
        orderLabel = `${desc ? 'high-low' : 'low-high'} `;
      }else if(type === 'string') {
        orderLabel = `${desc ? 'Z-A' : 'A-Z'}`;
      }
      

      return `${option ? option.label : id} (${orderLabel})`;
    },
    [fieldOptions]
  );

  const getSortByOptionValue = useCallback(
    (value: any) => `${value.id} ${value.desc}`,
    [fieldOptions]
  );

  const getSortByOptions = useCallback(
    (fields: Array<any>, order: string) =>
      fields.map((field) => ({ id: field.field, desc: order === 'DESC', type: field.type })),
    []
  );

  const sortByOptions = useMemo(() => {
    const sortableFields = fieldOptions.filter(
      (field) => field.type === 'date' || field.type === 'number' || field.type === 'string'
    );
    const sortableSelectedFields = selectedFields.filter((field: any) =>
      sortableFields.find((sortableField) => sortableField.value === field.field)
    );
    const ascOptions = getSortByOptions(sortableSelectedFields, 'ASC');
    const descOptions = getSortByOptions(sortableSelectedFields, 'DESC');
    
    const sortOptions = [...ascOptions, ...descOptions].sort((a, b) => {
      const aLabel = getSortByOptionLabel(a);
      const bLabel = getSortByOptionLabel(b);
      return aLabel.localeCompare(bLabel);
    });

    return sortOptions;
  }, [selectedFields, fieldOptions]);

  useEffect(() => {
    if (isEnableWidgetWatch) {
      if (shouldUpdateDisplayOnWidget) {
        const anyWidgetEnabled = selectedFields.some((field) => field.displayOnWidget);
        if (!anyWidgetEnabled) {
          const selectedFieldsClone = _.cloneDeep(selectedFields);
          selectedFieldsClone.forEach((field) => (field.displayOnWidget = true));
          form.setValue('selectedFields', selectedFieldsClone);
        }
        setShouldUpdateDisplayOnWidget(false);
      }
    } else {
      setShouldUpdateDisplayOnWidget(true);
    }
  }, [isEnableWidgetWatch, shouldUpdateDisplayOnWidget, selectedFields]);

  useEffect(() => {
    if (!open) {
      form.reset({ ...getDefaultValues() });
      return;
    }

    dispatch(getReportPermissionsModules())
      .unwrap()
      .then((data) => setPermissions(data));

    dispatch(getJobStatusCodesSettings())
      .unwrap()
      .then((data) => setJobStatusCodes(data));

    dispatch(getReportFieldsOptions(reportType))
      .unwrap()
      .then((data) => {
        setFieldOptions(data);
      });

    refreshForm();
  }, [open, settingId, isUpdate]);

  useEffect(() => {
    if (reportType) {
      dispatch(getReportFieldsOptions(reportType))
        .unwrap()
        .then((data) => {
          setFieldOptions(data);
          if (!isUpdate) {
            const newItems = data
              .filter((x) => x?.includeByDefault > 0)
              .map((field) => {
                return {
                  field: field.value,
                  showCode: fieldOptions.find((f) => f.value === field.value)?.canShowCode || false,
                  displayOnWidget: false,
                };
              });

            if (newItems.length > 0) {
              form.setValue('selectedFields', newItems);
            }
          }
        });

      if (permissions?.length > 0 && !isUpdate) {
        setActionOptions(getActionOptions(reportType));

        switch (reportType) {
          case 'project':
            form.setValue(
              'permissions',
              (permissions as any).find((x) => x.moduleCode === reportAccess.PROJECTLIST).moduleCode
            );
            form.setValue('actions', ['editProject', 'promoteLead', 'editBid', 'addNote']);
            break;
          case 'bid':
            form.setValue(
              'permissions',
              (permissions as any).find((x) => x.moduleCode === reportAccess.BRR).moduleCode
            );
            form.setValue('actions', ['editProject', 'editBid']);
            break;
          case 'contact':
            form.setValue(
              'permissions',
              (permissions as any).find((x) => x.moduleCode === reportAccess.CLP).moduleCode
            );
            form.setValue('actions', ['editContact', 'addNote']);
            break;
          case 'company':
            form.setValue(
              'permissions',
              (permissions as any).find((x) => x.moduleCode === reportAccess.COMLV).moduleCode
            );
            form.setValue('actions', ['editContact', 'addNote']);
            break;
        }
      }
    }
  }, [reportType, isUpdate]);

  const handleManageFields = useCallback(
    (fields: any[]) => {
      const items = _.cloneDeep(selectedFields);

      // reorder new items list according to order in fields, add any items that are not in selectedFields and remove any items from selectedFields that are not in fields

      const newItems = fields.map((field) => {
        const existingField = items.find((item) => item.field === field.value);
        return (
          existingField ?? {
            field: field.value,
            showCode: fieldOptions.find((f) => f.value === field.value)?.canShowCode || false,
            displayOnWidget: false,
          }
        );
      });

      form.setValue('selectedFields', newItems);
    },
    [selectedFields]
  );

  const handleRemoveField = useCallback(
    (index: number) => {
      const items = _.cloneDeep(selectedFields);
      items.splice(index, 1);

      form.setValue('selectedFields', items);
    },
    [selectedFields]
  );

  const onDragEnd = useCallback(
    (result: any) => {
      if (!result.destination) return;

      const items = _.cloneDeep(selectedFields);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      form.setValue('selectedFields', items);
    },
    [selectedFields]
  );

  const sortBy = useWatch({ control: form.control, name: 'sortBy' });

  const handleSortByChange = useCallback(
    (fieldId: string) => {
      const currentSortBy = form.getValues('sortBy');
      let newSortBy;

      if (currentSortBy && currentSortBy.id === fieldId) {
        if (currentSortBy.desc) {
          newSortBy = null; // Reset sorting
        } else {
          newSortBy = { id: fieldId, desc: true }; // Change to descending
        }
      } else {
        newSortBy = { id: fieldId, desc: false }; // Set new ascending sort
      }

      form.setValue('sortBy', newSortBy, { shouldDirty: true });
    },
    [form]
  );

  const selectedFieldsList = useMemo(() => {
    const list = selectedFields.map((field: any, index) => {
      const fieldOption = fieldOptions.find((item) => item.value === field.field);
      const fieldEnabled = !(fieldOption?.trash === true);
      //const isSortable = ['date', 'number','string'].includes(fieldOption?.type);
      const isSorted = sortBy && sortBy.id === field.field;
      const sortDirection = isSorted ? (sortBy.desc ? 'down' : 'up') : '';

      return (
        <Draggable key={field.field} draggableId={field.field} index={index}>
          {(provided) => (
            <div
              className='row mb-2'
              key={field.field}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
            >
              <div className='col-1'>
                <span className='d-flex align-items-center justify-content-center mt-1'>
                  <i className='fa fa-bars' aria-hidden='true' role='button' />
                </span>
              </div>
              <div className={`col-4${fieldEnabled ? '' : ' disabledText'}`}>
                {fieldEnabled
                  ? fieldOption?.label || field.field
                  : (fieldOption?.label ?? field.field) + ' (Disabled)'}{' '}
                {isSorted && (
                  <span className='text-muted'>{sortDirection === 'up' ? '↑' : '↓'}</span>
                )}
              </div>
              {/*} <div className='col-2'>
              {isSortable && (
                  <button 
                    className={`sort-button ${isSorted ? 'sorted' : ''}`}
                    onClick={() => handleSortByChange(field.field)}
                    title={isSorted ? (sortDirection === 'up' ? 'Sorted Ascending' : 'Sorted Descending') : 'Click to sort'}
                  >
                    <i className={`fas fa-sort${sortDirection ? `-${sortDirection}` : ''}`}></i>
                  </button>
                )}
              </div>*/}
              <div className='ms-auto col-2'>
                {!!fieldOption?.canShowCode && (
                  <FormCheckbox name={`selectedFields.${index}.showCode`} />
                )}
              </div>
              <div className={`col-2 ${isEnableWidgetWatch ? '' : 'invisible'}`}>
                <FormCheckbox name={`selectedFields.${index}.displayOnWidget`} />
              </div>
              <div className='col-1'>
                <i
                  className='fa fa-times'
                  aria-hidden='true'
                  onClick={() => handleRemoveField(index)}
                />
              </div>
            </div>
          )}
        </Draggable>
      );
    });

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='droppable'>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {list}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }, [selectedFields, onDragEnd, fieldOptions, isEnableWidgetWatch, handleSortByChange, sortBy]);

  const updateFormFilterRules = (newRules: RuleGroupType) => {
    setFilteringRules(newRules);
    form.setValue('filterRules', newRules);
  };

  const updateFormHighlightRules = (newHighlightRules: HighlightRuleGroup[]) => {
    setHighlightRules(newHighlightRules);
    form.setValue('highlightRules', newHighlightRules);
  };

  const onResetClick = async () => {
    const ok = await confirm({
      title: 'Reset to default',
      question: 'Are you sure you want to reset this report to its default settings?',
    });

    if (!ok) {
      return;
    }

    const report = await dispatch(
      resetReport({
        reportData: {
          _id: settingId,
          reset: true,
        },
      })
    ).unwrap();

    onSubmit?.(report);
    onClose();
  };

  const handleSubmit = form.handleSubmit(async (data) => {
    const report = await dispatch(
      isUpdate
        ? updateReport({
            reportData: {
              ...data,
              _id: settingId,
            },
          })
        : addReport({
            reportData: data,
          })
    ).unwrap();

    onSubmit?.(report);
    onClose();
  });

  const handleToggle = async () => {
    if (isTrash) {
      await dispatch(enableReport({ reportId: settingId })).unwrap();
    } else {
      const ok = await confirm({
        title: 'Disable Report',
        question: 'Are you sure you want to disable this report?',
      });

      if (!ok) {
        return;
      }

      await dispatch(disableReport({ reportId: settingId })).unwrap();
    }

    onSubmit?.();

    if(isTrash && reportType === 'divider') {
      onClose();
    }

    refreshForm();
  };

  const onIsEnableWidgetChange = useCallback(() => {
    if (isEnableWidgetWatch) {
      const anyWidgetEnabled = selectedFields.some((field) => field.displayOnWidget);
      if (!anyWidgetEnabled) {
        const selectedFieldsClone = _.cloneDeep(selectedFields);
        selectedFieldsClone.forEach((field) => (field.displayOnWidget = true));
        form.setValue('selectedFields', selectedFieldsClone);
      }
    }
  }, [isEnableWidgetWatch, selectedFields]);

  const dialogTitle = isUpdate ? 'Edit report' : 'Add report';

  const getActionOptions = (reportType: string) => {
    switch (reportType) {
      case 'project':
        return [
          { value: 'editProject', label: 'Edit project' },
          { value: 'promoteLead', label: 'Promote lead' },
          { value: 'editBid', label: 'Edit bid' },
          { value: 'updateProgress', label: 'Update progress' },
          { value: 'addNote', label: 'Add note' },
        ];
      case 'bid':
        return [
          { value: 'editBid', label: 'Edit bid' },
          { value: 'editProject', label: 'Edit project' },
          { value: 'addNote', label: 'Add note' },
        ];
      case 'contact':
        return [
          { value: 'editContact', label: 'Edit contact' },
          { value: 'addNote', label: 'Add note' },
        ];
      case 'company':
        return [
          { value: 'editCompany', label: 'Edit company' },
          { value: 'addNote', label: 'Add note' },
        ];
      default:
        return [];
    }
  };

  return (
    <Modal isOpen={open} toggle={onClose} style={{ width: '1200px' }} size={'lg'} backdrop='static'>
      <ModalHeader toggle={onClose}>{dialogTitle}</ModalHeader>
      <ModalBody>
        <FormProvider {...form}>
          <style>
            {`
              .sort-button {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                border: 1px solid #ced4da;
                background-color: #f8f9fa;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0;
                font-size: 12px;
                cursor: pointer;
                transition: all 0.2s ease-in-out;
              }
              .sort-button:hover {
                background-color: #e9ecef;
              }
              .sort-button.sorted {
                background-color: #007bff;
                color: white;
                border-color: #007bff;
              }
              .sort-button.sorted:hover {
                background-color: #0056b3;
              }
            `}
          </style>
          <div className='container'>
            <div className='row'>
              <div className='col-6'>
                <FormInput
                  name='name'
                  {...(reportType === 'divider' ? { required: false } : { required: true })}
                  label={(reportType === 'divider' ? 'Divider label' : 'Report title')}
                />
              </div>
              <div className='col-6'>
                <FormSelect
                  name='reportType'
                  label='Report type'
                  disabled={isUpdate}
                  options={reportTypes}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-6'>
                <FormSelect name='section' required label='Menu section' options={reportSections} />
              </div>

              <div className='col-6'>
                <FormSelect
                  formatOptionLabel={(option: any) => option.moduleName}
                  getOptionValue={(option: any) => option.moduleCode}
                  name='permissions'
                  required={reportType !== 'divider'}
                  label='Associated report permissions'
                  options={permissions}
                  disabled={isDefaultReport}
                />
              </div>

              {!isStatic && reportType === 'project' && (
                <>
                  <div className='col-6'>
                    <FormMultiSelect
                      getOptionLabel={(option: any) => option.jobStatusCodesName}
                      getOptionValue={(option: any) => option._id}
                      name='jobStatusCodes'
                      required
                      label={isExcludingStatus ? 'Excluded Job Status' : 'Included Job Status'}
                      options={jobStatusCodes}
                    />
                  </div>

                  <div className='col-6 mt-4'>
                    <FormCheckbox name='isExcludedJobStatus' label='Excluded Status Mode' />
                  </div>
                </>
              )}
            </div>

            {(isStatic || reportType?.length > 0) && reportType !== 'divider' && (
              <>
                {!isStatic && (
                  <div className='row d-flex flex-row mt-1 mb-2'>
                    <div className='col-12'>
                      <button
                        className='btn btn-primary w-auto'
                        onClick={handleQueryBuilderModalToggle}
                      >
                        Advanced Report Filtering
                      </button>

                      <button
                        className='ms-2 btn btn-primary w-auto'
                        onClick={handleHighlightRulesModalToggle}
                      >
                        Highlight Rules
                      </button>
                    </div>
                  </div>
                )}

                <div className='row mt-2'>
                  <div className='col-12'>
                    <FormInput name='definition' textArea rows={5} label='Report description' />
                  </div>
                </div>

                {isStatic && url && (
                  <div className='row'>
                    <div className='col-12'>
                      <b>Report URL: </b> {url}
                    </div>
                  </div>
                )}

                {!isStatic && (
                  <>
                    <div className='row'>
                      <div className='col-6'>
                        <FormCheckbox
                          name='isShowAdditionalColumns'
                          label='Show additional columns'
                        />
                      </div>

                      <div className='col-6'>
                        <FormCheckbox name='showAggregateRow' label='Show totals row (aggregate)' />
                      </div>
                      <div className='col-6'>
                        <FormMultiSelect
                          name='actions'
                          label='Report actions'
                          options={actionOptions}
                          placeholder='Actions'
                        />
                      </div>
                      <div className='col-6'>
                        <FormSelect
                          name='sortBy'
                          label='Sort By'
                          options={sortByOptions}
                          isClearable
                          useObjectValue
                          formatOptionLabel={getSortByOptionLabel}
                          getOptionValue={getSortByOptionValue}
                        />
                      </div>
                      <div className='col-6'>
                        <FormCheckbox
                          name='isEnableWidget'
                          label='Show as widget on homepage'
                          onChange={onIsEnableWidgetChange}
                        />
                      </div>
                    </div>

                    {!!selectedFields?.length && (
                      <div className='row mt-4'>
                        <div className='col-12'>
                          <div className='row'>
                            <div className='col-1'></div>
                            <div className='col-4'>Field name</div>
                            {/*<div className='col-2'>Sorted by</div>*/}
                            <div className='ms-auto col-2'>Shorten field</div>
                            <div className={`col-2 ${isEnableWidgetWatch ? '' : 'invisible'}`}>
                              On Widget
                            </div>
                            <div className='col-1'></div>
                          </div>

                          <hr />

                          {selectedFieldsList}
                        </div>
                      </div>
                    )}
                    <div className='row'>
                      <div className='col-12'>
                        <div className='d-flex justify-content-end'>
                          <a
                            onClick={(e) => {
                              e.preventDefault();
                              setManageFieldsModalOpen(true);
                            }}
                          >
                            Manage fields
                          </a>

                          <ManageFieldsModal
                            open={manageFieldsModalOpen}
                            onClose={() => setManageFieldsModalOpen(false)}
                            onSubmit={handleManageFields}
                            options={
                              fieldOptions
                                .filter((item) => !item.trash)
                                .map((item) => ({
                                  label: item.label,
                                  value: item.value,
                                })) as any
                            }
                            selectedFields={selectedFields.map((item) => item.field)}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        {isUpdate && isDefaultReport && (
          <button className='btn btn-primary mr-auto' onClick={onResetClick}>
            Reset to default
          </button>
        )}
        <button className='btn btn-primary' onClick={onClose}>
          Cancel
        </button>
        {isUpdate && (
          <button className='btn btn-primary' onClick={handleToggle}>
            {isTrash ? 'Enable' : reportType === 'divider' ? 'Delete' : 'Disable'}
          </button>
        )}
        <button className='btn btn-primary' onClick={handleSubmit}>
          Save
        </button>
      </ModalFooter>

      <ReportQueryBuilderModal
        open={queryBuilderModalOpen}
        fields={fieldOptions}
        setFilterRules={updateFormFilterRules}
        filterRules={filteringRules as unknown as RuleGroupType}
        onClose={handleQueryBuilderModalToggle}
        isHighlightRule={false}
      />

      <HighlightRulesModal
        open={highlightRulesModalOpen}
        fields={fieldOptions}
        setHighlightRules={updateFormHighlightRules}
        highlightRules={highlightRules as unknown as HighlightRuleGroup[]}
        onClose={handleHighlightRulesModalToggle}
      />
    </Modal>
  );
};
