import { useEffect, useMemo, useState, useCallback } from 'react';
import { useConfirm } from '../../../shared/confirmation-dialog';
import { useAppDispatch } from '../../../shared/hooks/use-app-dispatch';
import {
  getProjectFieldsSettings,
  disableCustomField,
  enableCustomField,
  reorderProjectCustomFields,
} from '../../../modules/settings';
import { useAppSelector } from '../../../shared/hooks/use-app-selector';
import { selectGetProjectFieldsSettings } from '../../../modules/settings/selectors';
import { EditProjectFieldModal } from './edit-project-field-modal';
import {
  inputTypeOptions,
  projectSections,
  projectStages,
} from '../../../shared/custom-fields-controls/constants';
import { usePageTitle } from '../../../layouts/title-context';
import { SettingsCodesLayoutNext } from '../../../layouts/settings-codes-layout/settings-codes-layout-next';
import _ from 'lodash';

export const ProjectCustomFieldsPage = () => {
  const confirm = useConfirm();
  const dispatch = useAppDispatch();

  usePageTitle('Project Fields');

  useEffect(() => {
    refreshTable();
  }, []);

  const getLabelByValue = (list: any[], value) => list.find((item) => item.value === value)?.label;

  const { data: settings, loading } = useAppSelector(selectGetProjectFieldsSettings);

  const [open, setOpen] = useState(false);
  const [selectedFieldId, setSelectedFieldId] = useState<string | null>(null);

  const refreshTable = async () => {
    await dispatch(getProjectFieldsSettings()).unwrap();
  };

  const onToggle = useCallback(async (trash: boolean, rowValue: any) => {
    if (trash) {
      const ok = await confirm({
        title: 'Disabling Custom Field',
        question: 'Are you sure you want to disable this custom field?',
      });

      if (!ok) return;

      await dispatch(disableCustomField({ fieldId: rowValue._id })).unwrap();
    } else {
      await dispatch(enableCustomField({ fieldId: rowValue._id })).unwrap();
    }

    refreshTable();
  }, []);

  const onRowClick = (row: any) => {
    setSelectedFieldId(row._id);
    setOpen(true);
  };

  const onAddNewItemClick = () => {
    setSelectedFieldId(null);
    setOpen(true);
  };

  const getProjectSectionLabel = (section: string) => {
    return projectSections.find((item) => item.value === section)?.label;
  };

  const getProjectStageLabel = (stage: string) => {
    return projectStages.find((item) => item.value === stage)?.label;
  };

  const columns = useMemo(() => {
    return [
      {
        Header: 'Section',
        accessor: 'section',
        width: 180,
        Cell: ({ value, row }: any) => {
          const label = getProjectSectionLabel(value);
          if (row.original.isGroup === true) {
            return <b className='font-weight-bold'>{label}</b>;
          }

          if(row.original.controlType === 'DIVIDER')
            return <b className='font-weight-bold'>&nbsp;&nbsp;-&nbsp;&nbsp;{row.original.name}</b>;

          return null;
        },
        searchable: true,
      },
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ value, row }: any) => {
          if (row.original.controlType === 'DIVIDER'){
            return <>-</>;
          }
          return (
           <>{value}</>
          );
        },
        width: 200,
        searchable: true,
      },
      {
        Header: 'Short Name',
        accessor: 'shortName',
        width: 150,
        searchable: true,
        Cell: ({ value, row }: any) => {
          if (row.original.controlType === 'DIVIDER'){
            return <>-</>
          }
          return (
           <>{value}</>
          );
        },
      },
      {
        Header: 'Stages',
        accessor: 'stages',
        width: 200,
        Cell: ({ value, row }: any) => {
          if (row.original.isGroup) return null;

          return (
            <div className='text-truncate'>
              {value?.map((stage) => getProjectStageLabel(stage)).join(', ')}
            </div>
          );
        },
        searchable: true,
        getSearchValue: (value) => {
          return value?.map((stage) => getProjectStageLabel(stage)).join(', ');
        },
      },
      {
        Header: 'Type',
        accessor: 'controlType',
        width: 100,
        Cell: ({ value }: any) => {
          return <div>{getLabelByValue(inputTypeOptions, value)}</div>;
        },
        searchable: true,
        getSearchValue: (value) => {
          return getLabelByValue(inputTypeOptions, value);
        },
      },
      {
        Header: 'Definition',
        accessor: 'definition',
        width: 300,
        Cell: ({ value }: any) => {
          return <div className='text-truncate'>{value}</div>;
        },
        searchable: true,
      },
    ];
  }, []);

  const onDragEnd = async (result: any) => {
    const { destination, item } = result;

    await dispatch(
      reorderProjectCustomFields({
        itemId: item._id,
        section: destination.droppableId,
        index: destination.index,
      })
    ).unwrap();

    await refreshTable();
  };

  const groupedRows = useMemo(() => {
    const groups = projectSections.map((section) => section.value);

    return groups.map((group) => {
      const groupData = settings?.filter((item) => item.section === group);

      return {
        section: group,
        isGroup: true,
        subRows: groupData,
      };
    });
  }, [settings, projectSections]);

  const getActiveItems = (rows: any[]) => {
    const newRows = _.cloneDeep(rows);

    newRows.forEach((row) => {
      row.subRows = row.subRows.filter((subRow) => !subRow.trash);
    });

    return newRows;
  };

  return (
    <>
      <SettingsCodesLayoutNext
        tableTitle='Project Fields'
        columns={columns}
        rows={groupedRows}
        loading={loading}
        onToggle={onToggle}
        onRowClick={onRowClick}
        onAddNewItemClick={onAddNewItemClick}
        onDragEnd={onDragEnd}
        getSubRows={(row) => row.subRows}
        getActiveItems={getActiveItems}
      />

      <EditProjectFieldModal
        open={open}
        onClose={() => setOpen(false)}
        onAfterSubmit={refreshTable}
        selectedFieldId={selectedFieldId}
      />
    </>
  );
};
