import React, { useMemo } from 'react';
import { Select } from 'antd';

const { Option } = Select;

interface SelectOption {
  value: string;
  label?: string;
}

interface SelectColumnFilterProps {
  column: {
    filterValue: any;
    setFilter: (value: any) => void;
    preFilteredRows: any[];
    id: string;
  };
  options?: SelectOption[];
}

export const SelectColumnFilter: React.FC<SelectColumnFilterProps> = ({
  column: { filterValue, setFilter, preFilteredRows, id },
  options: providedOptions
}) => {
  const options = useMemo(() => {
    if (providedOptions) {
      return providedOptions.map(option => ({
        value: option.value,
        label: option.label || option.value
      }));
    }

    const optionsSet = new Set<string>();
    preFilteredRows.forEach((row) => {
      const rowVals = row.values[id];
      if (Array.isArray(rowVals)) {
        rowVals.forEach(val => {
          const optionValue = val?.Label ?? val;
          if (optionValue) optionsSet.add(String(optionValue));
        });
      } else if (rowVals) {
        optionsSet.add(String(rowVals));
      }
    });

    return Array.from(optionsSet).sort().map(value => ({ value, label: value }));
  }, [id, preFilteredRows, providedOptions]);

  return (
    <Select
      style={{ width: '100%' }}
      value={filterValue}
      onChange={(value) => setFilter(value || undefined)}
      allowClear
      className="no-print"
    >
      <Option value="">All</Option>
      {options.map((option, i) => (
        <Option key={i} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};