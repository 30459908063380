import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../shared/hooks/use-app-dispatch';
import {
  getReportDisplayingData,
  //getReportForm,
  getReportTableColumns,
} from '../../modules/settings';
import { useSubmit } from '../../shared/hooks/use-submit';
import { usePageTitle } from '../../layouts/title-context';
import CustomTable, { customSortByKey } from '../../components/Common/CustomTable';
import { AddProjectModal } from '../../modals/add-project-modal';
import { BidModal } from '../../modals/bid-modal/bid-modal';
import { ManageContractModal } from '../../modals/manage-contract-modal';
import { Tooltip } from 'antd';
import { formatBidDueDate, formatDate } from '../../utils/date-formatters';
import formattedPhone from '../../shared/formatted-phone';
import { isAllowed, moduleConstants, appConstants } from '../../_constants';
import { getAllJobScopeAction } from '../../actions/jobScope';
import { getAllJobStatusAction } from '../../actions/jobStatusCode';
import { configureFilters } from './helpers/configure-filters';
import { configureAggregator } from './helpers/configure-aggregator';
import { AddLeadModal } from '../../modals/add-lead-modal';
import NotePopUp from '../../components/Common/NotePopUp';
import ProjectStatusModal from '../../components/Common/ProjectStatusModal.jsx';
import { ProjectStatusModalProps } from '../../components/Common/ProjectStatusModalProps.d';
import { getEventById } from '../../modules/schedule';
import { SchedulePopUp } from '../../shared/schedule-pop-up/schedule-pop-up';
import moment from 'moment';
import { PromoteToBiddingModal } from '../../modals/promote-to-bidding-modal';
import { isEmpty } from 'lodash';
import AddContactModal from '../../modals/add-contact-modal';
import AddCompanyModal from '../../modals/add-company-modal/add-company-modal';
import ReactLoading from 'react-loading';

export const ReportViewPage = (props: any) => {
  const { reportId_params } = useParams<{ reportId_params: string }>();
  const dispatch = useAppDispatch();

  const isWidget = props?.isWidget;

  const reportId = (props?.reportId ?? '')?.length > 0 ? props.reportId : reportId_params;

  const [reportSettings, setReportSettings] = useState<any>(null);
  usePageTitle(reportSettings?.name || 'Report View');

  const projectStatusModalRef = useRef<ProjectStatusModalProps>();

  const [eventData, setEventData] = useState<string>('');
  const [editProjectId, setEditProjectId] = useState<string>('');
  const [editObjectId, setEditObjectId] = useState<string>('');
  const [editBidId, setEditBidId] = useState<string | undefined>('');
  const [isAddProjectModalOpen, setIsAddProjectModalOpen] = useState<boolean>(false);
  const [isAddLeadModalOpen, setIsAddLeadModalOpen] = useState<boolean>(false);
  const [isAddContactModalOpen, setIsAddContactModalOpen] = useState<boolean>(false);
  const [isAddCompanyModalOpen, setIsAddCompanyModalOpen] = useState<boolean>(false);
  const [isBidManagementModalOpen, setIsBidManagementModalOpen] = useState<boolean>(false);
  const [isNoteModalOpen, setIsNoteModalOpen] = useState<boolean>(false);
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState<boolean>(false);
  const [selectedNote, setSelectedNote] = useState<string>('');
  const [columns, setColumns] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [userId] = useState<string>(localStorage.getItem('userId') || '');
  const addProjectModalRef = useRef<{ getProjectData: () => void }>({
    getProjectData: () => undefined,
  });

  const [reportItemType, setReportItemType] = useState<string>('');

  useEffect(() => {
    if (reportSettings) {
      setReportItemType(reportSettings?.reportType);
    }
  }, [reportSettings]);

  const [promotionData, setPromotionData] = useState<any>({});

  const [statusModal, setStatusModal] = useState(false);
  const [statusUpdateType, setStatusModalUpdateType] = useState('');
  const [isManageContractModalOpen, setIsManageContractModalOpen] = useState<boolean>(false);
  const [isPromoteToBiddingProcess, setIsPromoteToBiddingProcess] = useState<boolean>(false);

  const toggleStatusUpdate = (updateType, shouldUpdate = true) => {
    if (!statusModal) {
      setStatusModalUpdateType(updateType);
    } else {
      setStatusModalUpdateType('');
      //projectStatusModalRef.current?.toggleStatusUpdateReset();
      if (shouldUpdate) {
        getData();
      }
    }

    setStatusModal(!statusModal);
  };

  const canEditProject = useCallback(
    (projectData: any) => {
      if (projectData) {
        const { createdBy = {}, assignments = [] } = projectData;
        const isCreator = createdBy.userId === userId;
        const canEditDetails = isAllowed(
          moduleConstants.EDITPROJDETAILS,
          isCreator ? 'anyAssigned' : 'assigned',
          assignments
        );

        return canEditDetails;
      }

      return false;
    },
    [userId]
  );

  const hasBidProject = useCallback(
    (projectData: any) => {
      if (projectData) {
        const {
          curBidId,
          lastBidId,
          jobStatusCodesCode,
          createdBy = {},
          assignments = [],
        } = projectData;
        const isCreator = createdBy.userId === userId;
        const isAllowedOpenDialog = isAllowed(
          moduleConstants.EBAGP,
          isCreator ? 'anyAssigned' : 'assigned',
          assignments
        );
        const isProjectStatus = !['L', 'NP'].includes(jobStatusCodesCode);

        if ((curBidId || lastBidId) && isProjectStatus && isAllowedOpenDialog) {
          return true;
        }
      }

      return false;
    },
    [userId]
  );

  const handleProjectRoles = (value, column) => {
    if (!value?.length) {
      return <div className='text-center'>-</div>;
    }

    const { showCode } = column;

    return (
      <div className={showCode ? 'text-center' : 'text-start'}>
        {value?.map((item: any) => {
          const {
            isAccountAffiliation,
            contactId,
            companyId,
            companyName,
            contactFirstName,
            contactMiddleName,
            contactLastName,
            emailArr,
            phoneArr,
          } = item;

          const primaryEmail = emailArr?.find((email) => email.isPrimary);
          const primaryPhone = phoneArr?.find((phone) => phone.isPrimary);

          let fullName = `${contactFirstName || ''} ${contactLastName || ''}`;

          if (contactMiddleName) {
            fullName = `${contactFirstName || ''} ${contactMiddleName?.[0] || ''}. ${
              contactLastName || ''
            }`;
          }

          fullName = fullName.trim();

          let fullNameCellValue = fullName;
          let companyNameCellValue = companyName;
          let phoneCellValue =
            primaryPhone && !isAccountAffiliation ? (
              <a
                href={`tel:${primaryPhone.fullPhone}${primaryPhone.extPhone ? `,${primaryPhone.extPhone}` : ''}`}
              >
                {formattedPhone({ phoneObject: primaryPhone })}
              </a>
            ) : (
              ''
            );

          if (showCode) {
            fullNameCellValue =
              `${contactFirstName?.[0] || ''}${contactLastName?.[0] || ''}` || '-';
            companyNameCellValue = companyName?.[0] || '-';
            phoneCellValue = '';
          }

          const renderTooltip = () => {
            let tooltipHeader = (
              <div>
                <span>{companyName}</span> - <span>{fullName}</span>
              </div>
            );

            if (!companyId && contactId)
              tooltipHeader = (
                <div>
                  <span>{fullName}</span>
                </div>
              );

            if (companyId && !contactId)
              tooltipHeader = (
                <div>
                  <span>{companyName}</span>
                </div>
              );

            return (
              <div>
                <div>{tooltipHeader}</div>
                {primaryEmail?.email && (
                  <div>
                    <b>{primaryEmail.label || 'Email'}</b>: <span>{primaryEmail.email}</span>
                  </div>
                )}
                {primaryPhone && (
                  <div>
                    <b>{primaryPhone.type || 'Phone'}</b>:{' '}
                    <span>{formattedPhone({ phoneObject: primaryPhone })}</span>
                  </div>
                )}
              </div>
            );
          };

          if (contactId && companyId && !isAccountAffiliation) {
            return (
              <Tooltip title={renderTooltip()} key={item}>
                <div key={item}>
                  <a href={`/company-details/${companyId}`}>{companyNameCellValue}</a> -{' '}
                  <a href={`/contact-details/${contactId}`}>{fullNameCellValue}</a>
                </div>
                <div>{phoneCellValue}</div>
              </Tooltip>
            );
          }

          if (contactId && (!companyId || isAccountAffiliation)) {
            return (
              <Tooltip title={renderTooltip()} key={item}>
                <div>
                  <a href={`/contact-details/${contactId}`}>{fullNameCellValue}</a>
                  <div>{phoneCellValue}</div>
                </div>
              </Tooltip>
            );
          }

          if (!contactId && companyId) {
            return (
              <Tooltip title={renderTooltip()} key={item}>
                <div key={item}>
                  <a href={`/company-details/${companyId}`}>{companyNameCellValue}</a>
                </div>
              </Tooltip>
            );
          }

          return <div key={item}>-</div>;
        })}
      </div>
    );
  };

  const handleCustomFields = (value, column) => {
    const { customFieldDefinition } = column;
    const { valueDefinition } = customFieldDefinition;

    if (valueDefinition.type === 'boolean') {
      return <div className='text-center'>{value?.value ? '✔' : '-'}</div>;
    }

    if (!value) {
      return <div className='text-center'>-</div>;
    }

    if (customFieldDefinition.controlType === 'RATE') {
      const maxRate = valueDefinition.count;
      const rate = value?.value || 0;

      return (
        <div className='text-end'>
          {rate} / {maxRate}
        </div>
      );
    }

    if (customFieldDefinition.controlType === 'NUMBER_INPUT') {
      const prefix = valueDefinition.prefix || '';
      const postfix = valueDefinition.postfix || '';
      const result = value.value || '0';

      return (
        <div className='text-end'>
          {prefix} {result}
          {postfix}
        </div>
      );
    }

    if (['SINGLE_SELECT', 'RADIO'].includes(customFieldDefinition.controlType)) {
      const foundOptions = valueDefinition.options.find((option) => option.value === value.value);

      if (!foundOptions && !value.value) {
        return <div className='text-center'>-</div>;
      } else if (!foundOptions && value.value) {
        return <div className='text-center'>{value.value}</div>;
      }

      const { label, labelField, codeField } = foundOptions;

      let tooltipText = label;

      if (labelField && codeField) {
        tooltipText = `${labelField} (${codeField})`;
      }

      if (valueDefinition.options) {
        return (
          <Tooltip title={tooltipText}>
            <div className='text-center'>{label}</div>
          </Tooltip>
        );
      }

      return <div className='text-center'>{value.value}</div>;
    }

    if (customFieldDefinition.controlType === 'MULTI_SELECT') {
      const values = value.value || [];

      if (valueDefinition.options) {
        return (
          <div className='text-start'>
            {values.map((item: any) => {
              const foundOptions = valueDefinition.options.find((option) => option.value === item);

              if (!foundOptions && !item) {
                return <div key={item}>-</div>;
              } else if (!foundOptions && item) {
                return <div key={item}>{item}</div>;
              }

              const { label, labelField, codeField } = foundOptions;

              let tooltipText = label;

              if (labelField && codeField) {
                tooltipText = `${labelField} (${codeField})`;
              }

              return (
                <Tooltip title={tooltipText} key={item}>
                  <div>{label}</div>
                </Tooltip>
              );
            })}
          </div>
        );
      }

      return values.map((item: any) => (
        <div key={item} className='text-center'>
          {item}
        </div>
      ));
    }

    if (valueDefinition.type === 'date') {
      if (!value.value) {
        return <div className='text-center'>-</div>;
      }

      let formattedDate = formatDate(value.value);

      if (valueDefinition.allowTime) {
        formattedDate = formatBidDueDate(value.value);
      }

      return <div className='text-center'>{formattedDate}</div>;
    }

    if (valueDefinition.isUrl) {
      const label = value.value;
      const url = value.url;

      if (!label && !url) {
        return <div className='text-center'>-</div>;
      }

      return (
        <div className='text-start'>
          <a href={url}>{label}</a>
        </div>
      );
    }

    return <div className='text-start'>{value.value}</div>;
  };

  const handleNoteClick = (noteId: string, typeId: string) => {
    setSelectedNote(noteId);
    setEditObjectId(typeId);
    setIsNoteModalOpen(true);
  };

  const handleEventClick = async (id: string) => {
    dispatch(getEventById({ id }))
      .unwrap()
      .then((data: any) => {
        setEventData(data);
        setIsScheduleModalOpen(true);
      });
  };

  const handlePredefinedFields = (value, column) => {
    if (column.type === 'event') {
      if (!value) {
        return <div className='reportNote text-center'>-</div>;
      }

      const { title, startDate, _id } = value;
      const date = formatBidDueDate(startDate);

      return (
        <div className='text-truncate' onClick={() => handleEventClick(_id)}>
          <i className='fa-regular fa-calendar'></i>&nbsp;{title}
          <br />
          <i className='fa-regular fa-clock'></i>&nbsp;{date}
        </div>
      );
    }

    if (column.type === 'note') {
      if (!value) {
        return <div className='reportNote text-center'>-</div>;
      }

      const { _id, description, typeId } = value;

      return (
        <div
          className='reportNote text-truncate'
          onClick={handleNoteClick.bind(this, _id, typeId)}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      );
    }

    if (column.type === 'contact') {
      if (!value) {
        return <div className='text-center'>-</div>;
      }

      const { _id, firstName, middleName, lastName } = value;

      let fullName = `${firstName} ${lastName}`;

      if (middleName) {
        fullName = `${firstName} ${middleName[0]}. ${lastName}`;
      }

      fullName = fullName.trim();

      return <a href={`/contact-details/${_id}`}>{fullName}</a>;
    }

    if (column.type === 'address') {
      if (!value) {
        return <div className='text-start'>-</div>;
      }

      const { address1, address2, city, state, zip } = value;

      if (!address1 && !address2 && !city && !state && !zip) {
        return <div className='text-start'>-</div>;
      }

      const address = [address1, address2, city, state, zip]
        .map((item) => item?.trim())
        .filter(Boolean)
        .join(', ');

      return <a href={`https://maps.google.com/?q=${address}`}>{address}</a>;
    }

    if (column.type === 'boolean') {
      return <div className='text-center'>{value ? 'Yes' : 'No'}</div>;
    }

    if (!value) {
      return <div className='text-center'>-</div>;
    }

    if (column.type === 'array') {
      if (column.value === 'phoneArr') {
        return (
          <div className='text-start'>
            {value.map((phone: any) => (
              <div key={phone.fullPhone}>
                <a href={`tel:${phone.fullPhone}${phone.extPhone ? `,${phone.extPhone}` : ''}`}>
                  {formattedPhone({ phoneObject: phone })}
                </a>
              </div>
            ))}
          </div>
        );
      } else if (column.value === 'emailArr') {
        return (
          <div className='text-start'>
            {value.map((email: any) => (
              <div key={email.email}>
                <a href={`mailto:${email.email}`}>{email.email}</a>
              </div>
            ))}
          </div>
        );
      } else if (column.value === 'addressArr') {
        return (
          <div className='text-start'>
            {value.map((address: any) => {
              const formattedAddressLink = [
                address.address1,
                address.address2,
                address.city,
                address.state,
                address.zip,
              ]
                .map((item) => item?.trim())
                .filter(Boolean)
                .join(', ');

              return (
                <div key={address.address1}>
                  <a href={`https://maps.google.com/?q=${formattedAddressLink}`}>
                    {formattedAddressLink}
                  </a>
                </div>
              );
            })}
          </div>
        );
      } else if (column.value === 'contactAffiliations') {
        return (
          <div className='text-start'>
            {value.map((affiliation: any) => (
              <div key={affiliation._id}>
                <a href={`/company-details/${affiliation.companyId}`}>
                  {affiliation.companyName.label}
                </a>
              </div>
            ))}
          </div>
        );
      } else if (column.options) {
        return (
          <div className='text-start'>
            {value?.map((item: any) => {
              const foundOptions = column.options?.find((option) => option.value === item);

              if (!foundOptions) {
                return <div key={item}>-</div>;
              }

              const { label, labelField, codeField } = foundOptions;

              let tooltipText = label;

              if (labelField && codeField) {
                tooltipText = `${labelField} (${codeField})`;
              }

              return (
                <Tooltip title={tooltipText} key={item}>
                  <div>{label}</div>
                </Tooltip>
              );
            })}
          </div>
        );
      }

      return value?.map((item: any) => (
        <div key={item} className='text-center'>
          {item}
        </div>
      ));
    }

    if (column.type === 'date') {
      if (column?.valueDefinition?.allowTime) {
        return <div className='text-center'>{formatBidDueDate(value)}</div>;
      } else {
        return <div className='text-center'>{formatDate(value)}</div>;
      }
    }

    if (column.type === 'number') {
      if (column?.valueDefinition?.isCurrency) {
        return (
          <div className='text-end'>
            {value.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: 0,
            })}
          </div>
        );
      } else {
        return <div className='text-end'>{value}</div>;
      }
    }

    if (column.isUrl) {
      if (typeof value === 'string') return <div className='text-start'>{value}</div>;
      else {
        return <a href={value.value}>{value.label}</a>;
      }
    }

    if (column.options) {
      const foundOptions = column.options.find((option) => option.value === value);

      if (!foundOptions) {
        return <div className='text-center'>-</div>;
      }

      const { label, labelField, codeField } = foundOptions;

      let tooltipText = label;

      if (labelField && codeField) {
        tooltipText = `${labelField} (${codeField})`;
      }

      return (
        <Tooltip title={tooltipText}>
          <div className='text-center'>{label}</div>
        </Tooltip>
      );
    }

    return <div className='text-start'>{value}</div>;
  };

  const onAddNoteClick = (objectId: string) => {
    setSelectedNote('');
    setPromotionData({});
    if (reportItemType === 'Bid' || reportItemType === 'Project') {
      //setEditProjectId(objectId);
      setEditObjectId(objectId);
    } else if (reportItemType === 'contact') {
      setEditObjectId(objectId);
    } else if (reportItemType === 'company') {
      setEditObjectId(objectId);
    } else {
      setEditObjectId(objectId);
    }
    setIsNoteModalOpen(true);
  };

  const [isPromoteLeadProcess, setIsPromoteLeadProcess] = useState<boolean>(false);

  const onPromoteLeadClick = (projectId: string) => {
    setEditProjectId(projectId);
    setPromotionData({});
    setIsPromoteLeadProcess(true);
  };

  useEffect(() => {
    addProjectModalRef.current.getProjectData();
  }, [editProjectId]);

  const getManageProjectControls = (row: any, settings: any) => {
    const isBid = settings.reportType === 'bid';
    const isContact = settings.reportType === 'contact';
    const isCompany = settings.reportType === 'company';
    const isProject = settings.reportType === 'project';

    const canEdit = canEditProject(row.original);
    const hasBid = isBid || hasBidProject(row.original);

    const projectId = !isBid ? row.original?._id : row.original?.projectId;
    const objectId = isBid ? row.original?.projectId : row.original?._id;
    const bidId = isBid ? row.original?._id : row.original?.curBidId;
    const isLead = ['L', 'NP'].includes(row.original?.jobStatusCodesCode);
    const hasOriginalContract = !!row.original?.baselineStartDate;
    const actions = !isWidget ? settings.actions ?? [] : [];
    const isEditProjectEnabled = (isProject || isBid) && actions.includes('editProject');
    const isEditContactEnabled = isContact && actions.includes('editContact');
    const isEditCompanyEnabled = isCompany && actions.includes('editCompany');
    const isEditBidEnabled = actions.includes('editBid');
    const isUpdateProgressEnabled = actions.includes('updateProgress');
    const isAddNoteEnabled = actions.includes('addNote');
    const isPromoteLeadEnabled = actions.includes('promoteLead');

    const contactId = isContact ? row.original?.contactId : undefined;
    const companyId = isCompany
      ? row.original?._id
      : isContact
        ? row.original?.companyId
        : undefined;

    return (
      <div className='no-print d-flex gap-2'>
        {canEdit && isEditProjectEnabled && (
          <Tooltip title='Edit Project'>
            <span
              onClick={() => onEditProjectButtonClick(projectId, isLead)}
              className='rowControl'
            >
              <i className='fa fa-pencil' aria-hidden='true'></i>
            </span>
          </Tooltip>
        )}
        {canEdit && isEditContactEnabled && (
          <Tooltip title='Edit Contact'>
            <span onClick={() => onEditContactButtonClick(contactId)} className='rowControl'>
              <i className='fa fa-pencil' aria-hidden='true'></i>
            </span>
          </Tooltip>
        )}
        {canEdit && isEditCompanyEnabled && (
          <Tooltip title='Edit Company'>
            <span onClick={() => onEditCompanyButtonClick(companyId)} className='rowControl'>
              <i className='fa fa-pencil' aria-hidden='true'></i>
            </span>
          </Tooltip>
        )}
        {canEdit && isLead && isPromoteLeadEnabled && (
          <Tooltip title='Promote to bidding process'>
            <span onClick={() => onPromoteLeadClick(projectId)} className='rowControl'>
              <i className='fa fa-arrow-up' aria-hidden='true'></i>
            </span>
          </Tooltip>
        )}

        {canEdit && isAddNoteEnabled && (
          <Tooltip title='Add Note'>
            <span onClick={() => onAddNoteClick(objectId)} className='rowControl'>
              <i className='fa fa-sticky-note' aria-hidden='true'></i>
            </span>
          </Tooltip>
        )}
        {hasBid && isEditBidEnabled && (
          <Tooltip title='View bid'>
            <span onClick={() => onOpenBidModalClick(projectId, bidId)} className='rowControl'>
              <i className='fa fa-clock' aria-hidden='true'></i>
            </span>
          </Tooltip>
        )}
        {hasOriginalContract && isUpdateProgressEnabled && (
          <Tooltip title='Update progress'>
            <span onClick={() => onOpenUpdateProgressClick(projectId)} className='rowControl'>
              <i className='fa fa-bar-chart' aria-hidden='true'></i>
            </span>
          </Tooltip>
        )}
      </div>
    );
  };

  const configureAccessor = (row, column) => {
    if (column.value.includes('.')) {
      const [first, ...rest] = column.value.split('.');
      const value = row[first];
      return configureAccessor(value, { ...column, value: rest.join('.') });
    }

    const initialValue = row?.[column.value];

    if (!initialValue) return null;

    if (column.type === 'projectRole') {
      return initialValue
        ?.map((item) => {
          const { companyName, contactFirstName, contactMiddleName, contactLastName } = item;
          let fullName = `${contactFirstName || ''} ${contactLastName || ''}`;
          if (contactMiddleName) {
            fullName = `${contactFirstName || ''} ${contactMiddleName[0]}. ${
              contactLastName || ''
            }`;
          }
          fullName = fullName.trim();

          if (item.contactId && item.companyId) {
            return `${companyName} - ${fullName}`;
          }

          if (item.contactId && !item.companyId) {
            return fullName;
          }

          if (!item.contactId && item.companyId) {
            return companyName;
          }

          return '';
        })
        .join(', ');
    }

    if (column.type === 'array') {
      if (column.value === 'phoneArr') {
        return initialValue.map((phone: any) => phone.fullPhone).join(', ');
      } else if (column.value === 'emailArr') {
        return initialValue.map((email: any) => email.email).join(', ');
      } else if (column.value === 'addressArr') {
        return initialValue
          .map((address: any) =>
            [address.address1, address.address2, address.city, address.state, address.zip]
              .filter(Boolean)
              .join(', ')
          )
          .join(' :');
      } else if (column.value === 'contactAffiliations') {
        return initialValue.map((affiliation: any) => affiliation.companyName.label).join(', ');
      } else {
        return initialValue.join(', ');
      }
    }

    if (column.type === 'customField') {
      if (column.customFieldDefinition?.valueDefinition?.hasDataSrc) {
        const values = Array.isArray(initialValue?.value)
          ? initialValue?.value
          : [initialValue?.value];

        return values
          .map((value) => {
            const foundOptions = column.customFieldDefinition?.valueDefinition?.options?.find(
              (option) => option.value === value
            );

            if (!foundOptions) {
              return value;
            }

            return foundOptions.codeField;
          })
          .join(', ');
      }

      if (column.customFieldDefinition?.valueDefinition?.type === 'date') {
        if (!initialValue) return null;

        return new Date(initialValue?.value);
      }

      if (column.customFieldDefinition?.valueDefinition?.type === 'number') {
        if (!initialValue) return null;

        return Number(initialValue?.value);
      }

      if (column.customFieldDefinition?.valueDefinition?.isUrl) {
        return initialValue?.url;
      }

      return initialValue?.value;
    }

    if (column.type === 'contact') {
      if (!initialValue) return null;

      const { firstName, middleName, lastName } = initialValue;

      let fullName = `${firstName || ''} ${lastName || ''}`;

      if (middleName) {
        fullName = `${firstName || ''} ${middleName[0]}. ${lastName || ''}`;
      }

      fullName = fullName.trim();

      return fullName;
    }

    if (column.type === 'address') {
      if (!initialValue) return null;

      const { address1, address2, city, state, zip } = initialValue;

      if (!address1 && !address2 && !city && !state && !zip) {
        return null;
      }

      return [address1, address2, city, state, zip].filter((item) => item).join(', ');
    }

    if (column.type === 'date') {
      if (!initialValue) return null;

      return moment(initialValue).toDate();
    }

    if (Array.isArray(column.options)) {
      const option = column.options?.find((item) => item.value === initialValue);

      return option?.label;
    }

    if (column.type === 'string' && column.isUrl) {
      if (typeof initialValue === 'string') return initialValue;
      else return initialValue?.label;
    }

    return initialValue;
  };

  const canGroupBy = (column) => {
    if (column.type === 'date') {
      return false;
    }

    if (column.type === 'customField') {
      if (column.customFieldDefinition?.valueDefinition?.type === 'date') {
        return false;
      }
    }

    return true;
  };

  const configureColumns = (data: any) => {
    const { columns, isShowAdditionalColumns, actions, showAggregateRow } = data;

    const newColumns = columns
      .filter((column: any) => (isShowAdditionalColumns ? true : !column.isHidden))
      .map((column: any) => ({
        Header: column.label,
        LongHeader: column.tooltip,
        ...configureFilters(column),
        id: column.value,
        ...configureAggregator(column),
        accessor: (row) => configureAccessor(row, column),
        canGroupBy: canGroupBy(column),
        sortType:
          column.type === 'date' ||
          (column.type === 'customField' &&
            column.customFieldDefinition.valueDefinition.type === 'date')
            ? customSortByKey
            : customSortByKey,
        hidden: column.isHidden,
        Cell: ({ row }: any) => {
          let originalData = row.original;

          if (row.isGrouped && row.subRows.length > 0) {
            originalData = row.subRows[0].original;
          }

          let lookupVal = column.value;
          if (lookupVal.includes('.')) {
            const [first, ...rest] = lookupVal.split('.');
            originalData = originalData?.[first];
            lookupVal = rest.join('.');
          }
          const value = originalData?.[lookupVal];

          if (column.type === 'projectRole') {
            return handleProjectRoles(value, column);
          }

          if (column.type === 'customField') {
            return handleCustomFields(value, column);
          }

          return handlePredefinedFields(value, column);
        },
      }));

    if (!isWidget && showAggregateRow) {
      const aggregateColumn = {
        Header: 'aggregateColumn',
        id: 'aggregateColumn',
        accessor: () => '',
        className: 'no-print',
        hidden: true,
        alwaysHidden: true,
        canGroupBy: true,
        defaultGrouped: true,
        width: 0,
        Cell: () => '',
      };

      newColumns.unshift(aggregateColumn);
    }

    if (!isWidget && Array.isArray(actions) && actions.length > 0) {
      const controlColumn = {
        id: 'action',
        Header: 'Action',
        accessor: 'Action',
        className: 'no-print',
        hidden: false,
        canHide: false,
        canSortBy: false,
        canGroupBy: false,
        width: 30,
        Cell: ({ row }: any) => getManageProjectControls(row, data),
      };

      newColumns.push(controlColumn);
    }

    setColumns(newColumns);
  };

  const [getReportSettings, settingsLoading] = useSubmit(
    async () =>
      dispatch(getReportTableColumns({ reportId, isWidget }))
        .unwrap()
        .then((data) => {
          setReportSettings(data);

          configureColumns(data);
        }),
    [reportId]
  );

  const [getData, dataLoading] = useSubmit(
    async () =>
      dispatch(getReportDisplayingData({ reportId, isWidget }))
        .unwrap()
        .then((data) => {
          setData(data);
        }),
    [reportId]
  );

  const reportNotFound = !settingsLoading && !reportSettings;

  useEffect(() => {
    dispatch(getAllJobScopeAction({ className: 'jobScopes' }));
    dispatch(getAllJobStatusAction({ className: 'jobStatusCodes' }));
  }, []);

  useEffect(() => {
    getReportSettings();
    getData();
  }, [getReportSettings, getData]);

  useEffect(() => {
    if (editProjectId?.length > 0 && !isAddProjectModalOpen && !isEmpty(promotionData)) {
      setIsAddProjectModalOpen(true);
      setIsPromoteLeadProcess(false);
    }
  }, [isPromoteLeadProcess, promotionData]);

  const [printContainer, setPrintContainer] = useState<any>(null);
  const printLogo = localStorage.getItem('companyLogo') || appConstants.CONTENT_CPOST_LOGO_URL;

  const onEditProjectButtonClick = useCallback((objectId: string, isLead: boolean) => {
    setEditProjectId(objectId);
    isLead ? setIsAddLeadModalOpen(true) : setIsAddProjectModalOpen(true);
  }, []);

  const onEditContactButtonClick = useCallback((objectId: string) => {
    setEditObjectId(objectId);
    setIsAddContactModalOpen(true);
  }, []);

  const onEditCompanyButtonClick = useCallback((objectId: string) => {
    setEditObjectId(objectId);
    setIsAddCompanyModalOpen(true);
  }, []);

  const onOpenBidModalClick = useCallback((projectId: string, bidId?: string) => {
    if (projectId) {
      setEditProjectId(projectId);
      if (bidId) {
        setEditBidId(bidId);
      } else {
        setEditBidId(undefined);
      }
      setIsBidManagementModalOpen(true);
    }
  }, []);

  const onOpenUpdateProgressClick = useCallback((projectId: string) => {
    if (projectId) {
      setEditProjectId(projectId);
      setStatusModalUpdateType('UpdateWIP');

      //projectStatusModalRef.current?.toggleStatusUpdateReset();
      projectStatusModalRef.current?.toggleModal();
    }
  }, []);

  const onEditSubmit = useCallback(async () => {
    getData();
  }, [getData]);

  const onBidManagementModalSubmit = useCallback(() => {
    addProjectModalRef.current.getProjectData();
    getData();
  }, []);

  if (reportNotFound) {
    return <div>Report not found</div>;
  }

  if (settingsLoading) {
    return (
      <ReactLoading
        className='table-loader'
        type={appConstants.LOADER_TYPE as any}
        color={appConstants.LOADER_COLOR}
        height={appConstants.LOADER_HEIGHT}
        width={appConstants.LOADER_WIDTH}
      />
    );
  }

  const ProjectStatusModalComponent: React.ComponentType<ProjectStatusModalProps> =
    ProjectStatusModal;

  const preferredColumns = JSON.parse(
    localStorage.getItem(`reportPreferences/${reportId}`) || '[]'
  );

  const onSavePreferences = (preferences) => {
    preferences.reportId = reportId;
    localStorage.setItem(`reportPreferences/${reportId}`, JSON.stringify(preferences));
  };

  const onNextProjectStatusModalClick = () => {
    projectStatusModalRef.current?.toggleModal();
    setIsManageContractModalOpen(true);
  };

  const onSubmitManageContract = (currentProjectData) => {
    //if (this.state.isPromoteToActiveProcess) {
    projectStatusModalRef.current?.submitUpdateProject({}, currentProjectData, 'Approved');
    //}
  };

  const onAfterPromoteToBidding = () => {
    setEditBidId(undefined);
    setIsBidManagementModalOpen(true);
    setIsPromoteToBiddingProcess(true);
  };

  const onToggleBidModal = () => {
    setIsBidManagementModalOpen(false);
    setIsPromoteToBiddingProcess(false);
  };

  return (
    <div>
      <div className='px-3'>
        <div ref={(el) => setPrintContainer(el)} className='table-responsive reportTableSection'>
          <div className='pdfLogoBox'>
            <div className='reportHead'>
              <div className='logo'>
                <img src={printLogo} alt='Upload Logo' />
              </div>
              <h2>{reportSettings?.name}</h2>
            </div>
          </div>
          <CustomTable
            isLoading={dataLoading}
            customTableTitle={!isWidget ? reportSettings?.name : ''}
            list={data}
            columns={columns}
            componentRef={printContainer}
            canShowDateFilters
            initialSorted={reportSettings?.sortBy}
            initialExpanded={true}
            canGroupBy={!isWidget}
            canSort={!isWidget}
            canFilter={!isWidget}
            canShowHeaderItems={!isWidget}
            preferences={preferredColumns}
            onSavePreferences={onSavePreferences}
            reportId={reportSettings?._id ?? ''}
            rowProps={(rowInfo) => {
              if (rowInfo && rowInfo.original?.highlightColor) {
                return {
                  className: 'highlightedRow',
                  style: {
                    backgroundColor: rowInfo.original.highlightColor ?? 'yellow',
                    color: rowInfo.original.highlightTextColor ?? 'black',
                    'a:active': { color: rowInfo.original.highlightTextColor ?? 'black' },
                    a: { color: rowInfo.original.highlightTextColor ?? 'black' }, // Set link color here
                  },
                };
              } else {
                return {};
              }
            }}
          />
          <PromoteToBiddingModal
            open={isPromoteLeadProcess}
            onClose={() => {
              setIsPromoteLeadProcess(false);
            }}
            projectId={editProjectId}
            onSubmit={(promotionDataLocal) => {
              setPromotionData(promotionDataLocal);
              //setIsAddProjectModalOpen(true);
            }}
          />
          <AddProjectModal
            open={isAddProjectModalOpen}
            showSaveAndNext={false}
            onClose={() => setIsAddProjectModalOpen(false)}
            projectId={editProjectId}
            onSubmit={onEditSubmit}
            onManageBid={() => onOpenBidModalClick(editProjectId)}
            promotionData={isEmpty(promotionData) ? null : promotionData}
            ref={addProjectModalRef}
          />

          <AddContactModal
            open={isAddContactModalOpen}
            onClose={() => setIsAddContactModalOpen(false)}
            contact={{ contactId: editObjectId }}
            onSubmit={onEditSubmit}
            isAddToCompany={false}
            isQuickAdd={false}
          />

          <AddCompanyModal
            open={isAddCompanyModalOpen}
            onClose={() => setIsAddCompanyModalOpen(false)}
            companyId={editObjectId as any}
            onCompanyUpdate={onEditSubmit}
            isQuickAdd={false}
          />

          <AddLeadModal
            open={isAddLeadModalOpen}
            showSaveAndNext={false}
            onClose={() => setIsAddLeadModalOpen(false)}
            projectId={editProjectId}
            onSubmit={onEditSubmit}
          />
          <BidModal
            isOpen={isBidManagementModalOpen}
            toggle={onToggleBidModal}
            toggleStatusUpdate={(updateType) => toggleStatusUpdate(updateType)}
            onSubmit={onBidManagementModalSubmit}
            projectId={editProjectId}
            bidId={editBidId}
            showSaveSubmit={!isAddProjectModalOpen}
            isPromote={isPromoteToBiddingProcess}
          />
          <NotePopUp
            isOpen={isNoteModalOpen}
            onClick={() => {
              setIsNoteModalOpen(false);
              setSelectedNote('');
              setEditObjectId('');
            }}
            modalClassName={'noteModal'}
            modalTitle={'Note details'}
            noteInfo={{
              objectId: selectedNote?.length > 0 ? selectedNote : null,
              description: null,
              typeId: editObjectId,
              typeRef:
                reportItemType === 'project' || reportItemType === 'bid'
                  ? 'PROJECT'
                  : reportItemType.toUpperCase(),
            }}
          />

          <ManageContractModal
            isOpen={isManageContractModalOpen}
            projectId={editProjectId}
            toggle={() => {
              setIsManageContractModalOpen(false);
              projectStatusModalRef.current?.toggleStatusUpdateReset();
            }}
            onSubmit={(projectData) => onSubmitManageContract(projectData)}
            //onSuggestProgress={(changes) => this.onSuggestProgress(changes)}
          />
          <SchedulePopUp
            isOpen={isScheduleModalOpen}
            data={eventData}
            toggle={() => setIsScheduleModalOpen(false)}
          />
          <ProjectStatusModalComponent
            projectId={editProjectId}
            modalStatusUpdate={statusModal}
            initialUpdateType={statusUpdateType}
            toggle={toggleStatusUpdate}
            onNext={onNextProjectStatusModalClick}
            onAfterPromoteToBidding={onAfterPromoteToBidding}
            onAfterSubmit={onEditSubmit}
            childRef={(ref) => (projectStatusModalRef.current = ref)}
          />
        </div>
      </div>
    </div>
  );
};
